import './tasklisthorizontal.css'

import React, { useEffect, useState } from 'react'

import abTestingIconGreen from '../../images/ab_testing_icon_green.svg'
import contractViewIcon from '../../images/contract_view_icon.svg'
import horizontalScrollLeftIconGreen from '../../images/horizontal_scroll_left_icon_green.svg'
import horizontalScrollRightIconGreen from '../../images/horizontal_scroll_right_icon_green.svg'
import imageIconGreen from '../../images/image_icon/image_icon_green.svg'
import pauseRecordingIconGreen from '../../images/pause_recording_icon_green.svg'
import ratingIconGreen from '../../images/rating_icon_green.svg'
import tolIconGreen from '../../images/tol_icon_green.svg'
import urlIconGreen from '../../images/url_icon_green.svg'
import videoIconGreen from '../../images/video_icon_green.svg'
import { onClickCommentTime } from '../video/CommentEventHandlers'

/**
 *
 * @param {*} props
 */
const TaskListHorizontal = (props) => {
    const { taskHover, setTaskHover, videoRef, tester, setPlaybackOptionsVisible } = props

    const [isScrollRightButtonVisible, setIsScrollRightButtonVisible] = useState(false)
    const [isScrollLeftButtonVisible, setIsScrollLeftButtonVisible] = useState(false)
    const [toolTipContent, setToolTipContent] = useState({
        selectedItem: null,
        selectedContent: null
    })
    const [titleWidth, setTitleWidth] = useState(0)

    /**
     * Function to make right scroll button appear only when overflow occurs
     */
    useEffect(() => {
        if (window.innerWidth > 1439 && props.tasks.length < 3) {
            setIsScrollRightButtonVisible(false)
        } else if (window.innerWidth <= 1439 && props.tasks.length < 2) {
            setIsScrollRightButtonVisible(false)
        } else {
            setIsScrollRightButtonVisible(true)
        }
    }, [props.tasks.length])

    /**
     * Callback function called when user clicks on manual scrolling button.
     */
    const onClickScrollIcon = (direction) => {
        const containerElt = document.getElementById('taskListScrollContainer')
        const maxWidth = containerElt.scrollWidth - containerElt.clientWidth
        const scrollLeft = containerElt.scrollLeft
        const divisor = Math.floor(scrollLeft / 227)
        const remainder = scrollLeft % 227
        if (direction === 'right') {
            if (scrollLeft < maxWidth) {
                containerElt.scrollLeft = 227 * (remainder > 207 ? divisor + 2 : divisor + 1)
            }
        } else if (direction === 'left') {
            if (scrollLeft > 0) {
                const nextScrollLeft = 227 * Math.max(remainder === 0 ? divisor - 1 : divisor, 0)
                containerElt.scrollLeft = nextScrollLeft
            }
        }
    }

    /**
     * Return a filename without AWS and protocol
     */
    const extractFileName = (file = '') => {
        const splitStr = file.substring(0, file.indexOf('?')).split('/')
        const filename = splitStr[splitStr.length - 1]
        return filename
    }

    /**
     *
     */
    const onScrollScrollContainer = (e) => {
        const containerElt = document.getElementById('taskListScrollContainer')
        const maxWidth = containerElt.scrollWidth - containerElt.clientWidth
        const newScroll = e.target.scrollLeft
        if (newScroll >= maxWidth && isScrollRightButtonVisible) {
            setIsScrollRightButtonVisible(false)
        } else if (newScroll < maxWidth && !isScrollRightButtonVisible) {
            setIsScrollRightButtonVisible(true)
        }
        if (newScroll === 0 && isScrollLeftButtonVisible) {
            setIsScrollLeftButtonVisible(false)
        } else if (newScroll > 0 && !isScrollLeftButtonVisible) {
            setIsScrollLeftButtonVisible(true)
        }
    }

    return (
        <div className="task__list__horizontal">
            {isScrollLeftButtonVisible && (
                <img
                    alt="horizontal_scroll_icon_left"
                    className="horizontal__scroll__icon left"
                    onClick={() => onClickScrollIcon('left')}
                    src={horizontalScrollLeftIconGreen}
                />
            )}
            <div
                className="scroll__container full"
                id="taskListScrollContainer"
                onScroll={onScrollScrollContainer}
                style={{ gridTemplateColumns: `repeat(${props.tasks.length}, 207px)` }}>
                {props.tasks.map((task, idx) => (
                    <div
                        key={task.taskId}
                        onMouseLeave={() =>
                            setToolTipContent({
                                selectedItem: null,
                                selectedContent: null
                            })
                        }>
                        <div className="task__name__animation__wrapper">
                            <div
                                onMouseOver={() => {
                                    setTitleWidth(
                                        document.getElementById(
                                            'task__name__width__finder__wrapper'
                                        ).offsetWidth
                                    )
                                }}
                                className={`${titleWidth > 203 ? 'task__list__task__name__long' : 'task__list__task__name__short'}`}>
                                <span id="task__name__width__finder__wrapper">{task.name}</span>
                            </div>
                        </div>

                        <div
                            className="task__card"
                            id={`taskCard${idx}`}
                            onClick={() => {
                                if (tester) {
                                    onClickCommentTime(tester.tasks[idx].time_rel_start, videoRef)
                                }
                            }}
                            onMouseOver={() => {
                                setTaskHover(idx)
                                setPlaybackOptionsVisible(true)
                            }}
                            onMouseLeave={() => {
                                setTaskHover(null)
                                if (videoRef) {
                                    if (videoRef.current && videoRef.current.state.videoPlaying) {
                                        setPlaybackOptionsVisible(false)
                                    }
                                }
                            }}
                            style={{
                                filter: taskHover === idx ? 'brightness(0.9)' : '',
                                cursor: tester ? 'pointer' : ''
                            }}>
                            <div
                                className={`task__card__description__space ${toolTipContent.selectedItem === idx ? 'task__card__blur__ani' : ''}`}>
                                <p className="task__card__description__text">{task.description}</p>
                            </div>

                            <div className="task__icons">
                                {task.pauseRecording && (
                                    <img
                                        alt="pause_recording_icon_green"
                                        className={`task__icon media 
                                            ${toolTipContent.selectedItem === idx && toolTipContent.selectedContent === 'Recording was disabled for this task' ? '' : 'task__icon__tooltip__unselected'}
                                        `}
                                        src={pauseRecordingIconGreen}
                                        onMouseEnter={() =>
                                            setToolTipContent({
                                                selectedItem: idx,
                                                selectedContent:
                                                    'Recording was disabled for this task'
                                            })
                                        }
                                    />
                                )}

                                {task.isABTest && (
                                    <img
                                        alt="ab_icon_green"
                                        className={`task__icon ab 
                                            ${toolTipContent.selectedItem === idx && toolTipContent.selectedContent === 'This is an A/B Test' ? '' : 'task__icon__tooltip__unselected'}
                                        `}
                                        src={abTestingIconGreen}
                                        onMouseEnter={() =>
                                            setToolTipContent({
                                                selectedItem: idx,
                                                selectedContent: 'This is an A/B Test'
                                            })
                                        }
                                    />
                                )}

                                {!task.isABTest && task.url != null && (
                                    <img
                                        alt="url_icon_green"
                                        className={`task__icon media clickable
                                            ${toolTipContent.selectedItem === idx && toolTipContent.selectedContent === 'URL Destination: ' + task.url ? '' : 'task__icon__tooltip__unselected'}
                                        `}
                                        src={urlIconGreen}
                                        onClick={() =>
                                            window.open(
                                                task.url.startsWith('http')
                                                    ? task.url
                                                    : 'https://' + task.url,
                                                '_blank'
                                            )
                                        }
                                        onMouseEnter={() =>
                                            setToolTipContent({
                                                selectedItem: idx,
                                                selectedContent: `URL Destination: ${task.url}`
                                            })
                                        }
                                    />
                                )}

                                {!task.isABTest && task.image != null && (
                                    <img
                                        alt="tooltip_green"
                                        className={`task__icon media clickable
                                            ${toolTipContent.selectedItem === idx && toolTipContent.selectedContent === 'Image Source: ' + extractFileName(task.image) ? '' : 'task__icon__tooltip__unselected'}
                                        `}
                                        src={imageIconGreen}
                                        onClick={() => window.open(task.image, '_blank')}
                                        onMouseEnter={() =>
                                            setToolTipContent({
                                                selectedItem: idx,
                                                selectedContent: `Image Source: ${extractFileName(task.image)}`
                                            })
                                        }
                                    />
                                )}

                                {!task.isABTest && task.video != null && (
                                    <img
                                        alt="video_icon_green"
                                        className={`task__icon media clickable
                                            ${toolTipContent.selectedItem === idx && toolTipContent.selectedContent === 'Video Source: ' + extractFileName(task.video) ? '' : 'task__icon__tooltip__unselected'}
                                        `}
                                        src={videoIconGreen}
                                        onClick={() => window.open(task.video, '_blank')}
                                        onMouseEnter={() =>
                                            setToolTipContent({
                                                selectedItem: idx,
                                                selectedContent: `Video Source: ${extractFileName(task.video)}`
                                            })
                                        }
                                    />
                                )}

                                {task.rating && (
                                    <img
                                        alt="rating_icon_green"
                                        className={`task__icon media 
                                            ${toolTipContent.selectedItem === idx && toolTipContent.selectedContent === `Rating Instruction: ${task.ratingText}` ? '' : 'task__icon__tooltip__unselected'}
                                        `}
                                        src={ratingIconGreen}
                                        onMouseEnter={() =>
                                            setToolTipContent({
                                                selectedItem: idx,
                                                selectedContent: `Rating Instruction: ${task.ratingText}`
                                            })
                                        }
                                    />
                                )}

                                {task.tol && (
                                    <img
                                        alt="tol_icon_green"
                                        className={`task__icon  media 
                                            ${toolTipContent.selectedItem === idx && toolTipContent.selectedContent === 'Instructions given: ' + task.tolInstructions ? '' : 'task__icon__tooltip__unselected'}
                                        `}
                                        src={tolIconGreen}
                                        onMouseEnter={() =>
                                            setToolTipContent({
                                                selectedItem: idx,
                                                selectedContent: `Instructions given: ${task.tolInstructions}`
                                            })
                                        }
                                    />
                                )}
                            </div>

                            {toolTipContent.selectedItem === idx &&
                                toolTipContent.selectedContent !== null && (
                                    <div
                                        onMouseLeave={() =>
                                            setToolTipContent({
                                                selectedItem: null,
                                                selectedContent: null
                                            })
                                        }>
                                        <div className="task__card__tooltip__selector">
                                            <img
                                                className="task__card__tooltip__close"
                                                alt="close icon"
                                                src={contractViewIcon}
                                                onClick={() =>
                                                    setToolTipContent({
                                                        selectedItem: null,
                                                        selectedContent: null
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="task__card__tooltip__box">
                                            {toolTipContent.selectedContent}
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                ))}
            </div>

            {isScrollRightButtonVisible && (
                <img
                    alt="horizontal_scroll_icon_right"
                    className="horizontal__scroll__icon right"
                    onClick={() => onClickScrollIcon('right')}
                    src={horizontalScrollRightIconGreen}
                />
            )}
        </div>
    )
}

export default React.memo(TaskListHorizontal)
