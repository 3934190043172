import './taskcard.css'

import React from 'react'
import styled from 'styled-components'

import imageIconBlue from '../../images/image_icon/image_icon_blue.svg'
import linkIconBlue from '../../images/link_icon_blue.svg'
import CloseButton from '../atoms/buttons/CloseButton'
import { isValidURL } from '../utils/functions'

const Height8px = styled.div`
    height: 8px;
`

const urlTooltip = 'Add the link to any prototype or URL that you want to test.'
const imageTooltip =
    'Add an image or video that you want to test. The maximum file size is 2 MB for images and 20 MB for videos.'

const TaskCardMediaAB = (props) => {
    const {
        idx,
        onBlurTaskAttribute,
        onChangeTaskAttribute,
        onChangeTaskFile,
        task,
        taskErrors,
        windowWidth
    } = props

    const isFileError = 'file' in taskErrors && (!task.isABTest || taskErrors.file[0 || 1]) != null

    let showingUrlA = false
    let showingUrlB = false
    let showingFileA = false
    let showingFileB = false
    let mediaObjA = null
    let mediaObjB = null

    if (task) {
        showingUrlA = task.media[0].key == null || task.media[0].key === 'url'
        showingUrlB = task.media[1].key == null || task.media[1].key === 'url'
        showingFileA = task.media[0].key == null || task.media[0].key === 'file'
        showingFileB = task.media[1].key == null || task.media[1].key === 'file'
        if (task.media[0].key === 'file') {
            const value = task.media[0].value
            if (typeof value === 'string') {
                const splitStr = value.substring(0, value.indexOf('?')).split('/')
                mediaObjA = splitStr[splitStr.length - 1]
            } else {
                mediaObjA = value
            }
        } else {
            mediaObjA = task.media[0].value
        }
        if (mediaObjA == null) {
            showingUrlA = true
            showingFileA = true
        }
        if (task.media[1].key === 'file') {
            const value = task.media[1].value
            if (typeof value === 'string') {
                const splitStr = value.substring(0, value.indexOf('?')).split('/')
                mediaObjB = splitStr[splitStr.length - 1]
            } else {
                mediaObjB = value
            }
        } else {
            mediaObjB = task.media[1].value
        }
        if (mediaObjB == null) {
            showingUrlB = true
            showingFileB = true
        }
    }

    const mediaArr = [
        {
            media: 'A',
            urlCondition: showingUrlA,
            fileCondition: showingFileA,
            object: mediaObjA
        },
        {
            media: 'B',
            urlCondition: showingUrlB,
            fileCondition: showingFileB,
            object: mediaObjB
        }
    ]

    return (
        <div className="bottom__row" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
                {mediaArr.map((media, mediaIdx) => (
                    <div
                        key={`${idx + mediaIdx}`}
                        className="left__side"
                        style={{ marginRight: '4px' }}>
                        <h5>{`Media ${media.media}`}</h5>
                        <Height8px />
                        <div style={{ display: 'flex' }}>
                            {media.urlCondition &&
                                (media.object != null ? (
                                    <div className="input__wrapper url">
                                        <input
                                            className={`task__url task__attr__input ${task.media[mediaIdx].value.length > 0 && !isValidURL(task.media[mediaIdx].value) ? 'error' : ''}`}
                                            onBlur={(e) =>
                                                onBlurTaskAttribute(
                                                    idx,
                                                    'url',
                                                    e.target.value === '' ? null : e.target.value,
                                                    media.media
                                                )
                                            }
                                            onChange={(e) =>
                                                onChangeTaskAttribute(
                                                    idx,
                                                    'url',
                                                    e.target.value,
                                                    media.media
                                                )
                                            }
                                            placeholder="Specify url link"
                                            type="text"
                                            value={media.object ? media.object : ''}
                                        />
                                        <CloseButton
                                            onClick={() => {
                                                onChangeTaskAttribute(idx, 'url', null, media.media)
                                                onBlurTaskAttribute(idx, 'url', null, media.media)
                                            }}
                                            top={-15}
                                            right={0}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <button
                                            className="secondary"
                                            onClick={() => {
                                                onChangeTaskAttribute(idx, 'url', '', media.media)
                                                onBlurTaskAttribute(idx, 'url', '', media.media)
                                            }}
                                            style={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                alt="url_icon"
                                                src={linkIconBlue}
                                                style={{ height: '16px' }}
                                            />

                                            <div style={{ marginLeft: '8px', color: '#4B8CF7' }}>
                                                {windowWidth > 1240 ? 'URL' : ' '}
                                            </div>
                                        </button>
                                        <div className="media__tooltip"> {urlTooltip} </div>
                                    </div>
                                ))}

                            <div style={{ width: '8px' }} />

                            {/* task file upload button */}
                            {media.fileCondition && (
                                <div className={`${media.object ? 'input__wrapper file' : ''}`}>
                                    <button
                                        className={`secondary ${media.object ? 'task__file task__attr__input' : 'file icon__button'} ${isFileError ? 'error' : ''}`}
                                        id={`fileUploadButtonDiv${idx}${media.media}`}
                                        onClick={() => {
                                            document
                                                .getElementById(
                                                    `fileUploadButton${idx}${media.media}`
                                                )
                                                .click()
                                        }}
                                        style={{ display: 'flex', alignItems: 'center' }}>
                                        <img alt="image_icon" src={imageIconBlue} />

                                        {windowWidth > 1240 && (
                                            <div style={{ marginLeft: '8px', color: '#5995F8' }}>
                                                {'Media'}
                                            </div>
                                        )}

                                        <input
                                            className="file__upload__button"
                                            id={`fileUploadButton${idx}${media.media}`}
                                            onClick={() => {
                                                document.body.onfocus = function () {
                                                    setTimeout(() => {
                                                        document.body.onfocus = null
                                                    }, 100)
                                                }
                                            }}
                                            onChange={(e) =>
                                                onChangeTaskFile(
                                                    e,
                                                    idx,
                                                    e.target.value,
                                                    media.media
                                                )
                                            }
                                            type="file"
                                            value=""
                                        />

                                        {media.object && (
                                            <div className="text">
                                                {typeof media.object === 'string'
                                                    ? media.object
                                                    : media.object.name}
                                            </div>
                                        )}
                                    </button>
                                    <div className="media__tooltip"> {imageTooltip} </div>

                                    {(media.object || isFileError) && (
                                        <CloseButton
                                            onClick={(e) => {
                                                onChangeTaskFile(e, idx, null, media.media)
                                                onBlurTaskAttribute(idx, 'file', null, media.media)
                                            }}
                                            top={-15}
                                            right={-13}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default React.memo(TaskCardMediaAB)
