import React from 'react'
import styled from 'styled-components'

const DeletionModCont = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #00000066;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 900;
`
const InnerDeletionModCont = styled.div`
    background: white;
    border-radius: 24px;
    justify-content: center;
    margin: auto;
    position: absolute;
    padding: 2rem 2rem 1rem;
`
const IconContainer = styled.div`
    padding: 0;
    margin: 0;
`

/**
 * Component for the deletion modal, final warning before user deletes a test.
 */
const AlertModal = (props) =>
    props.isOpen && (
        <DeletionModCont className="deletion__modal">
            <InnerDeletionModCont className="inner__div">
                <IconContainer>
                    <img alt="delete_icon" src={props.icon} style={{ transform: 'scale(2)' }} />
                </IconContainer>

                <div className="modal__content">
                    <div className="header__text">{props.header}</div>
                    <div className="body__text"> {props.body} </div>
                </div>

                <div className="modal__footer">
                    <div className="modal__footer__buttons">
                        <button className="button secondary" onClick={props.onClickCancel}>
                            <div className="button__text"> Cancel</div>
                        </button>
                        <button
                            className="button primary alert_button"
                            style={{ background: props.primeColor }}
                            onClick={props.onClickPrimary}>
                            <div className="button__text"> {props.primeText} </div>
                        </button>
                    </div>
                </div>
            </InnerDeletionModCont>
        </DeletionModCont>
    )

export default AlertModal
