import { isLinkValidUrl } from '../../utilities/link'
import { isAlphanumericOnly } from '../utils/functions'

const validImageFileTypes = ['jpeg', 'jpg', 'png', 'bmp', 'gif', 'svg']

/**
 *
 * @param {*} key
 * @param {*} value
 */
export const validateScreenerAttributeValue = (key, value) => {
    let isValid = true
    let error = ''

    switch (key) {
        case 'option':
            if (value === '') {
                error = "You can't have a blank screener answer"
                isValid = false
            }
            break
        case 'text':
            if (value === '') {
                error = 'You must have a question for your custom screener'
                isValid = false
            }
            break
        default:
            break
    }

    return [isValid, error]
}

/**
 *
 * @param {*} key
 * @param {*} value
 */
export const validateSurveyAttributeValue = (key, value) => {
    let isValid = true
    let error = ''

    switch (key) {
        case 'url':
            if (!value) {
                error = 'URL is required'
                isValid = false
            } else if (!isLinkValidUrl(value)) {
                error = 'Please enter a valid URL'
                isValid = false
            }
            break
        case 'variable':
            if (!value) {
                error = 'Variable is required'
                isValid = false
            } else if (!isAlphanumericOnly(value)) {
                error = 'Your variable must contain only alpha-numeric characters'
                isValid = false
            }
            break
        default:
            break
    }

    return [isValid, error]
}

/**
 *
 * @param {*} key
 * @param {*} value
 * @param {*} tol
 */
export const validateTaskAttributeValue = (key, value, tol, allowedTestParams) => {
    let isValid = true
    let error = ''

    switch (key) {
        case 'description':
            if (!value || value === '') {
                error = 'Task description is required'
                isValid = false
            }
            break
        case 'duration':
            if (value !== '') {
                if (parseInt(value) > (allowedTestParams.max_test_duration_max || 0)) {
                    error = `Your task duration must be shorter than ${allowedTestParams.max_test_duration_max}s`
                    isValid = false
                } else if (parseInt(value) < 1) {
                    error = 'Your task duration must be longer than 1s'
                    isValid = false
                }
            }
            break
        case 'file':
            if (typeof value !== 'string' && value) {
                if (!value.type.startsWith('image') && !value.type.startsWith('video')) {
                    error = 'Invalid file type; must be either image or video file'
                    isValid = false
                } else if (value.type.startsWith('image')) {
                    const imageFileType = value.name.split('.').slice(-1)[0]
                    if (validImageFileTypes.indexOf(imageFileType) === -1) {
                        error = `Supported image file types: ${validImageFileTypes.join(', ')}`
                        isValid = false
                    } else if (value.size > 2000000) {
                        error = 'The maximum allowed image size is 2MB'
                        isValid = false
                    }
                } else if (value.type.startsWith('video')) {
                    if (!value.name.endsWith('.mp4')) {
                        error = 'We only support .mp4 video files right now'
                        isValid = false
                    } else if (value.size > 20000000) {
                        error = 'The maximum allowed video size is 20MB'
                        isValid = false
                    }
                }
            }
            break
        case 'name':
            if (!value || value === '') {
                error = 'Task name is required'
                isValid = false
            }
            break
        case 'ratingText':
            if (!value) {
                error = 'Text is required if you want to prompt the user to give a rating'
                isValid = false
            }
            break
        case 'tolInstructions':
            if (tol) {
                if (!value) {
                    error =
                        'Instructions are required if you want to use the think out-loud feature'
                    isValid = false
                }
            }
            break
        case 'url':
            if (value && !isLinkValidUrl(value)) {
                error = 'Please enter a valid URL'
                isValid = false
            }
            break
        default:
            break
    }

    return [isValid, error]
}

/**
 *
 * @param {*} key
 * @param {*} value
 * @param {*} testNames
 * @param {*} allowedTestParams
 * @param {*} tol
 */
export const validateTestAttributeValue = (key, value, testNames, allowedTestParams) => {
    let isValid = true
    let error = ''

    switch (key) {
        case 'name':
            if (!value) {
                error = 'Test name is required'
                isValid = false
            } else if (testNames.indexOf(value) !== -1) {
                error = `You already have a test named '${value}'`
                isValid = false
            }
            break
        case 'numTesters':
            if (!value) {
                error = 'Num. testers is required'
                isValid = false
            } else if (parseInt(value) < parseInt(allowedTestParams.max_num_testers_min)) {
                error = `Please select more than ${allowedTestParams.max_num_testers_min} tester(s)`
                isValid = false
            } else if (parseInt(value) > parseInt(allowedTestParams.max_num_testers_max)) {
                error = `Your payment plan allows at most ${allowedTestParams.max_num_testers_max} testers`
                isValid = false
            }
            break
        default:
            break
    }

    return [isValid, error]
}
