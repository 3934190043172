// import '../../auth/auth.scss'

import React from 'react'

import exclamationIcon from '../../../images/exclamation_icon.svg'

const TextInput = (props) => {
    const {
        label,
        error,
        onChange,
        placeholder,
        value,
        icon,
        autoComplete,
        hideHelpers,
        id,
        tabIndex,
        className,
        maxLength = 200,
        onBlur = () => null
    } = props

    const inputFieldStyle = { outline: error && '1px solid #FF6262' }
    const imgStyle = {
        position: 'absolute',
        right: 16,
        bottom: 16
    }

    const isLoginPath = window.location.pathname === '/login'

    return (
        <div className={'input__field'}>
            <div className={`input-container ${className || ''}`}>
                {icon && error && <img src={exclamationIcon} style={imgStyle} alt="exclamation" />}
                <label htmlFor={id}>{label}</label>
                <input
                    onChange={onChange}
                    placeholder={placeholder}
                    defaultValue={value}
                    style={inputFieldStyle}
                    autoComplete={autoComplete}
                    id={id}
                    tabIndex={tabIndex || 0}
                    maxLength={maxLength}
                    onBlur={onBlur}
                />
            </div>
            {error && error.length > 0 && (
                <div className={'form-validation-error'}>
                    <span className="helper-text">{error}</span>
                </div>
            )}
            {!hideHelpers && (
                <div className={'input-helpers'}>
                    {isLoginPath && (
                        <a className={'reset-link'} href="/reset" tabIndex={4}>
                            Forgot Password
                        </a>
                    )}
                </div>
            )}
        </div>
    )
}

export default TextInput
