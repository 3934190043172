import React from 'react'

import { iconColors } from './icons'

const AddTestIcon = ({ isActive = false, size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            {isActive ? (
                <>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z"
                        fill={iconColors(isActive).main}
                    />
                    <path
                        d="M12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z"
                        fill={iconColors(isActive).accent}
                    />
                </>
            ) : (
                <>
                    <path
                        d="M12.6977 9.4593C12.6977 9.07399 12.3853 8.76163 12 8.76163C11.6147 8.76163 11.3023 9.07399 11.3023 9.4593L11.3023 11.5523H9.2093C8.82399 11.5523 8.51163 11.8647 8.51163 12.25C8.51163 12.6353 8.82399 12.9477 9.2093 12.9477H11.3023V15.0407C11.3023 15.426 11.6147 15.7384 12 15.7384C12.3853 15.7384 12.6977 15.426 12.6977 15.0407L12.6977 12.9477H14.7907C15.176 12.9477 15.4884 12.6353 15.4884 12.25C15.4884 11.8647 15.176 11.5523 14.7907 11.5523H12.6977V9.4593Z"
                        fill="#7A8697"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 2.25C6.47715 2.25 2 6.72715 2 12.25C2 17.7728 6.47715 22.25 12 22.25C17.5228 22.25 22 17.7728 22 12.25C22 6.72715 17.5228 2.25 12 2.25ZM3.39535 12.25C3.39535 7.49778 7.24778 3.64535 12 3.64535C16.7522 3.64535 20.6047 7.49778 20.6047 12.25C20.6047 17.0022 16.7522 20.8547 12 20.8547C7.24778 20.8547 3.39535 17.0022 3.39535 12.25Z"
                        fill="#7A8697"
                    />
                </>
            )}
        </svg>
    )
}

export default AddTestIcon
