import React, { useState } from 'react'
import styled from 'styled-components'

import External from '../../images/external_link.svg'
import closeIcon from '../../images/icons/closeIcon.svg'
import Tick from '../../images/payment_plan_tick.svg'

// Style Shorthand
const radiusLarge = 'border-radius: 8px;'
const fullScreen = 'height: 100vh; width: 100vw;'

const container = (format) => `display: flex; justify-content: ${format};`

// Components
const DarkBackdrop = styled.div`
    position: absolute;
    top: 0;
    z-index: 150;
    ${fullScreen} ${container('center')}
    background-color: black;
    opacity: 0.5;
`
const ModalContainer = styled.div`
    position: absolute;
    top: 0;
    z-index: 200;
    ${fullScreen} ${container('center')}
    align-items: center;
`
const OuterCard = styled.div`
    width: 760px;
    background-color: white;
    ${radiusLarge};
    padding: 16px;
`
const TitleCont = styled.div`
    ${{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center'
    }}
`
const Title = styled.h5`
    ${{ fontSize: '20px', fontWeight: '700', width: '680px' }}
`
const Close = styled.h5`
    ${{ fontSize: '20px', fontWeight: '400', cursor: 'pointer' }}
`

const PlanCard = styled.div`
    border: 1px solid ${(props) => props.border};
    ${{ width: '48%', backgroundColor: '#F5F7FA', padding: '16px', borderRadius: '4px' }}
`
const PlanTitle = styled.h5`
    ${{ fontSize: '24px', fontWeight: '700', marginBottom: '16px' }}
`
const PlanSubheader = styled.p`
    ${{ color: '#585E67', marginBottom: '16px' }}
`

const ItemCont = styled.div`
    ${{
        fontSize: '14px',
        fontWeight: '400',
        display: 'flex',
        justifyContent: 'left',
        marginBottom: '8px'
    }}
`

const PlanItem = ({ copy }) => (
    <ItemCont>
        <img src={Tick} alt="tick icon" />
        <div style={{ width: '8px' }} />
        <div>{copy}</div>
    </ItemCont>
)

const Underline = styled.div`
    ${{ border: 'none', borderTop: '1px dashed #585E67', height: 0, margin: '20px 0' }}
`

const CreditsModal = (props) => {
    const [mouseOverModal, setMouseOverModal] = useState(false)

    const freePercs = ['One test: Try all the features for free']

    const professionalPercs = [
        'Launch multiple tests',
        'Invite up to 50 participants per test',
        'Test prototypes, live websites & images',
        'Screen and audio recordings',
        'Gaze and tap overlays',
        'Dashboard with eye tracking/ behavioural metrics and visualisations',
        'Dedicated customer support'
    ]

    return (
        <>
            <DarkBackdrop />
            <ModalContainer onClick={() => !mouseOverModal && props.setCreditsModalVisible(false)}>
                <OuterCard
                    onMouseOver={() => setMouseOverModal(true)}
                    onMouseLeave={() => setMouseOverModal(false)}>
                    <TitleCont>
                        <Title>
                            {
                                'Upgrade your plan to launch and share your tests with as many participants as you need'
                            }
                        </Title>
                        <Close onClick={() => props.setCreditsModalVisible(false)}>
                            <img src={closeIcon} alt="close icon" />
                        </Close>
                    </TitleCont>

                    <div style={{ height: '24px' }} />

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <PlanCard border={'#E1E2E5'}>
                            <PlanTitle>{'Free'}</PlanTitle>
                            <PlanSubheader>{'This is your current plan'}</PlanSubheader>
                            {freePercs.map((copy) => (
                                <PlanItem key={copy} copy={copy} />
                            ))}
                        </PlanCard>
                        <PlanCard border={'#4B8CF7'}>
                            <PlanTitle>{'Professional'}</PlanTitle>
                            <PlanSubheader>{'Same as the free plan plus:'}</PlanSubheader>
                            {professionalPercs.map((copy) => (
                                <PlanItem key={copy} copy={copy} />
                            ))}
                            <Underline />
                            <div style={{ display: 'flex' }}>
                                <img
                                    src={External}
                                    alt="new window icon"
                                    style={{ marginRight: '8px' }}
                                />
                                <a
                                    href="https://www.tobii.com/contact#form"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: 'black' }}>
                                    Check out price & details
                                </a>
                            </div>
                        </PlanCard>
                    </div>
                </OuterCard>
            </ModalContainer>
        </>
    )
}

export default CreditsModal
