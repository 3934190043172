import './auth.scss'

import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Userpilot } from 'userpilot'

import { navigate } from '../../navigation/navigate'
import { formatToLocalDate } from '../../utilities/date'
import CloseButton from '../atoms/buttons/CloseButton'
import PrimaryButton from '../atoms/buttons/PrimaryButton'
import SecondaryButton from '../atoms/buttons/SecondaryButton'
import { sendPostRequestNoJSON, wrapRequestInRefreshToken } from '../utils/api'

const InfoText = styled.div`
    ${{
        width: '400px',
        padding: '16px',
        backgroundColor: '#F5F7FA',
        border: '1px solid #BFD5F4',
        borderRadius: '4px'
    }}
`
const ATagRow = styled.div`
    ${{ width: '100%', textAlign: 'center' }}
`
const ATag = styled.a`
    ${{ color: 'black', textDecoration: 'underline', weight: '700' }}
`

/**
 * Component to render user account page
 */
const Billing = (props) => {
    const [plan, setPlan] = useState({ plan: '', billing: '', info: '' })
    const [ctaClicked, setCtaClicked] = useState('billing')

    const location = useLocation()

    useEffect(() => {
        Userpilot.reload()
    }, [location])

    const updateSubscription = (data) => {
        setCtaClicked('thinking')
        const userID = localStorage.getItem('userID')
        const successFunc = () => {
            setCtaClicked('sent')
        }
        const errorFunc = (err) => err.json().then((body) => console.log('get credits err, ', body))
        wrapRequestInRefreshToken(props, sendPostRequestNoJSON, [
            `subscription/${userID}/notify`,
            JSON.stringify(data),
            successFunc,
            errorFunc,
            true
        ])
    }

    useEffect(() => {
        if (!props.plan) return

        switch (props.plan) {
            case 'free':
                setPlan({
                    plan: 'Free',
                    billing: '-',
                    info: 'You have the free plan with limited features. Learn more about how our Professional plan can release all the power of  Tobii UX Explore!',
                    data: { cancel: false },
                    button: {
                        color: 'brand-blue',
                        cta: 'Upgrade'
                    }
                })
                break
            case 'trial':
                setPlan({
                    plan: 'Professional Trial',
                    billing: props.expires,
                    info: props.renews
                        ? 'This plan will automatically convert to the Professional plan (billed annually) unless canceled 30 days before the expiration date.'
                        : 'You have canceled your plan, but you can still use pro features until it expires. You can upgrade or manage your plan any time.',
                    data: props.renews
                        ? {
                              cancel: true
                          }
                        : {
                              cancel: false
                          },
                    button: props.renews
                        ? {
                              color: 'red',
                              cta: 'Cancel plan'
                          }
                        : {
                              color: 'brand-blue',
                              cta: 'Upgrade'
                          }
                })
                break
            case 'professional':
                setPlan({
                    plan: 'Professional',
                    billing: props.expires,
                    info: props.renews
                        ? 'This plan renews automatically unless canceled 30 days before the billing date.'
                        : 'You have canceled your plan, but you can still use pro features until it expires. You can manage your plan and resubscribe any time.',
                    data: props.renews
                        ? {
                              cancel: true
                          }
                        : {
                              cancel: false
                          },
                    button: props.renews
                        ? {
                              color: 'red',
                              cta: 'Cancel plan'
                          }
                        : {
                              color: 'brand-blue',
                              cta: 'Upgrade'
                          }
                })
                break
            default:
                setPlan({
                    plan: 'Free',
                    billing: '-',
                    info: 'You have the free plan with limited features. Learn more about how our Professional plan can release all the power of  Tobii UX Explore!',
                    data: { cancel: false },
                    button: {
                        color: 'brand-blue',
                        cta: 'Upgrade'
                    }
                })
        }
    }, [props.plan, props.expires, props.renews])

    return (
        <div id="accountOverview" className="account-overview">
            <div className="account-card">
                {ctaClicked === 'billing' ? (
                    <>
                        <CloseButton onClick={() => navigate('/')} />
                        <h1>Plan & Billing</h1>

                        <div className="info__row">
                            <div>{'Plan:'}</div>
                            <div>{plan.plan}</div>
                        </div>

                        <div className="info__row">
                            <div>{'Billing date:'}</div>
                            <div>{formatToLocalDate(plan.billing)}</div>
                        </div>

                        <div style={{ height: '24px' }} />

                        <InfoText>{plan.info}</InfoText>

                        <div style={{ height: '40px' }} />

                        <ATagRow>
                            <ATag
                                href="https://help.uxexplore.tobii.com/hc/en-us/articles/16521828203921-What-are-Tobii-UX-Explore-s-pricing-plans-"
                                target="_blank">
                                {'Learn more about plans'}
                            </ATag>
                        </ATagRow>

                        <div style={{ height: '40px' }} />

                        {plan.plan && (
                            <div className={'buttons-wrapper'}>
                                <SecondaryButton
                                    color={plan.button.color}
                                    onClick={() => updateSubscription(plan.data)}>
                                    {plan.button.cta}
                                </SecondaryButton>
                                <PrimaryButton onClick={() => updateSubscription({ manage: true })}>
                                    Manage plan with sales
                                </PrimaryButton>
                            </div>
                        )}
                    </>
                ) : ctaClicked === 'sent' ? (
                    <>
                        <CloseButton onClick={() => navigate('/')} />
                        <h1>Message sent</h1>
                        <div>
                            Our sales team will get in touch with you soon to manage your plan.
                        </div>
                    </>
                ) : (
                    <div>{'One moment please...'}</div>
                )}
            </div>
        </div>
    )
}

export default Billing

Billing.propTypes = {
    plan: PropTypes.string,
    expires: PropTypes.string,
    renews: PropTypes.bool
}
