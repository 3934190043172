import './createtestcontainer.scss'

import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import warningIcon from '../../images/yellow_warning_icon.svg'
import WiredButton from '../atoms/buttons/WIredButton'
import useErrorsOnSave from './custom_hooks/useErrorsOnSave'

const ErrorsBox = styled.div.attrs({ className: 'errors__box' })`
    ${{
        padding: '24px',
        marginTop: '8px',
        borderRadius: '8px',
        backgroundColor: '#FEF6E9',
        cursor: 'pointer',
        display: 'flex'
    }}
`

const SaveButton = styled.button.attrs({ className: 'secondary button save-button' })`
    ${{
        width: `${(props) => (props.savingDraft === true ? '2400px' : '180px')}`
    }}
`
const ContinueButton = styled.button.attrs({ className: 'primary button save-button' })`
    ${{ width: `${(props) => (props.stage === 'draft' ? '180px' : '120px')}` }}
`

const Spinner = styled.div.attrs({ className: 'spinner lds-ring' })`
    ${{ height: '12px', marginLeft: '8px' }}
`

const ErrorsOnSave = (props) => {
    const {
        tasks,
        errors,
        draft,
        taskErrors,
        screeners,
        testNames,
        name,
        savingDraft,
        onSaveDraftAndExit,
        onContinue,
        emailContact,
        currentPrivacyPolicy,
        activePrivacyInput,
        stage,
        onCancel
    } = props

    const [
        errorsToResolve,
        backButtonDisabled,
        testWithBadEmail,
        taskWithBadUrl,
        taskWithNoName,
        taskWithNoDescription,
        taskWithEmptyRating,
        taskWithEmptyTol,
        taskWithNoMedia,
        screenerWithNoQuestion,
        screenerWithEmptyOption,
        privacyWithBadEmail,
        privacyEmpty
    ] = useErrorsOnSave(
        name,
        taskErrors,
        screeners,
        testNames,
        currentPrivacyPolicy,
        activePrivacyInput,
        emailContact,
        tasks,
        errors
    )

    return (
        <>
            {errorsToResolve && (
                <ErrorsBox>
                    <div>
                        <img src={warningIcon} alt={'warning icon'} style={{ marginTop: '-2px' }} />
                    </div>
                    <div style={{ width: '16px' }} />
                    <div>
                        <h4>Please resolve this issues to continue:</h4>
                        <div style={{ height: '24px' }} />
                        <ul style={{ marginLeft: '12px' }}>
                            {errors.name && <li>{`${errors.name}`}</li>}
                            {!emailContact && <li>{'An email contact address is required'}</li>}
                            {testWithBadEmail && (
                                <li>{'Your email address is not formatted correctly'}</li>
                            )}
                            {tasks.length < 1 && (
                                <li>{'You must have at least one task in your list'}</li>
                            )}
                            {taskWithNoName && <li>{'Each task requires a name'}</li>}
                            {taskWithNoDescription && <li>{'Each task requires a description'}</li>}
                            {taskWithEmptyRating && (
                                <li>
                                    {
                                        'You may not leave the rating input field empty if rating is selected'
                                    }
                                </li>
                            )}
                            {taskWithEmptyTol && (
                                <li>
                                    {
                                        'You may not leave the thinking aloud input field empty if thinking aloud is selected'
                                    }
                                </li>
                            )}
                            {taskWithNoMedia && (
                                <li>
                                    {'A source of media is required for each task in your tasklist'}
                                </li>
                            )}
                            {taskWithBadUrl && (
                                <li>
                                    {
                                        'A Task Media Url is not formatted correctly. Please check the domain and that your url is secure (https://)'
                                    }
                                </li>
                            )}
                            {screenerWithNoQuestion && (
                                <li>{'Each of your Quick Survey cards must contain a question'}</li>
                            )}
                            {screenerWithEmptyOption && (
                                <li>{'You may not leave a Quick Survey answer field empty'}</li>
                            )}
                            {privacyWithBadEmail && (
                                <li>
                                    {
                                        "The link for your privacy policy is invalid. Please remember to include the entire address and 'http' or 'https'"
                                    }
                                </li>
                            )}
                            {privacyEmpty && <li>{'Each test requires a privacy policy'}</li>}
                        </ul>
                    </div>
                </ErrorsBox>
            )}

            <div className={'create-test-footer'}>
                <SaveButton
                    disabled={backButtonDisabled}
                    onClick={() => {
                        console.log('is it draft', draft)
                        if (draft) {
                            onSaveDraftAndExit()
                        }
                    }}
                    savingDraft={savingDraft}>
                    {savingDraft === true
                        ? 'Saving Draft...'
                        : draft
                          ? 'Save draft & close'
                          : 'Cancel'}
                    {savingDraft === true && (
                        <Spinner>
                            <div />
                            <div />
                            <div />
                            <div />
                        </Spinner>
                    )}
                </SaveButton>

                <ContinueButton disabled={errorsToResolve} onClick={onContinue} stage={stage}>
                    {stage === 'draft' ? 'Create & Review ' : 'Save test'}
                </ContinueButton>
                <WiredButton onPress={onCancel} isSecondary={true}>
                    Cancel
                </WiredButton>
            </div>
        </>
    )
}

export default ErrorsOnSave

ErrorsOnSave.propTypes = {
    onSaveDraftAndExit: PropTypes.func.isRequired
}
