import './barchart.css'

import * as d3 from 'd3'
import React from 'react'
import { Element } from 'react-faux-dom'

import barChartIcon from '../../images/bar_chart_icon.svg'

/* eslint-disable import/namespace */
/**
 * Component to create one yAxis (either left or right) on the BarChart.
 * @param {*} chart chart svg object to append graphical elements to.
 * @param {*} width width of the chart in pixels.
 * @param {*} side whether we are creating the left or right yAxis.
 * @param {*} metricName name of the metric on the axis.
 * @param {*} yScale d3 scale function in the Y direction.
 */
const createYAxis = (chart, width, side, metricName, yScale) => {
    // configure axis label, transform, axis & color depending on 'side'.
    let axis
    let axisTransform
    if (side === 'left') {
        axis = d3.axisLeft()
        axisTransform = 'translate(0, 0)'
    } else {
        axis = d3.axisRight()
        axisTransform = `translate(${width}, 0)`
    }

    // append y axis to the chart.
    chart
        .append('g')
        .classed(`y_axis ${metricName} ${side}`, true)
        .attr('transform', axisTransform)
        .call(axis.scale(yScale).ticks(5))
        .style('font-size', '11px')
}

/**
 * Function to do all the grunt work creating the BarChart.
 * @param {*} data data needed to plot the Barchart
 *      {
 *          data: a list of objects, each object containing 'metric,' 'index,' and 'value'
 *          metrics: a list of metric names (as strings)
 *          testers: a list of tester ids (ints).
 *      }
 * @param {*} chart svg chart element for appending elements to.
 * @param {*} width width of the chart in pixels (excluding the margins).
 * @param {*} height height of the chart in pixels (excluding the margins).
 * @param {*} config Any aesthetic configuration parameters.
 * @param {*} xAxis xAxis label as a string
 */
const plotChart = (data, chart, width, height, config, metricColours, metricNames, xAxis) => {
    const { paddingFraction } = config

    const xScale = d3
        .scaleBand()
        .domain(data.testers)
        .range([0, width])
        .paddingInner(0.15)
        .paddingOuter(0.1)

    // create x axis
    chart
        .append('g')
        .classed('x axis', true)
        .attr('transform', `translate(0, ${height})`)
        .call(d3.axisBottom().scale(xScale))
        .style('font-size', '12px')
        .append('text')
        .attr('x', width / 2)
        .attr('y', 35)
        .attr('fill', '#000')
        .style('font-size', '14px')
        .style('text-anchor', 'middle')
        .text(xAxis)

    const metrics = data.metrics
    const numMetrics = metrics.length
    data = data.data

    // create y axes (none if 0 metrics, left only if 1, + right if 2)
    const yScales = {}
    if (numMetrics > 0) {
        yScales[0] = d3
            .scaleLinear()
            .domain([
                0,
                d3.max(data, (d) => {
                    if (d.metric === metrics[0]) {
                        return d.value
                    }
                })
            ])
            .range([height, 0])
        createYAxis(chart, width, 'left', metricNames[metrics[0]], yScales[0])
        chart
            .append('g')
            .classed('gridline', true)
            .style('opacity', 0.1)
            .call(
                d3
                    .axisLeft()
                    .scale(yScales[0])
                    .tickFormat('')
                    .tickSize(-width)
                    .tickSizeOuter(0)
                    .ticks(5)
            )
        if (numMetrics === 2) {
            yScales[1] = d3
                .scaleLinear()
                .domain([
                    0,
                    d3.max(data, (d) => {
                        if (d.metric === metrics[1]) {
                            return d.value
                        }
                    })
                ])
                .range([height, 0])
            createYAxis(chart, width, 'right', metricNames[metrics[1]], yScales[1])
        }
    }

    const innerPad = paddingFraction * xScale.bandwidth()
    const pad = innerPad * (numMetrics - 1)
    const barWidth = (xScale.bandwidth() - pad) / numMetrics

    const bars = chart.selectAll().data(data).enter().append('g').classed('bar', true)

    bars.append('rect')
        .attr('class', (d) => `bar-${d.metric}`)
        .attr('id', (d, i) => `bar-${d.metric}-${i}`)
        .attr('x', (d) => xScale(d.index) + metrics.indexOf(d.metric) * (barWidth + innerPad))
        .attr('y', (d) => yScales[metrics.indexOf(d.metric)](d.value))
        .attr('height', (d) => height - yScales[metrics.indexOf(d.metric)](d.value))
        .attr('width', barWidth)
        .style('fill', (d) => metricColours[metrics.indexOf(d.metric)])
        .on('mouseenter', function (d, i, nodes) {
            const thisMetric = d.metric
            const thisMetricData = data.filter((elt) => elt.metric === thisMetric)
            const thisMetricIdx = metrics.indexOf(thisMetric)
            const thisIndex = d.index
            const thisValue = d.value

            const notThisMetricIdx = thisMetricIdx === 0 ? 1 : 0
            const notThisMetric = metrics[notThisMetricIdx]
            const notThisMetricData = data.filter((elt) => elt.metric === notThisMetric)
            const notThisMetricOpacity = 0.3

            // modify bar labels of selected metric
            d3.selectAll(`.bar-label-${thisMetric}`)
                .data(thisMetricData)
                .style('fill', 'black')
                .style('font-weight', (a) => {
                    if (a.index === thisIndex) {
                        return 'bold'
                    }
                })
                .text((a, j) => {
                    if (a.metric === thisMetric) {
                        if (a.index === thisIndex) {
                            return Math.round((a.value + Number.EPSILON) * 100) / 100
                        } else {
                            const divergence = ((100 * (a.value - thisValue)) / thisValue).toFixed(
                                1
                            )
                            let text = ''
                            if (divergence > 0) text += '+'
                            text += `${divergence}%`
                            return j !== i ? text : ''
                        }
                    }
                })

            // broaden bars of selected metric
            d3.selectAll(`.bar-${thisMetric}`)
                .data(thisMetricData)
                .transition()
                .duration(300)
                .attr(
                    'x',
                    (a) => xScale(a.index) + thisMetricIdx * (barWidth + innerPad) - innerPad
                )
                .attr('width', barWidth + innerPad * 2)

            // shrink bars of unselected metric
            d3.selectAll(`.bar-${notThisMetric}`)
                .attr('opacity', notThisMetricOpacity)
                .data(notThisMetricData)
                .transition()
                .duration(300)
                .attr(
                    'x',
                    (a) => xScale(a.index) + notThisMetricIdx * (barWidth + innerPad) + innerPad
                )
                .attr('width', barWidth - innerPad * 2)

            // add horizontal line across top of selected bar
            const y = yScales[thisMetricIdx](d.value)
            d3.select('.display')
                .append('line')
                .attr('id', 'limit')
                .attr('x1', 0)
                .attr('y1', y)
                .attr('x2', width)
                .attr('y2', y)
                .attr('stroke', 'black')
                .attr('stroke-width', '2')
                .attr('stroke-dasharray', '3 6')
                .attr('opacity', 0.5)

            // dim everything to do with unselected metric
            d3.selectAll(`.bar-label-${notThisMetric}`).attr('opacity', 0)
            d3.select(`.y.axis.${notThisMetric}`).attr('opacity', notThisMetricOpacity)
        })

        .on('mouseleave', function (d, _) {
            const thisMetric = d.metric
            const thisMetricData = data.filter((elt) => elt.metric === thisMetric)
            const thisMetricIdx = metrics.indexOf(thisMetric)
            const notThisMetricIdx = thisMetricIdx === 0 ? 1 : 0
            const notThisMetric = metrics[notThisMetricIdx]
            const notThisMetricData = data.filter((elt) => elt.metric === notThisMetric)

            // revert bar labels of selected metric
            d3.selectAll(`.bar-label-${thisMetric}`)
                .data(thisMetricData)
                .style('fill', 'black')
                .style('font-weight', 'medium')
                .text((a) => Math.round((a.value + Number.EPSILON) * 100) / 100)

            // revert size of all bars back to regular
            d3.selectAll(`.bar-${thisMetric}`)
                .data(thisMetricData)
                .transition()
                .duration(300)
                .attr('x', (a) => xScale(a.index) + thisMetricIdx * (barWidth + innerPad))
                .attr('width', barWidth)
            d3.selectAll(`.bar-${notThisMetric}`)
                .attr('opacity', 1)
                .data(notThisMetricData)
                .transition()
                .duration(300)
                .attr('x', (a) => xScale(a.index) + notThisMetricIdx * (barWidth + innerPad))
                .attr('width', barWidth)

            // delete line
            d3.select('#limit').remove()

            // revert all changes to unselected metric bar labels & axis
            d3.selectAll(`.bar-label-${notThisMetric}`).attr('opacity', 1)
            d3.select(`.y.axis.${notThisMetric}`).attr('opacity', 1)
        })

    // plot numerical bar labels (need to sort out how to handle different rounding demands...)
    bars.append('text')
        .attr('class', (d) => `bar-label-${d.metric}`)
        .attr('id', (d, i) => `bar-label-${d.metric}-${i}`)
        .attr(
            'x',
            (d) =>
                xScale(d.index) + metrics.indexOf(d.metric) * (barWidth + innerPad) + barWidth / 2
        )
        .attr('y', (d) => yScales[metrics.indexOf(d.metric)](d.value))
        .attr('dy', '1em')
        .style('fill', 'black')
        .style('font-size', barWidth > 50 ? '15px' : '12px')
        .style('text-anchor', 'middle')
        .text((d) => Math.round((d.value + Number.EPSILON) * 100) / 100)
}

/**
 * Functional component to render a BarChart using d3.
 * @param {*} props
 */
const BarChart = (props) => {
    const { chartHeight, chartWidth, data, metricColours, metricNames, xAxis } = props

    const marginFractions = {
        left: 0.1,
        right: 0.1,
        top: 0.05,
        bottom: 0.15
    }

    const margin = {
        left: parseInt(chartWidth * marginFractions.left),
        right: parseInt(chartWidth * marginFractions.right),
        top: parseInt(chartHeight * marginFractions.top),
        bottom: parseInt(chartHeight * marginFractions.bottom)
    }

    const config = {
        paddingFraction: 0.02
    }

    const el = new Element('div')
    const svg = d3
        .select(el)
        .append('svg')
        .attr('id', 'chart')
        .attr('width', chartWidth)
        .attr('height', chartHeight)

    const chart = svg
        .append('g')
        .classed('display', true)
        .attr('transform', `translate(${margin.left}, ${margin.top})`)

    const plotHeight = chartHeight - margin.top - margin.bottom
    const plotWidth = chartWidth - margin.left - margin.right
    plotChart(data, chart, plotWidth, plotHeight, config, metricColours, metricNames, xAxis)

    return (
        <div className="bar__chart" id="barChartWrapper">
            {chartHeight && chartWidth && el.toReact()}
        </div>
    )
}

/**
 * Icon visualising an empty bar chart.
 */
const EmptyBarChart = () => {
    return (
        <div className="empty__barchart">
            <div className="empty__barchart__inner">
                <img alt="bar_chart_icon" className="empty__barchart__icon" src={barChartIcon} />
                <div className="empty__barchart__text">
                    To create a plot, choose any number of testers or tasks from the table on the
                    left and up to two metrics.
                </div>
            </div>
        </div>
    )
}

/**
 * Wrapper around the D3 bar chart to manage some properties.
 */
const BarChartWrapper = (props) => {
    return (
        <div className="barchart__wrapper" id="barchartWrapper">
            {props.barchartContainerWidth &&
                (props.barChartDisabled ? (
                    <EmptyBarChart />
                ) : (
                    <BarChart
                        chartHeight={props.barchartContainerHeight}
                        chartWidth={props.barchartContainerWidth}
                        data={{
                            data: props.barChartData,
                            metrics: props.metrics,
                            testers: props.rowIndices
                        }}
                        metricColours={props.metricColours}
                        metricNames={props.metricNames}
                        xAxis={props.xAxis}
                    />
                ))}
        </div>
    )
}

export default React.memo(BarChartWrapper)
