import { createContext } from 'react'
import { useNavigate } from 'react-router-dom'

export const navigate = (path, config = null) => {
    try {
        const navigate = useNavigate()
        if (config && navigate) {
            navigate(path, config)
            return
        }

        navigate(path)
    } catch (e) {
        window.location.pathname = path
    }
}

export const NavigationContextItem = {
    NO_NAVIGATION: 'noNavigation',
    MAIN_SIDE_BAR: 'mainSideBar',
    TEST_SIDE_BAR: 'testSideBar',
    DISABLED_SIDE_BAR: 'disabledSideBar',
    DISABLED_TEST_CREATION: 'disabledTestCreation',
    CONFIRM_EXIT: 'confirmExit',
    ADMIN_PANEL: 'adminPanel',
    TEST_CREATION_LIMIT: 'testCreationLimit',
    TEST_CHANGED: 'testChanged',
    CONFIRM_TEST_EXIT: 'confirmTestExit'
}

export const NavigationContext = createContext({
    navState: NavigationContextItem.NO_NAVIGATION,
    setNavState: () => {}
})

export const activePath = () => {
    try {
        return window.location?.pathname?.split('/')[1]
    } catch (e) {
        console.log('error', e)
    }
}

export const routeAttribute = (index) => {
    try {
        return window.location?.pathname?.split('/')[index]
    } catch (e) {
        console.log('error', e)
    }
}

export const testIdFromUrl = () => {
    return window.location.pathname.split('/')[2]
}
