import React, { useEffect, useRef, useState } from 'react'

import contractViewIconGreen from '../../images/contract_view_icon_green.svg'
import SolitaireColumn from './SolitaireColumn'

/**
 * Wrapper for Solitaire column (to account for either regular tasks or AB tasks)
 * @param {} props
 * @returns
 */
const SolitaireColumnWrapper = (props) => {
    const { aggregateData, heatmapData, idx, onCloseTaskColumn, selectedTasks, task } = props

    const childrenRef = useRef(null)
    const [childrenWidth, setChildrenWidth] = useState(null)
    const [availWidth, setAvailWidth] = useState(0)
    const [spaceForImageZoom, setSpaceForImageZoom] = useState(window.innerHeight - 400)
    const [longInfoHeight, setLongInfoHeight] = useState(88)
    const [heightForInfoSpace, setHeightForInfoSpace] = useState(88)

    const abTasks = []
    if (task.isABTest) {
        abTasks.push({ ...task, url: task.media[0].value })
        abTasks.push({ ...task, url: task.media[1].value })
    }

    /**
     * Hook used to bind the width of the info section to the width of the one or two columns
     */
    useEffect(() => {
        if (childrenRef.current) {
            let width = 0
            const children = childrenRef.current.children
            for (let i = 0; i < children.length; i++) {
                const child = children[i]
                width += parseFloat(child.offsetWidth)
            }
            if (width !== childrenWidth) {
                setChildrenWidth(width)
                setAvailWidth(width)
            }
        }
    }, [childrenWidth, availWidth])

    /**
     * Window resize listener to set var for dynamic scaling of images
     */
    useEffect(() => {
        window.addEventListener('resize', () => {
            setSpaceForImageZoom(window.innerHeight - 400)
            setChildrenWidth(availWidth || 0)
        })
    }, [spaceForImageZoom, availWidth])

    /**
     * Handle column ifo that is taller than the allocated 88px
     */
    useEffect(() => {
        if (document.getElementById(`task-name-${task.taskIndex}`) === null || undefined) {
            setLongInfoHeight(88)
        } else if (
            document.getElementById(`task-description-${task.taskIndex}`) === null ||
            undefined
        ) {
            setLongInfoHeight(88)
        } else {
            const columnTitleHeight = document.getElementById(
                `task-name-${task.taskIndex}`
            ).offsetHeight
            const columnDescriptionHeight = document.getElementById(
                `task-description-${task.taskIndex}`
            ).offsetHeight

            setLongInfoHeight(columnDescriptionHeight + columnTitleHeight + 8)
        }
    }, [childrenWidth, task.taskIndex])

    const shouldRenderTask =
        (!task.isABTest && task.image) ||
        (task.isABTest && task.media[0].key === 'image' && task.media[1].key === 'image')

    return shouldRenderTask ? (
        <div
            className={`solitaire__column__wrapper ${task.isABTest ? 'column__wrapper__for__ab' : ''}`}
            style={{ height: spaceForImageZoom + 160 }}>
            <div
                onMouseEnter={() => setHeightForInfoSpace(longInfoHeight)}
                onMouseLeave={() => setHeightForInfoSpace(88)}
                className="column__info"
                style={{
                    width: childrenWidth,
                    height: heightForInfoSpace > 88 ? `${heightForInfoSpace + 8}px` : '88px'
                }}>
                <div id={`task-name-${task.taskIndex}`} className="task__name">
                    {task.name} {task.isABTest ? '(AB Test)' : ''}
                </div>
                <div id={`task-description-${task.taskIndex}`} className="task__description">
                    {task.description}
                </div>
            </div>

            {task.isABTest ? (
                <div className="ab__columns" ref={childrenRef}>
                    {abTasks[0].url in heatmapData && (
                        <SolitaireColumn
                            selectedTasks={selectedTasks}
                            aggregateData={aggregateData}
                            heatmapData={heatmapData}
                            idx={idx + 'A'}
                            spaceForImageZoom={spaceForImageZoom}
                            task={abTasks[0]}
                            taskIdx={task.taskIndex}
                            abSelector={'A'}
                        />
                    )}
                    {abTasks[0].url in heatmapData && abTasks[1].url in heatmapData && (
                        <div className="inter__dimensional__space" />
                    )}
                    {abTasks[1].url in heatmapData && (
                        <SolitaireColumn
                            selectedTasks={selectedTasks}
                            aggregateData={aggregateData}
                            heatmapData={heatmapData}
                            idx={idx + 'B'}
                            spaceForImageZoom={spaceForImageZoom}
                            task={abTasks[1]}
                            taskIdx={task.taskIndex}
                            abSelector={'B'}
                        />
                    )}
                </div>
            ) : (
                <div className="non__ab__columns" ref={childrenRef}>
                    <SolitaireColumn
                        selectedTasks={selectedTasks}
                        aggregateData={aggregateData}
                        heatmapData={heatmapData}
                        idx={idx}
                        spaceForImageZoom={spaceForImageZoom}
                        task={task}
                        taskIdx={task.taskIndex}
                    />
                </div>
            )}
            <div className="column__task__closer" onClick={() => onCloseTaskColumn(task)}>
                <img alt="close icon" className="icon" src={contractViewIconGreen} />
                <div className="text">Task {task.taskIndex + 1}</div>
            </div>
        </div>
    ) : null
}

export default React.memo(SolitaireColumnWrapper)
