/**
 * Helper function to format 'created' attribute of tester as a date/time string.
 * @param {*} time time in nanoseconds (since the big bang or something like that)
 */
export const formatCreated = (time) => {
    const date = new Date(time)
    let day = String(date.getDate())
    day = day.length >= 2 ? day : '0' + day
    let month = String(date.getMonth() + 1)
    month = month.length >= 2 ? month : '0' + month
    let hours = String(date.getHours())
    hours = hours.length >= 2 ? hours : '0' + hours
    let minutes = String(date.getMinutes())
    minutes = minutes.length >= 2 ? minutes : '0' + minutes
    return (
        day +
        '.' +
        month +
        '.' +
        date.getFullYear().toString().substring(2, 2) +
        ' ' +
        hours +
        ':' +
        minutes
    )
}

export const formatToLocalDate = (date) => {
    try {
        if (!date) {
            return null
        }
        const dateObject = new Date(date)
        return new Intl.DateTimeFormat(navigator.language, {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        }).format(dateObject)
    } catch (error) {
        return null
    }
}
