import React from 'react'

import uxExploreLogo from '@/images/uxexplore-logo.svg'

const BrandTitle = ({ isHorizontal = false, logoSize = 140 }) => {
    return (
        <div className={'brand-title-container is-vertical'}>
            <img src={uxExploreLogo} alt="icon" width={logoSize} height={logoSize} />
            <p>UX Explore</p>
        </div>
    )
}

export default BrandTitle
