import { useEffect, useState } from 'react'

let resizeTimer

const useWindowResize = () => {
    const [isNarrowScreen, setIsNarrowScreen] = useState(
        (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <
            1440
    )

    /**
     * Create window resize listener to determine which breakpoint we are in for rendering.
     */
    useEffect(() => {
        if (window) {
            window.addEventListener('resize', () => {
                clearTimeout(resizeTimer)
                resizeTimer = setTimeout(() => {
                    const newIsNarrowScreen =
                        (window.innerWidth ||
                            document.documentElement.clientWidth ||
                            document.body.clientWidth) < 1200
                    if (newIsNarrowScreen && !isNarrowScreen) {
                        setIsNarrowScreen(true)
                    } else if (!newIsNarrowScreen && isNarrowScreen) {
                        setIsNarrowScreen(false)
                    }
                }, 150)
            })
        }
    }, [isNarrowScreen])

    return {
        isNarrowScreen
    }
}

export default useWindowResize
