import './_launchmodal.scss'

import React from 'react'
import styled from 'styled-components'

import CloseButton from '../atoms/buttons/CloseButton'
import LinkButton from '../atoms/buttons/LinkButton'
import PrimaryButton from '../atoms/buttons/PrimaryButton'
import ForewardArrow from '../atoms/icons/ForewardArrow'
import UsersIcon from '../atoms/icons/UsersIcon'

const ModCont = styled.div.attrs({ className: 'close__modal' })`
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #00000066;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    overscroll-behavior: none;
    backdrop-filter: blur(5px);
`
const InnerModCont = styled.div.attrs({ className: 'inner__div' })`
    border-radius: 10px;
    box-shadow: 0px 4px 6px -5px rgba(0, 0, 0, 0.15);
    justify-content: center;
    width: 720px;
    padding: 24px;
`

const TitleCont = styled.div`
    ${{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
`
const Title = styled.h5`
    ${{ fontSize: '20px', fontWeight: '700' }}
`
const InfoCont = styled.div`
    ${{
        width: '100%',
        background: 'white',
        borderRadius: '4px',
        padding: '8px',
        margin: '24px 0px'
    }}
`
const InfoRow = styled.div`
    ${{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
`

const TextCont = styled.div`
    ${{ display: 'flex', alignItems: 'center' }}
`

const ActionCont = styled.div`
    ${{
        width: '100%',
        display: 'flex',
        justifyContent: 'right',
        gap: 20,
        alignItems: 'center'
    }}
`
const LaunchModal = ({ onClickCancel, onClickClose, launchModalOpen, currTesters, usersIcon }) => {
    if (launchModalOpen) {
        document.body.style.overflow = 'hidden'
    } else {
        document.body.style.overflow = 'auto'
    }

    return (
        launchModalOpen && (
            <ModCont>
                <InnerModCont style={{ background: 'white' }}>
                    <TitleCont>
                        <Title>
                            {'You are about to launch your test and enter the “Live” stage!'}
                        </Title>
                        <CloseButton onClick={() => onClickCancel()} />
                    </TitleCont>

                    <InfoCont>
                        <InfoRow>
                            <div className={'data-content-info'}>
                                <UsersIcon size={20} />
                                <span
                                    className={
                                        'amount'
                                    }>{`${currTesters > 0 ? currTesters : 3}`}</span>
                                <span>{'Pilot tests'}</span>
                            </div>
                            <div style={{ width: '16px' }} />
                            <TextCont>
                                <p className={'info-text'}>
                                    Use this stage to run 3 pilot tests with early participants for
                                    free, and edit it until you get it right.
                                </p>
                            </TextCont>
                        </InfoRow>

                        <div style={{ height: '16px' }} />

                        <InfoRow>
                            <div className={'data-content-info delete-info'}>
                                <UsersIcon size={22} color={'#932F40'} />
                                <span className={'amount'}>0</span>
                                <ForewardArrow color={'#932F40'} />
                                <span>{'Data deleted'}</span>
                            </div>
                            <TextCont>
                                <p className={'info-text'}>
                                    When you edit, all collected data and participants will be
                                    eliminated, so that you keep reviewing your test, but only with
                                    the last changes.
                                </p>
                            </TextCont>
                        </InfoRow>
                    </InfoCont>

                    <ActionCont>
                        <LinkButton
                            isUnderline={true}
                            target={'_blank'}
                            href="https://help.uxexplore.tobii.com/hc/en-us/articles/16520407553937">
                            Learn more about the “Live” stage
                        </LinkButton>
                        <PrimaryButton onClick={() => onClickClose()} title={'OK, continue'} />
                    </ActionCont>
                </InnerModCont>
            </ModCont>
        )
    )
}

export default LaunchModal
