import 'react-step-progress-bar/styles.css'
import './videocontainer.css'

import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Userpilot } from 'userpilot'

import flagIconBlue from '../../images/flag_icon_blue.svg'
import flagIconGreen from '../../images/flag_icon_green.svg'
import flagIconRed from '../../images/flag_icon_red.svg'
import TaskListHorizontal from '../dashboard/TaskListHorizontal'
import { getTesterData, sendGetRequestByID, wrapRequestInRefreshToken } from '../utils/api'
import { getComparator, objectSnakeToCamelCase, stableSort } from '../utils/functions'
import LoaderSpinner from '../utils/LoaderSpinner'
import CommentSection from './CommentSection'
import Progress from './Progress'
import Video from './Video'
import VideoSettings from './VideoSettings'

// settings for video overlay visualisation
const videoSettings = {
    gazePointFill: 'black',
    gazePointRadius: 0.25, // in cm
    gazePointTimeWindow: 0.2,
    heatmapTimeWindow: 0.2,
    heatmapColorScheme: 'jet',
    portholeBackgroundOpacity: 0.75,
    portholeRadius: 1, // in cm
    portholeTimeWindow: 0.2,
    swipeFill: 'yellow',
    swipeRadius: 0.3, // in cm
    swipeTimeWindow: 0.4,
    tapFill: 'yellow',
    tapRadius: 0.5, // in cm
    tapTimeWindow: 0.4 // time window for wich to render a tap
}

// flag color map (from indices to flag icons)
const flagMap = {
    1: flagIconRed,
    2: flagIconGreen,
    3: flagIconBlue
}

/** TODO: Clear formatting issues and enable eslint */
/* eslint-disable */

/**
 * Converts time in seconds to time in minutes and seconds (rounded to the
 * accuracy of the HTML5 video component).
 * @param {*} seconds
 */
const secondsToMinutesAndSeconds = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const minutesPadded = `${minutes}`

    const secondsRemaining = parseInt(seconds % 60)
    let secondsPadded = `${secondsRemaining}`
    if (secondsPadded.length === 1) {
        secondsPadded = '0' + secondsPadded
    }

    return `${minutesPadded}:${secondsPadded}`
}

let resizeTimer

/**
 * Component for video dashboard (including progress bar, playback rate control, & note taking functionality)
 * @param {*} props
 */
const VideoContainerPage = (props) => {
    // TODO: need to pass these values here once we have better state management
    const locationState = props.location.state
    const [test, setTest] = useState(
        locationState && locationState.test ? locationState.test : null
    )
    const [testerIDs, setTesterIDs] = useState(
        locationState && locationState.testerIDs ? locationState.testerIDs : null
    )
    const [testerID, setTesterID] = useState(
        locationState && locationState.testerIDs ? locationState.testerIDs[0].id : null
    )

    // data from server
    const [tester, setTester] = useState(null)
    const [videoData, setVideoData] = useState(null)
    const [videoURL, setVideoURL] = useState(null)
    const [comments, setComments] = useState(null)

    const [showingTesterDropdown, setShowingTesterDropdown] = useState(false)

    // commenting function
    const [commentActionMenuOpen, setCommentActionMenuOpen] = useState(null)
    const [commentBeingEdited, setCommentBeingEdited] = useState(-1)
    const [currEditingCommentText, setCurrEditingCommentText] = useState('')
    const [newCommentText, setNewCommentText] = useState('')
    const [commenting, setCommenting] = useState(false)

    // overlay state
    const [gazeOverlayDropdownOption, setGazeOverlayDropdownOption] = useState('heatmap')
    const [gazeOverlayIsSelected, setGazeOverlayIsSelected] = useState(true)
    const [showingGazeOverlayDropdown, setShowingGazeOverlayDropdown] = useState(false)
    const [tapOverlayIsSelected, setTapOverlayIsSelected] = useState(true)

    // playback state
    const [videoDataLoaded, setVideoDataLoaded] = useState(false)
    const [showingPlaybackRateDropdown, setShowingPlaybackRateDropdown] = useState(false)
    const [videoPlaybackRate, setVideoPlaybackRate] = useState(1)
    const [currentTimeOfVideo, setCurrentTimeOfVideo] = useState(0)
    const [playbackOptionsVisible, setPlaybackOptionsVisible] = useState(true)

    // dynamic dimensions
    const [videoWidth, setVideoWidth] = useState(350)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    // top level for video to task/comment items
    const [pixelHover, setPixelHover] = useState(null)
    const [commentHover, setCommentHover] = useState(null)
    const [taskHover, setTaskHover] = useState(null)

    // refs
    const videoRef = useRef(null)
    const newCommentRef = useRef(null)

    const manufacturer = tester && tester.device.manufacturer

    const location = useLocation()

    React.useEffect(() => {
        Userpilot.reload()
    }, [location])

    /**
     * Listen to window height the make video replay size dynamic
     */
    useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowHeight(window.innerHeight)
            setWindowWidth(window.innerWidth)
        })
    }, [props, test])

    /**
     * Load test object if this component wasn't already passed it (by the dashboard)
     */
    useEffect(() => {
        sessionStorage.removeItem('draftID')
        let isMounted = true
        if (test == null) {
            const testID = props.location.pathname.substring(
                props.location.pathname.lastIndexOf('/') + 1
            )
            const successFunc = (json) => {
                // sort tasks (need to blend AB tasks --> a single task)
                const loadedTasks = json.tasks.length > 0 ? objectSnakeToCamelCase(json.tasks) : []
                const taskIndices = [...new Set(loadedTasks.map((task) => task.taskIndex))]
                const sortedLoadedTasks = []
                for (const i in taskIndices) {
                    const taskIndex = taskIndices[i]
                    const thisIndexTasks = loadedTasks.filter(
                        (task) => task.taskIndex === taskIndex
                    )
                    if (thisIndexTasks.length === 1) {
                        sortedLoadedTasks.push({ ...thisIndexTasks[0], isABTest: false })
                    } else {
                        sortedLoadedTasks.push({ ...thisIndexTasks[0], isABTest: true }) // copy A task only (don't need to show media differences)
                    }
                }

                const newTest = json
                newTest.sortedTasks = sortedLoadedTasks
                if (isMounted) setTest(newTest)
            }
            const errorFunc = (err) => err.json().then((body) => console.log(body))
            wrapRequestInRefreshToken(props, sendGetRequestByID, [
                'test',
                testID,
                successFunc,
                errorFunc
            ])
        }
        return () => {
            isMounted = false
        }
    }, [props, test])

    /**
     * Load tester IDs for all testers in the given test if this component wasn't already passed them
     */
    useEffect(() => {
        let isMounted = true
        if (test != null) {
            if (testerIDs == null) {
                const testID = props.location.pathname.substring(
                    props.location.pathname.lastIndexOf('/') + 1
                )
                const successFunc = (json) => {
                    const testers = stableSort(json, getComparator('asc', 'id'))
                    const testerIDs = testers.map((tester) => {
                        return {
                            testSpecificTesterID: tester.test_specific_tester_id,
                            id: tester.id
                        }
                    })
                    if (isMounted) {
                        setTesterIDs(testerIDs)
                        setTesterID(testerIDs[0].id)
                    }
                }
                const errorFunc = (err) => err.json().then((body) => console.log(body))
                const url = process.env.REACT_APP_URL + `tester/?test_id=${testID}`
                wrapRequestInRefreshToken(props, getTesterData, [url, [], successFunc, errorFunc])
            }
        }

        return () => {
            isMounted = false
        }
    }, [props, test, testerIDs])

    /**
     * Load all data for the currently-viewed tester every time this changes
     */
    useEffect(() => {
        let isMounted = true
        if (testerID != null) {
            const successFunc = (json) => {
                // organise new tester & tester video data
                const newTester = json.tester
                newTester.tasks = JSON.parse(newTester.tasks)
                newTester.testID = newTester.test_id.id
                const newVideoData = json.video_data
                newVideoData.fixationDuration = JSON.parse(newVideoData.fixation_data)
                newVideoData.picData = JSON.parse(newVideoData.pic_data)
                newVideoData.touchData = JSON.parse(newVideoData.touch_data)

                // update videoURL + reset video component to load new video
                const newVideoURL = json.video_url

                if (videoRef.current != null) {
                    videoRef.current.updateTester()
                    videoRef.current.updateVideoURL(newVideoURL)
                    if (manufacturer === 'Apple') {
                        videoRef.current.updateDataIOS(
                            newVideoData,
                            newTester.device,
                            newTester.duration
                        )
                    } else {
                        videoRef.current.updateDataAndroid(
                            newVideoData,
                            newTester.device,
                            newTester.duration
                        )
                    }
                }

                // console.log(newVideoData.touchData.map(t => {return [t.time_rel, t.x, t.y]}))
                // console.log(newVideoData.picData.map(t => {return [t.time_rel, t.c_x, t.c_y]}))

                // set everything
                if (isMounted) {
                    setVideoURL(newVideoURL)
                    setTester(newTester)
                    setVideoData(newVideoData)
                    setComments(stableSort(json.video_comments, getComparator('asc', 'time')))
                }
            }
            const errorFunc = (err) => err.json().then((body) => console.log(body))
            wrapRequestInRefreshToken(props, sendGetRequestByID, [
                'alltestervideodata',
                testerID,
                successFunc,
                errorFunc
            ])
        }

        return () => {
            isMounted = false
        }
    }, [props, testerID, manufacturer])

    /**
     * Create window resize listener to changed video size as needed.
     */
    useEffect(() => {
        window.addEventListener('resize', () => {
            clearTimeout(resizeTimer)
            resizeTimer = setTimeout(() => {
                if (window.innerWidth < 1024 && videoWidth === 350) {
                    setVideoWidth(300)
                } else if (window.innerWidth >= 1024 && videoWidth === 300) {
                    setVideoWidth(350)
                }
            }, 150)
        })
    }, [videoWidth])

    /**
     * Close everything that needs to be closed when the user presses the escape key.
     */
    useEffect(() => {
        document.addEventListener(
            'keydown',
            (event) => {
                if (event.key === 'Escape') {
                    if (showingGazeOverlayDropdown) {
                        setShowingGazeOverlayDropdown(false)
                    }
                    if (showingPlaybackRateDropdown) {
                        setShowingPlaybackRateDropdown(false)
                    }
                    if (commentActionMenuOpen) {
                        setCommentActionMenuOpen(null)
                    }
                }
            },
            false
        )
    }, [commentActionMenuOpen, showingGazeOverlayDropdown, showingPlaybackRateDropdown])

    /**
     * Every time we change the comment we are editing, fill the text & position focus & cursor of new editable comment box.
     */
    useEffect(() => {
        if (comments && commentBeingEdited !== -1) {
            const elt = document.getElementById(`commentText${commentBeingEdited}`)
            elt.textContent = comments[commentBeingEdited].text // have to have comments have fixed height otherwise annoying visual glitch

            // put cursor at end of content
            elt.focus()
            document.execCommand('selectAll', false, null)
            document.getSelection().collapseToEnd()
        }
    }, [comments, commentBeingEdited])

    // compute stuff needed for tester dropdown
    let testSpecificTesterID
    let testerDropdownMenuOptions
    if (testerIDs && testerID) {
        testerDropdownMenuOptions = testerIDs.map(({ testSpecificTesterID, id }) => {
            return { value: id, text: `Participant ${testSpecificTesterID + 1}` }
        })
        testSpecificTesterID = testerIDs.find((elt) => elt.id === testerID).testSpecificTesterID
    }

    return (
        <div className="video__wrapper">
            {tester && videoData && videoURL && comments ? (
                <div
                    className="video__container"
                    id="videoContainer"
                    style={
                        windowWidth > 1400
                            ? { display: 'flex', justifyContent: 'space-between' }
                            : null
                    }>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: windowWidth > 1400 ? '49%' : '100%',
                            height: 'calc(100vh - 72px)'
                        }}>
                        <div
                            onMouseEnter={() => setPlaybackOptionsVisible(true)}
                            onMouseLeave={() => {
                                if (videoRef.current && videoRef.current.state.videoPlaying) {
                                    setPlaybackOptionsVisible(false)
                                }
                            }}
                            className="black__background"
                            style={{
                                background: 'black',
                                width: '600px',
                                display: 'flex',
                                justifyContent: 'center',
                                overflow: 'hidden'
                            }}>
                            <div
                                className="video__section"
                                style={{
                                    zoom: `calc(${windowHeight} / 920)`,
                                    opacity: videoDataLoaded ? '1' : '0',
                                    transitionDuration: videoDataLoaded ? '1s' : '0s'
                                }}>
                                <Video
                                    key={testSpecificTesterID}
                                    data={videoData}
                                    deviceParams={tester.device}
                                    duration={tester.duration}
                                    gazeOverlay={
                                        gazeOverlayIsSelected ? gazeOverlayDropdownOption : 'None'
                                    }
                                    ref={videoRef}
                                    settings={videoSettings}
                                    tapOverlay={tapOverlayIsSelected ? 'touch' : 'none'}
                                    tasks={tester.tasks}
                                    videoURL={videoURL}
                                    width={videoWidth}
                                    windowHeight={windowHeight}
                                    testSpecificTesterID={testSpecificTesterID}
                                    setCurrentTimeOfVideo={setCurrentTimeOfVideo}
                                    commenting={commenting}
                                    setCommenting={setCommenting}
                                    playbackOptionsVisible={playbackOptionsVisible}
                                    setVideoDataLoaded={setVideoDataLoaded}
                                />
                                <Progress
                                    tester={tester}
                                    videoWidth={videoWidth}
                                    videoRef={videoRef}
                                    comments={comments}
                                    pixelHover={pixelHover}
                                    setPixelHover={setPixelHover}
                                    commentHover={commentHover}
                                    setCommentHover={setCommentHover}
                                    taskHover={taskHover}
                                    setTaskHover={setTaskHover}
                                    currentTimeOfVideo={currentTimeOfVideo}
                                    playbackOptionsVisible={playbackOptionsVisible}
                                />
                            </div>
                        </div>

                        {/**
                         * Video Loading Spinner
                         */}
                        {!videoDataLoaded && (
                            <div
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    display: 'flex',
                                    top: '24px',
                                    left: '24px',
                                    opacity: '0.8'
                                }}>
                                <div className="spinner lds-ring">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div style={{ color: 'white', marginTop: '6px' }}>
                                    loading video...
                                </div>
                            </div>
                        )}

                        <div style={{ width: '8px' }} />

                        <VideoSettings
                            tester={tester}
                            testerIDs={testerIDs}
                            testerID={testerID}
                            testerDropdownMenuOptions={testerDropdownMenuOptions}
                            setShowingTesterDropdown={setShowingTesterDropdown}
                            showingTesterDropdown={showingTesterDropdown}
                            testSpecificTesterID={testSpecificTesterID}
                            showingPlaybackRateDropdown={showingPlaybackRateDropdown}
                            setShowingPlaybackRateDropdown={setShowingPlaybackRateDropdown}
                            videoPlaybackRate={videoPlaybackRate}
                            setTesterID={setTesterID}
                            tapOverlayIsSelected={tapOverlayIsSelected}
                            setTapOverlayIsSelected={setTapOverlayIsSelected}
                            videoRef={videoRef}
                            gazeOverlayIsSelected={gazeOverlayIsSelected}
                            setGazeOverlayIsSelected={setGazeOverlayIsSelected}
                            gazeOverlayDropdownOption={gazeOverlayDropdownOption}
                            setShowingGazeOverlayDropdown={setShowingGazeOverlayDropdown}
                            showingGazeOverlayDropdown={showingGazeOverlayDropdown}
                            setGazeOverlayDropdownOption={setGazeOverlayDropdownOption}
                            setVideoPlaybackRate={setVideoPlaybackRate}
                            windowHeight={windowHeight}
                            setVideoDataLoaded={setVideoDataLoaded}
                            progressBar={props.progressBar}
                            setProgressBar={props.setProgressBar}
                        />
                    </div>

                    <div
                        className="right__section"
                        style={{ width: windowWidth > 1400 ? '49%' : '100%' }}>
                        <div
                            className="tasks__section"
                            style={{
                                width: windowWidth > 1140 ? '200%' : '100%',
                                height: 'calc(30vh - 32px)',
                                marginTop: '2px',
                                paddingTop: '8px'
                            }}>
                            <TaskListHorizontal
                                tasks={test.sortedTasks}
                                taskHover={taskHover}
                                setTaskHover={setTaskHover}
                                videoRef={videoRef}
                                tester={tester}
                                setPlaybackOptionsVisible={setPlaybackOptionsVisible}
                            />
                        </div>

                        <div>
                            <CommentSection
                                comments={comments}
                                setComments={setComments}
                                commenting={commenting}
                                setCommenting={setCommenting}
                                commentBeingEdited={commentBeingEdited}
                                setCommentBeingEdited={setCommentBeingEdited}
                                videoRef={videoRef}
                                secondsToMinutesAndSeconds={secondsToMinutesAndSeconds}
                                currEditingCommentText={currEditingCommentText}
                                setCurrEditingCommentText={setCurrEditingCommentText}
                                flagMap={flagMap}
                                commentActionMenuOpen={commentActionMenuOpen}
                                setCommentActionMenuOpen={setCommentActionMenuOpen}
                                newCommentRef={newCommentRef}
                                newCommentText={newCommentText}
                                setNewCommentText={setNewCommentText}
                                testerID={testerID}
                                stableSort={stableSort}
                                getComparator={getComparator}
                                commentHover={commentHover}
                                setCommentHover={setCommentHover}
                                setPlaybackOptionsVisible={setPlaybackOptionsVisible}
                                windowHeight={windowHeight}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="videoContainerLoaderSpinner">
                    <LoaderSpinner />
                </div>
            )}
        </div>
    )
}

export default VideoContainerPage
