import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import AccountOverview from '../components/auth/AccountOverviewPage'
import Billing from '../components/auth/Billing'
import LogOut from '../components/auth/LogOut'
import ResetNew from '../components/auth/ResetNew'
import SignUpCode from '../components/auth/SignUpCode'
import TobiiModal from '../components/auth/TobiiModal'
import Update from '../components/auth/Update'
import Dashboard from '../components/dashboard/Dashboard'
import FAQ from '../components/general/FAQ'
import FourOhFour from '../components/general/FourOhFour'
import OverviewPage from '../components/overview/OverviewPage'
import Privacy from '../components/terms/Privacy'
import VideoContainerPage from '../components/video/VideoContainerPage'
import AdminDetails from '../pages/Admin/AdminDetails'
import AdminPanel from '../pages/Admin/AdminPanel'
import LogInPage from '../pages/Auth/LogInPage'
import ResetPage from '../pages/Auth/ResetPage'
import SignUpPage from '../pages/Auth/SignUpPage'
import CreateTestPage from '../pages/UXTest/CreateTestPage'
import Solitaire from '../pages/UXTest/SolitairePage'
import AuthenticatedRoute from './AuthenticatedRoute'
import PublicRoute from './PublicRoute'
import { userAuthProvider } from './userAuthenticationProvider'

const router = ({
    windowWidth,
    setParticipantFeedback,
    expires,
    setExpires,
    renews,
    setRenews,
    plan,
    setPlan,
    setCreditsModalVisible
}) => {
    return createBrowserRouter([
        {
            path: '/',
            element: (
                <AuthenticatedRoute>
                    <OverviewPage
                        expires={expires}
                        setPlan={setPlan}
                        setRenews={setRenews}
                        setExpires={setExpires}
                        plan={plan}
                        setCreditsModalVisible={setCreditsModalVisible}
                    />
                </AuthenticatedRoute>
            ),
            loader() {
                // Our root route always provides the user, if logged in
                return { user: userAuthProvider.username }
            },
            errorElement: <FourOhFour />
        },

        // authentication
        {
            path: '/login',
            element: (
                <PublicRoute>
                    <LogInPage />
                </PublicRoute>
            )
        },
        { path: '/reset/:uid/:auth/:id', element: <ResetNew /> },
        { path: '/reset', element: <ResetPage /> },
        {
            path: '/signup',
            element: (
                <PublicRoute>
                    <SignUpPage />
                </PublicRoute>
            )
        },
        { path: '/signup/:uid/:email', element: <SignUpCode /> },
        {
            path: '/logout',
            element: (
                <AuthenticatedRoute>
                    <LogOut />
                </AuthenticatedRoute>
            )
        },

        // test pages
        {
            path: '/overview',
            element: (
                <AuthenticatedRoute>
                    <OverviewPage
                        expires={expires}
                        setPlan={setPlan}
                        setRenews={setRenews}
                        setExpires={setExpires}
                        plan={plan}
                        setCreditsModalVisible={setCreditsModalVisible}
                    />
                </AuthenticatedRoute>
            )
        },
        {
            path: '/solitaire/:id',
            element: (
                <AuthenticatedRoute>
                    <Solitaire />
                </AuthenticatedRoute>
            )
        },
        {
            path: '/video/:id',
            element: (
                <AuthenticatedRoute>
                    <VideoContainerPage />
                </AuthenticatedRoute>
            )
        },
        //                <PrivateRoute
        //                    exact
        //                    path="/video/:id"
        //                    component={VideoContainer}
        //                    progressBar={progressBar}
        //                    setProgressBar={setProgressBar}
        //                />

        {
            path: '/create',
            element: (
                <AuthenticatedRoute>
                    <CreateTestPage plan={plan} />
                </AuthenticatedRoute>
            )
        },
        {
            path: '/update',
            element: (
                <AuthenticatedRoute>
                    <Update />
                </AuthenticatedRoute>
            )
        },

        // public routes
        { path: '/privacy', element: <Privacy /> },
        { path: '/faq', element: <FAQ /> },

        // account
        {
            path: '/account',
            element: (
                <AuthenticatedRoute>
                    <AccountOverview windowWidth={windowWidth} />
                </AuthenticatedRoute>
            )
        },
        {
            path: '/test/:id',
            element: (
                <AuthenticatedRoute>
                    <Dashboard setParticipantFeedback={setParticipantFeedback} />
                </AuthenticatedRoute>
            )
        },
        {
            path: '/billing',
            element: (
                <AuthenticatedRoute>
                    <Billing plan={plan} expires={expires} renews={renews} />
                </AuthenticatedRoute>
            )
        },
        {
            path: '/consent',
            element: (
                <AuthenticatedRoute>
                    <TobiiModal />
                </AuthenticatedRoute>
            )
        },

        // admin routes
        {
            path: '/admin',
            element: (
                <AuthenticatedRoute>
                    <AdminPanel />
                </AuthenticatedRoute>
            )
        },
        {
            path: '/admin/:id',
            element: (
                <AuthenticatedRoute>
                    <AdminDetails />
                </AuthenticatedRoute>
            )
        }
    ])
}

export { router }
