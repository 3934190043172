import { useEffect } from 'react'

import { sendGetRequestWithFilter, wrapRequestInRefreshToken } from '../../utils/api'

const useConsentForm = (
    props,
    userVal,
    name,
    setUsingConsentForm,
    currentConsentForm,
    setCurrentConsentForm,
    setDefaultFormOnMount
) => {
    /**
     * Set default consent form as current if one is found or assign id: 1 if it is the first
     */
    useEffect(() => {
        const draftID = sessionStorage.getItem('draftID')
        if (!draftID && userVal && name === null) {
            const successFunc = (json) => {
                if (json.results) {
                    json.results.map((result) => {
                        setCurrentConsentForm({ user: userVal, default: true, body: result.body })
                        setDefaultFormOnMount(true)
                        return result
                    })
                }

                if (!json.results || json.results.length === 0) {
                    setCurrentConsentForm({ user: userVal, default: false, body: '' })
                }
            }
            const errorFunc = (err) =>
                err.json().then((body) => console.log('if consent form err, ', body))
            wrapRequestInRefreshToken(props, sendGetRequestWithFilter, [
                `users/consentform/?user=${userVal}&default=True`,
                successFunc,
                errorFunc
            ])
        }
    }, [userVal, name, props, setCurrentConsentForm, setDefaultFormOnMount])

    /**
     * Find out if an existing consent form contains this name and assign the id to current consent
     */
    useEffect(() => {
        const draftID = sessionStorage.getItem('draftID')

        if (userVal && name && draftID !== null) {
            const successFunc = (json) => {
                if (json.results.length > 0) {
                    setCurrentConsentForm(json.results[0])
                }
            }
            const errorFunc = (err) =>
                err.json().then((body) => console.log('if no consent form err,', body))
            wrapRequestInRefreshToken(props, sendGetRequestWithFilter, [
                `users/consentform/?user=${userVal}&name=${name}`,
                successFunc,
                errorFunc
            ])
        }
    }, [name, userVal, props, setCurrentConsentForm])

    /**
     * Set not using consent form on load if form body is empty and test has draft
     */
    useEffect(() => {
        const draftID = sessionStorage.getItem('draftID')
        if (
            draftID &&
            name &&
            currentConsentForm.body === '' &&
            currentConsentForm.default === false
        ) {
            const successFunc = (json) => {
                const latestConsentForm = json.results[json.results.length - 1].body

                if (latestConsentForm === '') {
                    setUsingConsentForm(false)
                }
            }
            const errorFunc = (err) =>
                err.json().then((body) => console.log('no consent form if no test err, ', body))
            wrapRequestInRefreshToken(props, sendGetRequestWithFilter, [
                `users/consentform/?user=${userVal}&name=${name}`,
                successFunc,
                errorFunc
            ])
        }
    }, [currentConsentForm, name, props, userVal, setUsingConsentForm])
}

export default useConsentForm
