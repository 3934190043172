import './_overview-row.scss'

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import calendarIcon from '../../images/calendar.svg'
import testStatusLaunchedIcon from '../../images/icons/liveIcon.svg'
import deleteIcon from '../../images/overview_actions/delete_icon_black.svg'
import duplicateIcon from '../../images/overview_actions/duplicate_icon_black.svg'
import deleteModal from '../../images/overview_modal_icons/delete_icon.svg'
import editModal from '../../images/overview_modal_icons/edit_icon.svg'
import testStatusCompleteIcon from '../../images/test_status/test_status_complete.svg'
import testStatusDraftIcon from '../../images/test_status/test_status_draft.svg'
import testStatusExpiredIcon from '../../images/test_status/test_status_expired.svg'
import testStatusInactiveIcon from '../../images/test_status/test_status_inactive.svg'
import testStatusPausedIcon from '../../images/test_status/test_status_paused.svg'
import testStatusReviewIcon from '../../images/test_status/test_status_review.svg'
import testStatusReviewedIcon from '../../images/test_status/test_status_reviewed.svg'
import usersIcon from '../../images/users.svg'
import { getDate } from '../utils/functions'
import AlertModal from './AlertModal'
import LaunchModal from './LaunchModal'
import MultiParticipantModal from './MultiParticipantModal'
import OverviewActions from './OverviewActions'
import TestTitleLinks from './TestTitleLinks'

// Components
const Flex = styled.div`
    display: flex;
`
const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`
const ActionIcon = styled.div`
    cursor: pointer;
`

// Icons
const testStatusIcons = {
    draft: testStatusDraftIcon,
    review: testStatusReviewIcon,
    reviewed: testStatusReviewedIcon,
    live: testStatusLaunchedIcon,
    complete: testStatusCompleteIcon,
    inactive: testStatusInactiveIcon,
    paused: testStatusPausedIcon,
    archived: testStatusExpiredIcon,
    suspended: testStatusPausedIcon
}

const OverviewRow = (props) => {
    const {
        alertModalOpen,
        plan,
        testDuplicating,
        onDeleteTest,
        onDuplicateTest,
        onPauseTest,
        onLaunchLiveTest,
        onResumeTest,
        onEditTest,
        test,
        testIdx,
        tableCellWidth,
        setCreditsModalVisible,
        setAlertModalOpen,
        windowWidth,
        updating,
        launchModalOpen,
        setLaunchModalOpen,
        multiParticipantModalOpen,
        setMultiParticipantModalOpen,
        setSubscriptionNeededModalOpen,
        setInvitationModalOpen,
        multiPersonDevice,
        setMultiPersonDevice
    } = props

    const [revealOnHover, setRevealOnHover] = useState(null)
    const [nameTooLong, setNameTooLong] = useState(false)

    const reviewed = test.stage === 'review' && test.count === 3
    const rowHeight = '100px'
    const dateCreated = getDate(test && test.time)
    const dateOfDeletion = getDate(
        new Date(dateCreated).setMonth(new Date(dateCreated).getMonth() + 4)
    )

    /**
     * Find test name too long
     */
    useEffect(() => {
        if (!test) return
        const boundry = document.getElementsByClassName('test_name')
        const boundryWidth = boundry[testIdx] && boundry[testIdx].offsetWidth
        const nameLength = test.name && test.name.length
        if (boundryWidth / nameLength < 10) {
            setNameTooLong(true)
        } else {
            setNameTooLong(false)
        }
    }, [setNameTooLong, test, testIdx])

    /**
     * Callback function to handle user trying to delete test.
     */
    const onClickDeleteTest = () => {
        setAlertModalOpen({
            id: test.id,
            icon: deleteModal,
            header: <h6 className="edit-modal-title">Delete this test?</h6>,
            body: (
                <p>
                    All the data will be deleted.
                    <br /> This action is irreversible.
                </p>
            ),
            primeButton: {
                text: 'Delete Test',
                color: '#FF6262',
                func: () => {
                    setAlertModalOpen(null)
                    onDeleteTest(test.id, testIdx)
                }
            }
        })
    }

    /**
     * Callback function to handle user trying to duplicate test.
     */
    const onClickDuplicateTest = () => {
        if (plan === 'free') {
            setSubscriptionNeededModalOpen(test.id)
        } else {
            onDuplicateTest(test.id, test.name)
        }
    }

    /**
     * Callback function to handle user trying to edit (inactive) test.
     */
    const onClickEditTest = () => {
        if (test.stage === 'draft') {
            onEditTest(test.id)
        } else {
            setAlertModalOpen({
                id: test.id,
                icon: editModal,
                header: <h6 className="edit-modal-title">Edit this test?</h6>,
                body: (
                    <p className="edit-body-text">
                        All collected data will be deleted.
                        <br />
                        The test will be set back to “Draft” stage.
                    </p>
                ),
                primeButton: {
                    text: 'Edit Test',
                    color: '#4B8CF7',
                    func: () => {
                        setAlertModalOpen(null)
                        onEditTest(test.id)
                    }
                }
            })
        }
    }

    /**
     * Callback function to handle user trying to pause active test.
     */
    const onClickPauseTest = () => {
        onPauseTest(test.id, testIdx)
    }

    /**
     * Callback function to handle user trying to launch.
     */
    const onClickLaunchLiveTest = () => {
        setLaunchModalOpen(test.id)
    }

    /**
     * Callback function to handle user trying to resume paused/inactive test.
     */
    const onClickResumeTest = () => {
        onResumeTest(test.id, testIdx)
    }

    return test ? (
        <>
            <RowWrapper
                id="row-wrapper"
                className={`overview__table__row ${test.stage === 'draft' ? 'draft-' : ''}test-item-border `}
                onMouseOver={() => setRevealOnHover(testIdx)}
                onMouseLeave={() => setRevealOnHover(null)}
                style={{
                    height: rowHeight
                }}>
                {/* TEST */}
                <div style={{ width: tableCellWidth.test, overflow: 'hidden' }}>
                    <div
                        className="table__cell"
                        style={{ marginTop: `calc((${rowHeight} / 2) - 12px)`, fontWeight: '700' }}>
                        <div className="table__test__col" style={{ overflow: 'hidden' }}>
                            <div className="test_name">
                                {test.name !== null && nameTooLong
                                    ? test.name.slice(0, 27) + '...'
                                    : test.name}
                            </div>
                            {revealOnHover === testIdx && (
                                <TestTitleLinks
                                    test={test}
                                    setInvitationModalOpen={setInvitationModalOpen}
                                    onClickPauseTest={onClickPauseTest}
                                    onClickResumeTest={onClickResumeTest}
                                    onClickEditTest={onClickEditTest}
                                    setRevealOnHover={setRevealOnHover}
                                    onClickLaunchLiveTest={onClickLaunchLiveTest}
                                />
                            )}
                        </div>
                    </div>
                </div>

                {/* DATE */}
                <div
                    style={{
                        width: tableCellWidth.date,
                        display: windowWidth < 1140 ? 'none' : 'flex'
                    }}>
                    <div style={{ margin: '40px 8px 0px 0px' }}>
                        <img alt="calendar_icon" src={calendarIcon} />
                    </div>
                    <div
                        className="table__cell"
                        style={{ marginTop: `calc((${rowHeight} / 2) - 12px)` }}>
                        <div>{`${dateCreated} - ${dateOfDeletion}`}</div>
                    </div>
                </div>

                {/* PARTICIPANTS */}
                <div
                    style={{
                        width: tableCellWidth.participants,
                        display: windowWidth < 1140 ? 'none' : ''
                    }}>
                    <div
                        className="table__cell"
                        style={{ marginTop: `calc((${rowHeight} / 2) - 12px)` }}>
                        {test.stage === 'draft' ? (
                            <div>...</div>
                        ) : (
                            <Flex>
                                <img alt="users-icon" src={usersIcon} />
                                <div style={{ width: '4px' }} />
                                <div>{`${test.count + (test.stage === 'review' ? '/3' : '')}`}</div>
                            </Flex>
                        )}
                    </div>
                </div>

                {/* STATUS */}
                <div style={{ width: tableCellWidth.status }}>
                    <div
                        className="table__cell"
                        style={{ marginTop: `calc((${rowHeight} / 2) - 16px)` }}>
                        {updating === testIdx ? (
                            <div
                                className="spinner lds-ring"
                                style={{ zoom: '0.8', filter: 'brightness(0)' }}>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        ) : (
                            <div>
                                <img
                                    alt={`Test Progress Icon ${test.stage}`}
                                    className="table__progress__col__icon"
                                    src={testStatusIcons[reviewed ? 'reviewed' : test.stage]}
                                />
                                <div className="table__progress__col__text">
                                    {reviewed ? 'reviewed' : test.stage}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* ACTIONS */}
                {testDuplicating !== test.id ? (
                    <div
                        style={{ width: tableCellWidth.actions, display: 'flex' }}
                        className="test__actions">
                        <OverviewActions
                            test={test}
                            setInvitationModalOpen={setInvitationModalOpen}
                            launchModalOpen={launchModalOpen}
                            onClickLaunchLiveTest={onClickLaunchLiveTest}
                            onClickResumeTest={onClickResumeTest}
                            onClickEditTest={onClickEditTest}
                        />
                        {plan !== 'free' && (
                            <div
                                style={{
                                    marginTop: `calc(( ${rowHeight} / 2) - 22px)`,
                                    marginLeft: '16px',
                                    opacity: revealOnHover === testIdx ? '1' : '0'
                                }}>
                                <ActionIcon onClick={onClickDuplicateTest}>
                                    <img alt="Duplicate__icon" src={duplicateIcon} />
                                </ActionIcon>
                                <ActionIcon onClick={() => onClickDeleteTest(test, testIdx)}>
                                    <img alt="Delete__icon" src={deleteIcon} />
                                </ActionIcon>
                            </div>
                        )}
                    </div>
                ) : (
                    <div
                        style={{
                            width: tableCellWidth.actions,
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                        <div
                            className="spinner lds-ring"
                            style={{
                                marginTop: `calc(( ${rowHeight} / 2) - 12px)`,
                                marginRight: '40px',
                                zoom: '0.8',
                                filter: 'brightness(0)'
                            }}>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                )}
            </RowWrapper>

            {alertModalOpen && (
                <AlertModal
                    isOpen={alertModalOpen.id === test.id}
                    onClickCancel={() => {
                        setAlertModalOpen(null)
                    }}
                    onClickPrimary={alertModalOpen.primeButton.func}
                    icon={alertModalOpen.icon}
                    header={alertModalOpen.header}
                    body={alertModalOpen.body}
                    testID={test.id}
                    testName={test.name}
                    primeText={alertModalOpen.primeButton.text}
                    primeColor={alertModalOpen.primeButton.color}
                />
            )}

            <LaunchModal
                launchModalOpen={launchModalOpen === test.id}
                onClickCancel={() => setLaunchModalOpen(null)}
                onClickClose={() => {
                    setLaunchModalOpen(null)
                    if (plan !== 'free') {
                        setMultiParticipantModalOpen(test.id)
                    } else {
                        setCreditsModalVisible(true)
                    }
                }}
                currTesters={test.count}
                usersIcon={usersIcon}
            />

            <MultiParticipantModal
                multiParticipantModalOpen={multiParticipantModalOpen === test.id}
                onClickCancel={() => setMultiParticipantModalOpen(null)}
                onClickClose={() => {
                    setMultiParticipantModalOpen(null)
                    onLaunchLiveTest(test.id, testIdx)
                }}
                testID={test.id}
                currTesters={test.count}
                multiPersonDevice={multiPersonDevice}
                setMultiPersonDevice={setMultiPersonDevice}
            />
        </>
    ) : null
}

export default OverviewRow
