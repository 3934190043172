import React from 'react'

const LinkButton = ({ children, href, isUnderline, target }) => {
    return (
        <a
            href={href}
            target={target || '_self'}
            rel="noopener noreferrer"
            className={`link-button ${isUnderline ? 'underline-link' : ''}`}>
            {children}
        </a>
    )
}
export default LinkButton
