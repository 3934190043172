import React from 'react'

const CheckboxInput = ({ label, id, value, onChange }) => {
    return (
        <div className="checkbox-container">
            <button className="checkbox" type="button" onClick={() => onChange(id, !value)}>
                <div className={`checkbox-item ${value ? 'active' : ''}`} />
            </button>
            <input
                type="checkbox"
                id={id}
                name={id}
                defaultValue={value}
                onChange={(e) => onChange(id, e.target.checked)}
            />
            <label className="checkbox-label" htmlFor={id}>
                {label}
            </label>
        </div>
    )
}

export default CheckboxInput
