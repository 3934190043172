import './createtestcontainer.scss'

import React, { useEffect, useState } from 'react'

import TextInput from '../molecules/forms/TextInput'
import { isValidEmail } from '../utils/functions'

const TestIntroduction = (props) => {
    const {
        draft,
        updateDraft,
        onChangeTestAttribute,
        setDraftJsonFunc,
        emailContact,
        setEmailContact
    } = props

    const [emailValid, setEmailValid] = useState(false)

    /**
     * Validate Email address
     */
    useEffect(() => {
        if (emailContact) {
            if (isValidEmail(emailContact)) {
                setEmailValid(true)
            } else {
                setEmailValid(false)
            }
        }
    }, [emailContact])

    return (
        <div id="pilotEmail">
            <TextInput
                error={emailValid ? '' : 'Please enter a valid email address'}
                onChange={(e) => {
                    const value = e.target.value
                    setEmailContact(value)
                    onChangeTestAttribute('emailContact', value)
                }}
                placeholder={'email@example.com'}
                value={emailContact}
                autoComplete={'email'}
                tabIndex={1}
                maxLength={80}
                onBlur={(e) => {
                    if (e.target.value !== '') {
                        updateDraft('contact_email', emailContact, draft, props, setDraftJsonFunc)
                    }
                }}
            />
        </div>
    )
}

export default TestIntroduction
