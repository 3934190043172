const PublicRoute = ({ children, windowWidth, ...props }) => {
    const currTime = Math.round(Date.now() / 1000)
    const expirationTime = localStorage.getItem('tokenExp')

    if (localStorage.getItem('token') && currTime < expirationTime) {
        window.location.href = '/overview'
        return
    }

    return children
}

export default PublicRoute
