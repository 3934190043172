import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import checkedBox from '../../images/checkbox/checkbox_checked.svg'
import errorBox from '../../images/checkbox/checkbox_error.svg'
import uncheckedBox from '../../images/checkbox/checkbox_unchecked.svg'

const CheckboxImg = styled.img`
    width: 16px;
    margin-bottom: 2px;
    cursor: pointer;
`

/**
 * Checkbox Component
 * @param {*} props
 */
const Checkbox = (props) => {
    switch (props.status) {
        case 'checked':
            return (
                <CheckboxImg alt="checkbox_checked_icon" src={checkedBox} onClick={props.onClick} />
            )
        case 'unchecked':
            return (
                <CheckboxImg
                    alt="checkbox_unchecked_icon"
                    src={uncheckedBox}
                    onClick={props.onClick}
                />
            )
        case 'error':
            return <CheckboxImg alt="checkbox_error_icon" src={errorBox} onClick={props.onClick} />
        default:
            return <CheckboxImg alt="checkbox_unchecked_icon" src={uncheckedBox} />
    }
}

export default Checkbox

Checkbox.propTypes = {
    status: PropTypes.string,
    onClick: PropTypes.func
}
