import '../../utils/dropdown.css'

import PropTypes from 'prop-types'
import React from 'react'

import dropdownIcon from '../../../images/drop_down_icon.svg'
import dropupIcon from '../../../images/drop_up_icon.svg'
import { useOuterClick } from '../../utils/ClickOutside'
import DropdownMenu from './DropdownMenu'

/**
 * Generic React dropdown component
 * @param {*} props
 */
const Dropdown = (props) => {
    const {
        className,
        disabled,
        menuOptions,
        noDropdownIcon,
        onSelectMenuOption,
        selectedRowCheckboxes,
        selectedValues,
        setShowingDropdownMenu,
        showingDropdownMenu,
        triggerText
    } = props

    const innerRef = useOuterClick((e) => {
        if (showingDropdownMenu === true) {
            if (!['dropdown', 'trigger', 'account_icon'].includes(e.target.className)) {
                setShowingDropdownMenu(false)
            }
        }
    })

    return (
        <div className={`dropdown ${className}`} ref={innerRef}>
            <div
                className={`trigger ${disabled ? 'disabled' : ''}`}
                onClick={setShowingDropdownMenu}>
                {triggerText}
                {!noDropdownIcon && (
                    <img
                        alt="dropdown_icon"
                        className="dropdown__icon"
                        src={showingDropdownMenu ? dropupIcon : dropdownIcon}
                    />
                )}
            </div>

            {showingDropdownMenu && (
                <>
                    <DropdownMenu
                        menuOptions={menuOptions}
                        onSelectMenuOption={onSelectMenuOption}
                        selectedRowCheckboxes={selectedRowCheckboxes}
                        selectedValues={selectedValues}
                        showingDropdownMenu={showingDropdownMenu}
                        setShowingDropdownMenu={setShowingDropdownMenu}
                    />
                </>
            )}
        </div>
    )
}

export default React.memo(Dropdown)

Dropdown.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    menuOptions: PropTypes.array,
    noDropdownIcon: PropTypes.bool,
    onSelectMenuOption: PropTypes.func,
    selectedRowCheckboxes: PropTypes.bool,
    selectedValues: PropTypes.array,
    setShowingDropdownMenu: PropTypes.func,
    showingDropdownMenu: PropTypes.bool,
    triggerText: PropTypes.string
}
