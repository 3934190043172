import './createtestcontainer.scss'

import React, { useState } from 'react'

import RichTextConsent from './RichTextConsent'

const ConsentForm = (props) => {
    const {
        defaultFormOnMount,
        setDefaultFormOnMount,
        onConsentSave,
        onChangeTestAttribute,
        currentConsentForm,
        setCurrentConsentForm,
        name,
        handleCheckbox
    } = props

    const [consentFormSaveInit, setConsentFormSaveInit] = useState(false)
    const [assignValueClosed, setAssignValueClosed] = useState(false)

    return (
        <div
            id="pilotConsent"
            className="step__box"
            onMouseEnter={() => {
                setAssignValueClosed(true)
            }}>
            <div style={{ opacity: !name ? '0.5' : '1' }}>
                <h2> Consent form</h2>
                <p className="instruction">
                    Add a Consent Form to explicitly ask your participants for permission to process
                    their personal data. Your consent form should ensure the protection of their
                    data, explain clearly how it will be processed and name the person responsible
                    for it.
                </p>
            </div>

            <RichTextConsent
                name={name}
                currentConsentForm={currentConsentForm}
                setCurrentConsentForm={setCurrentConsentForm}
                setConsentFormSaveInit={setConsentFormSaveInit}
                onChangeTestAttribute={onChangeTestAttribute}
                setDefaultFormOnMount={setDefaultFormOnMount}
                onConsentSave={onConsentSave}
                assignValueClosed={assignValueClosed}
            />

            <div
                className="consent-checkbox-wrapper"
                onMouseEnter={() => {
                    if (consentFormSaveInit && currentConsentForm.default === false) {
                        onConsentSave()
                    }
                }}>
                {!name ? (
                    <div>Your test requires a name before consent data can be added</div>
                ) : defaultFormOnMount === true ? (
                    <div style={{ opacity: '0.5' }}>
                        This is currently the default version of your consent form
                    </div>
                ) : (
                    <div className="consent-checkbox">
                        <input
                            type="checkbox"
                            defaultChecked={currentConsentForm.default}
                            onClick={() => handleCheckbox()}
                        />
                        <label className="instruction">
                            Save this Consent Form as default for the next tests
                        </label>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ConsentForm
