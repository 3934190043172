import React from 'react'

const PrimaryButton = ({
    onClick,
    title,
    children,
    className = '',
    style,
    tabIndex,
    disabled,
    type = 'button'
}) => {
    return (
        <button
            className={`primary primary-button ${className}`}
            tabIndex={tabIndex}
            onClick={onClick}
            disabled={disabled}
            style={style}
            type={type}>
            {title || children || 'Action'}
        </button>
    )
}

export default PrimaryButton
