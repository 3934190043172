import './taskcard.css'

import React from 'react'
import styled from 'styled-components'

const Height8px = styled.div`
    height: 8px;
`

const tolTooltip =
    'Thinking out loud. Ask the participants to verbalize their thoughts as they perform the task.'
const ratingTooltip =
    'Ask your participants to rate out of 5 stars. For example, participants can rate the test material (your image, URL etc.), their experience or something else.'

const TaskCardTools = ({ idx, onChangeTaskBoolean, task, windowWidth }) => {
    return (
        <div className="bottom__row">
            <h5>{'Feedback tools'}</h5>
            <Height8px />
            <div style={{ display: 'flex' }}>
                {/* TOL Button */}
                <button
                    className={`secondary ${task.tol ? 'selected' : ''}`}
                    onClick={() => (task.pauseRecording ? '' : onChangeTaskBoolean(idx, 'tol'))}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <div>{windowWidth > 1240 ? '+ Thinking aloud' : '+ TOL'}</div>
                </button>
                <div className="feedback__tooltip tol" id={`thinkAloudTT${idx}`}>
                    {tolTooltip}
                </div>

                <div style={{ width: '8px' }} />

                {/* Rating Button */}
                <button
                    className={`secondary ${task.rating ? 'selected' : ''}`}
                    onClick={() => onChangeTaskBoolean(idx, 'rating')}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <div className={`feedback__description ${task.rating ? 'selected' : ''}`}>
                        {windowWidth > 1240 ? '+ Rating question' : '+ Rating'}
                    </div>
                </button>
                <div className="feedback__tooltip rating" id={`ratingIconTT${idx}`}>
                    {ratingTooltip}
                </div>
            </div>
        </div>
    )
}

export default TaskCardTools
