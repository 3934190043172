import '../../components/create/createtestcontainer.scss'

import React, { useCallback, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Userpilot } from 'userpilot'

import LineBreak from '../../components/atoms/layout/LineBreak'
import Separator from '../../components/atoms/layout/Separator'
import CompanyLogo from '../../components/create/CompanyLogo'
import ConfirmExitModal from '../../components/create/ConfirmExitModal'
import ConfirmNoConsentModal from '../../components/create/ConfirmNoConsentModal'
import ConsentForm from '../../components/create/ConsentForm'
import useConsentForm from '../../components/create/custom_hooks/useConsentForm'
import useLoadTest from '../../components/create/custom_hooks/useLoadTest'
import usePrivacy from '../../components/create/custom_hooks/usePrivacy'
import useWindowResize from '../../components/create/custom_hooks/useWindowResize'
import EmailField from '../../components/create/EmailField'
import ErrorsOnSave from '../../components/create/ErrorsOnSave'
import { formatId, formatScreeners, formatTasks } from '../../components/create/FormatterFunctions'
import MessageCardBox from '../../components/create/MessageCardBox'
import PrivacyPolicy from '../../components/create/PrivacyPolicy'
import ReviewModal from '../../components/create/ReviewModal'
import {
    saveAndExit,
    saveAndReview,
    updateConsent,
    updateDraft,
    updatePrivacy
} from '../../components/create/SaveAndUpdateFunctions'
import TasksColumn from '../../components/create/TasksColumn'
import * as V from '../../components/create/Validation'
import ScreenerColumn from '../../components/molecules/forms/ScreenerColumn'
import TestIntroduction from '../../components/organisms/uxTests/TestIntroduction'
import StageState from '../../components/utils/StageState'
import { navigate, NavigationContext, NavigationContextItem } from '../../navigation/navigate'

// stuff for tooltips
const welcomeMessageTooltipText =
    'The welcome message greets your tester right after they enter the test key to start your test.'
const thankyouMessageTooltipText =
    'The thank-you message is presented to your tester once they complete the test. You could use it to direct your testers to call-to-actions such as rewards or follow ups.'

/**
 * Container component for test creation web page.
 */
const CreateTestPage = (props) => {
    // test id
    const [name, setName] = useState(null)
    const [userVal, setUserVal] = useState(null)

    // state variables for gdpr
    const [currentPrivacyPolicy, setCurrentPrivacyPolicy] = useState({})
    const [activePrivacyInput, setActivePrivacyInput] = useState('')
    const [currentConsentForm, setCurrentConsentForm] = useState({})
    const [defaultFormOnMount, setDefaultFormOnMount] = useState(false)
    const [confirmNoConsentModal, setConfirmNoConsentModal] = useState(false)
    const [reviewModalOpen, setReviewModalOpen] = useState(false)

    // state variables to keep track of errors in user-entered values
    const [errors, setErrors] = useState({})
    const [screenerErrors, setScreenerErrors] = useState([])
    const [taskErrors, setTaskErrors] = useState([])

    // state variables for page rendering
    const [usingThankyouMessage, setUsingThankyouMessage] = useState(true)
    const [usingWelcomeMessage, setUsingWelcomeMessage] = useState(true)
    const [usingConsentForm, setUsingConsentForm] = useState(true)
    const [taskMenuShowing, setTaskMenuShowing] = useState(null)

    // state variables for save and server res
    const [savingDraft, setSavingDraft] = useState(false)
    const [draftJson, setDraftJson] = useState({})

    // custom hooks
    const { isNarrowScreen } = useWindowResize()
    const [
        draft,
        screeners,
        setScreeners,
        testNames,
        allowedTestParams,
        tasks,
        stage,
        active,
        companyLogo,
        setCompanyLogo,
        description,
        emailContact,
        setEmailContact,
        numTesters,
        setNumTesters,
        welcomeMessage,
        setWelcomeMessage,
        thankyouMessage,
        taskScheme,
        setTaskScheme,
        setStage,
        setDescription,
        logoImage,
        setLogoImage,
        setTasks,
        creditSistem,
        setCreditSistem,
        taskGroupShowing,
        setTaskGroupShowing,
        setThankyouMessage
    ] = useLoadTest(
        props,
        userVal,
        setUserVal,
        usingWelcomeMessage,
        setUsingWelcomeMessage,
        usingThankyouMessage,
        setUsingThankyouMessage,
        setDraftJson,
        setScreenerErrors,
        setTaskErrors,
        draftJson,
        setCurrentConsentForm,
        setDefaultFormOnMount,
        setCurrentPrivacyPolicy,
        taskErrors,
        setErrors,
        V,
        name,
        setName
    )
    useConsentForm(
        props,
        userVal,
        name,
        setUsingConsentForm,
        currentConsentForm,
        setCurrentConsentForm,
        defaultFormOnMount,
        setDefaultFormOnMount
    )
    usePrivacy(props, userVal, name, setCurrentPrivacyPolicy)

    const { navState, setNavState } = React.useContext(NavigationContext)

    // const location = useLocation()

    useEffect(() => {
        Userpilot.reload()
    }, [])

    const taskSchemeCallback = useCallback(() => {
        if (tasks.filter((task) => task.isABTest).length === 0) {
            setTaskScheme('none')
        } else {
            setTaskScheme('ab')
        }
    }, [tasks, setTaskScheme])

    /**
     * Update task scheme if test has or does not have an ab task
     */
    useEffect(() => {
        if (tasks) {
            taskSchemeCallback()
        }

        if (props.plan === 'free') {
            setNavState(
                props?.plan === 'free' && navState !== NavigationContextItem.TEST_CREATION_LIMIT
                    ? NavigationContextItem.TEST_CREATION_LIMIT
                    : NavigationContextItem.MAIN_SIDE_BAR
            )
            return
        }

        if (navState !== NavigationContextItem.TEST_CHANGED) {
            setNavState(NavigationContextItem.TEST_CHANGED)
        }
    }, [tasks, taskSchemeCallback])

    /**
     * Handles test attribute validation as the user customizes their test.
     * @param {*} key name of test attribute (e.g. 'name', 'description')
     * @param {*} value value user is attempting to set attribute to.
     */
    const onChangeTestAttribute = (key, value) => {
        const [isValid, error] = V.validateTestAttributeValue(
            key,
            value,
            testNames,
            allowedTestParams
        )
        const newErrors = { ...errors }
        if (isValid) {
            if (key in newErrors) {
                delete newErrors[key]
            }
        } else {
            newErrors[key] = error
        }

        if (newErrors) {
            setErrors(newErrors)
        }
    }

    /**
     * Set the state variable that holds the server response for draft updates
     */
    const setDraftJsonFunc = (json) => {
        if (stage === 'draft' && json) {
            setDraftJson(json)
        }
    }

    /**
     * If user hits Continue
     */
    const onContinue = () => {
        if (name) {
            onPrivacySave(true)
            setReviewModalOpen(true)
        }
    }

    /**
     * If user hits Save Draft
     */
    const onSaveDraftAndExit = () => {
        if (name) {
            setSavingDraft(true)
            onPrivacySave(false)
            saveAndExit(
                props,
                active,
                companyLogo,
                currentConsentForm,
                description,
                emailContact,
                stage,
                numTesters,
                usingWelcomeMessage,
                welcomeMessage,
                usingThankyouMessage,
                thankyouMessage,
                name,
                currentPrivacyPolicy,
                formatTasks,
                taskScheme,
                tasks,
                screeners
            )
        }
    }

    /**
     * Consent Save
     */
    const onConsentSave = () => {
        if (usingConsentForm) {
            const updateObj = {
                user: userVal,
                body: currentConsentForm.body,
                name,
                default: currentConsentForm.default
            }

            if (!currentConsentForm.id) {
                updateConsent(
                    updateObj,
                    draft,
                    props,
                    null,
                    setDraftJsonFunc,
                    currentConsentForm,
                    setCurrentConsentForm
                )
            } else {
                updateConsent(
                    updateObj,
                    draft,
                    props,
                    currentConsentForm.id,
                    setDraftJsonFunc,
                    currentConsentForm,
                    setCurrentConsentForm
                )
            }
        } else {
            const updateObj = { user: userVal, body: '', name, default: false }

            updateConsent(
                updateObj,
                draft,
                props,
                currentConsentForm.id,
                setDraftJsonFunc,
                currentConsentForm,
                setCurrentConsentForm
            )
        }
    }

    /**
     * Privacy Save
     */
    const onPrivacySave = (shouldContinue) => {
        const updateObj = { user: userVal, body: currentPrivacyPolicy.body }

        updatePrivacy(
            updateObj,
            draft,
            props,
            !currentPrivacyPolicy.id ? null : currentPrivacyPolicy.id,
            setDraftJsonFunc,
            currentPrivacyPolicy,
            setCurrentPrivacyPolicy,
            shouldContinue
        )
    }

    /**
     * Handle checkbox for default consent form
     */
    const handleCheckbox = () => {
        const isDefault = currentConsentForm.default === false
        setCurrentConsentForm({ ...currentConsentForm, default: isDefault })
    }

    return testNames != null && allowedTestParams != null ? (
        <DndProvider backend={HTML5Backend}>
            {navState === NavigationContextItem.CONFIRM_TEST_EXIT && (
                <ConfirmExitModal name={name} />
            )}

            <ReviewModal
                reviewModalOpen={reviewModalOpen === true}
                onClickCancel={() => setReviewModalOpen(false)}
                onClickClose={() =>
                    saveAndReview(
                        props,
                        active,
                        companyLogo,
                        currentConsentForm,
                        description,
                        emailContact,
                        stage,
                        numTesters,
                        usingWelcomeMessage,
                        welcomeMessage,
                        usingThankyouMessage,
                        thankyouMessage,
                        name,
                        currentPrivacyPolicy,
                        formatTasks,
                        taskScheme,
                        tasks,
                        screeners,
                        setReviewModalOpen
                    )
                }
            />

            <ConfirmNoConsentModal
                confirmNoConsentModal={confirmNoConsentModal}
                setConfirmNoConsentModal={setConfirmNoConsentModal}
                setUsingConsentForm={setUsingConsentForm}
                updateDraft={updateDraft}
                draft={draft}
                setDraftJsonFunc={setDraftJsonFunc}
                currentConsentForm={currentConsentForm}
                setCurrentConsentForm={setCurrentConsentForm}
                onConsentSave={onConsentSave}
            />

            <div className="create-test-wrapper">
                <div id="createTestContainer" className={'create-test-container'}>
                    <StageState setStage={setStage} stage={stage} name={name} />
                    <Separator height={80} />
                    <h1>Test Details</h1>
                    <div className={'row-container'}>
                        {'These details will '}
                        <strong>only visible to you</strong>
                    </div>
                    <TestIntroduction
                        name={name}
                        setName={setName}
                        errors={errors}
                        draft={draft}
                        updateDraft={updateDraft}
                        allowedTestParams={allowedTestParams}
                        description={description}
                        setDescription={setDescription}
                        onChangeTestAttribute={onChangeTestAttribute}
                        numTesters={numTesters}
                        setNumTesters={setNumTesters}
                        setDraftJsonFunc={setDraftJsonFunc}
                        onConsentSave={onConsentSave}
                        onPrivacySave={onPrivacySave}
                    />
                    <Separator height={80} />
                    <LineBreak />
                    <Separator height={80} />
                    <h2> {'Test content'} </h2>
                    <p className="instruction">
                        From here, all the information provided will be shown on the mobile app that
                        your participants will use to run your test.
                    </p>
                    <Separator height={30} />
                    <div id="pilotSurvey">
                        <h3>Survey questions</h3>
                        <p>
                            You can ask screener and/or multiple-choice questions to your
                            participants before starting the test.
                        </p>
                        <ScreenerColumn
                            formatScreeners={formatScreeners}
                            screeners={screeners}
                            screenerErrors={screenerErrors}
                            setScreenerErrors={setScreenerErrors}
                            setScreeners={setScreeners}
                            updateDraft={updateDraft}
                        />
                    </div>
                    <Separator height={80} />
                    <div id="pilotWelcome">
                        <h3>{'Welcome message'}</h3>
                        <p className={'instruction'}>
                            Briefly introduce your test to your participants, add the time needed to
                            run your test and if there is any reward in the end.
                        </p>
                        <MessageCardBox
                            allowedTestParams={allowedTestParams}
                            message={welcomeMessage}
                            messageType="your welcome"
                            messageTypeCaps="Your welcome"
                            onChangeTestAttribute={onChangeTestAttribute}
                            setMessage={setWelcomeMessage}
                            setUsingMessage={setUsingWelcomeMessage}
                            tooltipText={welcomeMessageTooltipText}
                            updateDraft={updateDraft}
                            usingMessage={usingWelcomeMessage}
                            updateFunc={() =>
                                updateDraft(
                                    'message_welcome',
                                    welcomeMessage,
                                    draft,
                                    props,
                                    setDraftJsonFunc
                                )
                            }
                        />
                    </div>
                    <Separator height={80} />
                    <div id="pilotLogo">
                        <h3> {'Company logo'} </h3>
                        <div className={'row-container'}>
                            {'Your logo will appear at the top of your welcome screen.'}
                        </div>
                        <CompanyLogo
                            draft={draft}
                            updateDraft={updateDraft}
                            setDraftJsonFunc={setDraftJsonFunc}
                            companyLogo={companyLogo}
                            setCompanyLogo={setCompanyLogo}
                            draftJson={draftJson}
                            logoImage={logoImage}
                            setLogoImage={setLogoImage}
                        />
                    </div>
                    <Separator height={80} />
                    <div id="pilotTasks">
                        <h1> {'Tasks'} </h1>
                        <p className={'instruction'}>
                            The core of your test. Create tasks with clear instructions for your
                            test participants to complete, and include realistic scenarios to help
                            them envision themselves using your product.
                        </p>
                        <TasksColumn
                            isNarrowScreen={isNarrowScreen}
                            allowedTestParams={allowedTestParams}
                            errors={errors}
                            formatTasks={formatTasks}
                            formatId={formatId}
                            setErrors={setErrors}
                            setTaskErrors={setTaskErrors}
                            setTaskGroupShowing={setTaskGroupShowing}
                            setTaskMenuShowing={setTaskMenuShowing}
                            setTaskScheme={setTaskScheme}
                            FCFDFF
                            setTasks={setTasks}
                            taskErrors={taskErrors}
                            taskGroupShowing={taskGroupShowing}
                            taskMenuShowing={taskMenuShowing}
                            taskScheme={taskScheme}
                            tasks={tasks}
                            draft={draft}
                            updateDraft={updateDraft}
                            setDraftJsonFunc={setDraftJsonFunc}
                        />
                    </div>
                    <Separator height={80} />
                    <div id="pilotThankyou">
                        <h3>Thank you message</h3>
                        <MessageCardBox
                            allowedTestParams={allowedTestParams}
                            message={thankyouMessage}
                            messageType="thankyou"
                            messageTypeCaps="Thank you"
                            onChangeTestAttribute={onChangeTestAttribute}
                            setMessage={setThankyouMessage}
                            setUsingMessage={setUsingThankyouMessage}
                            tooltipText={thankyouMessageTooltipText}
                            updateDraft={updateDraft}
                            usingMessage={usingThankyouMessage}
                            updateFunc={() => {
                                updateDraft(
                                    'message_thankyou',
                                    thankyouMessage,
                                    draft,
                                    props,
                                    setDraftJsonFunc
                                )
                            }}
                        />
                    </div>
                    <Separator height={80} />
                    <h3>Email Contact*</h3>
                    <div className={'row-container'}>
                        {` Insert your email address here to allow your participants to reach out to you, if they have any questions regarding your test. `}
                    </div>
                    <EmailField
                        draft={draft}
                        updateDraft={updateDraft}
                        onChangeTestAttribute={onChangeTestAttribute}
                        setDraftJsonFunc={setDraftJsonFunc}
                        emailContact={emailContact}
                        setEmailContact={setEmailContact}
                    />
                    <Separator height={80} />
                    <LineBreak />
                    <Separator height={80} />
                    <h1> Data protection </h1>
                    <p className={'instruction'}>
                        As the controller of the data collected in your test, you need to provide a
                        Privacy Notice and a Consent Form to your participants. This need to comply
                        with the the regulations for data collection, use, storage, and sharing of
                        personal data of your region (e.g. GDPR for Europe, LGPD for Brazil,
                        etc...).
                    </p>
                    <Separator height={80} />
                    <LineBreak />
                    <Separator height={80} />
                    <div id="pilotPrivacy">
                        <h3>Privacy Notice*</h3>
                        <p className={'instruction'}>
                            The privacy notice is informative and outlines the data protection
                            practices of your organisation.
                        </p>

                        <Separator height={20} />

                        <PrivacyPolicy
                            currentPrivacyPolicy={currentPrivacyPolicy}
                            setCurrentPrivacyPolicy={setCurrentPrivacyPolicy}
                            updateDraft={updateDraft}
                            onChangeTestAttribute={onChangeTestAttribute}
                            updateFuncPrivacy={onPrivacySave}
                            userVal={userVal}
                            setDraftJsonFunc={setDraftJsonFunc}
                            draft={draft}
                            onPrivacySave={onPrivacySave}
                            name={name}
                            activePrivacyInput={activePrivacyInput}
                            setActivePrivacyInput={setActivePrivacyInput}
                        />
                    </div>
                    <Separator height={80} />
                    <LineBreak />
                    <Separator height={80} />
                    <ConsentForm
                        usingConsentForm={usingConsentForm}
                        setUsingConsentForm={setUsingConsentForm}
                        defaultFormOnMount={defaultFormOnMount}
                        setDefaultFormOnMount={setDefaultFormOnMount}
                        updateDraft={updateDraft}
                        onChangeTestAttribute={onChangeTestAttribute}
                        currentConsentForm={currentConsentForm}
                        setCurrentConsentForm={setCurrentConsentForm}
                        onConsentSave={onConsentSave}
                        name={name}
                        handleCheckbox={handleCheckbox}
                        setDraftJsonFunc={setDraftJsonFunc}
                    />
                    <Separator height={40} />
                    <ErrorsOnSave
                        tasks={tasks}
                        errors={errors}
                        draft={draft}
                        taskErrors={taskErrors}
                        screeners={screeners}
                        testNames={testNames}
                        name={name}
                        numTesters={numTesters}
                        savingDraft={savingDraft}
                        onSaveDraftAndExit={onSaveDraftAndExit}
                        onContinue={onContinue}
                        emailContact={emailContact}
                        currentPrivacyPolicy={currentPrivacyPolicy}
                        activePrivacyInput={activePrivacyInput}
                        creditSistem={creditSistem}
                        setCreditSistem={setCreditSistem}
                        stage={stage}
                        onCancel={() => {
                            navigate('/overview')
                        }}
                    />
                </div>
            </div>
        </DndProvider>
    ) : null
}

export default CreateTestPage
