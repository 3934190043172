import './_admin.scss'

import { getCode, getName, getNames } from 'country-list'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import PrimaryButton from '../../components/atoms/buttons/PrimaryButton'
import Separator from '../../components/atoms/layout/Separator'
import {
    sendGetRequest,
    sendPatchRequest,
    sendPostRequest,
    wrapRequestInRefreshToken
} from '../../components/utils/api'
import { objectToFormData } from '../../components/utils/functions'
import arrowBack from '../../images/arrow_back.svg'
import { NavigationContext, NavigationContextItem } from '../../navigation/navigate'

const countries = getNames()
const countriesToExclude = [
    'Belarus',
    'Cuba',
    'Iran, Islamic Republic of',
    "Korea, Democratic People's Republic of",
    'Russian Federation',
    'Sudan',
    'South Sudan',
    'Syrian Arab Republic'
]
const filteredCountries = countries.filter((country) => !countriesToExclude.includes(country))
const filteredAndSortedCountries = filteredCountries.slice().sort((a, b) => a.localeCompare(b))

const AdminDetails = (props) => {
    const [personalData, setPersonalData] = useState({})
    const [personalDataInit, setPersonalDataInit] = useState({})
    const [subscriptionData, setSubscriptionData] = useState({})
    const [subscriptionDataInit, setSubscriptionDataInit] = useState({})
    const [subscriptionType, setSubscriptionType] = useState('free')
    const [subscriptionInitialised, setSubscriptionInitialized] = useState(false)
    const paramName = useParams()
    const [isLoaded, setIsLoaded] = useState(null)

    const { setNavState } = React.useContext(NavigationContext)

    const handlePersonalDisabled = () => {
        const { erp_number: erpNumber, company_name: companyName, country } = personalData
        const {
            erp_number: erpNumberInit,
            company_name: companyNameInit,
            country: countryInit
        } = personalDataInit

        return !(
            erpNumber !== erpNumberInit ||
            companyName !== companyNameInit ||
            country !== countryInit
        )
    }

    const handleSubscriptionDisabled = () => {
        return !(
            subscriptionDataInit.active !== subscriptionData.active ||
            subscriptionDataInit.expiry_date !== subscriptionData.expiry_date
        )
    }

    const handleSubmitPersonal = (event) => {
        event.preventDefault()

        const errorFunc = (err) =>
            err.json().then((body) => {
                console.log('user patch error, ', body)
            })
        const successFunc = (json) => {
            setPersonalData(json)
            setPersonalDataInit(json)
        }

        const formData = objectToFormData({
            erp_number: personalData.erp_number,
            company_name: personalData.company_name,
            country: personalData.country
        })

        wrapRequestInRefreshToken(props, sendPatchRequest, [
            `user`,
            paramName.id,
            formData,
            successFunc,
            errorFunc
        ])
    }

    const handleSubmitSubscription = (event) => {
        event.preventDefault()

        const errorFunc = (err) => {
            err.json().then((body) => {
                console.log('user patch error, ', body)
            })
        }
        const successFunc = (json) => {
            if (!json) return
            setSubscriptionData(json)
            setSubscriptionDataInit(json)
        }

        const formData = objectToFormData({
            active: subscriptionType !== 'free',
            author: paramName.id,
            expiry_date: subscriptionData.expiry_date,
            subscription_length: subscriptionType === 'pro' ? 12 : 3
        })

        if (subscriptionInitialised) {
            wrapRequestInRefreshToken(props, sendPatchRequest, [
                'subscription',
                paramName.id,
                formData,
                successFunc,
                errorFunc
            ])
        } else {
            wrapRequestInRefreshToken(props, sendPostRequest, [
                'subscription',
                formData,
                successFunc,
                errorFunc
            ])
        }
    }

    const handleSubscriptionType = (json) => {
        if (!json) return
        setSubscriptionInitialized(true)
        if (json.active) {
            if (json.subscription_length === 12) {
                setSubscriptionType('pro')
            } else {
                setSubscriptionType('pro_trial')
            }
        } else {
            setSubscriptionType('free')
        }
    }

    function getOneYearFromTodayFormatted() {
        const today = new Date()
        today.setFullYear(today.getFullYear() + 1)
        const year = today.getFullYear()
        const month = (today.getMonth() + 1).toString().padStart(2, '0') // Months are 0-indexed
        const day = today.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${day}`
    }

    function getThreeMonthsFromTodayFormatted() {
        const today = new Date()
        today.setMonth(today.getMonth() + 3)
        const year = today.getFullYear()
        const month = (today.getMonth() + 1).toString().padStart(2, '0') // Months are 0-indexed
        const day = today.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${day}`
    }

    function getTodayFormatted() {
        const today = new Date()
        const year = today.getFullYear()
        const month = (today.getMonth() + 1).toString().padStart(2, '0') // Months are 0-indexed
        const day = today.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${day}`
    }

    const handleExpiryDate = (type) => {
        if (type === 'pro') {
            setSubscriptionData({
                ...subscriptionData,
                expiry_date: getOneYearFromTodayFormatted()
            })
        }
        if (type === 'pro_trial') {
            setSubscriptionData({
                ...subscriptionData,
                expiry_date: getThreeMonthsFromTodayFormatted()
            })
        }
        if (type === 'free') {
            setSubscriptionData({ ...subscriptionData, expiry_date: getTodayFormatted() })
        }
    }

    /**
     * GET User Endpoint
     */
    useEffect(() => {
        setNavState(NavigationContextItem.ADMIN_PANEL)

        const successFunc = (json) => {
            setPersonalData(json)
            isLoaded(true)
            setPersonalDataInit(json)

            if (!json.erp_number) {
                setPersonalData({ ...personalData, erp_number: 12345 })
            }
            if (!json.company_name) {
                setPersonalData({ ...personalData, company_name: 'N/A' })
            }
            if (!json.country) {
                setPersonalData({ ...personalData, country: 'SE' })
            }
        }
        const errorFunc = (err) => {
            isLoaded(true)
            err.json().then((body) => console.log('admin panel err: ', body))
        }

        if (isLoaded === null) {
            wrapRequestInRefreshToken(props, sendGetRequest, [
                `user/${paramName.id}`,
                successFunc,
                errorFunc
            ])
            setIsLoaded(true)
        }
    }, [paramName.id, props, personalData])

    /**
     * GET Subscription Endpoint
     */
    useEffect(() => {
        const successFunc = (json) => {
            setIsLoaded(true)
            if (!json) return

            handleSubscriptionType(json)
            setSubscriptionData(json)
            setSubscriptionDataInit(json)
        }

        const errorFunc = (err) => {
            setIsLoaded(true)
            if (err.status !== 404) return
            setSubscriptionType('free')
            setSubscriptionInitialized(false)
            setSubscriptionData({
                ...subscriptionData,
                active: false,
                author: paramName.id,
                expiry_date: getTodayFormatted()
            })
            setSubscriptionDataInit({
                ...subscriptionData,
                active: false,
                author: paramName.id,
                expiry_date: getTodayFormatted()
            })
        }

        if (isLoaded === null) {
            wrapRequestInRefreshToken(props, sendGetRequest, [
                `subscription/${paramName.id}`,
                successFunc,
                errorFunc
            ])
        }
    }, [personalData, paramName.id, props, subscriptionData, isLoaded])

    return (
        <div className="admin-details-container">
            <Link to={`/admin`}>
                <img
                    src={arrowBack}
                    alt="back arrow"
                    style={{ position: 'absolute', transform: 'translateX(-52px)' }}
                />
            </Link>

            <h2>{'User Details'}</h2>
            <p>{'Basic management of users and subscriptions.'}</p>

            <div className="card-wrapper">
                {personalData && (
                    <form onSubmit={(event) => handleSubmitPersonal(event)}>
                        <div className="form-container">
                            <div>
                                <h3>{'Personal data'}</h3>

                                <Separator height={16} />

                                <label>{'Name'}</label>
                                <input
                                    type="text"
                                    defaultValue={personalData.full_name}
                                    onChange={(e) =>
                                        setPersonalData({
                                            ...personalData,
                                            full_name: e.target.value
                                        })
                                    }
                                    disabled={true}
                                    style={{ opacity: '0.5', pointerEvents: 'none' }}
                                />
                                <Separator height={16} />

                                <label>{'Email'}</label>
                                <input
                                    type="email"
                                    defaultValue={personalData.email}
                                    onChange={(e) =>
                                        setPersonalData({ ...personalData, email: e.target.value })
                                    }
                                    disabled={true}
                                    style={{ opacity: '0.5', pointerEvents: 'none' }}
                                />

                                <div style={{ height: '16px' }} />

                                <label>{'Occupation'}</label>
                                <input
                                    type="text"
                                    defaultValue={personalData.occupation}
                                    onChange={(e) =>
                                        setPersonalData({
                                            ...personalData,
                                            occupation: e.target.value
                                        })
                                    }
                                    disabled={true}
                                    style={{ opacity: '0.5', pointerEvents: 'none' }}
                                />

                                <Separator height={16} />

                                <label>{'ERP Number'}</label>
                                <input
                                    defaultValue={personalData.erp_number}
                                    onChange={(e) =>
                                        setPersonalData({
                                            ...personalData,
                                            erp_number: e.target.value
                                        })
                                    }
                                />

                                <Separator height={16} />

                                <label>{'Company'}</label>
                                <input
                                    defaultValue={personalData.company_name}
                                    onChange={(e) =>
                                        setPersonalData({
                                            ...personalData,
                                            company_name: e.target.value
                                        })
                                    }
                                />

                                <Separator height={16} />

                                <label>{'Country'}</label>
                                <select
                                    name={'country'}
                                    defaultValue={
                                        personalData.country && getName(personalData.country)
                                    }
                                    onChange={(e) =>
                                        setPersonalData({
                                            ...personalData,
                                            country: getCode(e.target.value)
                                        })
                                    }>
                                    <option defaultValue={''}>{''}</option>
                                    {filteredAndSortedCountries.map((country) => (
                                        <option key={country} value={country}>
                                            {country}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="buttons-wrapper">
                                <PrimaryButton disabled={handlePersonalDisabled()} type="submit">
                                    Save Changes
                                </PrimaryButton>
                            </div>
                        </div>
                    </form>
                )}

                {subscriptionData && (
                    <form onSubmit={(event) => handleSubmitSubscription(event)}>
                        <div className="form-container">
                            <div>
                                <h3>{'Subscription Plan'}</h3>

                                <Separator height={16} />

                                <label>{'Subscription Type'}</label>
                                <select
                                    name={'subscription_type'}
                                    value={subscriptionType}
                                    onChange={(e) => {
                                        setSubscriptionType(e.target.value)
                                        handleExpiryDate(e.target.value)
                                    }}>
                                    <option value="pro"> {'Pro'} </option>
                                    <option value="pro_trial"> {'Pro Trial'} </option>
                                    <option value="free"> {'Free'} </option>
                                </select>

                                <div style={{ height: '16px' }} />

                                <div style={{ display: subscriptionType === 'free' && 'none' }}>
                                    <label>{'Plan end date'}</label>
                                    <input
                                        type="date"
                                        value={subscriptionData.expiry_date}
                                        onChange={(e) =>
                                            setSubscriptionData({
                                                ...subscriptionData,
                                                expiry_date: e.target.value
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            <div className="buttons-wrapper">
                                <PrimaryButton
                                    disabled={handleSubscriptionDisabled()}
                                    type="submit">
                                    Save Changes
                                </PrimaryButton>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}

export default AdminDetails
