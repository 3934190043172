import React, { useEffect, useState } from 'react'
import RichTextEditor from 'react-rte'

const RichTextPrivacy = (props) => {
    const { currentPrivacyPolicy, setCurrentPrivacyPolicy, currentTextInput, assignValueClosed } =
        props

    const [value, setValue] = useState(RichTextEditor.createEmptyValue())

    const styleMap = {
        BOLD: {
            fontWeight: '700'
        }
    }

    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'rte__button' },
            { label: 'Italic', style: 'ITALIC', className: 'rte__button' },
            { label: 'Underline', style: 'UNDERLINE', className: 'rte__button' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled', className: 'rte__button' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item', className: 'rte__button' },
            { label: 'OL', style: 'ordered-list-item', className: 'rte__button' }
        ]
    }

    /**
     * Remove predefined style class for buttons
     */
    useEffect(() => {
        const element = document.getElementsByClassName('Button__root___1gz0c')
        if (element[0] !== undefined) {
            const classes = element[0].classList
            classes.remove('Button__root___1gz0c')
        }
    })

    /**
     * Loads correct privacy
     */
    useEffect(() => {
        if (currentTextInput && assignValueClosed === false) {
            if (currentTextInput !== value.toString('html')) {
                setValue(RichTextEditor.createValueFromString(currentTextInput, 'html'))
            }
        }
    }, [currentTextInput, value, assignValueClosed])

    return (
        <RichTextEditor
            id="rich-text-editor"
            className={'create__text__editor'}
            toolbarConfig={toolbarConfig}
            customStyleMap={styleMap}
            value={value}
            placeholder={'Your privacy notice...'}
            onChange={(e) => {
                setValue(e)
                setCurrentPrivacyPolicy({ ...currentPrivacyPolicy, body: e.toString('html') })
            }}
        />
    )
}

export default RichTextPrivacy
