import './auth.scss'

import React, { useState } from 'react'
import styled from 'styled-components'

import dropdownIcon from '../../images/dropdown_icon.svg'

const ClickableBack = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
`
const InputContainer = styled.div.attrs({ className: 'input__field' })`
    position: relative;
`
const Input = styled.div.attrs({ className: 'authDropdown' })`
    position: absolute;
    z-index: 40;
    background-color: white;
    overflow: hidden;
    display: inherit;
    transition: height 300ms;
    cursor: pointer;
    outline: ${(props) => props.inputInFocus && '2px solid #4b8cf7'};
`
const HeaderOption = styled.div`
    height: 48px;
    padding-left: 20px;
    font-size: 16px;
    color: #2c3032;
`
const DropdownIcon = styled.img`
    position: absolute;
    margin-top: -28px;
    margin-left: 294px;
`
const Option = styled.div`
    height: 48px;
    padding-top: 12px;
    padding-left: 20px;
    font-size: 16px;
    white-space: nowrap;

    &:hover {
        background-color: #f7fdfd;
    }
`
const Break = styled.div`
    height: 2px;
    background-color: #4b8cf7;
    width: calc(100% + 20px);
    margin-left: -20px;
`
const InputHelpers = styled.div`
    display: flex;
    justify-content: space-between;
`
const HelperText = styled.div`
    opacity: ${(props) => !props.error && '0'};
    color: #ff6262;
    font-size: 13px;
    position: absolute;
    top: 72px;
    margin-top: 4px;
    padding-left: 20px;
`

const DropdownInput = ({ label, error, setError, onChange, placeholder, value, icon, options }) => {
    const [inputInFocus, setInputInFocus] = useState(false)

    return (
        <>
            {inputInFocus && (
                <ClickableBack
                    onClick={() => {
                        inputInFocus && setInputInFocus(null)
                    }}
                />
            )}
            <InputContainer className={inputInFocus === true ? 'active' : ''}>
                <label> {label} </label>
                <Input
                    onClick={() => {
                        !inputInFocus ? setInputInFocus(true) : setInputInFocus(false)
                        setError(false)
                    }}
                    placeholder={placeholder}
                    type="text"
                    value={value}
                    // inputInFocus={inputInFocus}
                    // error={error}
                >
                    <HeaderOption>
                        <div
                            style={{
                                width: '280px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                color: !value && '#64748b',
                                fontSize: value && '14px',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                            {!value ? placeholder : value}
                        </div>
                        {icon && (
                            <DropdownIcon src={dropdownIcon} alt={'dropdown icon'}></DropdownIcon>
                        )}
                    </HeaderOption>
                    {inputInFocus && <Break />}
                    <div
                        style={{
                            height: inputInFocus ? '198px' : '0px',
                            overflowY: 'scroll',
                            overflowX: 'hidden'
                        }}>
                        {options.map((option, idx) => {
                            return (
                                <div key={option}>
                                    <Option onClick={() => onChange(option)}>{option}</Option>
                                    {idx !== options.length - 1 && <Break />}
                                </div>
                            )
                        })}
                    </div>
                </Input>

                <InputHelpers>
                    <HelperText error>{error}</HelperText>
                </InputHelpers>
            </InputContainer>

            {error && <div style={{ height: '16px' }} />}
        </>
    )
}

export default DropdownInput
