import './createtestcontainer.scss'

import React from 'react'

import deleteIcon from '../../images/delete_icon_pink_medium.svg'
import PrimaryButton from '../atoms/buttons/PrimaryButton'
import SecondaryButton from '../atoms/buttons/SecondaryButton'

const ConfirmNoConsentModal = (props) => {
    const {
        confirmNoConsentModal,
        setConfirmNoConsentModal,
        setUsingConsentForm,
        currentConsentForm,
        setCurrentConsentForm,
        onConsentSave
    } = props

    return (
        <div id="confirmExitModal" className={'confirm-consent-modal'}>
            {confirmNoConsentModal === true && (
                <div>
                    <div className={'consent-container'} />

                    <div className={'modal-content'}>
                        <div className="confirmation-modal">
                            <img alt="delete icon" src={deleteIcon} style={{ height: '32px' }} />
                            <div className={'text-container'}>
                                <div className="confirmation__modal__subheader">
                                    You are required to get consent from your test participants.
                                </div>
                                <div style={{ height: '16px' }} />
                                <div>
                                    By closing this form, you acknowledge that you will aquire
                                    consent via your own means.
                                </div>
                            </div>

                            <div className="buttons-container">
                                <div style={{ width: '32px' }} />
                                <SecondaryButton onClick={() => setConfirmNoConsentModal(false)}>
                                    Cancel
                                </SecondaryButton>
                                <PrimaryButton
                                    onClick={() => {
                                        setUsingConsentForm(false)
                                        setConfirmNoConsentModal(false)
                                        setCurrentConsentForm({
                                            ...currentConsentForm,
                                            default: false,
                                            body: ''
                                        })
                                        setTimeout(() => {
                                            onConsentSave()
                                        }, 1000)
                                    }}>
                                    I understand
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ConfirmNoConsentModal
