import { activePath } from '../navigation/navigate'

export const isLinkActive = (link) => {
    try {
        const pathParams = link?.split('/').filter((item) => item)
        return pathParams[0] === activePath()
    } catch (e) {
        console.log('error', e)
        return false
    }
}

export const isLinkValidUrl = (link) => {
    try {
        const url = new URL(link)
        if (url.origin !== 'null') {
            return true
        }
        return false
    } catch (e) {
        return false
    }
}
