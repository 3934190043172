import './faq.css'

import React from 'react'

export default function FAQCsvList({ csv }) {
    const regex = /[$§]/

    const urlLink = (link, arr, idx) => {
        const id = arr[idx + 1]
        if (id && id.trim().split(' ')[0].startsWith('µ')) {
            return link
        }

        return `mailto:${link}`
    }

    const replaceLink = (link, arr, idx) => {
        const id = arr[idx + 1]
        // Replacing the url with an specific word if needed
        if (id && id.trim().split(' ')[0].startsWith('µ')) {
            return id.trim().split(' ')[0].slice(1)
        }
        return link
        // return !arr[ idx + 1 ]?.trim().split(' ')[ 0 ].startsWith('µ') ? link : arr[ idx + 1 ]?.trim().split(' ')[ 0 ].slice(1)
    }

    return (
        <>
            {csv
                ? csv.map((item, index) => (
                      <div key={index}>
                          <div className="faq-body-text-header">{item.Question}</div>
                          <div className="faq-body-text-body">
                              {item.Answer &&
                                  item.Answer.split(regex).map((link, idx, arr) => {
                                      if (link.trim().startsWith('µ')) {
                                          // Finding if the element in the file will be return as text for a url or not:
                                          // Example in the csv link appear  -> https://www.google.com/ µGoogle. In the FAQ appear only -> Google
                                          link = link.trim().split(' ').slice(1).join(' ')
                                      }
                                      // Finding the Link itself, it has to start with character "$" and ending with "§".
                                      return link ===
                                          item.Answer.substring(
                                              item.Answer.lastIndexOf('$') + 1,
                                              item.Answer.lastIndexOf('§')
                                          ) // Converting the string coming from the csv in to a link tag
                                          ? link.length > 0 && (
                                                <a
                                                    key={idx}
                                                    href={urlLink(link, arr, idx)}
                                                    className="green__link"
                                                    target="blank"
                                                    rel="nooper noreferrer">
                                                    {replaceLink(link, arr, idx)}
                                                </a>
                                            )
                                          : link.split('|').map(
                                                (
                                                    item,
                                                    index // Creating unordered list  if needed
                                                ) =>
                                                    item.startsWith('-') ? (
                                                        <ul key={index} className="bullet__list">
                                                            <li>{item}</li>
                                                        </ul> // Checking if after the unordered list we still having some text to display
                                                    ) : (
                                                        <span
                                                            key={index}
                                                            className="faq-body-text-body">
                                                            {item.startsWith('**')
                                                                ? item.slice(2)
                                                                : item}
                                                        </span>
                                                    )
                                            )
                                  })}
                          </div>
                      </div>
                  ))
                : undefined}{' '}
        </>
    )
}
