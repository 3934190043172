import './explorecard.css'

import Modal from '@material-ui/core/Modal'
import React, { useEffect, useRef } from 'react'

import deleteIconPink from '../../images/delete_icon_pink_medium.svg'
import { getComparator, stableSort } from '../utils/functions'
import Tooltip from '../utils/Tooltip'
import ExploreCardControls from './ExploreCardControls'
import TesterTable from './TesterTable'

/**
 *
 * @param {*} props
 */
const TesterDeletionButton = (props) => {
    const {
        disabled,
        onDeleteTester,
        selectedRows,
        setShowingTesterDeletionModal,
        showingTesterDeletionModal
    } = props

    return (
        <div className="tester__deletion__button">
            <button
                className="button primary"
                disabled={disabled}
                onClick={() => setShowingTesterDeletionModal(true)}>
                <div className="button__text">
                    Delete Participant{selectedRows.length > 1 ? 's' : ''}
                </div>
            </button>

            <Modal
                aria-labelledby="tester-deletion-modal"
                className="tester__deletion__modal explore__card__modal"
                open={showingTesterDeletionModal}
                onClose={() => setShowingTesterDeletionModal(false)}>
                <div className="inner__div">
                    <img alt="delete_icon" src={deleteIconPink} />

                    <div className="modal__content">
                        <div className="header__text">
                            {`You are about to delete ${selectedRows.length === 0 ? 'a tester' : 'some testers'}`}
                        </div>
                        <div className="body__text">
                            {`This will permanently delete all data from the selected tester${selectedRows.length > 0 ? 's' : ''}.`}
                            <br /> Are you sure?
                        </div>
                    </div>

                    <div className="modal__footer">
                        <div className="modal__footer__buttons">
                            <button
                                className="button secondary"
                                onClick={() => setShowingTesterDeletionModal(false)}>
                                <div className="button__text"> Cancel</div>
                            </button>
                            <button className="button primary" onClick={onDeleteTester}>
                                <div className="button__text">
                                    {' '}
                                    Delete Participant{selectedRows.length > 1 ? 's' : ''}{' '}
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

/**
 *
 * @param {*} props
 */
const ExploreCardContent = (props) => {
    const {
        headerCells,
        onDeleteTester,
        selectedRows,
        setShowingExpandedTable,
        setShowingTesterDeletionModal,
        showingTesterDeletionModal,
        tableView,
        tableViewData,
        test,
        testerTableProps,
        showingExpandedTable,
        viewIcon,
        testers
    } = props

    return (
        <div className="explore__card__content">
            {/* explore card controls (download & expand) */}
            <ExploreCardControls
                headerCells={headerCells}
                setShowingExpandedTable={setShowingExpandedTable}
                tableView={tableView}
                tableViewData={tableViewData}
                test={test}
                viewIcon={viewIcon}
                testers={testers}
            />

            {/* explore card title & tooltip */}
            <div className="card__title">Explore</div>
            <Tooltip className="explore__card__tooltip" id="exploreCardTooltip">
                View all your test statistics either in aggregate (grouped by task, tester, or AB
                group) or in full detail.
            </Tooltip>

            {/* delete tester button */}
            {tableView === 2 && (
                <TesterDeletionButton
                    disabled={selectedRows.length === 0}
                    onDeleteTester={onDeleteTester}
                    selectedRows={selectedRows}
                    setShowingTesterDeletionModal={setShowingTesterDeletionModal}
                    showingTesterDeletionModal={showingTesterDeletionModal}
                />
            )}
            {/* allow more space if rendering in expanded mode */}
            {showingExpandedTable && <div style={{ marginTop: '80px' }} />}

            {/* the magical tester table */}
            <TesterTable {...testerTableProps} />
        </div>
    )
}

/**
 *
 * @param {*} props
 */
const ExploreCard = (props) => {
    const {
        barChartData,
        filters,
        headerCells,
        isAbTest,
        onDeleteTester,
        selectedRows,
        setBarChartData,
        setSelectedRows,
        setShowingExpandedTable,
        setShowingTesterDeletionModal,
        setTableOrder,
        setTableOrderBy,
        setTableView,
        setTableViews,
        showingExpandedTable,
        showingTesterDeletionModal,
        tableOrder,
        tableOrderBy,
        tableView,
        tableViews,
        test,
        testers
    } = props
    const tableViewData = tableViews[tableView]

    const isFirstRender = useRef(true)
    const tableViewRef = useRef(tableView)

    /**
     *
     * @param {*} rowData
     */
    const onChangeRowSelection = (rowData) => {
        const rowIdx =
            tableView === 1
                ? `${rowData.task + 1}${rowData.ab != null ? rowData.ab : ''}`
                : rowData.index
        let newBarChartData = [...barChartData]
        const newSelectedRows = [...selectedRows]
        if (selectedRows.indexOf(rowData.index) === -1) {
            if ((tableView === 1 || tableView === 3) && rowData.completionRate === '0/0') {
                return
            } else {
                newSelectedRows.push(rowData.index)
                if (filters && filters.length > 0) {
                    filters.forEach((metricName) => {
                        newBarChartData.push({
                            index: rowIdx,
                            metric: metricName,
                            value: parseFloat(rowData[metricName])
                        })
                    })
                }
            }
        } else {
            newSelectedRows.splice(newSelectedRows.indexOf(rowData.index), 1)
            if (barChartData && barChartData.length > 0) {
                newBarChartData = barChartData.filter((x) => x.index !== rowIdx)
            }
        }
        setBarChartData(newBarChartData)
        setSelectedRows(newSelectedRows)
    }

    /**
     *
     * @param {*} newTableView
     */
    const onChangeTableView = (newTableView) => {
        setBarChartData([])
        setSelectedRows([])
        setTableView(newTableView)
    }

    /**
     * Function called when user clicks button to (de)select all table rows.
     */
    const onSelectAllRows = () => {
        if (selectedRows.length !== 0) {
            setBarChartData([])
            setSelectedRows([])
        } else {
            const newData = []

            const tableViewDataClean =
                tableView === 0 || tableView === 1
                    ? tableViewData.filter((row) => !row.pauseRecording)
                    : tableViewData
            const newSelectedRows = tableViewDataClean
                .map((elt) => elt.index)
                .filter((idx) => {
                    if (tableView === 3) {
                        if (idx === 'A' && tableViewData[0].completionRate === '0/0') {
                            return false
                        } else if (idx === 'B' && tableViewData[1].completionRate === '0/0') {
                            return false
                        } else {
                            return true
                        }
                    } else if (tableView === 1 && tableViewData[idx].completionRate === '0/0') {
                        return false
                    } else {
                        return true
                    }
                })
            if (filters && filters.length > 0) {
                filters.forEach((metricName) => {
                    newSelectedRows.forEach((idx) => {
                        let rowData
                        if (idx === 'A' || idx === 'B') {
                            rowData = tableViewData[idx === 'A' ? 0 : 1]
                        } else {
                            rowData = tableViewData.find((r) => r.index === idx)
                        }
                        newData.push({
                            index:
                                tableView === 1
                                    ? `${rowData.task + 1}${rowData.ab != null ? rowData.ab : ''}`
                                    : rowData.index,
                            metric: metricName,
                            value: rowData[metricName] == null ? 0 : parseFloat(rowData[metricName])
                        })
                    })
                })
            }
            setBarChartData(newData)
            setSelectedRows(newSelectedRows)
        }
    }

    /**
     *
     * @param {*} newTableOrderBy
     */
    const onSortTable = (newTableOrderBy) => {
        const isAsc = tableOrderBy === newTableOrderBy && tableOrder === 'asc'
        const newTableOrder = isAsc ? 'desc' : 'asc'
        const newTableViews = { ...tableViews }
        newTableViews[tableView] = stableSort(
            newTableViews[tableView],
            getComparator(newTableOrder, newTableOrderBy)
        )
        if (tableOrder !== newTableOrder) {
            setTableOrder(newTableOrder)
        }
        if (tableOrderBy !== newTableOrderBy) {
            setTableOrderBy(newTableOrderBy)
        }
        setTableViews(newTableViews)
    }

    /**
     * Init Explore with all rows selected
     */
    useEffect(() => {
        if (isFirstRender.current) {
            onSelectAllRows()
            isFirstRender.current = false
        }
    })

    /**
     * Select all rows when the selected Explore tab changes
     */
    useEffect(() => {
        if (tableView !== tableViewRef.current) {
            onSelectAllRows()
            tableViewRef.current = tableView
        }
    })

    const testerTableProps = {
        isAbTest,
        headerCells,
        onChangeRowSelection,
        onChangeTableView,
        onSelectAllRows,
        onSortTable,
        order: tableOrder,
        orderBy: tableOrderBy,
        selectedRows,
        tableView,
        tableViewData
    }

    return (
        <div className="card explore__card">
            {!showingExpandedTable && (
                <ExploreCardContent
                    headerCells={headerCells}
                    onDeleteTester={onDeleteTester}
                    selectedRows={selectedRows}
                    setShowingExpandedTable={setShowingExpandedTable}
                    setShowingTesterDeletionModal={setShowingTesterDeletionModal}
                    showingTesterDeletionModal={showingTesterDeletionModal}
                    tableView={tableView}
                    tableViewData={tableViewData}
                    test={test}
                    testers={testers}
                    testerTableProps={testerTableProps}
                    showingExpandedTable={false}
                    viewIcon="expand"
                />
            )}

            <Modal
                aria-labelledby="expanded-table-modal"
                className="expanded__table__modal explore__card__modal"
                open={showingExpandedTable}
                onClose={() => setShowingExpandedTable(false)}>
                <div className="inner__div">
                    <div className="modal__content">
                        {showingExpandedTable && (
                            <ExploreCardContent
                                headerCells={headerCells}
                                onDeleteTester={onDeleteTester}
                                selectedRows={selectedRows}
                                setShowingExpandedTable={setShowingExpandedTable}
                                setShowingTesterDeletionModal={setShowingTesterDeletionModal}
                                showingTesterDeletionModal={showingTesterDeletionModal}
                                tableView={tableView}
                                tableViewData={tableViewData}
                                test={test}
                                testers={testers}
                                testerTableProps={testerTableProps}
                                showingExpandedTable={true}
                                viewIcon="contract"
                            />
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default React.memo(ExploreCard)
