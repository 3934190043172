// import './styles/legacy-style/_index.scss'
// import './styles/legacy-style/_buttons.scss'
// import './styles/legacy-style/_inputs.scss'
// import './styles/legacy-style/_fonts.scss'
// New style refactoring
import './styles/app.scss'

// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
import React from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'

import App from './App'

// if (process.env.NODE_ENV !== 'development') {
//    Sentry.init({
//        dsn: 'https://f9d5fb0aadaf45c1ac7077ef87baeee6@o1152318.ingest.sentry.io/4505193528164352',
//        integrations: [new Integrations.BrowserTracing()],
//        tracesSampleRate: 1.0,
//        environment: process.env.SENTRY_ENVIRONMENT
//    })
// }

createRoot(document.getElementById('body')).render(<App />)
// ReactDOM.render(<App />, document.getElementById('body'))
