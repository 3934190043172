import React, { useCallback, useEffect, useRef } from 'react'

/**
 *
 * @param {*} callback
 */
export function useOuterClick(callback) {
    const callbackRef = useRef() // initialize mutable callback ref
    const innerRef = useRef() // returned to client, who sets the "border" element

    // update callback on each render, so second useEffect has most recent callback
    useEffect(() => {
        callbackRef.current = callback
    })
    useEffect(() => {
        document.addEventListener('click', handleClick)

        return () => document.removeEventListener('click', handleClick)

        function handleClick(e) {
            if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
                callbackRef.current(e)
            }
        }
    }, []) // no dependencies -> stable click listener

    return innerRef // convenience for client (doesn't need to init ref himself)
}

/**
 * React functional component for wrapping component in 'click-outside' event handler.
 * @param {*} param0
 */
export default function ClickOutside({ children, onClick }) {
    const refs = React.Children.map(children, () => React.createRef())

    const handleClick = useCallback((e) => {
        const isOutside = refs.every((ref) => {
            return ref.current == null || !ref.current.contains(e.target)
        })

        if (isOutside) {
            onClick()
        }
    }, [])

    useEffect(() => {
        document.addEventListener('click', handleClick)

        return () => {
            document.removeEventListener('click', handleClick)
        }
    })

    return React.Children.map(children, (element, idx) =>
        React.cloneElement(element, { ref: refs[idx] })
    )
}
