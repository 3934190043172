export const BackgroundType = {
    WHITE: 'white',
    BLUE: 'blue'
}

export const ContentAlign = {
    CENTER: 'center',
    FLEX_START: 'flex-start',
    FLEX_END: 'flex-end'
}
