import './_bottom-link.scss'

import React from 'react'

export default function BottomLink({ message, link, linkText }) {
    return (
        <p className={'bottom-link'}>
            {message} <a href={link}>{linkText}</a>
        </p>
    )
}
