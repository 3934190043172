import './_launchmodal.scss'

import React, { useEffect } from 'react'
import styled from 'styled-components'

import closeIcon from '../../images/icons/closeIcon.svg'
import PrimaryButton from '../atoms/buttons/PrimaryButton'

const ModCont = styled.div.attrs({ className: 'close__modal' })`
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #00000066;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
`
const InnerModCont = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 6px -5px rgba(0, 0, 0, 0.15);
    justify-content: center;
    width: 720px;
    padding: 2rem 2.4rem;
`

const TitleCont = styled.div`
    ${{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
`
const Title = styled.h5`
    ${{ fontSize: '20px', fontWeight: '700' }}
`
const Close = styled.h5`
    ${{ fontSize: '20px', fontWeight: '700', cursor: 'pointer' }}
`

const InfoCont = styled.div`
    ${{
        width: '100%',
        borderRadius: '4px',
        margin: '2.4rem 0 2rem',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '1.6rem'
    }}
`
const InfoBox = styled.div`
    ${{
        width: '48%',
        backgroundColor: '#F6FAFD',
        padding: '24px',
        borderRadius: 6,
        border: '1px solid #DEDFE3'
    }}
`

const ActionCont = styled.div`
    ${{ width: '100%', display: 'flex', justifyContent: 'center' }}
`

const MultiParticipantCont = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    gap: 1.5rem;
`
const ToggleText = styled.div.attrs({ className: 'info-toggle-title' })`
    ${{ display: 'flex', alignItems: 'center' }}
`
const ToggleCont = styled.div`
    ${{
        height: '24px',
        width: '44px',
        backgroundColor: (props) => (props.multiPersonDevice ? '#EEF4FE' : '#EBECF0'),
        borderRadius: '24px',
        cursor: 'pointer'
    }}
`
const Toggle = styled.div`
    ${{
        height: '20px',
        width: '20px',
        margin: '2px',
        backgroundColor: (props) => (props.multiPersonDevice ? '#4B8CF7' : 'white'),
        boxShadow:
            '0px 2.68125px 2.4375px -1.4625px rgba(14, 14, 44, 0.2), inset 0px -0.4875px 1px rgba(14, 14, 44, 0.2)',
        borderRadius: '50%',
        transform: (props) => `translateX(${props.multiPersonDevice ? '0px' : '20px'})`,
        transition: 'transform 300ms'
    }}
`

const MultiParticipantModal = ({
    multiPersonDevice,
    setMultiPersonDevice,
    testID,
    onClickCancel,
    onClickClose,
    multiParticipantModalOpen
}) => {
    /**
     * Allow mult-participant by default
     */
    useEffect(() => {
        if (!multiParticipantModalOpen) return
        setMultiPersonDevice(testID)
    }, [multiParticipantModalOpen, setMultiPersonDevice, testID])

    return (
        multiParticipantModalOpen && (
            <ModCont>
                <InnerModCont>
                    <TitleCont>
                        <Title>{'Allow participants to run your test in the same device?'}</Title>
                        <Close onClick={() => onClickCancel()}>
                            <img src={closeIcon} alt="Close modal" />
                        </Close>
                    </TitleCont>

                    <InfoCont>
                        <InfoBox className="info-box">
                            <h5>{'Allow'}</h5>
                            <p>
                                if your test is <strong>moderated</strong> and you want to use the
                                same mobile device with all your test participants in the same room.
                            </p>
                        </InfoBox>
                        <InfoBox className="info-box">
                            <h5>{'Dont allow'}</h5>
                            <p>
                                if your test is <strong>remote</strong>, and you want to prevent
                                your participants from completing your test multiple times, for
                                example to get multiple rewards.
                            </p>
                        </InfoBox>
                    </InfoCont>

                    <MultiParticipantCont>
                        <ToggleText>{'Allow'}</ToggleText>

                        <ToggleCont
                            multiPersonDevice={multiPersonDevice}
                            onClick={() => setMultiPersonDevice((prev) => (prev ? null : testID))}>
                            <Toggle multiPersonDevice={multiPersonDevice} />
                        </ToggleCont>

                        <ToggleText>{"Don't allow"}</ToggleText>
                    </MultiParticipantCont>

                    <ActionCont>
                        <PrimaryButton onClick={() => onClickClose()}>
                            {'OK, Launch!'}
                        </PrimaryButton>
                    </ActionCont>
                </InnerModCont>
            </ModCont>
        )
    )
}

export default MultiParticipantModal
