import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import CompleteProgressbar from '../../images/progressbar_svgs/complete_progressbar.svg'
import DraftProgressbar from '../../images/progressbar_svgs/draft_progressbar.svg'
import EmptyProgressbar from '../../images/progressbar_svgs/empty_progressbar.svg'
import ResponsiveComplete from '../../images/progressbar_svgs/Falcon_1_Responsive_Progressbar/responsive_Complete_stage.svg'
import ResponsiveDraft from '../../images/progressbar_svgs/Falcon_1_Responsive_Progressbar/responsive_Draft_stage.svg'
import ResponsiveEmpty from '../../images/progressbar_svgs/Falcon_1_Responsive_Progressbar/responsive_Empty_stage.svg'
import ResponsiveLaunch from '../../images/progressbar_svgs/Falcon_1_Responsive_Progressbar/responsive_Launch_stage.svg'
import ResponsiveReview from '../../images/progressbar_svgs/Falcon_1_Responsive_Progressbar/responsive_Review_stage.svg'
import LaunchProgressbar from '../../images/progressbar_svgs/launch_progressbar.svg'
import ReviewProgressbar from '../../images/progressbar_svgs/review_progressbar.svg'

const ProgressbarContainer = styled.div`
    margin-left: 0;
    width: 50%;
    position: absolute;
    display: flex;
    padding-left: 0;
    top: 32px;
    z-index: 100;
`

// eslint-disable-next-line react/prop-types
function StageState({ stage, max }) {
    const [stateInCreation, setStateInCreation] = useState(null)
    const [altStages, setAlStages] = useState('Draft state')
    const [size, setSize] = useState(window.innerWidth)
    // Helper to change color and text base on the stage

    /**
     * Window Size Listener
     */
    useMemo(() => {
        window.addEventListener('resize', (ev) => {
            setSize(window.innerWidth)
        })
    }, [])

    /**
     * Correct style for paused and suspended stages
     * @returns
     */
    const progressStyle = useCallback(() => {
        if (stage === 'paused' || stage === 'suspended') {
            return { opacity: '0.5', marginRight: '16px' }
        } else {
            return { opacity: '0.8', marginRight: '16px' }
        }
    }, [stage])

    /**
     * Assign Progress Bar to correct stage
     */
    useEffect(() => {
        switch (stage) {
            case 'draft':
                size < 1020
                    ? setStateInCreation(ResponsiveDraft)
                    : setStateInCreation(DraftProgressbar)
                setAlStages('Draft state')
                break
            case 'review':
                size < 1020
                    ? setStateInCreation(ResponsiveReview)
                    : setStateInCreation(ReviewProgressbar)
                setAlStages('Review state')
                break
            case 'live':
                size < 1020
                    ? setStateInCreation(ResponsiveLaunch)
                    : setStateInCreation(LaunchProgressbar)
                setAlStages('Launch state')
                break
            case 'complete':
                size < 1020
                    ? setStateInCreation(ResponsiveComplete)
                    : setStateInCreation(CompleteProgressbar)
                setAlStages('Complete state')
                break
            case 'paused':
            case 'suspended':
                // review
                if (max === 3) {
                    size < 1020
                        ? setStateInCreation(ResponsiveReview)
                        : setStateInCreation(ReviewProgressbar)
                    setAlStages('Review state')
                }
                // live
                if (max === 10000) {
                    size < 1020
                        ? setStateInCreation(ResponsiveLaunch)
                        : setStateInCreation(LaunchProgressbar)
                    setAlStages('Launch state')
                }
                break
            default:
                size < 1020
                    ? setStateInCreation(ResponsiveEmpty)
                    : setStateInCreation(EmptyProgressbar)
                setAlStages('Empty state')
        }
    }, [stage, size, max])

    return (
        <ProgressbarContainer>
            <img alt={altStages} src={stateInCreation} style={progressStyle()} />
            {stage === 'paused' && <>Paused</>}
            {stage === 'suspended' && <>Suspended</>}
        </ProgressbarContainer>
    )
}

export default StageState
