import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { RouterProvider } from 'react-router-dom'
import { ProgressBar } from 'react-step-progress-bar'
import { Userpilot } from 'userpilot'

import Header from './components/layout/Header'
import SideBar from './components/organisms/layout/SideBar'
import { getUserFromStorage } from './data/entities/user'
import { NavigationContext, NavigationContextItem } from './navigation/navigate'
import { router } from './navigation/Routes'

const App = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [user, setUser] = useState(null)
    const [plan, setPlan] = useState('')
    const [expires, setExpires] = useState('')
    const [renews, setRenews] = useState(true)
    const [creditsModalVisible, setCreditsModalVisible] = useState(false)
    const [participantFeedback, setParticipantFeedback] = useState(false)
    const [progressBar, setProgressBar] = useState(null)
    const [navState, setNavState] = useState(NavigationContextItem.NO_NAVIGATION)
    const navValue = { navState, setNavState }

    Userpilot.initialize('NX-45f0b349')

    /**
     * Create window resize listener to determine which breakpoint we are in for rendering.
     */
    useEffect(() => {
        let resizeTimer
        window &&
            window.addEventListener('resize', () => {
                clearTimeout(resizeTimer)
                resizeTimer = setTimeout(() => {
                    setWindowWidth(window.innerWidth)
                }, 150)
            })
        if (!user) {
            setUser(getUserFromStorage())
        }
    }, [user, setUser, windowWidth])

    return (
        <React.StrictMode>
            <NavigationContext.Provider value={navValue}>
                {user !== null && (
                    <header>
                        <Header
                            setPlan={setPlan}
                            setExpires={setExpires}
                            setRenews={setRenews}
                            creditsModalVisible={creditsModalVisible}
                            setCreditsModalVisible={setCreditsModalVisible}
                            user={user}
                        />
                    </header>
                )}
                {navState !== NavigationContextItem.NO_NAVIGATION && (
                    <SideBar
                        participantFeedback={participantFeedback}
                        plan={plan}
                        setCreditsModalVisible={setCreditsModalVisible}
                        onChangeState={(state) => {
                            setNavState(state)
                        }}
                    />
                )}
                <main>
                    <RouterProvider
                        router={router({
                            windowWidth,
                            plan,
                            expires,
                            renews,
                            participantFeedback,
                            setParticipantFeedback,
                            setExpires,
                            setPlan
                        })}
                    />
                </main>
                {progressBar > 0 && (
                    <ProgressBar progressBar={progressBar} setProgressBar={setProgressBar} />
                )}
            </NavigationContext.Provider>
        </React.StrictMode>
    )
}

export default App

App.propTypes = {
    windowWidth: PropTypes.number,
    component: PropTypes.element
}
