import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

// Components
const ButtonContainer = styled.div``
const Button = styled.button.attrs({ className: 'secondary' })`
    width: 150px;
    margin-top: 30px;
`

const OverviewActions = (props) => {
    const {
        test,
        onClickEditTest,
        onClickLaunchLiveTest,
        setInvitationModalOpen,
        onClickResumeTest
    } = props

    const navigate = useNavigate()

    const ctas = [
        {
            name: 'test in draft',
            condition: test.stage === 'draft',
            button: 'Edit',
            func: onClickEditTest,
            props: null
        },
        {
            name: 'test in review but no participants',
            condition: test.stage === 'review' && test.count === 0,
            button: 'Share',
            func: setInvitationModalOpen,
            props: test.id
        },
        {
            name: 'test in review and participants between 0 and 3',
            condition: test.stage === 'review' && test.count > 0 && test.count < 3,
            button: 'Test results',
            func: navigate.push,
            props: { pathname: `/test/${test.id}`, state: { curTest: test } }
        },
        {
            name: 'test in review, 3 participants but not enough credits',
            condition: test.stage === 'review' && test.count === 3,
            button: 'Launch',
            func: onClickLaunchLiveTest,
            props: null
        },
        {
            name: 'test live, no participants but credits',
            condition: test.stage === 'live' && test.count === 0,
            button: 'Share',
            func: setInvitationModalOpen,
            props: test.id
        },
        {
            name: 'test live and participants',
            condition: test.stage === 'live' && test.count > 0,
            button: 'Test results',
            func: useNavigate(),
            props: { pathname: `/test/${test.id}`, state: { curTest: test } }
        },
        {
            name: 'test is paused',
            condition: test.stage === 'paused',
            button: 'Resume',
            func: onClickResumeTest,
            props: null
        },
        {
            name: 'test is suspended',
            condition: test.stage === 'suspended',
            button: 'Share',
            func: setInvitationModalOpen,
            props: test.id
        }
    ]

    return ctas.map((cta, idx) => {
        if (cta.condition) {
            return (
                <ButtonContainer key={idx}>
                    <Button disabled={false} onClick={() => cta.func(cta.props)}>
                        <div className="button__text action-button-item">{cta.button}</div>
                    </Button>
                </ButtonContainer>
            )
        }

        return <div key={idx}></div>
    })
}

export default OverviewActions
