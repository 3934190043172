export const surveyQuestions = [
    {
        question: 'What is your age?',
        text: 'Age',
        value: 'age',
        type: 'dropdown',
        defaultOptions: ['18-25 years old', '26-40 years old', '41-60 years old', '> 60 years old']
    },
    {
        question: 'What is your gender?',
        text: 'Gender',
        value: 'gender',
        type: 'dropdown',
        defaultOptions: ['Female', 'Male', 'Diverse', 'I prefer not to share']
    },
    {
        question: 'In which country are you located?',
        text: 'Country',
        value: 'location',
        type: 'location'
    },
    { text: 'Custom', value: 'custom', type: 'dropdown', defaultOptions: ['', ''] }
]
