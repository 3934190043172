import React, { useState } from 'react'
import styled from 'styled-components'

import downloadIcon from '../../images/download.svg'
import Dropdown from '../molecules/DropDownMenu/Dropdown'
import {
    onChangeGazeOverlayDropdown,
    onChangeGazeOverlaySlider,
    onChangeTapOverlaySlider
} from './CommentEventHandlers'
import Overlay from './Overlay'
import PlaybackRateSettings from './PlaybackRateSettings'

const DownloadButtonWrapper = styled.div`
    ${{ height: '20%', display: 'flex', alignItems: 'center' }}
`
const DownloadIconWrapper = styled.div`
    ${{
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%'
    }}
`
const DownloadText = styled.div`
    ${{ textDecoration: 'underline', marginLeft: '8px', cursor: 'pointer' }}
`

/** TODO: Clear formatting issues and enable eslint */
/* eslint-disable */

const VideoSettings = (props) => {
    const {
        tester,
        testerIDs,
        testerID,
        testerDropdownMenuOptions,
        setShowingTesterDropdown,
        showingTesterDropdown,
        testSpecificTesterID,
        videoPlaybackRate,
        setTesterID,
        tapOverlayIsSelected,
        setTapOverlayIsSelected,
        videoRef,
        gazeOverlayIsSelected,
        setGazeOverlayIsSelected,
        gazeOverlayDropdownOption,
        setShowingGazeOverlayDropdown,
        showingGazeOverlayDropdown,
        setGazeOverlayDropdownOption,
        setVideoPlaybackRate,
        windowHeight,
        setVideoDataLoaded,
        progressBar,
        setProgressBar
    } = props

    const [downloadHovering, setDownloadHovering] = useState(false)

    /**
     * Event handler to handle user selecting a new tester.
     */
    const onClickTesterDropdown = (e) => {
        setProgressBar(null)
        const liElt = e.target.nodeName === 'DIV' ? e.target.parentElement : e.target
        const newTesterID = liElt.getAttribute('value')
        if (parseInt(newTesterID) === testerID) {
            setShowingTesterDropdown(false)
        } else if (parseInt(newTesterID) !== testerID) {
            setTesterID(parseInt(newTesterID))
            setShowingTesterDropdown(false)
            setVideoDataLoaded(false)
            videoRef.current.props.setCurrentTimeOfVideo(0)
        }
    }

    return (
        <div className="video__settings__section" style={{ width: '49%' }}>
            {tester && (
                <>
                    <div className="top" style={{ height: 'calc(30vh - 80px)' }}>
                        <div className="test__title">{tester.test_id.name}</div>
                        {testerIDs && testerID && (
                            <Dropdown
                                className={'tester__dropdown'}
                                disabled={false}
                                menuOptions={testerDropdownMenuOptions}
                                onSelectMenuOption={onClickTesterDropdown}
                                selectedValues={[testerID]}
                                setShowingDropdownMenu={setShowingTesterDropdown}
                                showingDropdownMenu={showingTesterDropdown}
                                triggerText={`Participant ${testSpecificTesterID + 1}`}
                            />
                        )}
                    </div>

                    <div className="bottom" style={{ height: 'calc(50vh - 70px)' }}>
                        <div className="video__settings__title"> Video settings</div>

                        <div className="video__settings__content">
                            <Overlay
                                onChangeTapOverlaySlider={onChangeTapOverlaySlider}
                                tapOverlayIsSelected={tapOverlayIsSelected}
                                setTapOverlayIsSelected={setTapOverlayIsSelected}
                                videoRef={videoRef}
                                onChangeGazeOverlaySlider={onChangeGazeOverlaySlider}
                                gazeOverlayIsSelected={gazeOverlayIsSelected}
                                setGazeOverlayIsSelected={setGazeOverlayIsSelected}
                                gazeOverlayDropdownOption={gazeOverlayDropdownOption}
                                setShowingGazeOverlayDropdown={setShowingGazeOverlayDropdown}
                                showingGazeOverlayDropdown={showingGazeOverlayDropdown}
                                onChangeGazeOverlayDropdown={onChangeGazeOverlayDropdown}
                                setGazeOverlayDropdownOption={setGazeOverlayDropdownOption}
                                windowHeight={windowHeight}
                            />
                        </div>
                    </div>

                    <div className="video__settings__title"> Playback Speed</div>
                    <div className="video__settings__content">
                        <PlaybackRateSettings
                            videoPlaybackRate={videoPlaybackRate}
                            setVideoPlaybackRate={setVideoPlaybackRate}
                            videoRef={videoRef}
                        />
                    </div>

                    <DownloadButtonWrapper style={{ opacity: progressBar && '0.5' }}>
                        <DownloadIconWrapper
                            style={{ backgroundColor: downloadHovering && '#EFF4FB' }}>
                            <img src={downloadIcon} alt="download icon" />
                        </DownloadIconWrapper>
                        <DownloadText
                            onMouseEnter={() => setDownloadHovering(true)}
                            onMouseLeave={() => setDownloadHovering(false)}
                            onClick={() =>
                                setProgressBar({
                                    tester: testerID,
                                    duration: videoRef.current.props.duration,
                                    method: gazeOverlayDropdownOption
                                })
                            }>
                            {'Download video'}
                        </DownloadText>
                    </DownloadButtonWrapper>
                </>
            )}
        </div>
    )
}

export default VideoSettings
