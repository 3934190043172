import React, { useEffect, useState } from 'react'
import RichTextEditor from 'react-rte'

const br = '<br></br>'

const init = (contactEmail, verifier, id) => {
    return (
        '<p>Hi,</p>' +
        br +
        '<p>Thank you for participating in our test today, and helping us improve our digital experience!</p>' +
        br +
        '<p>To start testing please follow these few steps:</p>' +
        br +
        '<h4>Preparation:</h4>' +
        br +
        '<ol>' +
        '<li>You need a smartphone with at least Android 8 or iOS 15, or later versions.</li>' +
        br +
        '<li>The app will work best when on a stable WiFi connection.</li>' +
        br +
        '<li>Download the Tobii UX Explore App from the Google Play Store or the App Store.</li>' +
        br +
        '<li>Scan the QR code to enter into the test or insert the test key.</li>' +
        br +
        '</ol>' +
        '<p>Alternatively, you can also enter the key code manually:</p>' +
        br +
        `<h3>${verifier + '@@' + id}</h3>` +
        br +
        '<div style="height: 16px;"></div>' +
        br +
        '<h4>Test:</h4>' +
        br +
        '<ol>' +
        '<li>Sit comfortably and maintain the same position throughout the test. Please avoid very bright, direct light from the front and take off your glasses if possible.</li>' +
        br +
        '<li>Please review and accept the use of the data stated in the consent form. Only if the consent form is deactivated in the previous step.</li>' +
        br +
        '<li>Just follow the instructions in the app and click on upload at the end to finish.</li>' +
        br +
        '</ol>' +
        `<p>If you have any questions about the test, contact us at  ${contactEmail || 'support@tobii.com'}.</p>` +
        br +
        '<p>Thank you again and happy testing!</p>'
    )
}

const RichTextInvitation = ({ contactEmail, verifier, id }) => {
    const [value, setValue] = useState(
        RichTextEditor.createValueFromString(init(contactEmail, verifier, id), 'html')
    )

    const styleMap = { BOLD: { fontWeight: '700' } }

    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'rte__button' },
            { label: 'Italic', style: 'ITALIC', className: 'rte__button' },
            { label: 'Underline', style: 'UNDERLINE', className: 'rte__button' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled', className: 'rte__button' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item', className: 'rte__button' },
            { label: 'OL', style: 'ordered-list-item', className: 'rte__button' }
        ]
    }

    /**
     * Remove offset space from RTE
     */
    useEffect(() => {
        const element = document.getElementsByClassName('RichTextEditor__block___2Vs_D')
        if (element[0] !== undefined) {
            element[0].remove()
        }
    }, [])

    /**
     * Make RTE scrollable
     */
    useEffect(() => {
        const element = document.getElementsByClassName('RichTextEditor__editor___1QqIU')
        if (element[0] !== undefined) {
            element[0].style.height = '360px'
        }
    }, [])

    return (
        <RichTextEditor
            id="rich-text-editor"
            className={'create__text__editor'}
            toolbarConfig={toolbarConfig}
            customStyleMap={styleMap}
            value={value}
            onChange={(e) => {
                setValue(e)
            }}
        />
    )
}

export default RichTextInvitation
