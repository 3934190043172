import './charactercounter.css'

import React from 'react'

/**
 *
 * @param {*} props
 */
const CharacterCounter = ({ charLimit, className, numChars, styles }) => {
    return (
        <div
            style={styles}
            className={`${className} character__counter ${numChars >= charLimit ? 'max' : ''}`}>
            {`${numChars} / ${charLimit}`}
        </div>
    )
}

export default CharacterCounter
