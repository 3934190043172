import './taskcard.css'

import React, { useEffect, useState } from 'react'

const TaskCardMediaErrors = (props) => {
    const {
        idx,
        task,
        imageFormatError,
        videoFormatError,
        mediaFileSizeError,
        unrecognizedMediaError,
        moreThanOneTask
    } = props

    const [firstCardNoMedia, setFirstCardNoMedia] = useState(false)

    const errorCheck =
        imageFormatError === idx ||
        videoFormatError === idx ||
        mediaFileSizeError === idx ||
        unrecognizedMediaError === idx ||
        (firstCardNoMedia && moreThanOneTask)

    /**
     * Determine if the first card in the list has no media
     */
    useEffect(() => {
        if (idx === 0) {
            if (task.isABTest) {
                setFirstCardNoMedia(false)
            } else {
                if (!task.file && !task.url && !task.image && !task.video) {
                    setFirstCardNoMedia(true)
                } else {
                    setFirstCardNoMedia(false)
                }
            }
        }
    }, [idx, task.isABTest, task.media, task.file, task.url, task.image, task.video])

    return (
        <div
            style={{
                overflow: 'hidden',
                transition: '1s',
                borderRadius: '5px',
                height: errorCheck ? '28px' : '1px',
                marginTop: '8px'
            }}>
            <div
                style={{
                    width: '100%',
                    padding: '4px 8px 24px 8px',
                    borderRadius: '5px',
                    backgroundColor: errorCheck ? 'rgb(255 98 98)' : '#EBECF0'
                }}>
                {imageFormatError === idx && (
                    <p style={{ color: 'white', margin: '0px' }}>
                        {'Please upload an image with a .png, .jpg or .svg format'}
                    </p>
                )}
                {videoFormatError === idx && (
                    <p style={{ color: 'white', margin: '0px' }}>
                        {'Please upload a video with .mp4 format'}
                    </p>
                )}
                {mediaFileSizeError === idx && (
                    <p style={{ color: 'white', margin: '0px' }}>
                        {'The media file you are trying to upload is too large'}
                    </p>
                )}
                {unrecognizedMediaError === idx && (
                    <p style={{ color: 'white', margin: '0px' }}>
                        {'The media type you are uploading is not recognised'}
                    </p>
                )}
                {firstCardNoMedia && moreThanOneTask && (
                    <p style={{ color: 'white', margin: '0px' }}>
                        {'The first task in your list requires media'}
                    </p>
                )}
            </div>
        </div>
    )
}

export default TaskCardMediaErrors
