import './videocontainer.css'

import FormGroup from '@material-ui/core/FormGroup'
import React from 'react'

import switchOffIcon from '../../images/switch_off_icon.svg'
import switchOnIcon from '../../images/switch_on_icon.svg'

const PortholeIcon = () => {
    return (
        <div
            className="overlay-button-icon"
            style={{ backgroundColor: '#F8F8F8', borderRadius: '50%' }}
        />
    )
}

const GazePtsIcon = () => {
    return (
        <div
            className="overlay-button-icon"
            style={{ display: 'flex', justifyContent: 'space-between', opacity: '0.8' }}>
            <div>
                <div
                    style={{
                        height: '8px',
                        width: '8px',
                        backgroundColor: '#7FC8CD',
                        borderRadius: '50%'
                    }}
                />
                <div style={{ height: '8px', width: '8px' }} />
                <div
                    style={{
                        height: '8px',
                        width: '8px',
                        backgroundColor: '#7FC8CD',
                        borderRadius: '50%'
                    }}
                />
            </div>
            <div>
                <div style={{ height: '8px', width: '8px' }} />
                <div
                    style={{
                        height: '8px',
                        width: '8px',
                        backgroundColor: '#7FC8CD',
                        borderRadius: '50%'
                    }}
                />
                <div style={{ height: '8px', width: '8px' }} />
            </div>
            <div>
                <div
                    style={{
                        height: '8px',
                        width: '8px',
                        backgroundColor: '#7FC8CD',
                        borderRadius: '50%'
                    }}
                />
                <div style={{ height: '8px', width: '8px' }} />
                <div
                    style={{
                        height: '8px',
                        width: '8px',
                        backgroundColor: '#7FC8CD',
                        borderRadius: '50%'
                    }}
                />
            </div>
        </div>
    )
}

const HeatmapIcon = () => {
    return (
        <div
            className="overlay-button-icon"
            style={{ backgroundColor: 'lightblue', borderRadius: '50%', filter: 'blur(0px)' }}>
            <div
                style={{
                    height: '20px',
                    width: '20px',
                    backgroundColor: 'yellow',
                    borderRadius: '50%',
                    margin: '2px',
                    padding: '6px',
                    filter: 'blur(2px)'
                }}>
                <div
                    style={{
                        height: '8px',
                        width: '8px',
                        backgroundColor: 'orange',
                        borderRadius: '50%',
                        filter: 'blur(2px)'
                    }}
                />
            </div>
        </div>
    )
}

/** TODO: Clear formatting issues and enable eslint */
/* eslint-disable */

const Overlay = (props) => {
    const {
        onChangeTapOverlaySlider,
        tapOverlayIsSelected,
        setTapOverlayIsSelected,
        videoRef,
        onChangeGazeOverlaySlider,
        gazeOverlayIsSelected,
        setGazeOverlayIsSelected,
        gazeOverlayDropdownOption,
        setShowingGazeOverlayDropdown,
        onChangeGazeOverlayDropdown,
        setGazeOverlayDropdownOption,
        windowHeight
    } = props

    const gazeOptionsMap = {
        gazepoints: 'Gaze pts',
        heatmap: 'Heat map',
        porthole: 'Porthole'
    }

    const gazeOptions = ['porthole', 'heatmap', 'gazepoints']

    if (windowHeight < 720) {
        return (
            <div id="overlay">
                <FormGroup row>
                    <img
                        alt="switch_icon"
                        className="green__switch"
                        onClick={() =>
                            onChangeTapOverlaySlider(
                                tapOverlayIsSelected,
                                setTapOverlayIsSelected,
                                videoRef
                            )
                        }
                        src={tapOverlayIsSelected ? switchOnIcon : switchOffIcon}
                    />
                    <div className="switch__label">Taps</div>
                </FormGroup>

                <FormGroup row>
                    <img
                        alt="switch_icon"
                        className="green__switch"
                        onClick={() =>
                            onChangeGazeOverlaySlider(
                                gazeOverlayIsSelected,
                                setGazeOverlayIsSelected,
                                videoRef,
                                gazeOverlayDropdownOption
                            )
                        }
                        src={gazeOverlayIsSelected ? switchOnIcon : switchOffIcon}
                    />
                    <div className="switch__label">Eye Tracking</div>
                </FormGroup>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {gazeOptions.map((elt, idx) => (
                        <div key={idx}>
                            <div
                                onClick={(e) =>
                                    onChangeGazeOverlayDropdown(
                                        e,
                                        gazeOverlayDropdownOption,
                                        setGazeOverlayDropdownOption,
                                        videoRef,
                                        setShowingGazeOverlayDropdown
                                    )
                                }
                                value={elt}
                                className="gaze__overlay__selector"
                                style={{
                                    opacity: gazeOverlayIsSelected ? '1' : '0.5',
                                    pointerEvents: gazeOverlayIsSelected ? '' : 'none',
                                    outline:
                                        gazeOverlayIsSelected && gazeOverlayDropdownOption === elt
                                            ? '2px solid #4B8CF7'
                                            : '',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    backgroundColor:
                                        gazeOverlayIsSelected &&
                                        gazeOverlayDropdownOption === elt &&
                                        '#dbe8fd'
                                }}>
                                {/* Invisible clickable layer over icons that are not clickable */}
                                <div
                                    onClick={(e) =>
                                        onChangeGazeOverlayDropdown(
                                            e,
                                            gazeOverlayDropdownOption,
                                            setGazeOverlayDropdownOption,
                                            videoRef,
                                            setShowingGazeOverlayDropdown
                                        )
                                    }
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                        position: 'absolute',
                                        zIndex: '20',
                                        opacity: '0'
                                    }}
                                />

                                {gazeOptionsMap[elt] === 'Porthole' && <PortholeIcon />}

                                {gazeOptionsMap[elt] === 'Gaze pts' && <GazePtsIcon />}

                                {gazeOptionsMap[elt] === 'Heat map' && <HeatmapIcon />}
                            </div>

                            <div className="gaze__overlay__text" style={{ textAlign: 'center' }}>
                                {' '}
                                {gazeOptionsMap[elt]}{' '}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    } else {
        return (
            <div id="overlay">
                <FormGroup row>
                    <img
                        alt="switch_icon"
                        className="green__switch"
                        onClick={() =>
                            onChangeTapOverlaySlider(
                                tapOverlayIsSelected,
                                setTapOverlayIsSelected,
                                videoRef
                            )
                        }
                        src={tapOverlayIsSelected ? switchOnIcon : switchOffIcon}
                    />
                    <div className="switch__label">Taps</div>
                </FormGroup>

                <FormGroup row>
                    <img
                        alt="switch_icon"
                        className="green__switch"
                        onClick={() =>
                            onChangeGazeOverlaySlider(
                                gazeOverlayIsSelected,
                                setGazeOverlayIsSelected,
                                videoRef,
                                gazeOverlayDropdownOption
                            )
                        }
                        src={gazeOverlayIsSelected ? switchOnIcon : switchOffIcon}
                    />
                    <div className="switch__label">Eye Tracking</div>
                </FormGroup>

                <div>
                    <ul>
                        {gazeOptions.map((elt, idx) => (
                            <li
                                key={idx}
                                onClick={(e) =>
                                    onChangeGazeOverlayDropdown(
                                        e,
                                        gazeOverlayDropdownOption,
                                        setGazeOverlayDropdownOption,
                                        videoRef,
                                        setShowingGazeOverlayDropdown
                                    )
                                }
                                value={elt}
                                className="gaze__overlay__selector"
                                style={{
                                    opacity: gazeOverlayIsSelected ? '1' : '0.5',
                                    pointerEvents: gazeOverlayIsSelected ? '' : 'none',
                                    outline:
                                        gazeOverlayIsSelected && gazeOverlayDropdownOption === elt
                                            ? '2px solid #4B8CF7'
                                            : '',
                                    backgroundColor:
                                        gazeOverlayIsSelected &&
                                        gazeOverlayDropdownOption === elt &&
                                        '#dbe8fd'
                                }}>
                                {/* Invisible clickable layer over icons that are not clickable */}
                                <div
                                    onClick={(e) =>
                                        onChangeGazeOverlayDropdown(
                                            e,
                                            gazeOverlayDropdownOption,
                                            setGazeOverlayDropdownOption,
                                            videoRef,
                                            setShowingGazeOverlayDropdown
                                        )
                                    }
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                        position: 'absolute',
                                        zIndex: '20',
                                        opacity: '0'
                                    }}
                                />

                                {gazeOptionsMap[elt] === 'Porthole' && <PortholeIcon />}

                                {gazeOptionsMap[elt] === 'Gaze pts' && <GazePtsIcon />}

                                {gazeOptionsMap[elt] === 'Heat map' && <HeatmapIcon />}

                                <div className="gaze__overlay__text" style={{ marginLeft: '8px' }}>
                                    {' '}
                                    {gazeOptionsMap[elt]}{' '}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }
}

export default Overlay
