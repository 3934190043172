import * as d3 from 'd3'
import React, { useEffect, useRef, useState } from 'react'

import { interpolateColormap, jetColormap } from '../dashboard/colormaps'

const contourColorMap = (val) => interpolateColormap(val, jetColormap).map((e) => parseInt(255 * e))
// eslint-disable-next-line import/namespace
const scaleContourValue = d3.scaleLinear().range([0, 1])

/**
 * Component to render one URL column of the solitaire board.
 * @param {*} props
 */
const SolitaireColumn = (props) => {
    const { aggregateData, heatmapData, idx, spaceForImageZoom, task, taskIdx, abSelector } = props

    const imageRef = useRef(null)
    const overlayRef = useRef(null)

    const [dataSnapshotShowing, setDataSnapshotShowing] = useState(false)
    const [heightForImageZoom, setHeightForImageZoom] = useState(null)
    const widthStr = `calc(300px * ${heightForImageZoom})`

    const columnData = heatmapData[task.url]
    const snapshotValue = columnData.metrics

    const dwellTime =
        typeof snapshotValue.dwell_time === 'undefined'
            ? 'N/A'
            : snapshotValue.dwell_time.toFixed(2)
    const numFixations = snapshotValue.num_fix
    const ttff = typeof snapshotValue.ttff === 'undefined' ? 'N/A' : snapshotValue.ttff.toFixed(2)
    const ffd = typeof snapshotValue.ffd === 'undefined' ? 'N/A' : snapshotValue.ffd.toFixed(2)
    const numTaps = snapshotValue.num_touch

    const snapshotData = [
        { title: 'Users Tested:', data: columnData.count },
        { title: 'Avg Dwell Time:', data: `${dwellTime} s` },
        {
            title: 'Avg Number of Fixations:',
            data: `${numFixations ? (Number.isInteger(numFixations) ? numFixations : numFixations.toFixed(1)) : 0}`
        },
        { title: 'Avg Time to first Fixation:', data: `${ttff} s` },
        { title: 'Avg First Fixation Duration:', data: `${ffd} s` },
        {
            title: 'Avg Number of Screen Taps:',
            data: `${numFixations ? (Number.isInteger(numTaps) ? numTaps : numTaps.toFixed(1)) : 0}`
        }
    ]

    let screenshot
    if (typeof columnData !== 'undefined') {
        screenshot = columnData.screenshot
    } else if (task.isABTest) {
        screenshot = task.url in aggregateData ? aggregateData[task.url].screenshot : null
    }

    /**
     * Where the magic happens
     */
    useEffect(() => {
        if (
            imageRef.current &&
            overlayRef.current &&
            typeof columnData !== 'undefined' &&
            screenshot != null
        ) {
            const screenshot = columnData.screenshot
            const screenshotHeight = screenshot.height
            const screenshotWidth = screenshot.width

            const getAvgDisplayHeight = () => {
                const thisMediaUrl = screenshot.image_url
                if (task.isABTest) {
                    const aMedia = task.media[0]
                    const bMedia = task.media[1]
                    if (thisMediaUrl.includes(aMedia.value)) {
                        return aMedia.meanHeightActual
                    }
                    if (thisMediaUrl.includes(bMedia.value)) {
                        return bMedia.meanHeightActual
                    }
                } else {
                    return task.meanHeightActual
                }
            }

            // eslint-disable-next-line import/namespace
            const xScale = d3.scaleLinear()
            // eslint-disable-next-line import/namespace
            const yScale = d3.scaleLinear()

            // work out the width & height of this column's overlay (in frontend pixels)
            const overlayWidth = imageRef.current.offsetWidth
            const overlayHeight = overlayWidth * (screenshotHeight / screenshotWidth)

            // Initialize dynamic height values - work out height of window - fixed content and return a value for zoom css
            setHeightForImageZoom(
                (spaceForImageZoom < 680 ? spaceForImageZoom : 680) / overlayHeight
            )

            // Create scaling functions to convert predictions --> frontend pixels
            // screenshot.height_actual is the height of the image as it appeared on the phone screen; screenshot.height is the height of the image file in S3

            // const heightForScaling = screenshot.height_actual != null ? screenshot.height_actual : screenshotHeight;

            xScale.domain([0, screenshotWidth]).range([0, overlayWidth])
            yScale.domain([getAvgDisplayHeight(), 0]).range([overlayHeight, 0])

            // eslint-disable-next-line import/namespace
            d3.select(`#overlay__${idx}`).remove()
            const svg = d3
                // eslint-disable-next-line import/namespace
                .select(overlayRef.current)
                .append('svg')
                .attr('id', `overlay__${idx}`)
                .attr('height', overlayHeight)
                .attr('width', overlayWidth)

            // render heatmap
            let pics = columnData.pics.filter((x) => x.x__sel != null)
            if (taskIdx !== -1) {
                pics = pics.filter((p) => p.task_index === taskIdx)
            }
            const densityData = d3
                // eslint-disable-next-line import/namespace
                .contourDensity()
                .x((d) => xScale(d.x__sel))
                .y((d) => yScale(d.y__sel))
                .bandwidth(20)
                .cellSize(2)
                .size([overlayWidth, overlayHeight])(pics)

            // eslint-disable-next-line import/namespace
            scaleContourValue.domain(d3.extent(densityData, (d) => d.value))
            svg.insert('g', 'g')
                .selectAll('path')
                .data(densityData)
                .enter()
                .append('path')
                // eslint-disable-next-line import/namespace
                .attr('d', d3.geoPath())
                .attr('fill', (d, idx) => {
                    const scaledValue = scaleContourValue(d.value)
                    const a = Math.max(0.2, (idx / densityData.length) * 1) // we didn't have this Math.max here before
                    const colorString =
                        'rgba(' + contourColorMap(scaledValue).join(',') + ', ' + a + ')'
                    // eslint-disable-next-line import/namespace
                    return d3.color(colorString)
                })
        }
    }, [columnData, idx, screenshot, spaceForImageZoom, taskIdx, task])

    /**
     * Hotfix for broken images caused by ascii chars
     */
    const imageFromAwsIsBroken =
        screenshot.image_url.includes('Komplement%25C3%25A4rmedizin') ||
        screenshot.image_url.includes('Sch%25C3%25B6ner') ||
        screenshot.image_url.includes('%25C3%259Cberschriften')

    return (
        <div className="solitaire__column" style={{ width: widthStr }}>
            {/* Layer 1: Trim with outline */}
            <div
                className="trim__wrapper"
                onMouseEnter={() => setDataSnapshotShowing(task)}
                onMouseLeave={() => setDataSnapshotShowing(false)}>
                <div
                    className="trim__overlay"
                    style={{ height: `${spaceForImageZoom}px`, width: widthStr }}
                />
            </div>

            {/* Layer 2: Snapshot Data  */}
            <div className="snapshot__wrapper">
                <div
                    className={`snapshot__overlay ${dataSnapshotShowing ? 'enter' : 'exit'}`}
                    style={{ height: `${spaceForImageZoom}px`, width: widthStr }}>
                    <div
                        className="snapshot__content"
                        style={{ zoom: `${heightForImageZoom < 1 ? heightForImageZoom : ''}` }}>
                        <div className="snapshot__headers">
                            <h6>Data Snapshot {abSelector || ''}</h6>
                        </div>
                        <div className="snapshot__item__wrapper">
                            {snapshotData.map((data) => (
                                <div className="snapshot__item" key={data.title}>
                                    <div className="title"> {data.title} </div>
                                    <div className="data"> {data.data} </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Layer 3: Heatmap laid over image */}
            <div
                className={dataSnapshotShowing ? 'overlay__ani' : ''}
                style={{ zoom: `${heightForImageZoom}` }}>
                <div className="overlay" ref={overlayRef}>
                    <svg id={`overlay__${idx}`} />
                </div>
                <img
                    alt="url__image"
                    className="url__image"
                    ref={imageRef}
                    src={imageFromAwsIsBroken ? task.image : screenshot.image_url}
                />
            </div>
        </div>
    )
}

export default React.memo(SolitaireColumn)
