import './createtestcontainer.scss'

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import deleteIcon from '../../images/delete_icon_pink_medium.svg'
import {
    NavigationContext,
    NavigationContextItem as NavigationContextState
} from '../../navigation/navigate'
import PrimaryButton from '../atoms/buttons/PrimaryButton'
import WiredButton from '../atoms/buttons/WIredButton'
import Separator from '../atoms/layout/Separator'

const ConfirmExitModal = (props) => {
    const { name } = props
    const navigate = useNavigate()
    const { setNavState } = React.useContext(NavigationContext)

    const [confirmExitModal, setConfirmExitModal] = useState(true)

    return (
        <div className="confirm-exit-modal-backdrop">
            <div id="confirmExitModal" className="confirm-exit-modal">
                {confirmExitModal === true && name && (
                    <div className={'modal-back'}>
                        <div
                            className="pseudo__overview__button"
                            onClick={() => setConfirmExitModal(true)}></div>
                    </div>
                )}

                {confirmExitModal === true && (
                    <div className={'confirmation-exit-modal'}>
                        <div className="confirmation-modal">
                            <img alt="delete icon" src={deleteIcon} />
                            <div className={'confirm-modal-content'}>
                                <p className="confirmation__modal__subheader">
                                    You are about to leave a test without saving.
                                </p>
                                <div style={{ height: '16px' }} />
                                <Separator height={16} />
                                <div>
                                    You will lose unsaved changes. <br />
                                    Are you sure?
                                </div>
                            </div>

                            <div className={'buttons-wrapper'}>
                                <WiredButton
                                    onPress={() => {
                                        setConfirmExitModal(false)
                                        setNavState(NavigationContextState.TEST_CHANGED)
                                    }}>
                                    Cancel
                                </WiredButton>

                                <PrimaryButton
                                    onClick={() => {
                                        setConfirmExitModal(false)
                                        navigate('/overview')
                                    }}>
                                    Leave Test Creation
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ConfirmExitModal
