import './video.css'

import * as d3 from 'd3'
import React from 'react'

import videoButtonComment from '../../images/video_button_comment.svg'
import videoButtonPause from '../../images/video_button_pause.svg'
import videoButtonPlay from '../../images/video_button_play.svg'
import videoButtonSoundOffAndroid from '../../images/video_button_sound_off_android.svg'
import videoButtonSoundOffApple from '../../images/video_button_sound_off_apple.svg'
import videoButtonSoundOn from '../../images/video_button_sound_on.svg'
import { interpolateColormap, jetColormap } from './colormaps'

// default params for the IR PocoPhone...
const defaultDeviceParams = {
    h_cm: 14.155,
    h_pix: 2246,
    w_cm: 6.806,
    w_pix: 1080,
    x_trans_cm: 2.219,
    y_trans_cm: 0.485
}

const predType = 'c'
/** TODO: Clear formatting issues and enable eslint */
/* eslint-disable */
/**
 * Component for Gaze Replay Element (video screen recording + data overlay).
 */
export default class Video extends React.Component {
    constructor(props) {
        super(props)

        this.oldVideoURL = null

        this.freq = 0.033 // hardcoded to be faster FPS than anything we have => shouldn't have any timing errors
        const formattedData = this.formatData(props.data, props.duration)

        // compute dimensions of overlay & padding using device-specific parameters
        this.deviceParams = props.deviceParams !== null ? props.deviceParams : defaultDeviceParams

        // overlay height
        const deviceHeightAdjustment = 1.01
        const height =
            this.deviceParams.manufacturer === 'Apple'
                ? (props.width / this.deviceParams.x_max_pix) *
                  this.deviceParams.y_max_pix *
                  deviceHeightAdjustment
                : props.width * (1920 / 1080)
        const dynamicZoom = (832 - height) / 800 + 1

        // overlay width
        const deviceWidthAdjustment = 1.02
        const overlayWidth =
            this.deviceParams.manufacturer === 'Apple'
                ? props.width * deviceWidthAdjustment
                : (height / (this.deviceParams.y_pix_actual / this.deviceParams.x_pix_actual)) *
                  deviceWidthAdjustment

        // overlay padding
        const overlayPaddingX = ((props.width - overlayWidth) / 2) * 0.995 // one pixel of slack on the left side

        this.state = {
            data: formattedData,
            gazeOverlay: props.gazeOverlay,
            tapOverlay: props.tapOverlay,
            settings: props.settings,
            tasks: props.tasks,
            videoURL: props.videoURL,
            testSpecificTesterID: props.testSpecificTesterID,

            height,
            dynamicZoom,
            width: props.width,
            overlayPaddingX,
            overlayWidth,

            videoDataLoaded: props.videoDataLoaded,
            videoPlaying: false,
            soundOn: false
        }
    }

    componentDidUpdate(prevProps) {
        /**
         * Check that our scales & color maps are still correct every update.
         */
        if (this.state.data !== null && this.refs.videoElement) {
            this.setup()
            this.renderOverlay()
        }

        /**
         * Listen for comment window being selected.
         */
        if (this.props.commenting !== prevProps.commenting) {
            const video = this.refs.videoElement

            if (this.props.commenting === true) {
                this.setState({ videoPlaying: false })
                video.pause()
            }
        }
    }

    /**
     * Format data to standardised FPS (to ensure rendering of objects at correct timestamps).
     * @param {*} data all visualisation related data (passed to Video component as prop)
     * @param {*} duration duration of video
     * @returns Same data, formatted
     */
    formatData(data, duration) {
        const numIters = Math.ceil(duration / this.freq)

        // create pic & touch list at standard frequencies
        const picData = []
        const touchData = []
        for (var i = 0; i < numIters; i++) {
            const timeRel = (this.freq * i).toFixed(3)
            picData[i] = { time_rel: timeRel }
            touchData[i] = { time_rel: timeRel }
        }

        // find index of closest time stamp for each pic
        for (const pIdx in data.picData) {
            const pic = data.picData[pIdx]
            if (pic.x != null && pic.y != null) {
                const nearestTimeStampIdx = Math.round(pic.time_rel / this.freq)
                picData[nearestTimeStampIdx] = { ...pic, time_rel: (this.freq * i).toFixed(3) }
            }
        }
        data.picData = picData

        // find index of closest time stamp for each touch
        for (const tIdx in data.touchData) {
            const touch = data.touchData[tIdx]
            if (touch.x != null && touch.y != null) {
                const nearestTimeStampIdx = Math.round(touch.time_rel / this.freq)
                touchData[nearestTimeStampIdx] = { ...touch, time_rel: (this.freq * i).toFixed(3) }
            }
        }
        data.touchData = touchData

        return data
    }

    /**
     * Sets up all d3 functionality required for plotting the overlay (e.g. scaling functions, color maps,
     * and variables).
     */
    setup() {
        this.lastIdx = -1

        // color map & scale for heatmap overlay
        this.contourColorMap = (val) =>
            interpolateColormap(val, jetColormap).map((e) => parseInt(255 * e))
        this.densityDataCache = new Array(this.state.data.picData.length) // so we can avoid repeated computation
        this.scaleContourValue = d3.scaleLinear().range([0, 1])

        // scaling functions to convert from cm space --> video element space on the page. (depending whether we have cm or pixel predictions)
        let xMin
        let xMax
        let yMin
        const yMax = 0
        if (this.deviceParams.in_db) {
            // have to do this for existing devices/testers because predictions are in cm
            xMin = -this.deviceParams.x_trans_cm
            xMax = this.deviceParams.w_cm - this.deviceParams.x_trans_cm
            yMin = -this.deviceParams.h_cm
        } else {
            xMin = 0
            xMax = this.deviceParams.x_pix_actual
            yMin = -this.deviceParams.y_pix_actual
        }

        this.xScale = d3.scaleLinear().domain([xMin, xMax]).range([0, this.state.overlayWidth])
        this.yScale = d3.scaleLinear().domain([yMin, yMax]).range([this.state.height, 0])

        // color scale for gaze point overlay
        const firstPointFill = d3.color(this.state.settings.gazePointFill)
        firstPointFill.opacity = 0.33
        const lastPointFill = d3.color(this.state.settings.gazePointFill)
        lastPointFill.opacity = 1
        this.gazePointColorMap = d3.scaleLinear().range([firstPointFill, lastPointFill])
    }

    /**
     * Removes the overlay svg from the window.
     */
    removeOverlay() {
        d3.select('#overlaySVG').remove()
    }

    /**
     * Updates the dimensions of the video element at the instruction of parent dashboard.
     * @param {*} newWidth
     */
    updateDimensions(newWidth) {
        this.removeOverlay() // compute frequency of data & format touch data using pic data framing
        const newHeight = newWidth * (1920 / 1080)
        const newOverlayWidth = (newHeight / (2246 / 1080)) * 1.01
        const newOverlayPaddingX = ((newWidth - newOverlayWidth) / 2) * 0.995
        this.setState({
            height: newHeight,
            width: newWidth,
            overlayWidth: newOverlayWidth,
            overlayPaddingX: newOverlayPaddingX
        })
    }

    /**
     * Resets some important variables when the user selects to view a different tester (called from parent
     * Dashboard component).
     */
    updateTester() {
        if (this.refs.videoElement) {
            this.refs.videoElement.pause()
        }
        this.removeOverlay()
        this.lastIdx = -1
        this.newVideoLoad = true
    }

    /**
     * Updates the type of overlay we are showing (called from parent Dashboard component).
     * @param {*} newOverlay string representing the type of overlay to show.
     */
    updateOverlay(newGazeOverlay, newTapOverlay) {
        this.removeOverlay()
        newGazeOverlay = newGazeOverlay === null ? this.state.gazeOverlay : newGazeOverlay
        newTapOverlay = newTapOverlay === null ? this.state.tapOverlay : newTapOverlay
        this.setState({ gazeOverlay: newGazeOverlay, tapOverlay: newTapOverlay })
    }

    /**
     *
     * @param {*} newVideoURL
     */
    updateVideoURL(newVideoURL) {
        this.setState({ videoURL: newVideoURL })
    }

    /**
     * Updates the data used to plot the overlay on Android (e.g. we are getting data for a new tester).
     * @param {*} newData
     * @param {*} newVideoURL
     */
    updateDataAndroid(newData, newDeviceParams, newDuration) {
        const formattedData = this.formatData(newData, newDuration)

        // compute dimensions of overlay & padding using device-specific parameters
        this.deviceParams = newDeviceParams !== null ? newDeviceParams : defaultDeviceParams
        const height = this.state.width * (1920 / 1080)
        const overlayWidth =
            (height / (this.deviceParams.y_pix_actual / this.deviceParams.x_pix_actual)) * 1.02 // add one pixel of slack
        const overlayPaddingX = ((this.state.width - overlayWidth) / 2) * 0.995 // one pixel of slack on the left side

        this.setState({ data: formattedData, overlayPaddingX, overlayWidth })
    }

    /**
     * Updates the data used to plot the overlay on iOS (e.g. we are getting data for a new tester).
     * @param {*} newData
     * @param {*} newVideoURL
     */
    updateDataIOS(newData, newDeviceParams, newDuration) {
        const formattedData = this.formatData(newData, newDuration)

        // compute dimensions of overlay & padding using device-specific parameters
        this.deviceParams = newDeviceParams !== null ? newDeviceParams : defaultDeviceParams
        const height = (350 / this.deviceParams.x_max_pix) * this.deviceParams.y_max_pix * 1.01
        const overlayWidth = 350 * 1.02 // add one pixel of slack
        const overlayPaddingX = ((this.state.width - overlayWidth) / 2) * 0.995 // one pixel of slack on the left side

        this.setState({
            data: formattedData,
            overlayPaddingX,
            overlayWidth,
            height
        })
    }

    /**
     * Returns the index in the data array to access the data at the current time step in the video.
     * @param {*} currTime the current time of the video that is playing.
     */
    getCurrIdx(currTime) {
        return Math.floor(currTime / this.freq)
    }

    /**
     * Gets a slice of the data array (used for rolling mean, heatmap etc.)
     * @param {*} idx current/last index of the slice
     * @param {*} windowSize the size of the window we want (how many elements back to include in the slice).
     */
    getDataSlice(idx, windowSize, dataType) {
        let dataSlice
        idx = parseInt(idx)
        windowSize = parseInt(windowSize)
        const data = dataType === 'pic' ? this.state.data.picData : this.state.data.touchData
        if (idx > windowSize - 1) {
            dataSlice = data.slice(idx - windowSize, idx)
        } else {
            dataSlice = data.slice(0, idx)
        }
        if (dataType === 'pic') {
            return dataSlice.filter(
                (elt) => elt[`${predType}_x`] !== null && elt[`${predType}_y`] !== null
            ) // TODO: is this really what we want??
        } else {
            return dataSlice
        }
    }

    /**
     * Computes the mean of a slice of data (i.e. if computed at each time step we get a rolling mean).
     * @param {*} idx current/last index of the data slice.
     * @param {*} windowSize the size of the window we want (how many elements back to include in the slice).
     */
    rollingMeanCentroid(idx, windowSize) {
        const dataSlice = this.getDataSlice(idx, windowSize, 'pic').filter(
            (p) => typeof p[`${predType}_x`] !== 'undefined'
        )
        const meanX =
            dataSlice.map((d) => d[`${predType}_x`]).reduce((a, b) => a + b, 0) /
                dataSlice.length || -1
        const meanY =
            dataSlice.map((d) => d[`${predType}_y`]).reduce((a, b) => a + b, 0) /
                dataSlice.length || -1
        return { x: meanX, y: meanY }
    }

    /**
     * Converts circle parameters to a 'path' SVG element string (used for better rendering of the overlay)
     * @param {*} cx x coordinate of circle center (in pixels in chart space).
     * @param {*} cy y coordinate of circle center (in pixels in chart space).
     * @param {*} r radius of circle (in pixels).
     */
    circleToPath(cx, cy, r) {
        return (
            'M ' +
            cx +
            ' ' +
            cy +
            ' m -' +
            r +
            ', 0 a ' +
            r +
            ',' +
            r +
            ' 0 1,0 ' +
            r * 2 +
            ',0 a ' +
            r +
            ',' +
            r +
            ' 0 1,0 -' +
            r * 2 +
            ',0'
        )
    }

    /**
     * Converts rectangle parameters to a 'path' SVG element string (used for better rendering of the overlay)
     * @param {*} x x coordinate of top left corner of rectangle (in pixels in chart space).
     * @param {*} y y coordinate of top left corner of rectangle (in pixels in chart space).
     * @param {*} width width of rectangle (in pixels).
     * @param {*} height height of rectangle (in pixels).
     */
    rectToPath(x, y, width, height) {
        return 'M ' + x + ' ' + y + ' h' + width + ' v' + height + ' h-' + width + 'z'
    }

    /**
     * Computes the probabiliy density data needed to plot contour map (heatmap).
     * @param {*} dataSlice slice of data (only uses x and y points (of some type of prediction)).
     */
    computeDensityData(dataSlice) {
        return d3
            .contourDensity()
            .x((d) => this.xScale(d[`${predType}_x`]))
            .y((d) => this.yScale(d[`${predType}_y`]))
            .bandwidth(20)
            .cellSize(2)
            .size([this.state.overlayWidth, this.state.height])(dataSlice)
    }

    /**
     * Renders the porthole style of overlay (darker background, porthole where the tester was looking).
     * @param {*} currIdx 'current' index we are at in the data array (based on current video time).
     * @param {*} svg the svg element to append the overlay elements to.
     */
    renderPortholeOverlay(currIdx, svg) {
        const numFramesInWindow = this.state.settings.portholeTimeWindow / this.freq
        const centroid = this.rollingMeanCentroid(
            currIdx + numFramesInWindow / 2,
            numFramesInWindow
        )

        /**
         * If x or y co-ordinates have -1 val then no information provided.
         * Radius needs to be 0 in this instance to retain porthole background.
         * Creates slight blinking effect.
         */
        let radius
        if (centroid.x === -1 || centroid.y === -1) {
            radius = 0
        } else if (this.deviceParams.in_db) {
            radius =
                (this.state.settings.portholeRadius / this.deviceParams.w_cm) *
                this.state.overlayWidth
        } else {
            radius =
                this.state.settings.portholeRadius *
                this.deviceParams.pix_per_cm_x *
                (this.state.overlayWidth / this.deviceParams.x_pix_actual)
        }

        const circlePath = this.circleToPath(
            this.xScale(centroid.x),
            this.yScale(centroid.y),
            radius
        )
        const rectPath = this.rectToPath(0, 0, this.state.overlayWidth, this.state.height)
        svg.append('path')
            .attr('d', circlePath + ' ' + rectPath)
            .attr('fill', d3.color('black'))
            .attr('fill-opacity', this.state.settings.portholeBackgroundOpacity)
            .attr('fill-rule', 'evenodd')
    }

    /**
     * Renders the gaze point overlay (sequence of gaze points, increasing in opacity back in time).
     * @param {*} currIdx 'current' index we are at in the data array (based on current video time).
     * @param {*} svg the svg element to append the overlay elements to.
     */
    renderGazePointOverlay(currIdx, svg) {
        const numPointsInWindow = this.state.settings.gazePointTimeWindow / this.freq
        const circles = this.getDataSlice(currIdx + numPointsInWindow / 2, numPointsInWindow, 'pic')
        this.gazePointColorMap.domain([0, circles.length])
        let radius
        if (this.deviceParams.in_db) {
            radius =
                (this.state.settings.gazePointRadius / this.deviceParams.w_cm) *
                this.state.overlayWidth
        } else {
            radius =
                this.state.settings.gazePointRadius *
                this.deviceParams.pix_per_cm_x *
                (this.state.overlayWidth / this.deviceParams.x_pix_actual)
        }
        svg.selectAll('circle')
            .data(circles)
            .enter()
            .append('circle')
            .attr('cx', (d) => this.xScale(d[`${predType}_x`]))
            .attr('cy', (d) => this.yScale(d[`${predType}_y`]))
            .attr('r', (d) =>
                typeof d[`${predType}_x`] === 'undefined' || d[`${predType}_y`] === 'undefined'
                    ? 0
                    : radius
            )
            .attr('key', (_, i) => i)
            .style('fill', d3.color('#7FC8CD'))
    }

    /**
     * Renders the contour map overlay (curved contours representing a decrease in density of points out from the center)
     * @param {*} svg the svg element to append the overlay to.
     * @param {*} densityData the data needed to plot contour maps (returned by computeDensityData above).
     */
    renderContourmap(svg, densityData) {
        this.scaleContourValue.domain(d3.extent(densityData, (d) => d.value))
        svg.insert('g', 'g')
            .selectAll('path')
            .data(densityData)
            .enter()
            .append('path')
            .attr('d', d3.geoPath())
            .attr('fill', (d, idx) => {
                const scaledValue = this.scaleContourValue(d.value)
                const colorString =
                    'rgba(' +
                    this.contourColorMap(scaledValue).join(',') +
                    ', ' +
                    (idx / densityData.length) * 1 +
                    ')'
                return d3.color(colorString)
            })
    }

    /**
     * Wrapper around renderContourMap above to render contour map.
     * @param {*} currIdx 'current' index we are at in the data array (based on the current video time).
     * @param {*} svg the svg element to append the overlay to.
     */
    renderHeatmapOverlay(currIdx, svg) {
        let densityData
        if (!typeof this.densityDataCache[currIdx] === 'undefined') {
            densityData = this.allDensityData[currIdx]
        } else {
            const numFramesInWindow = this.state.settings.heatmapTimeWindow / this.freq
            let dataSlice = this.getDataSlice(
                currIdx + numFramesInWindow / 2,
                numFramesInWindow,
                'pic'
            )
            dataSlice = dataSlice.filter((p) => typeof p[`${predType}_x`] !== 'undefined')
            densityData = this.computeDensityData(dataSlice)
            this.densityDataCache[currIdx] = densityData
        }
        this.renderContourmap(svg, densityData)
    }

    /**
     * Renders the tap replay ('waterdrop' animation to indicate something different to the gaze replay).
     * @param {*} currIdx 'current' index we are at in the data array (based on the current video time).
     * @param {*} svg the svg element to append the overlay to.
     */
    renderTouch(currIdx, svg) {
        let numPointsInWindow = this.state.settings.tapTimeWindow / this.freq
        let dataSlice = this.getDataSlice(
            currIdx + numPointsInWindow / 2,
            numPointsInWindow,
            'touch'
        )

        // tapRadius
        let radius
        if (this.deviceParams.in_db) {
            radius =
                (this.state.settings.tapRadius / this.deviceParams.w_cm) * this.state.overlayWidth
        } else {
            radius =
                this.state.settings.tapRadius *
                this.deviceParams.pix_per_cm_x *
                (this.state.overlayWidth / this.deviceParams.x_pix_actual)
        }

        // plot touch events (make it look like a 'click' where the circle gets depressed)
        const indexOfTouchFrame = dataSlice.map((elt) => elt.label).indexOf('touch')
        if (indexOfTouchFrame !== -1) {
            let opacity = 0.5
            const thisFrameFrac = (numPointsInWindow - indexOfTouchFrame) / numPointsInWindow
            if (thisFrameFrac > 0.33 && thisFrameFrac < 0.8) {
                radius = 0.9 * radius
                opacity = 1
            }
            const color = d3.color(this.state.settings.tapFill)
            color.opacity = opacity

            svg.insert('g', 'g')
                .selectAll('circle')
                .data([dataSlice[indexOfTouchFrame]])
                .enter()
                .append('circle')
                .attr('cx', (d) => this.xScale(d.x))
                .attr('cy', (d) => this.yScale(d.y))
                .attr('r', radius)
                .attr('key', (_, i) => i)
                .style('fill', color)
        }

        // plot swipe events (identical to how we plot gaze points)
        numPointsInWindow = this.state.settings.swipeTimeWindow / this.freq
        dataSlice = this.getDataSlice(
            currIdx + numPointsInWindow / 2,
            numPointsInWindow,
            'touch'
        ).filter((elt) => elt !== null)
        const firstPointFill = d3.color(this.state.settings.swipeFill)
        firstPointFill.opacity = 0
        const lastPointFill = d3.color(this.state.settings.swipeFill)
        lastPointFill.opacity = 1
        const swipeColorMap = d3.scaleLinear().range([firstPointFill, lastPointFill])
        swipeColorMap.domain([0, dataSlice.length])

        let swipeRadius
        if (this.deviceParams.in_db) {
            swipeRadius =
                (this.state.settings.swipeRadius / this.deviceParams.w_cm) * this.state.overlayWidth
        } else {
            swipeRadius =
                this.state.settings.swipeRadius *
                this.deviceParams.pix_per_cm_x *
                (this.state.overlayWidth / this.deviceParams.x_pix_actual)
        }

        svg.insert('g', 'g')
            .selectAll('circle')
            .data(dataSlice)
            .enter()
            .append('circle')
            .attr('cx', (d) => this.xScale(d.x))
            .attr('cy', (d) => this.yScale(d.y))
            .attr('r', (d) =>
                typeof d.x === 'undefined' || typeof d.y === 'undefined' ? 0 : swipeRadius
            )
            .attr('key', (_, i) => i)
            .style('fill', (_, i) => swipeColorMap(i))
    }

    /**
     * Parent function to render the overlay, parametrised by this component's state.
     */
    renderOverlay() {
        // don't render the overlay if the video isn't loaded yet
        let currTime = this.refs.videoElement.currentTime
        // complex logic to prevent overlay from rendering until video loads
        if (this.newVideoLoad) {
            if (this.oldVideoURL === this.state.videoURL) {
                return
            } else {
                this.oldVideoURL = this.state.videoURL

                currTime = 0
                this.newVideoLoad = false
                this.refs.videoElement.currentTime = 0

                if (this.refs.videoElement.readyState !== 4) {
                    if (this.state.gazeOverlay !== 'None' || this.state.tapOverlay !== 'None') {
                        this.refs.videoElement.addEventListener('loadeddata', () => {
                            if (this.refs.videoElement) {
                                this.refs.videoElement.removeEventListener('loadeddata', null)
                                this.renderOverlay()
                            }
                        })
                    }
                    return
                }
            }
        }

        const currIdx = this.getCurrIdx(currTime)
        if (currIdx !== this.lastIdx) {
            this.removeOverlay()
            const svg = d3
                .select(this.refs.overlayElement)
                .append('svg')
                .attr('id', 'overlaySVG')
                .attr('height', this.state.height)
                .attr('width', this.state.overlayWidth)
            if (this.state.gazeOverlay === 'porthole') {
                this.renderPortholeOverlay(currIdx, svg)
            } else if (this.state.gazeOverlay === 'heatmap') {
                this.renderHeatmapOverlay(currIdx, svg)
            } else if (this.state.gazeOverlay === 'gazepoints') {
                this.renderGazePointOverlay(currIdx, svg)
            }

            if (this.state.tapOverlay === 'touch') {
                this.renderTouch(currIdx, svg)
            }
            this.lastIdx = currIdx
        }
    }

    /**
     * Callback function that renders the overlay while the video is playing.
     */
    onPlayingVideo() {
        this.playingIntervalID = setInterval(() => {
            if (this.refs.videoElement) {
                const fract = this.refs.videoElement.currentTime / this.refs.videoElement.duration
                if (document.getElementsByClassName('RSPBprogression')[0]) {
                    document.getElementsByClassName('RSPBprogression')[0].style.width =
                        `${fract * 100}%`
                }

                this.renderOverlay()

                this.props.setCurrentTimeOfVideo(this.refs.videoElement.currentTime)

                // Play back to pause once video complete
                if (this.refs.videoElement.currentTime === this.refs.videoElement.duration) {
                    this.setState({ videoPlaying: false })
                }
            }
        }, 1)
    }

    /**
     * Callback function that pauses overlay rendering while the video is paused (to avoid unecessary re-renders).
     */
    onPausingVideo() {
        clearInterval(this.playingIntervalID)
    }

    /**
     * Callback function that removes the overlay while the user is seeking through the video.
     */
    onSeekingVideo() {
        this.removeOverlay()
        this.lastIdx = -1

        this.playingIntervalID = setInterval(() => {
            if (this.refs.videoElement) {
                this.props.setCurrentTimeOfVideo(this.refs.videoElement.currentTime)
            }
        }, 1)
    }

    /**
     * Callback function that renders the overlay once the user has finished seeking through the video.
     */
    onSeekedVideo() {
        const fract = this.refs.videoElement.currentTime / this.refs.videoElement.duration
        document.getElementsByClassName('RSPBprogression')[0].style.width = `${fract * 100}%`
        this.renderOverlay()
    }

    render() {
        return (
            <div className="video">
                <div
                    id="videoDiv"
                    style={{ width: this.state.width, zoom: this.state.dynamicZoom }}>
                    <div
                        className="video-overlay"
                        ref="overlayElement"
                        style={{ marginLeft: this.state.overlayPaddingX }}>
                        <svg id="overlaySVG" />
                    </div>
                    {this.state.videoURL && this.state.data && this.state.data.picData && (
                        <div>
                            <div
                                className="clickable-play-area"
                                onClick={() => {
                                    const video = this.refs.videoElement

                                    if (this.state.videoPlaying === true) {
                                        this.setState({ videoPlaying: false })
                                        video.pause()
                                    }

                                    if (this.state.videoPlaying === false) {
                                        this.setState({ videoPlaying: true })
                                        video.play()
                                    }

                                    this.props.setCommenting(false)
                                }}
                            />
                            <video
                                id="video"
                                className="video"
                                controlsList="nofullscreen nodownload noremoteplayback"
                                disablePictureInPicture
                                muted
                                onPause={this.onPausingVideo.bind(this)}
                                onPlaying={this.onPlayingVideo.bind(this)}
                                onSeeked={this.onSeekedVideo.bind(this)}
                                onSeeking={this.onSeekingVideo.bind(this)}
                                preload="auto"
                                ref="videoElement"
                                src={this.state.videoURL}
                                width={this.state.width}
                                onLoadedData={() => this.props.setVideoDataLoaded(true)}
                            />
                        </div>
                    )}

                    <div
                        style={{
                            zoom: '120%',
                            display: 'flex',
                            justifyContent: 'center',
                            opacity: this.props.playbackOptionsVisible ? '1' : '0',
                            transitionDelay: !this.props.playbackOptionsVisible && '1s',
                            transitionDuration: !this.props.playbackOptionsVisible && '500ms'
                        }}>
                        <div
                            style={{
                                position: 'absolute',
                                zIndex: '10',
                                top: '0px',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                            {/*
                            COMMENTING BUTTON
                            */}
                            <button
                                style={{
                                    filter: this.props.commenting === false ? '' : 'invert(1)'
                                }}
                                className="video-button"
                                onClick={() => {
                                    const video = this.refs.videoElement

                                    if (this.state.videoPlaying === true) {
                                        this.setState({ videoPlaying: false })
                                        video.pause()
                                    }

                                    if (this.props.commenting) {
                                        this.props.setCommenting(false)
                                    } else {
                                        this.props.setCommenting(true)
                                    }
                                }}>
                                <img
                                    src={videoButtonComment}
                                    alt="comment"
                                    style={{ height: '100%' }}
                                />
                            </button>

                            {/*
                            PLAY BUTTON
                            */}
                            <button
                                style={{
                                    filter: this.state.videoPlaying === false ? '' : 'invert(1)'
                                }}
                                className="video-button"
                                onClick={() => {
                                    const video = this.refs.videoElement

                                    if (this.state.videoPlaying === true) {
                                        this.setState({ videoPlaying: false })
                                        video.pause()
                                    }

                                    if (this.state.videoPlaying === false) {
                                        this.setState({ videoPlaying: true })
                                        video.play()
                                    }

                                    this.props.setCommenting(false)
                                }}>
                                <img
                                    src={
                                        this.state.videoPlaying === false
                                            ? videoButtonPlay
                                            : videoButtonPause
                                    }
                                    alt="play and pause"
                                    style={{ height: '100%' }}
                                />
                            </button>

                            {/*
                            SOUND BUTTON
                            */}
                            <button
                                style={{ filter: this.state.soundOn === true ? 'invert(1)' : '' }}
                                className="video-button"
                                onClick={() => {
                                    const video = this.refs.videoElement

                                    if (video.muted === true) {
                                        video.muted = false
                                        this.setState({ soundOn: true })
                                    } else {
                                        video.muted = true
                                        this.setState({ soundOn: false })
                                    }
                                }}>
                                <img
                                    src={
                                        this.state.soundOn === true
                                            ? videoButtonSoundOn
                                            : this.deviceParams.manufacturer === 'Apple'
                                              ? videoButtonSoundOffApple
                                              : videoButtonSoundOffAndroid
                                    }
                                    alt="sound on or off"
                                    style={{ height: '100%' }}
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
