import {
    sendDeleteRequest,
    sendPatchRequest,
    sendPostRequest,
    wrapRequestInRefreshToken
} from '../utils/api'

/**
 * Event handler for when user selects new gaze overlay dropdown option
 */
export function onChangeGazeOverlayDropdown(
    e,
    gazeOverlayDropdownOption,
    setGazeOverlayDropdownOption,
    videoRef,
    setShowingGazeOverlayDropdown
) {
    const liElt = e.target.nodeName === 'DIV' ? e.target.parentElement : e.target
    const newGazeOverlay = liElt.getAttribute('value')
    if (newGazeOverlay !== gazeOverlayDropdownOption) {
        setGazeOverlayDropdownOption(newGazeOverlay)
        videoRef.current.updateOverlay(newGazeOverlay, null)
    }
    setShowingGazeOverlayDropdown(false)
}

/**
 * Event handler to handle when user changes the gaze overlay slider.
 */
export function onChangeGazeOverlaySlider(
    gazeOverlayIsSelected,
    setGazeOverlayIsSelected,
    videoRef,
    gazeOverlayDropdownOption
) {
    const newGazeOverlayIsSelected = !gazeOverlayIsSelected
    setGazeOverlayIsSelected(newGazeOverlayIsSelected)
    if (newGazeOverlayIsSelected) {
        videoRef.current.updateOverlay(gazeOverlayDropdownOption, null)
    } else {
        videoRef.current.updateOverlay('None', null)
    }
}

/**
 * Event handler to handle when user changes the tap overlay slider.
 */
export function onChangeTapOverlaySlider(tapOverlayIsSelected, setTapOverlayIsSelected, videoRef) {
    const newTapOverlayIsSelected = !tapOverlayIsSelected
    setTapOverlayIsSelected(newTapOverlayIsSelected)
    if (newTapOverlayIsSelected) {
        videoRef.current.updateOverlay(null, 'touch')
    } else {
        videoRef.current.updateOverlay(null, 'None')
    }
}

/**
 * Event handler to handle user making new comment.
 */
export function onEditNewComment(e, videoRef, setNewCommentText) {
    if (videoRef.current.refs.videoElement.paused) {
        setNewCommentText(e.target.textContent)
    }
}

/**
 * Event handler for user selecting one of the comment timestamps to jump to.
 * @param {*} time comment timestamp in seconds
 */
export function onClickCommentTime(time, videoRef) {
    videoRef.current.refs.videoElement.currentTime = time
}

/**
 * Event handler to handle user selecting existing comment to edit it.
 * @param {*} idx idx of comment that was clicked
 */
export function onClickExistingComment(
    idx,
    commentBeingEdited,
    setCommentBeingEdited,
    setCurrEditingCommentText,
    comments
) {
    if (commentBeingEdited !== idx) {
        setCommentBeingEdited(idx)
        setCurrEditingCommentText(comments[idx].text)
    }
}

/**
 * Event handle to handle user clicking on edit comment button in action menu.
 */
export function onClickEditCommentInMenu(idx, setCommentActionMenuOpen) {
    setCommentActionMenuOpen(null)
    onClickExistingComment(idx)
}

/**
 * Helper function that saves comment that has been edited.
 */
export function saveEditedComment(
    currEditingCommentText,
    comments,
    commentBeingEdited,
    setComments,
    props,
    setCommentBeingEdited,
    setCurrEditingCommentText
) {
    if (currEditingCommentText !== '') {
        const comment = comments[commentBeingEdited]
        if (currEditingCommentText !== comment.text) {
            // update comments list
            const newComments = [...comments]
            newComments[commentBeingEdited].text = currEditingCommentText
            setComments(newComments)

            // patch comment
            const patchData = JSON.stringify({ text: currEditingCommentText })
            const successFunc = () => null
            const errorFunc = (err) => err.json().then((body) => console.log(body))
            wrapRequestInRefreshToken(props, sendPatchRequest, [
                'testervideocomment',
                comment.id,
                patchData,
                successFunc,
                errorFunc,
                true
            ])
        }

        // update variables tracking comment currently being edited
        setCommentBeingEdited(-1)
        setCurrEditingCommentText('')
    }
}

/**
 * Event handler to handle user clicking out of comment they are editing
 */
export function onBlurEditingComment(
    currEditingCommentText,
    comments,
    commentBeingEdited,
    setComments,
    props,
    setCommentBeingEdited,
    setCurrEditingCommentText
) {
    saveEditedComment(
        currEditingCommentText,
        comments,
        commentBeingEdited,
        setComments,
        props,
        setCommentBeingEdited,
        setCurrEditingCommentText
    )
}

/**
 * Event handler to handle each keystroke of user editing existing comment.
 */
export function onEditExistingComment(e, setCurrEditingCommentText) {
    setCurrEditingCommentText(e.target.textContent)
}

/**
 * Event handler to handle user pressing enter or escape keys while editing comment.
 */
export function onKeyDownEditingComment(e, setCommentBeingEdited, setCurrEditingCommentText) {
    if (e.key === 'Enter') {
        saveEditedComment()
    } else if (e.key === 'Escape') {
        setCommentBeingEdited(-1)
        setCurrEditingCommentText('')
    }
}

/**
 * Event handler to handle user clicking on delete comment button in action menu.
 * @param {*} idx idx of comment to delete (idx, not ID)
 */
export function onClickDeleteCommentInMenu(
    idx,
    setCommentActionMenuOpen,
    comments,
    setComments,
    props
) {
    setCommentActionMenuOpen(null)
    const newComments = [...comments]
    newComments.splice(idx, 1)
    setComments(newComments)
    const successFunc = () => null
    const errorFunc = (err) => err.json().then((body) => console.log(body))
    wrapRequestInRefreshToken(props, sendDeleteRequest, [
        'testervideocomment',
        comments[idx].id,
        successFunc,
        errorFunc
    ])
}

/**
 * Event handler to handle user clicking on one of the three flag icons in the menu.
 * @param {*} idx idx of comment whose flag we want to modify.
 * @param {*} flagIdx idx of flag to apply to given comment
 */
export function onClickFlagIconInMenu(
    idx,
    flagIdx,
    setCommentActionMenuOpen,
    comments,
    setComments,
    props
) {
    setCommentActionMenuOpen(null)
    const newComments = [...comments]
    if (newComments[idx].flag !== flagIdx) {
        newComments[idx].flag = flagIdx
        setComments(newComments)
        const patchData = JSON.stringify({ flag: flagIdx })
        const successFunc = () => null
        const errorFunc = (err) => err.json().then((body) => console.log(body))
        wrapRequestInRefreshToken(props, sendPatchRequest, [
            'testervideocomment',
            newComments[idx].id,
            patchData,
            successFunc,
            errorFunc,
            true
        ])
    }
}

/**
 * Event handler to handle creation of a new comment.
 */
export function onAddNewComment(
    newCommentText,
    testerID,
    videoRef,
    props,
    comments,
    setComments,
    stableSort,
    getComparator,
    setNewCommentText,
    newCommentRef
) {
    if (videoRef && newCommentText !== '') {
        const comment = {
            flag: 0,
            tester: testerID,
            text: newCommentText,
            time: videoRef.current.refs.videoElement.currentTime
        }
        const successFunc = () => null
        const errorFunc = (err) => err.json().then((body) => console.log(body))
        wrapRequestInRefreshToken(props, sendPostRequest, [
            'testervideocomment',
            JSON.stringify(comment),
            successFunc,
            errorFunc,
            true
        ])

        const newComments = [...comments, comment]
        setComments(stableSort(newComments, getComparator('asc', 'time')))
        setNewCommentText('')
        newCommentRef.current.textContent = ''
    }
}

/**
 * Event handler to handle creation of a new comment when user presses Enter key (rather than using the button)
 */
export function onAddNewCommentEnter(e, newCommentRef) {
    if (e.key === 'Enter') {
        if (newCommentRef && document.activeElement === newCommentRef.current) {
            onAddNewComment()
        }
    }
}
