import React from 'react'

/** TODO: Clear formatting issues and enable eslint */
/* eslint-disable */

const PlaybackRateSettings = (props) => {
    const { videoPlaybackRate, videoRef, setVideoPlaybackRate } = props

    const playbackRateValues = [0.5, 0.75, 1, 1.25, 1.5]

    /**
     * Event handler for when user changes video playback rate.
     */
    const onChangeVideoPlaybackRate = (elt, idx) => {
        const newPlaybackRate = playbackRateValues[idx]

        if (newPlaybackRate !== null) {
            videoRef.current.refs.videoElement.playbackRate = newPlaybackRate
            setVideoPlaybackRate(elt)
        }
    }

    return (
        <div
            id="playbackRateSettings"
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center'
            }}>
            {playbackRateValues.map((elt, idx) => (
                <div
                    key={idx}
                    onClick={() => {
                        onChangeVideoPlaybackRate(elt, idx)
                    }}
                    value={elt}
                    className="playback-values"
                    style={{
                        padding: '4px calc(((100%/5)/2)*0.3)',
                        backgroundColor: elt === videoPlaybackRate ? 'white' : '',
                        outline: elt === videoPlaybackRate ? '2px solid #4B8CF7' : ''
                    }}>
                    <div style={{ fontSize: '12px' }}>{elt}</div>
                </div>
            ))}
        </div>
    )
}

export default PlaybackRateSettings
