import QRCode from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import CloseButton from '../../atoms/buttons/CloseButton'
import PrimaryButton from '../../atoms/buttons/PrimaryButton'
import Separator from '../../atoms/layout/Separator'
import RichTextInvitation from '../../invitation/RichTextInvitation'
import {
    copyInvitationToClipboard,
    copyKeyToClipboard,
    downloadQRCode
} from '../../utils/functions'

const radiusLarge = 'border-radius: 8px;'
const fullScreen = 'height: 100vh; width: 100vw;'
const cardShadow =
    'box-shadow: 0px 1px 2px rgba(44, 48, 50, 0.1), inset 0px -1px 2px rgba(14, 14, 44, 0.2);'

const container = (format) => `display: flex; justify-content: ${format};`

// Components
const DarkBackdrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    ${fullScreen}
    ${container('center')}
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 900;
    backdrop-filter: blur(5px);
    width: 100vw;
`
const ModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 901;
    ${fullScreen}
    ${container('center')}
    align-items: center;
    transform: scale(0.9);
`
const OuterCard = styled.div`
    width: 800px;
    padding: 24px;
    background: #f6fafd;
    dsisplay: none;
    ${radiusLarge}
`

const LinkSpace = styled.div`
    ${container('space-between')}
`
const CardSpace = styled.div`
    background: white;
    width: 48%;
    border-radius: 4px;
    ${cardShadow} ${container('space-between')}
    padding: .5rem .2rem;
`

const LeftSpace = styled.div`
    font-size: 14px;
    width: 50%;
    padding: 16px;
`
const CardCopy = styled.div`
    height: 90%;
`
const CardAction = styled.div`
    height: 20%;
    color: #4b8cf7;
    cursor: pointer;
    font-weight: 800;
`

const RightSpace = styled.div`
    width: 50%;
    border-left: 1px solid #e5e5e5;
    padding: 16px;
    ${container('center')}
`
const StringText = styled.div`
    width: 96px;
    font-size: 10px;
    transform: translateY(4px);
    overflow: hidden;
`

const InviteSpace = styled.div`
    background-color: white;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    ${cardShadow}
`

const InvitationModal = ({ setInvitationModalOpen, test }) => {
    const [mouseOverModal, setMouseOverModal] = useState(false)
    const [keyCopied, setKeyCopied] = useState(false)
    const [richTextCopied, setRichTextCopied] = useState(false)

    /**
     * On opening of modal, remove open modal val from session storage
     */
    useEffect(() => {
        sessionStorage.removeItem('open_modal_for_test')
    }, [])

    const modalTitle = () => {
        if (test.stage === 'review') {
            return 'Great, you created your new test!'
        } else {
            return 'Your test is now launched!!'
        }
    }

    const modalDescription = () => {
        if (test.stage === 'review') {
            return 'Share the invitation with more participants to collect results! You  have now four months to gather and analyse this test data while remaining compliant with GDPR.'
        }
        return 'You can now review it (test it) with up to 3 test participants for free before launching it.'
    }

    return (
        <>
            <DarkBackdrop />
            <ModalContainer
                className="invitation-modal-container"
                id={`invitationModal_${test.id}`}
                onClick={() => !mouseOverModal && setInvitationModalOpen(null)}>
                <OuterCard
                    onMouseOver={() => setMouseOverModal(true)}
                    onMouseLeave={() => setMouseOverModal(false)}
                    style={{ backgroundColor: '#F6FAFD' }}>
                    <div className="header-container">
                        <h3>{modalTitle()}</h3>
                        <CloseButton
                            onClick={() => setInvitationModalOpen(null)}
                            top={-20}
                            right={-10}
                        />
                    </div>
                    <p>{modalDescription()}</p>

                    <Separator />

                    <LinkSpace>
                        <CardSpace>
                            <LeftSpace>
                                <CardCopy>
                                    {
                                        'Scan this QR code with the Tobii UX Explore app to start testing.'
                                    }
                                </CardCopy>
                                <CardAction onClick={() => downloadQRCode('qr-gen', test.id)}>
                                    Download QR Code
                                </CardAction>
                            </LeftSpace>

                            <RightSpace>
                                <QRCode
                                    id="qr-gen"
                                    value={`${test.verifier}@@${test.id}`}
                                    size={88}
                                    level={'H'}
                                    includeMargin={true}
                                />
                            </RightSpace>
                        </CardSpace>

                        <CardSpace>
                            <LeftSpace>
                                <CardCopy>
                                    {'Or, enter this test-key into the Tobii UX Explore app.'}
                                </CardCopy>
                                <CardAction
                                    onClick={() => {
                                        copyKeyToClipboard('invitation_test_key')
                                        setKeyCopied(true)
                                        setTimeout(() => {
                                            setKeyCopied(false)
                                        }, 2000)
                                    }}>
                                    {keyCopied ? <>Copied!</> : <>Copy Key</>}
                                </CardAction>
                            </LeftSpace>

                            <RightSpace>
                                <StringText id="invitation_test_key">{`https://www.oculid-test-key.com/?key=${test.verifier}@@${test.id}`}</StringText>
                            </RightSpace>
                        </CardSpace>
                    </LinkSpace>

                    <Separator />

                    <p>
                        You can edit this test invitation template, copy it, and send it manually
                        via email to your test participants.
                    </p>

                    <Separator />

                    <InviteSpace>
                        <RichTextInvitation
                            contactEmail={test.contact_email}
                            verifier={test.verifier}
                            id={test.id}
                        />

                        <div style={{ height: '16px' }} />

                        <PrimaryButton
                            className="big-title"
                            onClick={(e) => {
                                copyInvitationToClipboard(test.id)
                                setRichTextCopied(true)
                                setTimeout(() => {
                                    setRichTextCopied(false)
                                }, 2000)
                            }}>
                            {richTextCopied ? `Copied!` : `Copy Invitation`}
                        </PrimaryButton>
                    </InviteSpace>
                </OuterCard>
            </ModalContainer>
        </>
    )
}

export default InvitationModal
