import './createtestcontainer.scss'

import React, { useEffect, useState } from 'react'

// import styled from 'styled-components'
import { convertNonAsciiChars } from '../utils/functions'

// const LogoButton = styled.button.attrs({ className: 'secondary button option-button' })`
//    ${{ width: '180px', backgroundColor: 'red' }}
// `

const CompanyLogo = (props) => {
    const {
        draft,
        updateDraft,
        setDraftJsonFunc,
        companyLogo,
        setCompanyLogo,
        draftJson,
        logoImage,
        setLogoImage
    } = props

    const [errorBar, setErrorBar] = useState({ field: null, message: null })

    if (errorBar.field !== null) {
        setTimeout(() => {
            setErrorBar({ field: null, message: null })
        }, 5000)
    }

    const onAddLogo = (e) => {
        const file = e.target.files[0]
        const blob = file.slice(0, file.size, 'image/png')
        const newFile = new File([blob], `${convertNonAsciiChars(file.name)}`, {
            type: 'image/png'
        })
        const extensions = ['png', 'jpg', 'jpeg']
        const hasValidExtension = extensions.some((ext) => newFile.name.toLowerCase().includes(ext))

        if (typeof newFile !== 'object') return

        if (newFile.size < 1000000 && hasValidExtension) {
            setCompanyLogo(newFile)
            updateDraft('company_logo', newFile, draft, props, setDraftJsonFunc)
        } else {
            setErrorBar({
                field: 'logo-image',
                message: 'Your company logo may only be in png or jpg formats and smaller than 1MB'
            })
            updateDraft(
                'company_logo',
                { name: null, size: null, type: null },
                draft,
                props,
                setDraftJsonFunc
            )
        }
    }

    const onDeleteLogo = () => {
        setLogoImage(null)
        setCompanyLogo({ name: null, size: null, type: null })
        updateDraft('company_logo', null, draft, props, setDraftJsonFunc)
    }

    /**
     * Once server responds with AWS link, set logo to link
     */
    useEffect(() => {
        if (draftJson.company_logo && companyLogo.name !== null) {
            setLogoImage(draftJson.company_logo)
        }
    }, [draftJson, companyLogo, setLogoImage])

    return (
        <>
            {logoImage ? (
                <div
                    className="create__remove__company__logo__overlay"
                    style={{ width: '232px' }}
                    onClick={() => onDeleteLogo()}>
                    <img alt={'Company logo'} src={logoImage} style={{ width: '200px' }} />
                    <div
                        className="create_logo_image_close_icon"
                        style={{ display: 'flex', color: 'rgb(255 98 98)' }}>
                        <div> delete:</div>
                        <div style={{ width: '16px' }} />
                        <div style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                            {companyLogo.name}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="secondary button option-button add-logo-button">
                    <input
                        onClick={() => {
                            document.body.onfocus = function () {
                                setTimeout(() => {
                                    document.body.onfocus = null
                                }, 100)
                            }
                        }}
                        onChange={(e) => onAddLogo(e)}
                        type="file"
                        style={{
                            opacity: '0',
                            position: 'absolute',
                            cursor: 'pointer'
                        }}
                    />
                    <div
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            fontSize: '0.85rem'
                        }}>
                        {'+ Add Company Logo'}
                    </div>
                </div>
            )}

            <div
                style={{
                    opacity: '80%',
                    overflow: 'hidden',
                    transition: 'height 1s',
                    borderRadius: '5px',
                    height: `${errorBar.field === 'logo-image' ? '28px' : '0px'}`
                }}>
                <div
                    style={{
                        width: '100%',
                        padding: '4px 8px 24px 8px',
                        borderRadius: '5px',
                        backgroundColor: 'rgb(255 98 98)'
                    }}>
                    {errorBar.field === 'logo-image' && (
                        <p style={{ color: 'white', margin: '0px' }}> {errorBar.message} </p>
                    )}
                </div>
            </div>
        </>
    )
}

export default CompanyLogo
