export const getUserFromStorage = () => {
    const user = {
        tokenExp: localStorage.getItem('tokenExp'),
        userID: localStorage.getItem('userID'),
        token: localStorage.getItem('token'),
        refresh: localStorage.getItem('refresh'),
        email: localStorage.getItem('email'),
        username: localStorage.getItem('username'),
        fullName: localStorage.getItem('full_name'),
        dateJoined: localStorage.getItem('date_joined'),
        occupation: localStorage.getItem('occupation')
    }

    return user.userID ? user : null
}

export const fullName = (user, nameOnly = false) => {
    try {
        if (nameOnly) {
            return user.fullName?.split(' ')[0]
        }

        if (user.fullName) {
            const userNameArr = user.fullName?.split(' ')
            return userNameArr[0]
        }

        if (user.name) {
            return user?.name
        }
        return user.username
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        return ''
    }
}
