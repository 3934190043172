import { useNavigate } from 'react-router-dom'

import { apiLogOut } from '../components/utils/apiLogging'
import { loadFromStorage } from '../utilities/storage'

// Mock authentication check function. Replace it with your actual authentication logic.
const isAuthenticated = () => {
    const accessToken = loadFromStorage('token')
    const refreshToken = loadFromStorage('refresh')

    return accessToken && refreshToken
}

const AuthenticatedRoute = ({ children, ...props }) => {
    if (!isAuthenticated()) {
        apiLogOut()

        const navigate = useNavigate()
        navigate('/login', { replace: true })
    }

    return children
}

export default AuthenticatedRoute
