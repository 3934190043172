import './auth.scss'

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'

const InputContainer = styled.div.attrs({ className: 'input__field' })`
    position: relative;
`
const InputHelpers = styled.div`
    display: flex;
    justify-content: space-between;
`
const HelperText = styled.div`
    color: #ff6262;
    font-size: 13px;
`
const ResetLink = styled.a`
    color: #4b8cf7;
    font-size: 13px;
    text-decoration: underline;
`

const CodeInput = (props) => {
    const { label, error, onChange, inputVals, inputArr } = props

    const [inputInFocus, setInputInFocus] = useState(false)
    const [inputOnHover, setInputOnHover] = useState(false)

    const inputContainerStyle = `${inputInFocus === true ? 'active' : ''}`
    const inputFieldStyle = (id) => {
        return {
            textAlign: 'center',
            boxShadow: inputInFocus === id && '0px 0px 0px 2px #2C3032',
            outline: `${error ? '1px solid #FF6262' : inputOnHover === id ? '2px solid #2c3032' : '1px solid #8e8e8e'}`,
            borderRadius: '5px',
            margin: '4px',
            fontSize: '24px',
            fontWeight: '400'
        }
    }
    const helperStyle = { opacity: !error && '0' }
    const resetStyle = { display: window.location.pathname !== '/login' && 'none' }

    return (
        <InputContainer className={inputContainerStyle}>
            <label> {label} </label>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {inputArr.map((id) => {
                    return (
                        <input
                            key={id}
                            id={`input${id}`}
                            onFocus={() => setInputInFocus(id)}
                            onBlur={() => setInputInFocus(null)}
                            onMouseEnter={() => setInputOnHover(true)}
                            onMouseLeave={() => setInputOnHover(false)}
                            onChange={(e) => onChange(e, id)}
                            type="text"
                            value={inputVals[`inputVals${id}`]}
                            style={inputFieldStyle(id)}
                            maxLength={1}
                            autoComplete="new-password"
                        />
                    )
                })}
            </div>
            <InputHelpers>
                <HelperText style={helperStyle}>{error}</HelperText>
                <ResetLink style={resetStyle} href="/reset">
                    Forgot Password
                </ResetLink>
            </InputHelpers>
        </InputContainer>
    )
}

export default CodeInput

CodeInput.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    inputVals: PropTypes.object,
    inputArr: PropTypes.array
}
