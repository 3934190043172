import './taskscolumn.css'

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { convertNonAsciiChars } from '../utils/functions'
import TaskList from './TaskList'
import * as V from './Validation'

const TaskWrapper = styled.div.attrs({ className: 'column tasks__column active' })``
const TaskButtonWrapper = styled.div`
    ${{
        width: '100%',
        display: 'flex',
        justifyContent: 'left',
        opacity: `${(props) => (props.taskAdded ? '0' : '1')}`
    }}
`
const TaskButton = styled.button.attrs({ className: 'secondary button option-button' })`
    margin-right: 8px;
    margin-top: 16px;
    font-size: 0.82rem;
    background: #dbe8fd;
`

const TasksColumn = (props) => {
    const {
        allowedTestParams,
        errors,
        formatTasks,
        formatId,
        setErrors,
        setTaskErrors,
        setTaskGroupShowing,
        setTaskMenuShowing,
        setTasks,
        taskErrors,
        taskGroupShowing,
        taskMenuShowing,
        tasks,
        draft,
        updateDraft,
        setDraftJsonFunc
    } = props

    const [shiftingDown, setShiftingDown] = useState(null)
    const [taskAdded, setTaskAdded] = useState(null)
    const [imageFormatError, setImageFormatError] = useState(null)
    const [videoFormatError, setVideoFormatError] = useState(null)
    const [mediaFileSizeError, setMediaFileSizeError] = useState(null)
    const [unrecognizedMediaError, setUnrecognizedMediaError] = useState(null)

    /**
     *
     */
    useEffect(() => {
        if (taskAdded !== null) {
            setTimeout(() => {
                setTaskAdded(null)
            }, 1200)
        }
    }, [taskAdded])

    /**
     * Close the error box after setTimeout. Data never saved for bad files so this is just aesthetic
     */
    useEffect(() => {
        if (imageFormatError !== null) {
            setTimeout(() => {
                setImageFormatError(null)
            }, 3000)
        } else if (videoFormatError !== null) {
            setTimeout(() => {
                setVideoFormatError(null)
            }, 3000)
        } else if (mediaFileSizeError !== null) {
            setTimeout(() => {
                setMediaFileSizeError(null)
            }, 3000)
        } else if (unrecognizedMediaError !== null) {
            setTimeout(() => {
                setUnrecognizedMediaError(null)
            }, 3000)
        }
    }, [imageFormatError, videoFormatError, mediaFileSizeError, unrecognizedMediaError])

    /**
     * Function called when user clicks on add task button (regular task, not an AB test task).
     */
    const onAddTask = () => {
        const newTasks = [...tasks]
        newTasks.push({
            description: '',
            isABTest: false,
            name: '',
            pauseRecording: false,
            rating: false,
            ratingText: allowedTestParams.rating_instructions_default,
            taskIndex: tasks.length,
            tol: false,
            tolInstructions: allowedTestParams.tol_instructions_default,
            url: null
        })
        const newTaskErrors = [...taskErrors]
        newTaskErrors.push({})
        if ('taskList' in errors) {
            const newErrors = { ...errors }
            delete newErrors.taskList
            setErrors(newErrors)
        }
        updateDraft('tasks', formatId(newTasks), draft, props, setDraftJsonFunc)
        setTaskErrors(newTaskErrors)
        setTasks(newTasks)
        setTaskAdded(1)
    }

    /**
     * Function called when user clicks on add task button (regular task, not an AB test task).
     */
    const onAddABTask = () => {
        const newTasks = [...tasks]
        newTasks.push({
            description: '',
            isABTest: true,
            media: [
                { key: null, value: null },
                { key: null, value: null }
            ],
            name: '',
            pauseRecording: false,
            rating: false,
            ratingText: allowedTestParams.rating_instructions_default,
            taskIndex: tasks.length,
            tol: false,
            tolInstructions: allowedTestParams.tol_instructions_default
        })
        const newTaskErrors = [...taskErrors]
        newTaskErrors.push({})
        if ('taskList' in errors) {
            const newErrors = { ...errors }
            delete newErrors.taskList
            setErrors(newErrors)
        }
        updateDraft('tasks', formatId(newTasks), draft, props, setDraftJsonFunc)
        setTaskErrors(newTaskErrors)
        setTasks(newTasks)
        setTaskAdded(1)
    }

    /**
     * Function called when user 'blurs' from editing of a task attribute (the moment we save a draft).
     * @param {*} idx idx of task being edited
     * @param {*} key key of task attribute being edited
     * @param {*} value new value of tasks[idx][key]
     */
    const onBlurTaskAttribute = (idx, key, value, media) => {
        const newTasks = [...tasks]
        if (newTasks[idx].isABTest && (key === 'url' || key === 'file')) {
            newTasks[idx].media[media === 'A' ? 0 : 1] = { key, value }
        } else {
            newTasks[idx][key] = value
        }
    }

    /**
     * Handles task attribute validation as the user customizes their test.
     * @param {*} idx idx of task being modified
     * @param {*} key name of attribute being modified
     * @param {*} value new value of attribute
     */
    const onChangeTaskAttribute = (idx, key, value, media) => {
        const [isValid, error] = V.validateTaskAttributeValue(
            key,
            value,
            tasks[idx].tol,
            allowedTestParams
        )

        // update tasks list
        const newTasks = [...tasks]
        const isABTask = newTasks[idx].isABTest
        const abGroupIdx = media === 'A' ? 0 : 1
        const otherABGroupIdx = abGroupIdx === 0 ? 1 : 0
        if (key === 'url' && isABTask) {
            newTasks[idx].media[abGroupIdx] =
                value == null ? { key: null, value: null } : { key, value }
        } else {
            newTasks[idx][key] = value
        }

        // update task errors list
        const newTaskErrors = [...taskErrors]
        if (isValid) {
            if (isABTask) {
                if (key === 'url') {
                    if ('overall' in newTaskErrors[idx]) {
                        if (newTaskErrors[idx].overall[otherABGroupIdx] != null) {
                            newTaskErrors[idx].overall[abGroupIdx] = null
                        } else {
                            delete newTaskErrors[idx].overall
                        }
                    }
                    if ('file' in newTaskErrors[idx]) {
                        if (newTaskErrors[idx].file[otherABGroupIdx] != null) {
                            newTaskErrors[idx].file[abGroupIdx] = null
                        } else {
                            delete newTaskErrors[idx].file
                        }
                        newTasks[idx].file = null // TODO: do we need this
                    }
                }

                if (key in newTaskErrors[idx]) {
                    if (newTaskErrors[idx][key] != null) {
                        newTaskErrors[idx][key] = null
                    } else {
                        delete newTaskErrors[idx][key]
                    }
                }
            } else {
                if (key === 'url') {
                    if ('overall' in newTaskErrors[idx]) {
                        delete newTaskErrors[idx].overall
                    }
                    if ('file' in newTaskErrors[idx]) {
                        delete newTaskErrors[idx].file
                        newTasks[idx].file = null
                    }
                }
                if (key in newTaskErrors[idx]) {
                    delete newTaskErrors[idx][key]
                }
            }

            // there is an error
        } else {
            if (isABTask) {
                if (key in newTaskErrors[idx]) {
                    newTaskErrors[idx][key] = error
                } else {
                    newTaskErrors[idx][key] = [null, null]
                    newTaskErrors[idx][key][abGroupIdx] = error
                }
            } else {
                newTaskErrors[idx][key] = error
            }
        }

        if (key === 'url' && value == null) {
            const errorMessage = 'You need at least one of URL, image, or video for this task'
            if (isABTask) {
                if (!('overall' in newTaskErrors[idx])) {
                    newTaskErrors[idx].overall = [null, null]
                }
                newTaskErrors[idx].overall[abGroupIdx] = errorMessage
            } else {
                newTaskErrors[idx].overall = errorMessage
            }
        }
        setTasks(newTasks)
        setTaskErrors(newTaskErrors)
    }

    /**
     *
     * @param {*} idx
     * @param {*} key
     */
    const onChangeTaskBoolean = (idx, key) => {
        const newTasks = [...tasks]

        // if the custom text for anything is blank, revert to the defaults ()
        if (key === 'tol' && newTasks[idx].tolInstructions === '') {
            newTasks[idx].tolInstructions = allowedTestParams.tol_instructions_default
        } else if (key === 'rating' && newTasks[idx].ratingText === '') {
            newTasks[idx].ratingText = allowedTestParams.rating_instructions_default
        } else if (key === 'pauseRecording' && newTasks[idx].tol === true) {
            newTasks[idx].tol = false
        }

        newTasks[idx][key] = !newTasks[idx][key]
        setTasks(newTasks)
    }

    /**
     * Callback function called when user uploads a new file for a task
     * @param {*} e the event object
     * @param {*} idx the idx of the task being modified
     * @param {*} value the file object of the newly uploaded file
     * @param {*} media whether value is a assigned to an A, B or single tasks
     */
    const onChangeTaskFile = (e, idx, rawValue, media) => {
        const value = convertNonAsciiChars(rawValue)

        const newTasks = [...tasks]
        const newTaskErrors = [...taskErrors]
        const taskGroupShowingIdx = media === 'A' ? 0 : 1

        const isABTask = newTasks[idx].isABTest

        // update file attribute in tasks list
        if (isABTask) {
            if (value) {
                const newFile = e.target.files[0]

                // Check file size - image - AB
                if (newFile.type.includes('image') && newFile.size < 2000000) {
                    setMediaFileSizeError(null)

                    // Check format - image - AB
                    if (
                        newFile.name.includes('.png') ||
                        newFile.name.includes('.jpg') ||
                        newFile.name.includes('.jpeg') ||
                        newFile.name.includes('.PNG') ||
                        newFile.name.includes('.JPG') ||
                        newFile.name.includes('.JPEG')
                    ) {
                        newTasks[idx].media[taskGroupShowingIdx].key = 'file'
                        newTasks[idx].media[taskGroupShowingIdx].value = newFile
                        setVideoFormatError(null)
                        setImageFormatError(null)
                    } else {
                        newTasks[idx].media[taskGroupShowingIdx].key = null
                        newTasks[idx].media[taskGroupShowingIdx].value = null
                        setImageFormatError(idx)
                    }

                    // Check file size - video - AB
                } else if (newFile.type.includes('video') && newFile.size < 20000000) {
                    setMediaFileSizeError(null)

                    // Check format - video - AB
                    if (newFile.name.includes('.mp4') || newFile.name.includes('.MP4')) {
                        newTasks[idx].media[taskGroupShowingIdx].key = 'file'
                        newTasks[idx].media[taskGroupShowingIdx].value = newFile
                        setImageFormatError(null)
                        setVideoFormatError(null)
                    } else {
                        newTasks[idx].media[taskGroupShowingIdx].key = null
                        newTasks[idx].media[taskGroupShowingIdx].value = null
                        setVideoFormatError(idx)
                    }
                } else if (!newFile.type.includes('image') && !newFile.type.includes('video')) {
                    newTasks[idx].media[taskGroupShowingIdx].key = null
                    newTasks[idx].media[taskGroupShowingIdx].value = null
                    setUnrecognizedMediaError(idx)
                } else {
                    newTasks[idx].media[taskGroupShowingIdx].key = null
                    newTasks[idx].media[taskGroupShowingIdx].value = null
                    setMediaFileSizeError(idx)
                }
            } else {
                newTasks[idx].media[taskGroupShowingIdx].key = null
                newTasks[idx].media[taskGroupShowingIdx].value = null
            }
        } else {
            if (!tasks[idx].file) {
                const newFile = e.target.files[0]

                // Check file size - image - single
                if (newFile.type.includes('image') && newFile.size < 2000000) {
                    // Check format - image - single
                    if (
                        newFile.name.includes('.png') ||
                        newFile.name.includes('.jpg') ||
                        newFile.name.includes('.jpeg') ||
                        newFile.name.includes('.PNG') ||
                        newFile.name.includes('.JPG') ||
                        newFile.name.includes('.JPEG')
                    ) {
                        newTasks[idx].file = newFile
                        setImageFormatError(null)
                        setVideoFormatError(null)
                    } else {
                        newTasks[idx].file = null
                        setImageFormatError(idx)
                    }

                    // Check file size - video - single
                } else if (newFile.type.includes('video') && newFile.size < 20000000) {
                    setMediaFileSizeError(null)

                    // Check format - video - single
                    if (newFile.name.includes('.mp4') || newFile.name.includes('.MP4')) {
                        newTasks[idx].file = newFile
                        setImageFormatError(null)
                        setVideoFormatError(null)
                    } else {
                        newTasks[idx].file = null
                        setVideoFormatError(idx)
                    }
                } else if (!newFile.type.includes('image') && !newFile.type.includes('video')) {
                    newTasks[idx].file = null
                    setUnrecognizedMediaError(idx)
                } else {
                    newTasks[idx].file = null
                    setMediaFileSizeError(idx)
                }
            } else {
                newTasks[idx].file = null
            }
        }

        if (
            imageFormatError === null &&
            videoFormatError === null &&
            mediaFileSizeError === null &&
            unrecognizedMediaError === null
        ) {
            setTaskErrors(newTaskErrors)
            setTasks(newTasks)
        }
    }

    /**
     * Event handler to handle user deleting a task
     * @param {*} idx
     */
    const onDeleteTask = (idx) => {
        setTaskMenuShowing(null)

        const newTasks = [...tasks]
        const newTaskErrors = [...taskErrors]

        newTasks.splice(idx, 1)
        for (const taskIdx in newTasks) {
            newTasks[taskIdx].taskIndex = parseInt(taskIdx)
        }
        updateDraft(
            'tasks',
            newTasks.length === 0 ? 'None' : formatTasks(newTasks),
            draft,
            props,
            setDraftJsonFunc
        )

        newTaskErrors.splice(idx, 1)

        // add overall error if we have deleted the last task
        if (newTasks.length < 1 || (newTasks.length === 1 && newTasks[0].isEmpty)) {
            const newErrors = { ...errors }
            newErrors.taskList = 'Your test must have at least one task'
            setErrors(newErrors)
        }

        setTaskErrors(newTaskErrors)
        setTasks(newTasks)
    }

    /**
     *
     * @param {*} oldIdx
     * @param {*} newIdxAbove
     * @param {*} newIdxBelow
     * @param {*} spaceIsInABTest
     */
    const onMoveTaskCardIntoSpace = (oldIdx, newIdxAbove, newIdxBelow) => {
        // shift around tasks & task errors to match the drag & drop
        const newTasks = [...tasks]
        const newTaskErrors = [...taskErrors]

        // const oldTask = tasks[oldIdx];
        newIdxBelow = oldIdx < newIdxBelow ? newIdxAbove : newIdxBelow // to account for shifting everything up one
        newTasks.splice(newIdxBelow, 0, newTasks.splice(oldIdx, 1)[0])
        newTaskErrors.splice(newIdxBelow, 0, newTaskErrors.splice(oldIdx, 1)[0])

        setShiftingDown(oldIdx)

        if (shiftingDown) {
            setTimeout(() => {
                setShiftingDown(null)
            }, 1000)
        }

        // POST update draft + set state variables accordingly
        // updateDraft("tasks", formatId(newTasks), draft, props, setDraftJsonFunc);
        setTasks(newTasks)
        setTaskErrors(newTaskErrors)
    }

    return (
        <div className="task__section">
            <TaskWrapper>
                {tasks.length > 0 && (
                    <TaskList
                        onBlurTaskAttribute={onBlurTaskAttribute}
                        onChangeTaskAttribute={onChangeTaskAttribute}
                        onChangeTaskBoolean={onChangeTaskBoolean}
                        onChangeTaskFile={onChangeTaskFile}
                        onDeleteTask={onDeleteTask}
                        onMoveTaskCardIntoSpace={onMoveTaskCardIntoSpace}
                        shiftingDown={shiftingDown}
                        setTaskGroupShowing={setTaskGroupShowing}
                        setTaskMenuShowing={setTaskMenuShowing}
                        taskErrors={taskErrors}
                        taskGroupShowing={taskGroupShowing}
                        taskMenuShowing={taskMenuShowing}
                        tasks={tasks}
                        imageFormatError={imageFormatError}
                        videoFormatError={videoFormatError}
                        mediaFileSizeError={mediaFileSizeError}
                        unrecognizedMediaError={unrecognizedMediaError}
                    />
                )}

                {allowedTestParams && (
                    <TaskButtonWrapper taskAdded={taskAdded}>
                        <TaskButton onClick={() => onAddTask()}>{'+ Add task'}</TaskButton>

                        <TaskButton onClick={() => onAddABTask()}>{'+ Add AB task'}</TaskButton>
                    </TaskButtonWrapper>
                )}
            </TaskWrapper>
        </div>
    )
}

export default React.memo(TasksColumn)
