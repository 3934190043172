import './_dropdownmenu.scss'

import React from 'react'

import Checkbox from '../../utils/Checkbox'

/**
 *
 * @param {*} props
 */
const DropdownMenu = (props) => {
    const { menuOptions, onSelectMenuOption, selectedRowCheckboxes, selectedValues } = props

    return (
        <div className="menu">
            <ul>
                {menuOptions.map((elt, idx) => (
                    <li
                        className={`${selectedValues.indexOf(elt.value) !== -1 ? 'selected' : ''} ${elt.disabled ? 'disabled' : ''}`}
                        key={idx}
                        onClick={onSelectMenuOption}
                        value={elt.value}>
                        {selectedRowCheckboxes && (
                            <Checkbox
                                checked={!elt.disabled && selectedValues.indexOf(elt.value) !== -1}
                                useSmallIcon={true}
                            />
                        )}
                        <div className={`text ${selectedRowCheckboxes ? 'indent' : ''}`}>
                            {elt.text2 ? (
                                <div>
                                    <div className="text__column__1">{elt.text}</div>
                                    {elt.text2 ? (
                                        <div className="text__column__2">{elt.text2}</div>
                                    ) : null}
                                </div>
                            ) : (
                                elt.text
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default React.memo(DropdownMenu)
