import './messagecardbox.css'

import React from 'react'

import CharacterCounter from '../utils/CharacterCounter'

/**
 *
 * @param {*} props
 */
const MessageCardBox = (props) => {
    const { message, messageType, onChangeTestAttribute, setMessage, updateFunc, usingMessage } =
        props

    return (
        <div className="message__card__box">
            <div className={`message__card ${usingMessage ? '' : 'inactive'}`}>
                <textarea
                    className={`test-input ${usingMessage ? '' : 'inactive'}`}
                    maxLength={400}
                    onBlur={updateFunc}
                    name={`message-card`}
                    onChange={(e) => {
                        const value = e.target.value
                        setMessage(value)
                        onChangeTestAttribute(`${messageType}Message`, value)
                    }}
                    readOnly={!usingMessage}
                    value={message || ''}
                />
                <CharacterCounter
                    charLimit={400}
                    className={`${messageType}_message`}
                    numChars={message ? message.length : 0}
                />
            </div>
        </div>
    )
}

export default MessageCardBox
