export const addToLocalStorage = (key, value, duration = 120) => {
    const expirationInMinutes = duration * 60 * 1000
    const expirationDate = new Date(new Date().getTime() + expirationInMinutes)
    const item = {
        value,
        expirationDate: expirationDate.toISOString()
    }

    localStorage.setItem(key, JSON.stringify(item))
}

export const storeTokens = (token, refreshToken) => {
    if (token.length < 10 || typeof window === 'undefined') {
        return
    }

    addToLocalStorage('token', token)
    addToLocalStorage('refreshToken', refreshToken)
}

export const loadFromStorage = (key) => {
    const result = localStorage.getItem(key)

    if (!result) {
        return null
    }

    try {
        const item = JSON.parse(result)
        const now = new Date()
        const expirationDate = new Date(item.expirationDate)

        if (now > expirationDate) {
            localStorage.removeItem(key)

            return null
        }

        return item.value
    } catch (error) {
        return result
    }
}

export const clearStorage = () => {
    const shouldRememberMe = localStorage.getItem('rememberMe')
    const email = localStorage.getItem('email')
    const password = localStorage.getItem('password')

    if (typeof window !== 'undefined') {
        localStorage.clear()
    }
    if (shouldRememberMe) {
        localStorage.setItem('password', password)
        localStorage.setItem('email', email)
        localStorage.setItem('rememberMe', true)
    }
}

export const storageUserKey = (key) => {
    return `${key}_${localStorage.getItem('userID')}`
}
