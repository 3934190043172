import React, { useEffect, useState } from 'react'

import PrimaryButton from '@/components/atoms/buttons/PrimaryButton'
import BottomLink from '@/components/atoms/links/BottomLink'
import BrandTitle from '@/components/atoms/text/BrandTitle'
import PasswordInput from '@/components/molecules/forms/PasswordInput'
import TextInput from '@/components/molecules/forms/TextInput'

import CheckboxInput from '../../molecules/forms/CheckboxInput'
import { apiLogIn } from '../../utils/apiLogging'

const LogInForm = (props) => {
    const [formData, setFormData] = useState({ username: '', password: '' })
    const [formErrors, setErrors] = useState({ username: '', password: '' })
    const [shouldRemember, setShouldRemember] = useState(false)

    useEffect(() => {
        const rememberMe = localStorage.getItem('rememberMe') === 'true'
        setShouldRemember(rememberMe)
        setShouldRemember(rememberMe)
    })

    const activeUserName = () => {
        const email = localStorage.getItem('email')
        if (shouldRemember && email) {
            return email
        }
        return formData.username
    }

    const onChangeRememberMe = (id, checked) => {
        setShouldRemember(checked)
        localStorage.setItem('rememberMe', checked)
    }

    const onFormSubmit = async (event) => {
        event.preventDefault()
        let { password, username } = formData

        if (!username) {
            username = activeUserName()
        }

        if (!shouldRemember) {
            localStorage.removeItem('rememberMe')
        }

        await apiLogIn(event, setErrors, { password, username })
    }

    return (
        <form
            onSubmit={onFormSubmit}
            className={`login-form-container ${props.viewPort.toLowerCase()}`}>
            <BrandTitle />

            <h1 className={'header'}>Welcome Back</h1>
            <TextInput
                label={'Email or Username'}
                error={formErrors.username}
                onChange={(e) => {
                    setFormData({ ...formData, username: e.target.value })
                    setErrors({ password: '', username: '' })
                }}
                placeholder="Type your email"
                value={activeUserName()}
                icon={props.viewPort === 'DESKTOP'}
                autoComplete={'email'}
                hideHelpers={false}
                tabIndex={1}
            />

            <PasswordInput
                id="password"
                error={formErrors.password}
                labelText="Password"
                onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value })
                    setErrors({ password: '', username: '' })
                }}
                placeholder="Type password"
                value={formData.password}
                icon={props.viewPort === 'DESKTOP'}
                autoComplete={'current-password'}
                tabIndex={2}
            />
            {/* TODO: Add remember me functionality */}
            <CheckboxInput
                label={'Remember me'}
                id={'remember-me'}
                value={shouldRemember}
                onChange={onChangeRememberMe}
            />

            <PrimaryButton type={'submit'} className={'auth'} tabIndex={3}>
                Login
            </PrimaryButton>
            <BottomLink link={'/signup'} linkText={'Sign Up'} message={'Not a member yet?'} />
        </form>
    )
}

export default LogInForm
