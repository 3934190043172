import '../../styles/app.scss'
import './fourohfour.scss'

import React from 'react'

import fourOhFourIcon from '../../images/404_eyes.svg'

/**
 * Component (temporary) to indicate an invalid URL.
 */
function FourOhFour() {
    sessionStorage.removeItem('draftID')
    return (
        <div className="container four-oh-four-container">
            <div className="row wrapper-row">
                <div className="four-oh-four-header">404</div>
                <div className="four-oh-four-text">
                    <p className="row span1">Oops!</p>
                    <p className="row span2">
                        Things are looking <span className="blurry-text">blurry</span>
                    </p>
                </div>
            </div>
            <div className="row">
                <img alt="fourohfour_icon" className="fourOhFourIcon" src={fourOhFourIcon} />
            </div>

            <p className="helpful-text">
                {`Sorry that page can't be found.`}
                <br />
                <br />
                Please go back or try these useful links:
            </p>
            <div className="links">
                <a href="/" id="homeLink">
                    Home
                </a>
                <a href="/login" id="logInLink">
                    Log In
                </a>
            </div>
        </div>
    )
}

export default FourOhFour
