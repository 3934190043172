import '../../create/createtestcontainer.scss'

import React, { useState } from 'react'
import styled from 'styled-components'

import Separator from '../../atoms/layout/Separator'
import CharacterCounter from '../../utils/CharacterCounter'

const ErrorBarWrapper = styled.div`
    ${{ overflow: 'hidden', transition: 'height 1s', borderRadius: '5px' }}
`
const ErrorBarContent = styled.div`
    ${{
        width: '100%',
        padding: '4px 8px 24px 8px',
        borderRadius: '5px',
        backgroundColor: 'rgb(255 98 98)'
    }}
`

const TestIntroduction = (props) => {
    const {
        errors,
        draft,
        updateDraft,
        description,
        setName,
        onChangeTestAttribute,
        name,
        setDescription,
        setDraftJsonFunc
    } = props

    const [errorBar, setErrorBar] = useState({ field: null, message: null })

    const nameBlur = (e) => {
        if (e.target.value !== '') {
            updateDraft('name', e.target.value, draft, props, setDraftJsonFunc)
            return
        }

        setErrorBar({ field: 'name', message: 'Your test must have a name to be valid' })
    }

    return (
        <div id="testIntroduction" className="step__box">
            <ErrorBarWrapper style={{ height: `${errorBar.field !== null ? '28px' : '0px'}` }}>
                <ErrorBarContent>
                    {errorBar.field !== null && (
                        <p style={{ color: 'white', margin: '0px' }}> {errorBar.message} </p>
                    )}
                </ErrorBarContent>
            </ErrorBarWrapper>

            <Separator height={8} />

            <div id="pilotName" className={'test-name-container'}>
                <p className="create-title"> {'Test Name*'} </p>
                <input
                    autoFocus
                    className={`text-input ${'name' in errors ? 'error' : ''}`}
                    id="testName"
                    maxLength={50}
                    onBlur={(e) => {
                        nameBlur(e)
                    }}
                    onChange={(e) => {
                        const value = e.target.value
                        setName(value)
                        onChangeTestAttribute('name', value)
                        if (name?.length > 0 && errorBar.field !== null) {
                            setErrorBar({ field: null, message: null })
                        }
                    }}
                    placeholder="My Test"
                    type="text"
                    value={name || ''}
                />
                <div style={{ display: 'flex', justifyContent: 'right', marginTop: '4px' }}>
                    <CharacterCounter
                        charLimit={50}
                        className="name"
                        numChars={name ? name.length : 0}
                    />
                </div>
            </div>

            <Separator height={24} />

            <div id="pilotDescription" className="description-container">
                <p className="create-title">
                    Description <span>(optional)</span>
                </p>
                <p>
                    You can use the description to write the goal of your test, take notes about
                    your participants, etc...
                </p>
                <textarea
                    className="create-input-field"
                    maxLength={500}
                    onBlur={(e) => {
                        if (e.target.value !== '') {
                            updateDraft(
                                'description',
                                e.target.value,
                                draft,
                                props,
                                setDraftJsonFunc
                            )
                        }
                    }}
                    onChange={(e) => {
                        const value = e.target.value
                        setDescription(value)
                        onChangeTestAttribute('description', value)
                    }}
                    placeholder={
                        'Description: the test description is visible only by you. Test name & description are for you to frame the purpose of the test & analyse the results in your dashboard.'
                    }
                    value={description || ''}
                />
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <CharacterCounter
                        charLimit={500}
                        className="description"
                        numChars={description ? description.length : 0}
                    />
                </div>
            </div>
        </div>
    )
}

export default TestIntroduction
