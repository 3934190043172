import './dashboard.css'

import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Userpilot } from 'userpilot'

import { NavigationContext, NavigationContextItem, testIdFromUrl } from '../../navigation/navigate'
import { formatCreated } from '../../utilities/date'
import {
    getTesterData,
    sendDeleteRequest,
    sendGetRequestByID,
    wrapRequestInRefreshToken
} from '../utils/api'
import {
    aggregator,
    formatNumberString,
    getComparator,
    objectSnakeToCamelCase,
    stableSort,
    upperCaseFirstChar
} from '../utils/functions'
import LoaderSpinner from '../utils/LoaderSpinner'
import AnalyseCard from './AnalyseCard'
import ExploreCard from './ExploreCard'
import { headerCellsDict } from './headerCells'
import ScreenerCard from './ScreenerCard'
import TestInfo from './TestInfo'

/**
 *
 * @param {*} testersTable
 * @param {*} test
 */
function getDetailedTableView(testersTable, test, isAbTest) {
    const testTasks = test.tasks
    const detailedTableView = []
    for (const i in testersTable) {
        const testerData = testersTable[i]
        const testerTasks = testerData.tasks
        for (const j in testerTasks) {
            const task = testerTasks[j]
            const testTask = testTasks.find(
                (tt) =>
                    tt.task_id ===
                    (Object.prototype.hasOwnProperty.call(task, 'task_id')
                        ? task.task_id
                        : task.task_index)
            )
            const pauseRecording = testTask.pause_recording
            const duration = pauseRecording
                ? null
                : parseFloat((task.time_rel_end - task.time_rel_start).toFixed(1))
            const meanFixationDuration = pauseRecording
                ? null
                : testerData.fixationProperties.tasks[j].mean_fixation_duration
            const numFixations = pauseRecording
                ? null
                : testerData.fixationProperties.tasks[j].num_fixations
            const numTaps = pauseRecording ? null : testerData.touchProperties.tasks[j].num_taps
            const rating = testTask.rating
                ? Date.parse(task.created) > Date.parse('2022-03-28T18:00:00')
                    ? task.rating
                    : task.rating + 1
                : null

            detailedTableView.push({
                status: 'success_tester' in task ? task.success_tester : task.success,
                index: detailedTableView.length,
                testerID: testerData.testerID, // not used to render anything
                testerIDStr: testerData.externalID,

                ab: isAbTest ? testerData.ab : null,
                abStr: isAbTest ? testerData.ab.toUpperCase() : null,
                created: testerData.created,
                createdStr: testerData.created,
                device: testerData.device,
                deviceStr: testerData.device,
                duration,
                durationStr: duration != null ? formatNumberString(duration, 1) : '-',
                meanFixationDuration,
                meanFixationDurationStr:
                    meanFixationDuration != null
                        ? meanFixationDuration.toFixed(3).padStart('    ')
                        : '-',
                numFixations,
                numFixationsStr: numFixations != null ? formatNumberString(numFixations, 0) : '-',
                numTaps,
                numTapsStr: numTaps != null ? formatNumberString(numTaps, 0) : '-',
                pauseRecording,
                ratingRate: rating,
                ratingRateStr: rating != null ? `${formatNumberString(rating, 1)}/5` : '-',
                task: parseInt(task.task_index),
                taskStr: parseInt(task.task_index) + 1,
                testSpecificTesterID: testerData.testSpecificTesterID,
                testSpecificTesterIDStr: testerData.testSpecificTesterID + 1
            })
        }
    }

    return detailedTableView
}

/**
 *
 * @param {*} detailedTableView
 * @param {*} testersTable
 * @param {*} testTasks
 */
function getTaskAggregatedTableView(detailedTableView, testTasks, isAbTest) {
    return stableSort(testTasks, getComparator('asc', 'task_index')).map((task) => {
        let thisTaskData = detailedTableView.filter((elt) => elt.task === task.task_index)

        let taskGroup = null
        if (isAbTest && task.task_group !== 0) {
            taskGroup = task.task_group === 1 ? 'a' : 'b'
            thisTaskData = thisTaskData.filter((elt) => elt.ab === taskGroup)
        }
        const numTesters = thisTaskData.length
        const numSuccessfulTesters = thisTaskData.filter((elt) => elt.status).length

        const pauseRecording = task.pause_recording

        const ab = taskGroup
        const completionRate = `${numSuccessfulTesters}/${numTesters}`
        const duration =
            pauseRecording || numTesters === 0
                ? null
                : aggregator(
                      thisTaskData.map((elt) => elt.duration),
                      'mean',
                      1
                  )
        const meanFixationDuration =
            pauseRecording || numTesters === 0
                ? null
                : aggregator(
                      thisTaskData.map((elt) => elt.meanFixationDuration),
                      'mean',
                      3
                  )
        const numFixations =
            pauseRecording || numTesters === 0
                ? null
                : aggregator(
                      thisTaskData.map((elt) => elt.numFixations),
                      'mean',
                      1
                  )
        const numTaps =
            pauseRecording || numTesters === 0
                ? null
                : aggregator(
                      thisTaskData.map((elt) => elt.numTaps),
                      'mean',
                      1
                  )
        const rating =
            numTesters === 0
                ? null
                : task.rating
                  ? aggregator(
                        thisTaskData.map((elt) => elt.ratingRate),
                        'mean',
                        1
                    )
                  : null

        return {
            status: numSuccessfulTesters === numTesters,
            index: task.task_id,

            ab,
            abStr: ab != null ? ab.toUpperCase() : '-',
            completionRate,
            completionRateStr: completionRate,
            duration,
            durationStr: duration != null ? formatNumberString(duration, 1) : '-',
            meanFixationDuration,
            meanFixationDurationStr:
                meanFixationDuration != null
                    ? meanFixationDuration.toFixed(3).padStart('    ')
                    : '-',
            numFixations,
            numFixationsStr: numFixations != null ? formatNumberString(numFixations, 0) : '-',
            numTaps,
            numTapsStr: numTaps != null ? formatNumberString(numTaps, 0) : '-',
            pauseRecording,
            ratingRate: rating,
            ratingRateStr: rating != null ? `${formatNumberString(rating, 1)}/5` : '-',
            task: parseInt(task.task_index)
        }
    })
}

/**
 *
 * @param {*} detailedTableView
 * @param {*} testersTable
 * @param {*} testTasks
 */
function getTesterAggregatedTableView(detailedTableView, testersTable, testTasks) {
    return testersTable.map((elt) => {
        const thisTesterData = detailedTableView.filter((elt2) => elt2.testerID === elt.testerID)
        const numTasks = testTasks
            .map((elt) => elt.task_index)
            .filter((value, index, self) => self.indexOf(value) === index).length
        const numTasksSucceeded = thisTesterData.filter((task) => task.status).length

        const completionRate = `${numTasksSucceeded}/${numTasks}`
        const duration = parseFloat(elt.duration)
        const meanFixationDuration = elt.fixationProperties.overall.mean_fixation_duration
        const numFixations = elt.fixationProperties.overall.num_fixations
        const numTaps = elt.touchProperties.overall.num_taps
        let rating = thisTesterData
            .map((task) => task.ratingRate)
            .filter((taskRating) => taskRating && taskRating !== -1)
        rating = rating.length > 0 ? aggregator(rating, 'mean', 1) : null

        return {
            status: numTasksSucceeded === numTasks,
            index: elt.testSpecificTesterID,
            screeners: elt.screeners,
            testerID: elt.testerID,
            testerIDStr: elt.externalID,

            ab: elt.ab,
            abStr: elt.ab != null ? elt.ab.toUpperCase() : '-',
            completionRate,
            completionRateStr: completionRate,
            duration,
            durationStr: formatNumberString(duration, 1),
            meanFixationDuration,
            meanFixationDurationStr: meanFixationDuration.toFixed(3).padStart('    '),
            numFixations,
            numFixationsStr: formatNumberString(numFixations, 0),
            numTaps,
            numTapsStr: formatNumberString(numTaps, 0),
            ratingRate: rating,
            ratingRateStr: rating != null ? `${formatNumberString(rating, 1)}/5` : '-'
        }
    })
}

/**
 *
 * @param {*} detailedTableView
 * @param {*} testersTable
 * @param {*} testTasks
 */
const getABGroupAggregatedView = (detailedTableView, testersTable, testTasks) => {
    // organise all tasks in A and B groups respectively
    const aTaskIndices = testTasks
        .filter((task) => task.task_group === 1)
        .map((task) => task.task_index)
    const bTaskIndices = testTasks
        .filter((task) => task.task_group === 2)
        .map((task) => task.task_index)
    const aTasks = []
    const bTasks = []
    detailedTableView.forEach((elt) => {
        if (elt.ab === 'a' && aTaskIndices.indexOf(elt.task) !== -1) {
            aTasks.push(elt)
        } else if (elt.ab === 'b' && bTaskIndices.indexOf(elt.task) !== -1) {
            bTasks.push(elt)
        }
    })

    return [aTasks, bTasks].map((taskList, idx) => {
        const taskListLength = taskList.length
        const ab = idx === 0 ? 'a' : 'b'
        const completionRate = `${taskList.filter((elt) => elt.status).length}/${taskList.length}`
        const duration =
            taskListLength === 0
                ? null
                : aggregator(
                      taskList.map((task) => task.duration),
                      'mean',
                      1
                  )
        const meanFixationDuration =
            taskListLength === 0
                ? null
                : aggregator(
                      taskList.map((task) => task.meanFixationDuration),
                      'mean',
                      3
                  )
        const numTaps =
            taskListLength === 0
                ? null
                : aggregator(
                      taskList.map((task) => task.numTaps),
                      'mean',
                      1
                  )
        const numFixations =
            taskListLength === 0
                ? null
                : aggregator(
                      taskList.map((task) => task.numFixations),
                      'mean',
                      1
                  )
        const ratings = detailedTableView
            .map((task) => {
                if (task.ab === ab) {
                    return task.ratingRate
                } else {
                    return null
                }
            })
            .filter((rating) => rating != null)
        const rating =
            taskListLength === 0
                ? null
                : ratings.length === 0
                  ? null
                  : aggregator(ratings, 'mean', 1)
        const numTesters = testersTable.filter((tester) => tester.ab === ab).length

        return {
            index: ab.toUpperCase(),

            completionRate,
            completionRateStr: completionRate,
            duration,
            durationStr: duration == null ? '-' : formatNumberString(duration, 1),
            numTaps,
            numTapsStr: numTaps == null ? '-' : formatNumberString(numTaps, 0),
            numTesters,
            numTestersStr: formatNumberString(numTesters, 0),
            numFixations,
            numFixationsStr: numFixations == null ? '-' : formatNumberString(numFixations, 0),
            meanFixationDuration,
            meanFixationDurationStr:
                meanFixationDuration == null
                    ? '-'
                    : meanFixationDuration.toFixed(3).padStart('    '),
            ratingRate: rating,
            ratingRateStr: rating != null ? `${formatNumberString(rating, 1)}/5` : '-'
        }
    })
}

let resizeTimer

/**
 *
 */
const Dashboard = (props) => {
    const [barChartData, setBarChartData] = useState([])
    const [filters, setFilters] = useState(['numFixations', 'meanFixationDuration'])
    const [filterColours, setFilterColours] = useState(['#4B8CF7', '#FFF78F'])
    const [isNarrowScreen, setIsNarrowScreen] = useState(
        (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <
            1440
    )
    const [screenerVisible, setScreenerVisible] = useState(0)
    const [selectedRows, setSelectedRows] = useState([])
    const [showingExpandedBarChart, setShowingExpandedBarChart] = useState(false)
    const [showingExpandedScreener, setShowingExpandedScreener] = useState(false)
    const [showingExpandedTable, setShowingExpandedTable] = useState(false)
    const [showingTesterDeletionModal, setShowingTesterDeletionModal] = useState(false)
    const [surveyTabActive, setSurveyTabActive] = useState(null)
    const [tableOrder, setTableOrder] = useState('asc')
    const [tableOrderBy, setTableOrderBy] = useState('index')
    const [tableView, setTableView] = useState(0)
    const [tableViews, setTableViews] = useState(null)
    const [test, setTest] = useState(null)
    const [testers, setTesters] = useState(null)
    const [hasExternalId, setHasExternalId] = useState(true)
    const [taskHover, setTaskHover] = useState(null)
    const [playbackOptionsVisible, setPlaybackOptionsVisible] = useState(null)

    const location = useLocation()
    const { setNavState } = React.useContext(NavigationContext)

    React.useEffect(() => {
        Userpilot.reload()
        setNavState(NavigationContextItem.TEST_SIDE_BAR)
    }, [location])

    /**
     * If no external IDs exist, remove the column
     */
    useEffect(() => {
        const externalIDs = []
        for (const i in testers) {
            if (testers[i].externalID) {
                externalIDs.push(testers[i].externalID)
            }
        }
        if (testers) {
            if (externalIDs.length > 0) {
                setHasExternalId(true)
            } else {
                setHasExternalId(false)
            }
        }
    }, [testers])

    /**
     * Set feedback for disabling the video replay option when in dashboard
     */
    useEffect(() => {
        if (test && test.count > 0) {
            props.setParticipantFeedback(true)
        } else {
            props.setParticipantFeedback(false)
        }
    }, [test, props])

    /**
     * Create window resize listener to determine which breakpoint we are in for rendering.
     */
    useEffect(() => {
        window.addEventListener('resize', () => {
            clearTimeout(resizeTimer)
            resizeTimer = setTimeout(() => {
                const newIsNarrowScreen =
                    (window.innerWidth ||
                        document.documentElement.clientWidth ||
                        document.body.clientWidth) < 1440
                if (newIsNarrowScreen && !isNarrowScreen) {
                    setIsNarrowScreen(true)
                } else if (!newIsNarrowScreen && isNarrowScreen) {
                    setIsNarrowScreen(false)
                }
            }, 150)
        })
    }, [isNarrowScreen])

    /**
     * Load test object if this component wasn't already passed it
     */
    useEffect(() => {
        sessionStorage.removeItem('draftID')

        let isMounted = true
        const testID = testIdFromUrl() // props.match.params.id

        const successFunc = (json) => {
            if (typeof json !== 'undefined') {
                // sort tasks (need to blend AB tasks --> a single task)
                const loadedTasks = json.tasks.length > 0 ? objectSnakeToCamelCase(json.tasks) : []
                const taskIndices = [...new Set(loadedTasks.map((task) => task.taskIndex))]
                const sortedLoadedTasks = []
                for (const i in taskIndices) {
                    const taskIndex = taskIndices[i]
                    const thisIndexTasks = loadedTasks.filter(
                        (task) => task.taskIndex === taskIndex
                    )
                    if (thisIndexTasks.length === 1) {
                        sortedLoadedTasks.push({ ...thisIndexTasks[0], isABTest: false })
                    } else {
                        sortedLoadedTasks.push({ ...thisIndexTasks[0], isABTest: true }) // copy A task only (don't need to show media differences)
                    }
                }

                const newTest = json
                newTest.sortedTasks = sortedLoadedTasks
                if (isMounted) setTest(newTest)

                // determine what we are rendering in screener / questionnaire tab
                if (json.screener && json.screener.length > 0) {
                    if (isMounted) setSurveyTabActive('screeners')
                } else if (json.survey != null) {
                    if (isMounted) setSurveyTabActive('survey')
                }

                if (json.task_scheme !== 'ab') {
                    const newHeaderCellsDict0 = headerCellsDict[0].filter(
                        (cell) => cell.id !== 'ab'
                    )
                    const newHeaderCellsDict1 = headerCellsDict[1].filter(
                        (cell) => cell.id !== 'ab'
                    )
                    const newHeaderCellsDict2 = headerCellsDict[2].filter(
                        (cell) => cell.id !== 'ab'
                    )

                    headerCellsDict[0] = newHeaderCellsDict0
                    headerCellsDict[1] = newHeaderCellsDict1
                    headerCellsDict[2] = newHeaderCellsDict2
                }

                let noTasksHaveRating = true
                json.tasks.forEach((task) => {
                    if (task.rating) noTasksHaveRating = false
                })
                if (noTasksHaveRating) {
                    const newHeaderCellsDict0 = headerCellsDict[0].filter(
                        (cell) => cell.id !== 'ratingRate'
                    )
                    const newHeaderCellsDict1 = headerCellsDict[1].filter(
                        (cell) => cell.id !== 'ratingRate'
                    )
                    const newHeaderCellsDict2 = headerCellsDict[2].filter(
                        (cell) => cell.id !== 'ratingRate'
                    )

                    headerCellsDict[0] = newHeaderCellsDict0
                    headerCellsDict[1] = newHeaderCellsDict1
                    headerCellsDict[2] = newHeaderCellsDict2
                }

                if (hasExternalId === false) {
                    const newHeaderCellsDict0 = headerCellsDict[0].filter(
                        (cell) => cell.id !== 'testerID'
                    )
                    const newHeaderCellsDict1 = headerCellsDict[1].filter(
                        (cell) => cell.id !== 'testerID'
                    )
                    const newHeaderCellsDict2 = headerCellsDict[2].filter(
                        (cell) => cell.id !== 'testerID'
                    )

                    headerCellsDict[0] = newHeaderCellsDict0
                    headerCellsDict[1] = newHeaderCellsDict1
                    headerCellsDict[2] = newHeaderCellsDict2
                }
            }
        }

        const errorFunc = (err) => err.json().then((body) => console.log(body))
        wrapRequestInRefreshToken(props, sendGetRequestByID, [
            'test',
            testID,
            successFunc,
            errorFunc
        ])

        return () => {
            isMounted = false
        }
    }, [props, hasExternalId])

    /**
     * Load tester data for all testers in current test
     */
    useEffect(() => {
        let isMounted = true

        if (test) {
            const successFunc = (json) => {
                const testers = stableSort(json, getComparator('asc', 'id'))

                // convert tester data --> javascript, formatted version
                const testersTable = testers.map((elt) => {
                    const deviceParams = elt.device
                    let manufacturer = deviceParams.manufacturer
                    if (!/^[A-Z]/.test(manufacturer)) {
                        manufacturer = upperCaseFirstChar(manufacturer)
                    }
                    if (manufacturer === manufacturer.toUpperCase()) {
                        manufacturer = upperCaseFirstChar(manufacturer.toLowerCase())
                    }

                    return {
                        ab: elt.ab,
                        created: formatCreated(elt.time),
                        device: manufacturer + ' ' + deviceParams.model,
                        duration: Math.floor(elt.duration),
                        externalID: elt.external_id,
                        fixationProperties: JSON.parse(elt.fixation_statistics),
                        rawTesterID: elt.raw_tester_id,
                        screeners: JSON.parse(elt.screeners),
                        touchProperties: JSON.parse(elt.touch_statistics),
                        tasks: JSON.parse(elt.tasks),
                        testerID: elt.id,
                        testSpecificTesterID: parseInt(elt.test_specific_tester_id)
                    }
                })

                // organise 3 distinct table views
                const testTasks = test.tasks
                const isAbTest = test.task_scheme === 'ab'
                const detailedTableView = getDetailedTableView(testersTable, test, isAbTest)
                const tableViews = {
                    0: detailedTableView,
                    1: getTaskAggregatedTableView(detailedTableView, testTasks, isAbTest),
                    2: getTesterAggregatedTableView(detailedTableView, testersTable, testTasks)
                }
                if (isAbTest) {
                    tableViews[3] = getABGroupAggregatedView(
                        detailedTableView,
                        testersTable,
                        testTasks
                    )
                }

                if (isMounted) {
                    setTableViews(tableViews)
                    setTesters(testersTable)
                }
            }
            const errorFunc = (err) => err.json().then((body) => console.log(body))
            const url = process.env.REACT_APP_URL + `tester/?test_id=${testIdFromUrl()}`
            wrapRequestInRefreshToken(props, getTesterData, [url, [], successFunc, errorFunc])
        }

        return () => {
            isMounted = false
        }
    }, [props, test])

    /**
     * Function called when user tries to delete selection of tester(s).
     */
    const onDeleteTester = () => {
        setShowingTesterDeletionModal(false)

        // remove testers from testers list & keep track of the IDs of deleted testers & send deletion requests
        const newTesters = [...testers]
        const testerIDsRemoved = []
        const testSpecificTesterIDsRemoved = []
        const currTableData = tableViews[tableView]
        for (let i = selectedRows.length - 1; i >= 0; i--) {
            const objectIndex = selectedRows[i]
            const idxOfTester = currTableData.findIndex((row) => row.index === objectIndex)
            const tester = newTesters[idxOfTester]
            testerIDsRemoved.push(tester.testerID)
            testSpecificTesterIDsRemoved.push(tester.testSpecificTesterID)
            newTesters.splice(idxOfTester, 1)

            // send tester deletion request to backend
            const successFunc = () => console.log('successfully deleted tester')
            const errorFunc = (err) => err.json().then((body) => console.log(body))
            wrapRequestInRefreshToken(props, sendDeleteRequest, [
                'tester',
                tester.testerID,
                successFunc,
                errorFunc
            ])
        }

        setTesters(newTesters)

        // set test to have no testers if required
        if (newTesters.length === 0) {
            const navigate = useNavigate()
            navigate('/overview')
        }

        // recompute all different table views
        const testTasks = test.tasks
        const isAbTest = test.task_scheme === 'ab'
        const newDetailedView = tableViews[0].filter(
            (row) => testerIDsRemoved.indexOf(row.testerID) === -1
        )
        const newTableViews = {
            0: newDetailedView,
            1: getTaskAggregatedTableView(newDetailedView, testTasks, isAbTest),
            2: tableViews[2].filter((row) => testerIDsRemoved.indexOf(row.testerID) === -1)
        }
        if (isAbTest) {
            newTableViews[3] = getABGroupAggregatedView(newDetailedView, newTesters, testTasks)
        }
        setTableViews(newTableViews)

        // set remaining state vars because no longer have any rows selected
        setBarChartData([])
        setSelectedRows([])
    }
    return (
        <div className="dashboard__flex__wrapper">
            <div className="dashboard__content__area">
                {test && (
                    <div>
                        <TestInfo
                            test={test}
                            taskHover={taskHover}
                            setTaskHover={setTaskHover}
                            playbackOptionsVisible={playbackOptionsVisible}
                            setPlaybackOptionsVisible={setPlaybackOptionsVisible}
                        />
                        {testers && tableViews && barChartData && test.screener && selectedRows ? (
                            <div className="dashboard__test__info__space">
                                <div className="dashboard__explore__card__wrapper">
                                    <ExploreCard
                                        barChartData={barChartData}
                                        filters={filters}
                                        headerCells={headerCellsDict[tableView]}
                                        isAbTest={test.task_scheme === 'ab'}
                                        onDeleteTester={onDeleteTester}
                                        selectedRows={selectedRows}
                                        setBarChartData={setBarChartData}
                                        setSelectedRows={setSelectedRows}
                                        setShowingExpandedTable={setShowingExpandedTable}
                                        setShowingTesterDeletionModal={
                                            setShowingTesterDeletionModal
                                        }
                                        showingExpandedTable={showingExpandedTable}
                                        showingTesterDeletionModal={showingTesterDeletionModal}
                                        setTableOrder={setTableOrder}
                                        setTableOrderBy={setTableOrderBy}
                                        setTableView={setTableView}
                                        setTableViews={setTableViews}
                                        tableOrder={tableOrder}
                                        tableOrderBy={tableOrderBy}
                                        tableView={tableView}
                                        tableViews={tableViews}
                                        test={test}
                                        testers={testers}
                                    />
                                </div>
                                <div className="dashboard__analyse__and__screener__card__wrapper">
                                    <ScreenerCard
                                        isNarrowScreen={isNarrowScreen}
                                        screeners={test.screener}
                                        screenerVisible={screenerVisible}
                                        selectedRows={selectedRows}
                                        surveyTabActive={surveyTabActive}
                                        setScreenerVisible={setScreenerVisible}
                                        setShowingExpandedScreener={setShowingExpandedScreener}
                                        setSurveyTabActive={setSurveyTabActive}
                                        showingExpandedScreener={showingExpandedScreener}
                                        tableView={tableView}
                                        tableViewData={tableViews[tableView]}
                                        test={test}
                                    />
                                    <AnalyseCard
                                        barChartData={barChartData}
                                        filters={filters}
                                        filterColours={filterColours}
                                        isNarrowScreen={isNarrowScreen}
                                        selectedRows={selectedRows}
                                        setBarChartData={setBarChartData}
                                        setFilters={setFilters}
                                        setFilterColours={setFilterColours}
                                        setShowingExpandedBarChart={setShowingExpandedBarChart}
                                        showingExpandedBarChart={showingExpandedBarChart}
                                        surveyTabActive={surveyTabActive}
                                        tableView={tableView}
                                        tableViewData={tableViews[tableView]}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="dashboardLoaderSpinner">
                                <LoaderSpinner />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Dashboard
