import React from 'react'

import { iconColors } from './icons'

const PlayIcon = ({ isActive = false, size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 12.125C22 12.1664 22 12.2081 22 12.25C22 16.964 22 19.321 20.5355 20.7855C19.0711 22.25 16.714 22.25 12 22.25C7.28595 22.25 4.92893 22.25 3.46447 20.7855C2 19.321 2 16.964 2 12.25C2 12.2081 2 12.1664 2 12.125C2 12.0835 2 12.0418 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28596 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28596 22 12C22 12.0418 22 12.0835 22 12.125Z"
                fill={iconColors(isActive).main}
            />
            <path
                d="M16 12.2C16 11.3133 15.0732 10.7153 13.2195 9.51929C11.3406 8.30696 10.4011 7.70079 9.70056 8.14586C9 8.59093 9 9.79395 9 12.2C9 14.606 9 15.8091 9.70056 16.2541C10.4011 16.6992 11.3406 16.093 13.2196 14.8807C15.0732 13.6847 16 13.0867 16 12.2Z"
                fill={iconColors(isActive).accent}
            />
        </svg>
    )
}

export default PlayIcon
