import { useEffect, useState } from 'react'

const useAutoGrow = (task, idx) => {
    const [cardLoaded, setCardLoaded] = useState(null)

    /**
     * Function to handle dynamic sizing of text areas.
     * @param {*} element
     */
    const autoGrow = (element) => {
        element.style.height = '5px'
        element.style.height = element.scrollHeight + 'px'
    }

    /**
     * Auto-size description when this component is loaded
     */
    useEffect(() => {
        autoGrow(document.getElementById(`taskDescription${idx}`))
    }, [idx])

    /**
     * Auto-size TOL instructions when TOL is changed (in case we have more text than minimum text box size)
     */
    useEffect(() => {
        if (task.tol) {
            autoGrow(document.getElementById(`taskTolInstructions${idx}`))
        }
    }, [idx, task.tol])

    /**
     * Auto-size rating text when rating is changed (in case we have more text than minimum text box size)
     */
    useEffect(() => {
        if (task.rating) {
            autoGrow(document.getElementById(`taskRatingText${idx}`))
        }
    }, [idx, task.rating])

    /**
     * Focus name input by default
     */
    useEffect(() => {
        if (cardLoaded === idx) {
            document.getElementById(`card-name${cardLoaded}`).focus()
        }
    }, [cardLoaded, idx])

    return [setCardLoaded, autoGrow]
}

export default useAutoGrow
