export const testStatus = ({ draft, active, count, complete, max_num_testers: totalTesters }) => {
    if (draft && count === 0) {
        return 'Draft'
    }
    if (complete) {
        return 'Complete'
    }
    if (active) {
        return count < totalTesters ? 'Active' : 'Complete'
    }

    if (count === 0) {
        return 'Inactive'
    }

    return 'Paused'
}
