import React from 'react'

import { iconColors } from './icons'

const HeatMapIcon = ({ isActive = false, size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22Z"
                fill={iconColors(isActive).main}
            />
            <path
                d="M12.6119 7.43793C12.8664 8.00935 13.3461 8.41812 13.9083 8.62932C14.1756 8.72927 14.4369 8.79978 14.6763 8.86015C15.1608 8.98208 15.5764 9.08985 15.8753 9.44462C16.1479 9.81092 16.1359 10.2205 16.1242 10.6865C16.1149 10.995 16.1023 11.3198 16.1724 11.6502C16.2068 11.804 16.2481 11.9536 16.3135 12.1024C16.7887 13.1763 18.0838 13.7014 19.2137 13.2784C20.3435 12.8553 20.8622 11.641 20.3881 10.5618C20.1827 10.102 19.8251 9.74086 19.3984 9.51227C19.0281 9.30089 18.6437 9.21645 18.2997 9.14026C17.8071 9.02799 17.417 8.94236 17.1099 8.56898C16.8119 8.18053 16.8403 7.80821 16.8588 7.33792C16.881 6.9079 16.8889 6.40157 16.6739 5.90035C16.2171 4.82464 14.922 4.29949 13.7864 4.72134C12.6565 5.14438 12.1378 6.35869 12.6119 7.43793Z"
                fill={iconColors(isActive).accent}
            />
            <path
                d="M12.5806 11.5641C11.734 11.0064 10.5173 11.2226 9.9504 12.0529C9.76577 12.3169 9.67111 12.6222 9.64028 12.9181L9.61809 12.894C9.55991 13.1939 9.42604 13.4231 9.42604 13.4231C9.34933 13.5346 9.24262 13.6323 9.1346 13.7211C8.62694 14.0692 8.07441 14.0507 7.41761 14.0203C6.9967 14.0097 6.56668 14.0004 6.0951 14.0883C5.88667 14.1282 5.67043 14.1784 5.4699 14.2718C3.99182 14.8634 3.28809 16.5333 3.89746 17.9454C4.50815 19.3664 6.18626 20.0653 7.64348 19.4585C8.26726 19.2024 8.76957 18.7549 9.07105 18.2097C9.34527 17.7321 9.46183 17.2597 9.56279 16.8078C9.71168 16.1758 9.82429 15.6767 10.3358 15.2916C10.7224 15.0068 10.9555 14.9451 11.3269 14.9357L11.2943 14.9041C11.9641 14.9599 12.6609 14.6931 13.0509 14.1165C13.653 13.2719 13.414 12.0964 12.5688 11.5476L12.5806 11.5641Z"
                fill={iconColors(isActive).accent}
            />
            <path
                d="M17 20C18.1068 20 19 19.1068 19 18C19 16.8932 18.1068 16 17 16C15.8932 16 15 16.8932 15 18C15 19.1068 15.9256 20 17 20Z"
                fill={iconColors(isActive).accent}
            />
            <path
                d="M7.25567 9.9864C8.62103 10.1306 9.84209 9.10986 9.9864 7.74512C10.1307 6.38038 9.10946 5.15987 7.74409 5.01563C6.37872 4.8603 5.15766 5.88108 5.01336 7.25692C4.86905 8.62166 5.9125 9.84216 7.25567 9.9864Z"
                fill={iconColors(isActive).accent}
            />
        </svg>
    )
}

export default HeatMapIcon
