import '../molecules/forms/_screenercolumn.scss'

import React from 'react'
import styled from 'styled-components'

import closeIcon from '../../images/cross_with_circle_icon_black.svg'
import deleteIcon from '../../images/delete_icon_black.svg'

const ScreenerQuestionCard = styled.div.attrs({ className: 'screener__question__card' })``
const TitleRow = styled.div`
    ${{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
`
const getClass = (props) => ('text' in props.screenerErrors[props.screenerIdx] ? 'error' : '')
const CustomTitleInput = styled.input.attrs((props) => ({
    className: `title custom ${getClass(props)}`
}))`
    width: calc(100% - 40px);
`
const OptionRow = styled.div.attrs({ className: 'option__row' })`
    ${{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between'
    }}
`
const PlusOneButton = styled.div`
    cursor: pointer;
    width: 80px;
    color: #4b8cf7;
`

const ScreenerCard = (props) => {
    const {
        screeners,
        screenerErrors,
        updateDraft,
        formatScreeners,
        onChangeScreenerText,
        autoGrow,
        screenerQuestionDict,
        onChangeScreenerOption,
        onDeleteScreenerOption,
        onAddScreenerOption,
        onDeleteScreener
    } = props

    return (
        screeners &&
        screenerErrors &&
        screeners.map((screener, screenerIdx) => (
            <ScreenerQuestionCard key={screenerIdx} id={`screenerCard${screenerIdx}`}>
                <TitleRow>
                    {screener.name === 'custom' ? (
                        <>
                            <CustomTitleInput
                                id={`screenerText${screenerIdx}`}
                                maxLength={100}
                                onBlur={() => updateDraft('screener', formatScreeners(screeners))}
                                onChange={(e) => onChangeScreenerText(e, screenerIdx)}
                                onInput={(e) => autoGrow(e.target)}
                                placeholder="Question"
                                type="text"
                                value={screener.question}
                                screenerErrors={screenerErrors}
                                screenerIdx={screenerIdx}
                            />
                            {/* <CharacterCounter
                                charLimit={100}
                                className='question'
                                numChars={screener.question ? screener.question.length : 0}
                            /> */}
                        </>
                    ) : (
                        <div className="title">{screenerQuestionDict[screener.name].question}</div>
                    )}
                    <div
                        onClick={() => onDeleteScreener(screenerIdx)}
                        style={{ cursor: 'pointer' }}>
                        <img src={deleteIcon} alt="delete icon" />
                    </div>
                </TitleRow>

                <div style={{ height: '24px' }} />

                {screener.type === 'dropdown' &&
                    screener.options.map((option, optionIdx) => (
                        <OptionRow key={`${screenerIdx} ${optionIdx}`}>
                            <input
                                className={`option ${screenerErrors[screenerIdx].options && screenerErrors[screenerIdx].options.constructor === Array && screenerErrors[screenerIdx].options[optionIdx] != null ? 'error' : ''}`}
                                id={`option${screenerIdx}${optionIdx}`}
                                maxLength={100}
                                onBlur={() => updateDraft('screener', formatScreeners(screeners))}
                                onChange={(e) => onChangeScreenerOption(e, screenerIdx, optionIdx)}
                                onInput={(e) => autoGrow(e.target)}
                                placeholder="Answer"
                                type="text"
                                value={option}
                                style={{
                                    paddingRight: '65px',
                                    width: 'calc(100% - 40px)',
                                    marginBottom: '4px'
                                }}
                            />

                            {/* <CharacterCounter
                                charLimit={100}
                                className='description'
                                numChars={option ? option.length : 0}
                            /> */}

                            <div
                                className="remove__option__div"
                                id={`rod${screenerIdx}${optionIdx}`}>
                                <img
                                    alt="close_icon"
                                    className={`remove__option ${screener.options.length <= 2 && 'disabled'}`}
                                    onClick={() =>
                                        screener.options.length > 2 &&
                                        onDeleteScreenerOption(screenerIdx, optionIdx)
                                    }
                                    src={closeIcon}
                                    style={{ cursor: 'pointer' }}
                                />
                                <div
                                    className="tooltip close__icon__tooltip"
                                    id={`closeIconTooltip${screenerIdx}_${optionIdx}`}>
                                    You must have at least two answers for a quick survey question.
                                </div>
                            </div>
                        </OptionRow>
                    ))}

                {screener.type === 'dropdown' && screener.options.length < 5 && (
                    <PlusOneButton onClick={() => onAddScreenerOption(screenerIdx)}>
                        {'+1 option'}
                    </PlusOneButton>
                )}
            </ScreenerQuestionCard>
        ))
    )
}

export default ScreenerCard
