import { Userpilot } from 'userpilot'

export const userPilotIdentify = ({ plan }) => {
    Userpilot.identify(`${localStorage.getItem('email')}`, {
        oculid_name: localStorage.getItem('full_name'),
        email: localStorage.getItem('email'),
        oculid_user_creation_date: localStorage.getItem('date_joined'),
        occupation: localStorage.getItem('occupation'),
        is_oculid: true,
        subscription: plan
    })
}
