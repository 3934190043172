import './analysecard.css'

import Modal from '@material-ui/core/Modal'
import React from 'react'

// import { GFontToDataURI } from '../utils/functions';
import contractViewIcon from '../../images/contract_view_icon.svg'
import expandViewIcon from '../../images/expand_view_icon.svg'
import Checkbox from '../utils/Checkbox'
import Tooltip from '../utils/Tooltip'
import BarChartWrapper from './BarChart'
import { headerCellsDict } from './headerCells'

const barChartColours = ['#4B8CF7', '#FFF78F']

/**
 *
 * @param {*} props
 */
const AnalyseCardControls = (props) => {
    const { barChartDisabled, setShowingExpandedBarChart, viewIcon } = props

    return (
        <div className={`analyse__card__controls ${barChartDisabled ? 'disabled' : ''}`}>
            <img
                alt={`${viewIcon}_view_icon`}
                className={`control__button ${viewIcon}`}
                onClick={() => {
                    if (!barChartDisabled) setShowingExpandedBarChart(viewIcon === 'expand')
                }}
                src={viewIcon === 'expand' ? expandViewIcon : contractViewIcon}
            />
        </div>
    )
}

/**
 *
 * @param {*} props
 */
const AnalyseCardContent = (props) => {
    const {
        barChartData,
        barchartContainerHeight,
        barchartContainerWidth,
        barChartDisabled,
        filters,
        filterColours,
        metricHeaderCells,
        metricNames,
        onChangeFilters,
        setShowingExpandedBarChart,
        showingExpandedBarChart,
        sortedRowIndices,
        tableView,
        tableViewData,
        viewIcon
    } = props

    let rows = sortedRowIndices
    if (tableView === 1) {
        rows = sortedRowIndices.map(
            (i) =>
                `${tableViewData[i].task + 1}${tableViewData[i].ab != null ? tableViewData[i].ab : ''}`
        )
    }

    return (
        <div className="analyse__card__content">
            {/* controls for downloading / full-screening the analyse section */}
            <AnalyseCardControls
                barChartDisabled={barChartDisabled}
                setShowingExpandedBarChart={setShowingExpandedBarChart}
                viewIcon={viewIcon}
            />

            {/* title row */}
            <div className="card__title">Analyse</div>
            <Tooltip className="analyse__card__tooltip" id="analyseCardTooltip">
                Compare behavioural & eye-movement data between your participants to discover where
                their experience varies. Select the participants or tasks that you wish to compare
                in the “Explore“ box to the left.
            </Tooltip>

            {/* metric filter checkboxes */}
            <div className="column__flex__wrapper">
                <div className="barchart__filters analyse__left__column">
                    <div className="title">Metrics</div>
                    {metricHeaderCells.map((headerCell, idx) => (
                        <div className="filter__row" key={idx} onClick={onChangeFilters}>
                            <Checkbox
                                alternateCheck={
                                    filterColours[filters.indexOf(headerCell.id)] ===
                                    barChartColours[1]
                                }
                                checked={filters.indexOf(headerCell.id) !== -1}
                                className="filter"
                                id={headerCell.id}
                                onClick={null}
                                useSmallIcon={false}
                            />
                            <div className="filter__text" id={headerCell.id}>
                                {headerCell.label}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="analyse__right__column">
                    {!barChartDisabled && (
                        <div className="barchart__legend">
                            {filters.map((filter, filterIdx) => (
                                <div className="legend__item" key={filterIdx}>
                                    <div
                                        className="colour"
                                        style={{ background: filterColours[filterIdx] }}
                                    />
                                    <div className="text">
                                        {
                                            metricHeaderCells.find(
                                                (headerCell) => headerCell.id === filter
                                            ).label
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {/* bar chart component */}
                    <BarChartWrapper
                        barchartContainerHeight={barchartContainerHeight}
                        barchartContainerWidth={barchartContainerWidth}
                        barChartData={barChartData}
                        barChartDisabled={barChartDisabled}
                        expandBarChart={showingExpandedBarChart}
                        metrics={filters}
                        metricColours={filterColours}
                        metricNames={metricNames}
                        rowIndices={rows}
                        xAxis={{ 0: 'Index', 1: 'Task', 2: 'Tester', 3: 'AB Group' }[tableView]}
                    />
                </div>
            </div>
        </div>
    )
}

/**
 *
 * @param {*} props
 */
const AnalyseCard = (props) => {
    const {
        barChartData,
        filters,
        filterColours,
        isNarrowScreen,
        selectedRows,
        setBarChartData,
        setFilters,
        setFilterColours,
        setShowingExpandedBarChart,
        showingExpandedBarChart,
        tableView,
        tableViewData
    } = props

    const headerCells = headerCellsDict[tableView]
    const metricHeaderCells = headerCells.filter((headerCell) => headerCell.isMetric)
    const metricNames = {}
    metricHeaderCells.forEach((headerCell) => (metricNames[headerCell.id] = headerCell.label))

    let sortedSelectedRows = []
    if (tableView === 0 || tableView === 1) {
        for (let i = 0; i < selectedRows.length; i++) {
            if (!tableViewData[selectedRows[i]].pauseRecording) {
                sortedSelectedRows.push(selectedRows[i])
            }
        }
    } else {
        sortedSelectedRows = selectedRows
    }

    let sortedRowIndices = tableViewData
        .map((elt) => elt.index)
        .filter((elt) => sortedSelectedRows.indexOf(elt) !== -1)
    let barChartDataModified = barChartData
    if (tableView !== 1 && tableView !== 3) {
        sortedRowIndices = sortedRowIndices.map((elt) => elt + 1)
        barChartDataModified = barChartData.map((elt) => {
            return { index: elt.index + 1, metric: elt.metric, value: elt.value }
        })
    }

    const barchartContainerHeight = isNarrowScreen ? 235 : 280
    const barchartContainerHeightExpanded = isNarrowScreen ? 500 : 700
    const barchartContainerWidth = isNarrowScreen ? 360 : 420
    const barchartContainerWidthExpanded = isNarrowScreen ? 750 : 1050

    /**
     * On adding a new filter, make sure BarChart plotting data is correct (after checking conditions).
     * @param {*} addedFilter filter that was selected
     */
    const onAddFilter = (addedFilter) => {
        if (filters && filters.length < 2 && filters.indexOf(addedFilter) === -1) {
            const newFilters = [...filters, addedFilter]
            if (newFilters.length === 1) {
                setFilterColours([barChartColours[0]])
            } else {
                const existingBarChartColourIdx = barChartColours.indexOf(filterColours[0])
                setFilterColours([
                    ...filterColours,
                    barChartColours[existingBarChartColourIdx === 0 ? 1 : 0]
                ])
            }
            setFilters(newFilters)

            if (sortedSelectedRows && sortedSelectedRows.length > 0) {
                const newBarChartData = [...barChartData]
                sortedSelectedRows.forEach((index) => {
                    const rowData = tableViewData.find((t) => t.index === index)
                    newBarChartData.push({
                        index:
                            tableView === 1
                                ? `${rowData.task + 1}${rowData.ab != null ? rowData.ab : ''}`
                                : rowData.index,
                        metric: addedFilter,
                        value: parseFloat(rowData[addedFilter])
                    })
                })
                setBarChartData(newBarChartData)
            }
        }
    }

    /**
     * On changing the filters that are selected, make sure our data structures for BarChart are correct.
     */
    const onChangeFilters = (e) => {
        let value = e.target.id
        if (e.target.nodeName === 'IMG') {
            value = e.target.parentNode.id
        }
        const checked = filters.indexOf(value) !== -1
        if (checked) {
            onRemoveFilter(value)
        } else {
            if (filters.length < 2) {
                onAddFilter(value)
            } else {
                e.target.checked = false
            }
        }
    }

    /**
     * On removing a filter, make sure BarChart plotting data is correct.
     * @param {*} removedFilter filter that was unselected
     */
    const onRemoveFilter = (removedFilter) => {
        const newFilters = [...filters]
        const newFilterColours = [...filterColours]
        const filterIdx = newFilters.indexOf(removedFilter)
        if (filterIdx !== -1) {
            newFilters.splice(filterIdx, 1)
            newFilterColours.splice(filterIdx, 1)
            if (barChartData && barChartData.length > 0) {
                setBarChartData(
                    [...barChartData].filter((x) => {
                        return x.metric !== removedFilter
                    })
                )
            }
        }
        setFilters(newFilters)
        setFilterColours(newFilterColours)
        if (newFilters.length === 0 && showingExpandedBarChart) {
            setShowingExpandedBarChart(false)
        }
    }

    return (
        <div className="card analyse__card">
            {!showingExpandedBarChart && (
                <AnalyseCardContent
                    barchartContainerHeight={barchartContainerHeight}
                    barchartContainerWidth={barchartContainerWidth}
                    barChartData={barChartDataModified}
                    barChartDisabled={filters.length === 0 || sortedRowIndices.length === 0}
                    filters={filters}
                    filterColours={filterColours}
                    metricHeaderCells={metricHeaderCells}
                    metricNames={metricNames}
                    onChangeFilters={onChangeFilters}
                    setShowingExpandedBarChart={setShowingExpandedBarChart}
                    showingExpandedBarChart={showingExpandedBarChart}
                    sortedRowIndices={sortedRowIndices}
                    tableView={tableView}
                    tableViewData={tableViewData}
                    viewIcon="expand"
                />
            )}

            <Modal
                aria-labelledby="expanded-barchart-modal"
                className="expanded__barchart__modal"
                open={showingExpandedBarChart}
                onClose={() => setShowingExpandedBarChart(false)}>
                <div className="inner__div">
                    <div className="modal__content">
                        {showingExpandedBarChart && (
                            <AnalyseCardContent
                                barchartContainerHeight={barchartContainerHeightExpanded}
                                barchartContainerWidth={barchartContainerWidthExpanded}
                                barChartData={barChartDataModified}
                                barChartDisabled={
                                    filters.length === 0 || sortedRowIndices.length === 0
                                }
                                filters={filters}
                                filterColours={filterColours}
                                metricHeaderCells={metricHeaderCells}
                                metricNames={metricNames}
                                onChangeFilters={onChangeFilters}
                                setShowingExpandedBarChart={setShowingExpandedBarChart}
                                showingExpandedBarChart={showingExpandedBarChart}
                                sortedRowIndices={sortedRowIndices}
                                tableView={tableView}
                                tableViewData={tableViewData}
                                viewIcon="contract"
                            />
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default React.memo(AnalyseCard)
