import './_admin.scss'

import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePagination, useSortBy, useTable } from 'react-table'

import Separator from '../../components/atoms/layout/Separator'
import { sendGetRequestWithFilter, wrapRequestInRefreshToken } from '../../components/utils/api'
import { NavigationContext, NavigationContextItem } from '../../navigation/navigate'

const AdminPanel = (props) => {
    const [data, setData] = useState([])
    const [search, setSearch] = useState('')
    const columns = useMemo(
        () => [
            { Header: 'User Name', accessor: 'full_name' },
            { Header: 'Email', accessor: 'email' },
            { Header: 'Company', accessor: 'company_name' },
            { Header: 'Occupation', accessor: 'occupation' },
            { Header: 'Country', accessor: 'country' },
            { Header: 'Created', accessor: 'date_joined' },
            { Header: 'Plan', accessor: 'plan' },
            { Header: 'Last Edit', accessor: 'lastPlanEdit' },
            { Header: 'ERP Number', accessor: 'erpNumber' }
        ],
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable({ columns, data }, useSortBy, usePagination)

    const navigate = useNavigate()
    const { setNavState } = React.useContext(NavigationContext)
    /**
     * Call users endpoint with page number and limit
     */
    useEffect(() => {
        const successFunc = (json) => {
            setData(json.results)
        }
        const errorFunc = (err) => err.json().then((body) => console.log('admin panel err: ', body))

        // wrapRequestInRefreshToken(props, sendGetRequestWithFilter, [`user/?page=${pageIndex}&limit=${pageSize}&search=${search}`, successFunc, errorFunc]);
        wrapRequestInRefreshToken(props, sendGetRequestWithFilter, [
            `user/?search=${search}`,
            successFunc,
            errorFunc
        ])
        setNavState(NavigationContextItem.ADMIN_PANEL)
    }, [search, pageIndex, pageSize, props])

    return (
        <div className="admin-container">
            <div>
                <Separator height={40} />

                <input
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search..."
                    style={{ width: '240px' }}
                />

                <Separator height={40} />

                <div style={{ width: '72vw', overflowX: 'scroll' }}>
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps()
                                            )}>
                                            <div
                                                style={{
                                                    textAlign: 'left',
                                                    padding: '4px 8px',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap'
                                                }}>
                                                {column.render('Header')}
                                                {/* Add a sorting indicator */}
                                                <span>
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? ' 🔽'
                                                            : ' 🔼'
                                                        : ''}
                                                </span>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row)
                                const rowId = parseInt(row.id)
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        style={{
                                            backgroundColor: rowId % 2 === 0 && '#F8FAFC',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => navigate(`/admin/${row.original.id}`)}>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    <div
                                                        style={{
                                                            textAlign: 'left',
                                                            padding: '4px 8px',
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap'
                                                        }}>
                                                        {cell.render('Cell')}
                                                    </div>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                <div style={{ height: '16px' }} />

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        paddingBottom: '24px'
                    }}>
                    <p>{'Number per page'}</p>
                    <div style={{ width: '8px' }} />
                    <select
                        name={'pageSize'}
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value))
                        }}
                        style={{ width: '80px' }}>
                        {[5, 10, 20].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                    <div style={{ width: '24px' }} />
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>{' '}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>{' '}
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>{' '}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                    </button>{' '}
                    <div style={{ width: '24px' }} />
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default AdminPanel
