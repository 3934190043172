const headerCellsDetailed = [
    {
        id: 'status',
        isMetric: false,
        sortable: true,
        disablePadding: false,
        label: 'Status',
        tooltip: ''
    },
    {
        id: 'index',
        isMetric: false,
        sortable: true,
        disablePadding: true,
        label: 'Index',
        tooltip: ''
    },

    {
        id: 'task',
        isMetric: false,
        sortable: true,
        disablePadding: true,
        label: 'Task',
        whitespaceStringCell: false,
        tooltip: ''
    },
    {
        id: 'testSpecificTesterID',
        isMetric: false,
        sortable: true,
        disablePadding: true,
        label: 'Participant',
        whitespaceStringCell: false,
        tooltip: ''
    },
    {
        id: 'testerID',
        isMetric: false,
        sortable: true,
        disablePadding: true,
        label: 'Participant ID',
        whitespaceStringCell: false,
        tooltip: ''
    },
    {
        id: 'ab',
        isMetric: false,
        sortable: true,
        disablePadding: true,
        label: 'A/B Group',
        whitespaceStringCell: false,
        tooltip: ''
    },
    {
        id: 'ratingRate',
        isMetric: false,
        sortable: true,
        disablePadding: false,
        label: 'Rating',
        whitespaceStringCell: false,
        tooltip: ''
    },
    {
        id: 'duration',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Duration (s)',
        whitespaceStringCell: true,
        tooltip: 'the time taken by the given tester on the given task'
    },
    {
        id: 'device',
        isMetric: false,
        sortable: true,
        disablePadding: false,
        label: 'Device',
        whitespaceStringCell: false,
        tooltip: ''
    },
    {
        id: 'numTaps',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Taps',
        whitespaceStringCell: true,
        tooltip: 'the number of taps the given tester needed to complete the given task'
    },
    {
        id: 'numFixations',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Fixations',
        whitespaceStringCell: true,
        tooltip:
            'the number of times the given tester fixed their attention on a single object during the given task'
    },
    {
        id: 'meanFixationDuration',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Mean Fixation Duration (s)',
        whitespaceStringCell: true,
        tooltip:
            'the mean duration of all times the given tester fixed their attention on a single object during the given task'
    },
    {
        id: 'created',
        isMetric: false,
        sortable: false,
        disablePadding: false,
        label: 'Created',
        whitespaceStringCell: false,
        tooltip: ''
    }
]

const headerCellsTaskAggregated = [
    {
        id: 'status',
        isMetric: false,
        sortable: true,
        disablePadding: false,
        label: 'Status',
        tooltip: ''
    },
    {
        id: 'index',
        isMetric: false,
        sortable: true,
        disablePadding: true,
        label: 'Task',
        tooltip: ''
    },

    {
        id: 'ab',
        isMetric: false,
        sortable: true,
        disablePadding: true,
        label: 'A/B Group',
        whitespaceStringCell: false,
        tooltip: ''
    },
    {
        id: 'completionRate',
        isMetric: false,
        sortable: true,
        disablePadding: false,
        label: 'Completion rate',
        whitespaceStringCell: false,
        tooltip: 'the number of testers that reported to have sucessfully completed the given task'
    },
    {
        id: 'ratingRate',
        isMetric: false,
        sortable: true,
        disablePadding: false,
        label: 'Rating',
        whitespaceStringCell: false,
        tooltip: 'the avg. rating given by testers to the given task'
    },
    {
        id: 'duration',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Duration (s)',
        whitespaceStringCell: true,
        tooltip: 'the avg. time taken by testers to complete the given task'
    },
    {
        id: 'numTaps',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Taps',
        whitespaceStringCell: true,
        tooltip: 'the avg. number of taps per tester to complete the given task'
    },
    {
        id: 'numFixations',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Fixations',
        whitespaceStringCell: true,
        tooltip:
            'the avg. number of times a tester fixed their attention on a single object during the given task'
    },
    {
        id: 'meanFixationDuration',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Mean Fixation Duration (s)',
        whitespaceStringCell: true,
        tooltip:
            'the avg. duration of all times for which a tester fixed their attention on a single object during the given task'
    }
]

const headerCellsTesterAggregated = [
    {
        id: 'status',
        isMetric: false,
        sortable: true,
        disablePadding: false,
        label: 'Status',
        tooltip: ''
    },
    {
        id: 'testSpecificTesterID',
        isMetric: false,
        sortable: true,
        disablePadding: true,
        label: 'Participant',
        tooltip: ''
    },
    {
        id: 'testerID',
        isMetric: false,
        sortable: true,
        disablePadding: true,
        label: 'Participant ID',
        whitespaceStringCell: false,
        tooltip: ''
    },

    {
        id: 'ab',
        isMetric: false,
        sortable: true,
        disablePadding: true,
        label: 'A/B Group',
        whitespaceStringCell: false,
        tooltip: ''
    },
    {
        id: 'completionRate',
        isMetric: false,
        sortable: true,
        disablePadding: false,
        label: 'Completion rate',
        whitespaceStringCell: false,
        tooltip:
            'the number of tasks that were reported to have been sucessfully completed by the given tester'
    },
    {
        id: 'ratingRate',
        isMetric: false,
        sortable: true,
        disablePadding: false,
        label: 'Rating',
        whitespaceStringCell: false,
        tooltip: 'the avg. rating given by the tester across all tasks with ratings'
    },
    {
        id: 'duration',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Duration (s)',
        whitespaceStringCell: true,
        tooltip: 'the total time taken by the given tester across all tasks'
    },
    {
        id: 'numTaps',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Taps',
        whitespaceStringCell: true,
        tooltip: 'the total number of taps by the given tester across all tasks'
    },
    {
        id: 'numFixations',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Fixations',
        whitespaceStringCell: true,
        tooltip:
            'the total number of times the given tester fixed their attention on a single object across all tasks'
    },
    {
        id: 'meanFixationDuration',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Mean Fixation Duration (s)',
        whitespaceStringCell: true,
        tooltip:
            'the avg. duration of all times the given tester fixed their attention on a single object across all tasks'
    }
]

const headerCellsABAggregated = [
    // { id: 'status', sortable: true, disablePadding: false, label: 'Status', tooltip: ""},
    { id: 'index', sortable: true, disablePadding: false, label: 'A/B Group', tooltip: '' },

    {
        id: 'numTesters',
        isMetric: false,
        sortable: true,
        disablePadding: false,
        label: 'Participants',
        whitespaceStringCell: false,
        tooltip: 'the total number of testers in the given task group'
    },
    {
        id: 'completionRate',
        isMetric: false,
        sortable: true,
        disablePadding: false,
        label: 'Completion rate',
        whitespaceStringCell: false,
        tooltip:
            'the fraction of tasks in the given task group that were reported successfully completed by all testers in said group'
    },
    {
        id: 'ratingRate',
        isMetric: false,
        sortable: true,
        disablePadding: false,
        label: 'Rating',
        whitespaceStringCell: false,
        tooltip:
            'the avg. rating given by testers in the given A/B group across all tasks in said group'
    },
    {
        id: 'duration',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Duration (s)',
        whitespaceStringCell: true,
        tooltip:
            'the avg. time taken by testers in the given A/B group across all tasks in said group'
    },
    {
        id: 'numTaps',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Taps',
        whitespaceStringCell: true,
        tooltip:
            'the avg. number of taps by testers in the given A/B group across all tasks in said group'
    },
    {
        id: 'numFixations',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Fixations',
        whitespaceStringCell: true,
        tooltip:
            'the avg. number of times testers in the given A/B group fixed their attention on a single object across all tasks in said group'
    },
    {
        id: 'meanFixationDuration',
        isMetric: true,
        sortable: true,
        disablePadding: false,
        label: 'Mean Fixation Duration (s)',
        whitespaceStringCell: true,
        tooltip:
            'the avg. duration of all times A/B testers fixed their attention on a single object across all tasks in said group'
    }
]

export const headerCellsDict = {
    0: headerCellsDetailed,
    1: headerCellsTaskAggregated,
    2: headerCellsTesterAggregated,
    3: headerCellsABAggregated
}
