/**
 *
 * @param {*} arr
 * @param {*} agg
 * @param {*} roundTo
 */
export function aggregator(arr, agg, roundTo) {
    if (agg === 'mean') {
        return parseFloat((arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(roundTo))
    }
}

/**
 * Function to check for simple equality of two objects.
 * @param {*} a object A
 * @param {*} b object B
 */
export function areEquivalent(a, b) {
    const aProps = Object.getOwnPropertyNames(a)
    const bProps = Object.getOwnPropertyNames(b)
    if (aProps.length !== bProps.length) {
        return false
    }
    for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i]
        if (a[propName] !== b[propName]) {
            return false
        }
    }
    return true
}

/**
 * Function to find which of two objects is smaller according to some key.
 * @param {*} a object A.
 * @param {*} b object B.
 * @param {*} orderBy Key to compare objects based on.
 */
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    } else if (b[orderBy] > a[orderBy]) {
        return 1
    } else {
        return 0
    }
}

/**
 *
 * @param {*} num
 * @param {*} roundTo
 */
export function formatNumberString(num, roundTo) {
    if (num < 10) {
        return `   ${num.toFixed(roundTo)}`
    } else if (num < 100) {
        return ` ${num.toFixed(roundTo)}`
    } else {
        return `${num.toFixed(roundTo)}`
    }
}

/**
 * Function to return a descending comparator function.
 * @param {*} order what to order.
 * @param {*} orderBy key to order by.
 */
export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

/**
 * Function to sort an array of objects according to some comparator.
 * @param {*} array array of objects to sort.
 * @param {*} comparator comparison key.
 */
export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

/**
 *
 * @param {*} xs
 * @param {*} key
 */
export function groupBy(xs, key) {
    return xs.reduce(function (rv, x, idx) {
        ;(rv[x[key]] = rv[x[key]] || []).push([idx, x])
        return rv
    }, {})
}

/**
 *
 * @param {*} arr
 * @param {*} key
 */
export function indicesOfChangeValues(arr, key) {
    if (arr.length === 0) {
        return []
    } else if (arr.length === 1) {
        return [0]
    } else {
        const indices = [0]
        for (let idx = 1; idx < arr.length; idx++) {
            if (arr[idx][key] !== arr[idx - 1][key]) {
                indices.push(idx)
            }
        }
        return indices
    }
}

/**
 *
 * @param {*} arr
 */
export function arrContainsConsecNums(arr) {
    const num = arr.length
    if (num === 1) {
        return true
    }
    let count = 1
    for (let i = 0; i < arr.length; i++) {
        let el = arr[i]
        while (arr.includes(++el)) {
            count++
            if (count === num) {
                return true
            }
        }

        count = 1
    }

    return false
}

/**
 *
 * @param {*} arr
 * @param {*} indexA
 * @param {*} indexB
 */
export function swapArrayElements(arr, indexA, indexB) {
    const temp = arr[indexA]
    arr[indexA] = arr[indexB]
    arr[indexB] = temp
}

/**
 * Function to determine whether a string is a valid URL.
 * @param {*} myURL string representing the URL.
 */
export function isValidURL(myURL) {
    // eslint-disable-next-line
    const regexp = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/

    /// ^(?:https:\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
    const str = myURL.startsWith('https') ? myURL : 'https://' + myURL
    return regexp.test(str)
}

/**
 * Function to determine whether a string is a valid URL and includes the http protocol.
 * @param {*} myURL string representing the URL.
 */
export function isValidRestrictedURL(myURL) {
    // eslint-disable-next-line
    const regexp =
        /^(?:(?:http(s)?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
    return regexp.test(myURL)
}

/**
 * Function to determine whether a string is a valid email.
 * @param {*} email string representing the email.
 */
export function isValidEmail(email) {
    // eslint-disable-next-line
    const regexp =
        // eslint-disable-next-line
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    return regexp.test(email)
}

/**
 * Function to determine whether a string contains only alphanumeric characters
 * @param {*} string
 */
export function isAlphanumericOnly(string) {
    const regexp = /^[a-z0-9]+$/i
    return regexp.test(string)
}

export const isArray = (a) => Array.isArray(a)
export const isObject = (o) => o === Object(o) && !isArray(o) && typeof o !== 'function'

/**
 *
 * @param {*} s
 */
export const toCamel = (s) => {
    return s.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace('-', '').replace('_', '')
    })
}

/**
 *
 * @param {*} o
 */
export function objectSnakeToCamelCase(o) {
    if (isObject(o)) {
        const n = {}
        // eslint-disable-next-line no-return-assign
        Object.keys(o).forEach((k) => (n[toCamel(k)] = objectSnakeToCamelCase(o[k])))
        return n
    } else if (isArray(o)) {
        return o.map((i) => objectSnakeToCamelCase(i))
    }
    return o
}

/**
 * Turn non-Ascii characters into something that is understood across compilers
 * @param {*} string
 * @returns
 */
export function convertNonAsciiChars(string) {
    const combining = /[\u0300-\u036F]/g
    if (string) {
        const normalized = string.normalize('NFKD').replace(combining, '')
        return normalized.replace('ß', 'ss')
    }
}

/**
 *
 * @param {*} string
 */
export function camelCaseToSnakeCase(string) {
    return string.replace(/([A-Z])/g, function ($1) {
        return '_' + $1.toLowerCase()
    })
}

/**
 *
 * @param {*} string
 */
export function snakeCaseToCamelCase(string) {
    return lowerCaseFirstChar(
        string
            .split('_')
            .map((x) => upperCaseFirstChar(x))
            .join('')
    )
}

/**
 *
 * @param {*} string
 */
export function lowerCaseFirstChar(string) {
    return string.charAt(0).toLowerCase() + string.slice(1)
}

/**
 *
 * @param {*} string
 */
export function upperCaseFirstChar(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Helper function to convert ISO date --> nicely formatted string date.
 * @param {} dateISO
 */
export function getDate(dateISO) {
    const month = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ]
    const date = new Date(dateISO)
    return date.getDate() + ' ' + month[date.getMonth()] + ' ' + date.getFullYear()
}

/**
 * Function to copy the Rich Text of a particular test to the user's keyboard.
 * @param {*} e event from copy attempt.
 * @param {*} testID id of the test whose key we want to copy.
 */
export function copyInvitationToClipboard() {
    const range = document.createRange()
    range.selectNode(document.getElementsByClassName('DraftEditor-editorContainer')[0])
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
    document.execCommand('copy')
    window.getSelection().removeAllRanges()
}

/**
 * Function to copy the key of a particular test to the user's keyboard.
 * @param {*} e event from copy attempt.
 * @param {*} testID id of the test whose key we want to copy.
 */
export function copyKeyToClipboard(elId) {
    const range = document.createRange()
    range.selectNode(document.getElementById(elId))
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)
    document.execCommand('copy')
    window.getSelection().removeAllRanges()
}

/**
 * Download test link QR Code from Browser
 */
export function downloadQRCode(elId, value) {
    const canvas = document.getElementById(elId)
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    const downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = `oculid_test_${value}_qr_code.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
}

/**
 * Converts a javascript object (including nested objects, Files etc.) --> FormData object for
 * posting to RESTful API.
 * @param {*} obj
 * @param {*} form
 * @param {*} namespace
 */
export function objectToFormData(obj, form = null, namespace = '') {
    let fd = form || new FormData()
    let formKey

    for (const property in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, property)) {
            if (namespace) {
                formKey = namespace + '[' + property + ']'
            } else {
                formKey = property
            }

            // if the property is an object, but not a File, use recursivity.
            if (
                obj[property] != null &&
                typeof obj[property] === 'object' &&
                !(obj[property] instanceof File)
            ) {
                objectToFormData(obj[property], fd, formKey)

                // if it's a string or a File object
            } else {
                if (!fd) {
                    fd = new FormData()
                }
                fd.append(formKey, obj[property])
            }
        }
    }

    return fd
}

/**
 Taken from: https://stackoverflow.com/a/42405731
 Used to get Google Fonts on our downloaded BarChart.
 "Only tested on a really limited set of fonts, can very well not work
 This should be taken as a proof of concept rather than a solid script."

 @Params : an url pointing to an embed Google Font stylesheet
 @Returns : a Promise, fulfiled with all the cssRules converted to dataURI as an Array
 */
export function GFontToDataURI(url) {
    return fetch(url) // first fecth the embed stylesheet page
        .then((resp) => resp.text()) // we only need the text of it
        .then((text) => {
            // now we need to parse the CSSruleSets contained
            // but chrome doesn't support styleSheets in DOMParsed docs...
            const s = document.createElement('style')
            s.innerHTML = text
            document.head.appendChild(s)
            const styleSheet = s.sheet

            // this will help us to keep track of the rules and the original urls
            const FontRule = (rule) => {
                const src =
                    rule.style.getPropertyValue('src') || rule.style.cssText.match(/url\(.*?\)/g)[0]
                if (!src) return null
                const url = src?.split('url(')[1].split(')')[0]
                return {
                    rule,
                    src,
                    url: url.replace(/"/g, '')
                }
            }
            const fontRules = []
            const fontProms = []

            // iterate through all the cssRules of the embedded doc
            // Edge doesn't make CSSRuleList enumerable...
            for (let i = 0; i < styleSheet.cssRules.length; i++) {
                const r = styleSheet.cssRules[i]
                const fR = FontRule(r)
                if (!fR) {
                    continue
                }
                fontRules.push(fR)
                fontProms.push(
                    fetch(fR.url) // fetch the actual font-file (.woff)
                        .then((resp) => resp.blob())
                        .then((blob) => {
                            return new Promise((resolve) => {
                                // we have to return it as a dataURI
                                //   because for whatever reason,
                                //   browser are afraid of blobURI in <img> too...
                                const f = new FileReader()
                                f.onload = (e) => resolve(f.result)
                                f.readAsDataURL(blob)
                            })
                        })
                        .then((dataURL) => {
                            // now that we have our dataURI version,
                            // we can replace the original URI with it,
                            // and we return the full rule's cssText
                            return fR.rule.cssText.replace(fR.url, dataURL)
                        })
                )
            }

            document.head.removeChild(s) // clean up
            return Promise.all(fontProms) // wait for all this has been done
        })
}
