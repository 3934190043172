import './testinfo.css'

import React from 'react'

import TaskListHorizontal from './TaskListHorizontal'

/**
 *
 * @param {*} props
 */
const TestInfo = (props) => {
    const { test, taskHover, setTaskHover, setPlaybackOptionsVisible } = props
    return (
        <div className="test__info__wrapper">
            <div className="test__info__content">
                <div className="test__info__name">{test.name}</div>
                <div className="test__info__description">{test.description}</div>
            </div>
            <TaskListHorizontal
                tasks={test.sortedTasks}
                taskHover={taskHover}
                setTaskHover={setTaskHover}
                setPlaybackOptionsVisible={setPlaybackOptionsVisible}
            />
        </div>
    )
}

export default React.memo(TestInfo)
