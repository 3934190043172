import './dropspace.css'

import React from 'react'
import { useDrop } from 'react-dnd'

/**
 * Wrapper in space (placed between task cards to handle task rearranging).
 */
const DropSpace = (props) => {
    const {
        className,
        children,
        id,
        idxAbove,
        idxBelow,
        onMoveTaskCardIntoSpace,
        spaceIsInABTest
    } = props

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'taskCard',
        // canDrop: (item) => canDropTaskCardIntoSpace(item.idx, idxAbove, idxBelow, spaceIsInABTest),
        drop: (item) => onMoveTaskCardIntoSpace(item.idx, idxAbove, idxBelow, spaceIsInABTest),
        collect: (monitor) => ({
            canDrop: !!monitor.canDrop(),
            isOver: !!monitor.isOver()
        })
    })

    return (
        <div
            className={`dnd__drop__wrapper filler ${canDrop && isOver ? 'hovered' : ''} ${className}`}
            id={id}
            ref={drop}>
            <div className="inner">{children}</div>
        </div>
    )
}

export default React.memo(DropSpace)
