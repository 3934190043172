import './_screenercolumn.scss'

import React from 'react'

import { surveyQuestions } from '../../../data/enums/SurveyQuestion'
import ScreenerCard from '../../create/ScreenerCard'
import * as V from '../../create/Validation'

const screenerQuestionDict = {}

for (const screenerIdx in surveyQuestions) {
    screenerQuestionDict[surveyQuestions[screenerIdx].value] = surveyQuestions[screenerIdx]
}

const ScreenerColumn = (props) => {
    const {
        formatScreeners,
        screeners,
        screenerErrors,
        setScreeners,
        setScreenerErrors,
        updateDraft
    } = props

    // work out which screener options the user can still add (i.e. these appear in the dropdown)
    let screenerMenuOptions
    if (screeners) {
        screenerMenuOptions = surveyQuestions
            .slice(0, -1)
            .filter((elt) => screeners.map((s) => s.name).indexOf(elt.value) < 0)
        screenerMenuOptions.push(surveyQuestions.slice(-1)[0])
    } else {
        screenerMenuOptions = surveyQuestions
    }

    /**
     * Event handler for adding a new dropdown option to an existing screener question
     * @param {*} idx
     */
    const onAddScreenerOption = (idx) => {
        const newScreeners = [...screeners]
        const newOptions = [...newScreeners[idx].options, '']
        newScreeners[idx].options = newOptions

        // correct error if we had the issue with no options before
        if ('options' in screenerErrors[idx]) {
            if (
                typeof screenerErrors[idx].options === 'string' &&
                screenerErrors[idx].options.startsWith('You must have at least one')
            ) {
                const newScreenerErrors = [...screenerErrors]
                delete newScreenerErrors[idx].options
                setScreenerErrors(newScreenerErrors)
            } else if (screenerErrors[idx].options.constructor === Array) {
                const newScreenerErrors = [...screenerErrors]
                newScreenerErrors[idx].options.push(null)
                setScreenerErrors(newScreenerErrors)
            }
        }

        updateDraft('screener', formatScreeners(newScreeners))
        setScreeners(newScreeners)
    }

    /**
     * Event handler for adding a screener question
     */
    const onAddScreenerQuestion = (nameWithPlus) => {
        const name = nameWithPlus.split(' ')[1]
        const newScreenerName = name === 'Country' ? 'location' : name.toLowerCase()
        const newScreener = {
            name: newScreenerName,
            question:
                newScreenerName === 'custom' ? '' : screenerQuestionDict[newScreenerName].question,
            type: screenerQuestionDict[newScreenerName].type
        }
        if (newScreener.type === 'dropdown') {
            newScreener.options = [...screenerQuestionDict[newScreenerName].defaultOptions]
        }

        const newScreeners = screeners == null ? [newScreener] : [...screeners, newScreener]
        const newScreenerErrors = [...screenerErrors]
        newScreenerErrors.push({})

        updateDraft('screener', formatScreeners(newScreeners))
        setScreenerErrors(newScreenerErrors)
        setScreeners(newScreeners)
    }

    /**
     *
     * @param {*} e
     * @param {*} screenerIdx
     * @param {*} optionIdx
     */
    const onChangeScreenerOption = (e, screenerIdx, optionIdx) => {
        const value = e.target.value
        const [isValid, error] = V.validateScreenerAttributeValue('option', value)

        const newScreeners = [...screeners]
        newScreeners[screenerIdx].options[optionIdx] = value

        // update screener errors list
        const newScreenerErrors = [...screenerErrors]
        if (isValid) {
            if ('options' in newScreenerErrors[screenerIdx]) {
                newScreenerErrors[screenerIdx].options[optionIdx] = null
                if (newScreenerErrors[screenerIdx].options.every((element) => element == null)) {
                    delete newScreenerErrors[screenerIdx].options
                }
            }
        } else {
            if (!('options' in newScreenerErrors[screenerIdx])) {
                newScreenerErrors[screenerIdx].options = []
                for (const optionIdx2 in newScreeners[screenerIdx].options) {
                    newScreenerErrors[screenerIdx].options.push(
                        optionIdx === parseInt(optionIdx2) ? error : null
                    )
                }
            } else {
                newScreenerErrors[screenerIdx].options[optionIdx] = error
            }
        }

        setScreenerErrors(newScreenerErrors)
        setScreeners(newScreeners)
    }

    /**
     * Event handler called when user changes the question text of a custom screener
     * @param {*} e
     * @param {*} screenerIdx
     */
    const onChangeScreenerText = (e, screenerIdx) => {
        const value = e.target.value
        const [isValid, error] = V.validateScreenerAttributeValue('text', value)

        const newScreeners = [...screeners]
        newScreeners[screenerIdx].question = value

        // update screener errors list
        const newScreenerErrors = [...screenerErrors]
        if (isValid) {
            if ('text' in newScreenerErrors[screenerIdx]) {
                delete newScreenerErrors[screenerIdx].text
            }
        } else {
            newScreenerErrors[screenerIdx].text = error
        }

        setScreenerErrors(newScreenerErrors)
        setScreeners(newScreeners)
    }

    /**
     * Event handler to handle user deleting screener question
     * @param {*} idx
     */
    const onDeleteScreener = (idx) => {
        const newScreeners = [...screeners]
        const newScreenerErrors = [...screenerErrors]
        newScreeners.splice(idx, 1)
        newScreenerErrors.splice(idx, 1)
        updateDraft('screener', formatScreeners(newScreeners))
        setScreenerErrors(newScreenerErrors)
        setScreeners(newScreeners)
    }

    /**
     * Event handler to handle user deleting screener option
     * @param {*} screenerIdx
     * @param {*} optionIdx
     */
    const onDeleteScreenerOption = (screenerIdx, optionIdx) => {
        const newScreeners = [...screeners]
        const newOptions = newScreeners[screenerIdx].options
        newOptions.splice(optionIdx, 1)
        newScreeners[screenerIdx].options = newOptions

        // if we now have no dropdown options, present error
        if (newScreeners[screenerIdx].options.length < 1) {
            const newScreenerErrors = [...screenerErrors]
            newScreenerErrors[screenerIdx].options =
                'You must have at least one answer for this screener'
            setScreenerErrors(newScreenerErrors)

            // if the deleted option was the only one causing an error, remove
        } else {
            if ('options' in screenerErrors[screenerIdx]) {
                const optionsErrors = [...screenerErrors[screenerIdx].options]
                optionsErrors.splice(optionIdx, 1)
                if (optionsErrors.every((element) => element == null)) {
                    const newScreenerErrors = [...screenerErrors]
                    delete newScreenerErrors[screenerIdx].options
                    setScreenerErrors(newScreenerErrors)
                }
            }
        }

        updateDraft('screener', formatScreeners(newScreeners))
        setScreeners(newScreeners)
    }

    /**
     * Function to handle dynamic sizing of text areas.
     * @param {*} element
     */
    const autoGrow = (element) => {
        // element.style.height = "5px";
        // element.style.height = (element.scrollHeight) + "px";
    }

    /**
     * Auto sizes all custom screeners' texts
     */
    // useEffect(() => {
    //     if (screenerErrors) {
    //         for (var idx in screeners) {
    //             if (screeners[idx].name === 'custom') {
    //                 autoGrow(document.getElementById(`screenerText${idx}`));
    //                 for (var optionIdx in screeners[idx].options) {
    //                     autoGrow(document.getElementById(`option${idx}${optionIdx}`));
    //                 }
    //             }
    //         }
    //     }
    // }, [screeners, screenerErrors]);

    return (
        <div className={'screener-container'}>
            <ScreenerCard
                screeners={screeners}
                screenerErrors={screenerErrors}
                updateDraft={updateDraft}
                formatScreeners={formatScreeners}
                onChangeScreenerText={onChangeScreenerText}
                autoGrow={autoGrow}
                screenerQuestionDict={screenerQuestionDict}
                onChangeScreenerOption={onChangeScreenerOption}
                onDeleteScreenerOption={onDeleteScreenerOption}
                onAddScreenerOption={onAddScreenerOption}
                onDeleteScreener={onDeleteScreener}
                className="screener-container"
            />

            <div className={'screener-options-wrapper'}>
                {screenerMenuOptions.map((option, idx) => {
                    return (
                        <button
                            className={'secondary button screener-option'}
                            style={{ fontSize: '0.82rem' }}
                            key={idx}
                            onClick={(e) => onAddScreenerQuestion(e.target.innerText)}>
                            {`+ ${option.text}`}
                        </button>
                    )
                })}
            </div>
        </div>
    )
}

export default React.memo(ScreenerColumn)
