import React from 'react'

import { iconColors } from './icons'

const HomeIcon = ({ isActive = false, size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 12.2039C2 9.91549 2 8.77128 2.5192 7.82274C3.0384 6.87421 3.98695 6.28551 5.88403 5.10813L7.88403 3.86687C9.88939 2.62229 10.8921 2 12 2C13.1079 2 14.1106 2.62229 16.116 3.86687L18.116 5.10812C20.0131 6.28551 20.9616 6.87421 21.4808 7.82274C22 8.77128 22 9.91549 22 12.2039V13.725C22 17.6258 22 19.5763 20.8284 20.7881C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.7881C2 19.5763 2 17.6258 2 13.725V12.2039Z"
                fill={iconColors(isActive).main}
            />
            <path
                d="M8 16C8 14.8954 8.89543 14 10 14H14C15.1046 14 16 14.8954 16 16V22H8V16Z"
                fill={iconColors(isActive).accent}
            />
        </svg>
    )
}

export default HomeIcon
