import React, { useEffect, useRef, useState } from 'react'
import { ProgressBar, Step } from 'react-step-progress-bar'

/** TODO: Clear formatting issues and enable eslint */
/* eslint-disable */

const Progress = (props) => {
    const {
        tester,
        videoRef,
        comments,
        pixelHover,
        setPixelHover,
        commentHover,
        setCommentHover,
        taskHover,
        setTaskHover,
        currentTimeOfVideo,
        playbackOptionsVisible
    } = props

    const [eventArray, setEventArray] = useState([])
    const [progressPercent, setProgressPercent] = useState(0)

    const progressRef = useRef(null)

    /**
     * Number value for progress as a percentage and always below 100
     */
    useEffect(() => {
        if (currentTimeOfVideo) {
            if ((currentTimeOfVideo * 100) / tester.duration < 100) {
                setProgressPercent((currentTimeOfVideo * 100) / tester.duration)
            } else {
                setProgressPercent(100)
            }
        } else {
            setProgressPercent(0)
        }
    }, [currentTimeOfVideo, tester.duration])

    /**
     * Map Progress Bar Events
     */
    useEffect(() => {
        const events = []
        const pixelArr = []

        for (let i = 0; i < 350; i++) {
            pixelArr.push(i)
        }

        // PIXELS
        pixelArr.map((pixel, idx) => {
            const timeStamp =
                ((tester.duration / pixelArr.length) * (pixel + 1) * 100) / tester.duration
            events.push({
                type: 'pixel',
                time: timeStamp < 100 ? timeStamp : 100,
                idx
            })

            return pixel
        })

        // COMMENTS
        if (comments) {
            comments.map((comment, idx) => {
                const timeStamp = (comment.time * 100) / tester.duration
                events.push({
                    type: 'comment',
                    time: timeStamp < 100 ? timeStamp : 100,
                    idx
                })

                return comment
            })
        }

        // TASKS
        tester.tasks.map((task, idx) => {
            const timeStamp = (task.time_rel_start * 100) / tester.duration
            events.push({
                type: 'task',
                time: timeStamp < 100 ? timeStamp : 100,
                idx
            })

            return task
        })

        setEventArray(events)
    }, [comments, tester.duration, tester.tasks])

    /**
     * Event handler for user clicking on task marker in video progress bar.
     * @param {*} task task object for task user selected
     */
    const onClickStepMarkers = (event) => {
        const visualBalance = 1.4 / tester.duration
        const timeStamp = event.time / (visualBalance * 70)

        videoRef.current.refs.videoElement.currentTime = timeStamp

        const fract = timeStamp / tester.duration
        document.getElementsByClassName('RSPBprogression')[0].style.width = `${fract * 100}%`
    }

    /**
     * Formatter to turn raw timestamp into mins:secs:millisecs
     * @param {*} rawDuration
     * @returns
     */
    const formatCurrentTime = (rawDuration) => {
        const duration = rawDuration * 1000

        let milliseconds = parseInt(duration % 1000)
        let seconds = parseInt((duration / 1000) % 60)
        let minutes = parseInt((duration / (1000 * 60)) % 60)

        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds
        milliseconds = milliseconds < 10 ? '0' + milliseconds : milliseconds

        return minutes + ':' + seconds + '.' + Math.round(milliseconds / 10)
    }

    return (
        <div
            className="progress__container"
            style={{
                opacity: playbackOptionsVisible ? '1' : '0',
                transitionDelay: !playbackOptionsVisible && '1s',
                transitionDuration: !playbackOptionsVisible && '500ms'
            }}>
            <div
                style={{
                    background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(248,248,248,0) 100%)',
                    height: '20px'
                }}
            />
            {tester && (
                <div
                    className="progress__bar"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'black',
                        height: '80px',
                        paddingTop: '8px'
                    }}>
                    <div>
                        <div
                            style={{
                                color: 'white',
                                marginTop: '8px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                opacity: pixelHover ? '0.5' : '1'
                            }}>
                            <div> Tasks</div>

                            {progressRef.current && (
                                <div>
                                    {' '}
                                    {formatCurrentTime(currentTimeOfVideo)} /{' '}
                                    {formatCurrentTime(tester.duration)}{' '}
                                </div>
                            )}
                        </div>

                        <ProgressBar
                            ref={progressRef}
                            filledBackground={'#ffffff'}
                            percent={progressPercent || 0}
                            stepPositions={eventArray.map((event) => event.time)}
                            width="350px"
                            height="4px">
                            {eventArray.map((event, arrIdx) => (
                                <Step key={arrIdx} transition="scale">
                                    {() => (
                                        <span
                                            onClick={() => onClickStepMarkers(event)}
                                            style={{ cursor: 'pointer' }}>
                                            {/* MAPPED PIXELS */}
                                            {event.type === 'pixel' && (
                                                <div
                                                    onMouseEnter={() => {
                                                        setPixelHover(event.idx)
                                                    }}
                                                    onMouseLeave={() => {
                                                        setPixelHover(null)
                                                    }}>
                                                    <div
                                                        style={{
                                                            height: '24px',
                                                            width: '2px',
                                                            zIndex: '1'
                                                        }}>
                                                        {/* SMALL TRIANGLE FOR CURSOR */}
                                                        {event.idx === pixelHover && (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    bottom: '24px',
                                                                    right: '-8px',
                                                                    width: '0px',
                                                                    height: '0px',
                                                                    borderLeft:
                                                                        '8px solid transparent',
                                                                    borderRight:
                                                                        '8px solid transparent',
                                                                    borderTop: '8px solid white'
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            {/* MAPPED COMMENTS */}
                                            {event.type === 'comment' && (
                                                <div
                                                    onMouseEnter={() => {
                                                        setCommentHover(event.idx)
                                                    }}
                                                    onMouseLeave={() => {
                                                        setCommentHover(null)
                                                    }}>
                                                    <div
                                                        style={{
                                                            backgroundColor: 'white',
                                                            height: '8px',
                                                            width:
                                                                commentHover === event.idx
                                                                    ? '16px'
                                                                    : '8px',
                                                            zIndex: '2'
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            {/* MAPPED TASKS */}
                                            {event.type === 'task' && (
                                                <div
                                                    onMouseEnter={() => {
                                                        setTaskHover(event.idx)
                                                    }}
                                                    onMouseLeave={() => {
                                                        setTaskHover(null)
                                                    }}>
                                                    <div
                                                        style={{
                                                            backgroundColor: 'black',
                                                            height: '8px',
                                                            width: '16px',
                                                            zIndex: '3',
                                                            outline:
                                                                taskHover === event.idx
                                                                    ? '4px solid white'
                                                                    : ''
                                                        }}
                                                    />

                                                    <span
                                                        style={{
                                                            position: 'absolute',
                                                            top: '16px',
                                                            right: '2px',
                                                            color: 'white',
                                                            fontWeight: 'bold'
                                                        }}>
                                                        {event.idx + 1}
                                                    </span>
                                                </div>
                                            )}
                                        </span>
                                    )}
                                </Step>
                            ))}
                        </ProgressBar>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Progress
