import { useEffect } from 'react'

import { sendGetRequestWithFilter, wrapRequestInRefreshToken } from '../../utils/api'

const usePrivacy = (props, userVal, name, setCurrentPrivacyPolicy) => {
    /**
     * Set a default for Privacy if a form is not found
     */
    useEffect(() => {
        const draftID = sessionStorage.getItem('draftID')
        if (!draftID && userVal && name === null) {
            const successFunc = (json) => {
                const latestPrivacyPolicy = json.results[json.results.length - 1].body

                if (!json.results || json.results.length === 0) {
                    setCurrentPrivacyPolicy({ user: userVal, body: '' })
                } else {
                    setCurrentPrivacyPolicy({ user: userVal, body: latestPrivacyPolicy })
                }
            }
            const errorFunc = (err) =>
                err.json().then((body) => console.log('default for privacy err, ', body))
            wrapRequestInRefreshToken(props, sendGetRequestWithFilter, [
                `users/privacypolicy/?user=${userVal}&order_by=-created`,
                successFunc,
                errorFunc
            ])
        }
    }, [userVal, name, props, setCurrentPrivacyPolicy])
}

export default usePrivacy
