import React from 'react'

import closeIcon from '../../../images/icons/closeIcon.svg'

const CloseButton = ({ right = '1rem', top = 0, onClick }) => {
    return (
        <button className={'close-button'} onClick={onClick} style={{ right, top }}>
            <img src={closeIcon} alt="Close icon" />
        </button>
    )
}

export default CloseButton
