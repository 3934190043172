import { useEffect, useState } from 'react'

import { sendGetRequest, wrapRequestInRefreshToken } from '../../utils/api'

const useGetCredits = (props, setPlan, setExpires, setRenews) => {
    const { location = {} } = props
    const userID = localStorage.getItem('userID')
    const endpoint = location.pathname || ''
    const privateRoute =
        endpoint !== '/login' &&
        !endpoint.includes('/signup') &&
        !endpoint.includes('/reset') &&
        endpoint !== '/update'

    const [isMounted, setIsMounted] = useState(true)

    /**
     * If privateroute, new user logging in so set isMounted to false
     * This ensures that subscription is called on each new sign in
     */
    useEffect(() => {
        if (privateRoute) return

        setPlan(null)
        setIsMounted(true)
    }, [privateRoute, setPlan])

    /**
     * Get Credits endpoint call
     */
    useEffect(() => {
        if (endpoint === '/login') return
        if (isMounted === false) return

        const successFunc = (json) => {
            if (json) {
                // const
                // setRenews(json.renewal)

                if (!json.active) {
                    setPlan('free')
                    setExpires('-')
                } else {
                    setExpires(json.expiry_date)
                    if (json.subscription_length === 3) {
                        setPlan('trial')
                    }
                    if (json.subscription_length === 12) {
                        setPlan('professional')
                    }
                }
            }
        }

        const errorFunc = (err) => {
            if (err.status === 404) {
                setPlan('free')
            }
        }

        wrapRequestInRefreshToken(props, sendGetRequest, [
            `subscription/${userID}`,
            successFunc,
            errorFunc
        ])

        setIsMounted(false)
    }, [endpoint, props, userID, setPlan, setExpires, setRenews, isMounted])

    return [privateRoute]
}

export default useGetCredits
