import './createtestcontainer.scss'

import React, { useEffect, useState } from 'react'

import Separator from '../atoms/layout/Separator'
import { isValidRestrictedURL } from '../utils/functions'
import RichTextPrivacy from './RichTextPrivacy'

const PrivacyPolicy = (props) => {
    const {
        currentPrivacyPolicy,
        setCurrentPrivacyPolicy,
        onChangeTestAttribute,
        onPrivacySave,
        name,
        activePrivacyInput,
        setActivePrivacyInput
    } = props

    const [currentLinkInput, setCurrentLinkInput] = useState('')
    const [currentTextInput, setCurrentTextInput] = useState('')
    const [assignValueClosed, setAssignValueClosed] = useState(false)

    const isExternalLinkError =
        activePrivacyInput === 'link' &&
        currentLinkInput.length > 0 &&
        !isValidRestrictedURL(currentPrivacyPolicy.body)

    const onClickExternalLink = () => {
        setCurrentPrivacyPolicy({ ...currentPrivacyPolicy, body: currentLinkInput })
        setActivePrivacyInput('link')
    }

    const onChangeExternalLink = (e) => {
        const value = e.target.value
        setCurrentPrivacyPolicy({ ...currentPrivacyPolicy, body: value })
        setCurrentLinkInput(value)
        onChangeTestAttribute('privacyPolicy', value)
    }

    /**
     * Load link if body is link
     */
    useEffect(() => {
        if (activePrivacyInput !== '') return
        if (currentPrivacyPolicy.body) {
            if (isValidRestrictedURL(currentPrivacyPolicy.body)) {
                setCurrentLinkInput(currentPrivacyPolicy.body)
                setActivePrivacyInput('link')
            }
        }
    }, [currentPrivacyPolicy.body, setActivePrivacyInput, activePrivacyInput])

    /**
     * Load text if body is text
     */
    useEffect(() => {
        if (activePrivacyInput !== '') return
        if (currentPrivacyPolicy.body) {
            if (!isValidRestrictedURL(currentPrivacyPolicy.body)) {
                setCurrentTextInput(currentPrivacyPolicy.body)
                setActivePrivacyInput('text')
            }
        }
    }, [currentPrivacyPolicy.body, setActivePrivacyInput, activePrivacyInput])

    return (
        <div
            id="testIntroduction"
            className={`step__box privacy-policy-container ${!name ? 'inactive' : ''}`}
            onMouseEnter={() => {
                setAssignValueClosed(true)
            }}>
            <div className={`item-wrapper ${activePrivacyInput !== 'link' ? 'inactive' : ''}`}>
                <h3> External link:</h3>
                <input
                    name={'privacy'}
                    className={`privacy-input ${isExternalLinkError && 'error'}`}
                    onClick={() => onClickExternalLink()}
                    onChange={(e) => onChangeExternalLink(e)}
                    placeholder={'https://www.ourprivacypolicy.gdpr'}
                    defaultValue={currentLinkInput}
                    onFocus={() => setActivePrivacyInput('link')}
                />
            </div>
            <Separator height={24} />
            <div
                className={`item-wrapper ${(currentTextInput !== 'undefined' && currentTextInput?.length < 0) || activePrivacyInput !== 'text' ? 'inactive' : ''}`}
                onClick={() => {
                    setActivePrivacyInput('text')
                }}>
                <h3 className="create-title"> Create a new privacy notice</h3>
                <p className="instruction">
                    If you dont have a privacy link, you can create your own notice here.
                </p>
                <RichTextPrivacy
                    name={name}
                    currentPrivacyPolicy={currentPrivacyPolicy || ''}
                    setCurrentPrivacyPolicy={setCurrentPrivacyPolicy}
                    currentTextInput={
                        currentTextInput && currentTextInput !== 'undefined' ? currentTextInput : ''
                    }
                    setCurrentTextInput={setCurrentTextInput}
                    onChangeTestAttribute={onChangeTestAttribute}
                    activePrivacyInput={activePrivacyInput || ''}
                    setActivePrivacyInput={setActivePrivacyInput}
                    onPrivacySave={onPrivacySave}
                    assignValueClosed={assignValueClosed}
                />
            </div>

            {!name && (
                <div style={{ marginTop: '24px' }}>
                    Your test must include a name to change your privacy details
                </div>
            )}
        </div>
    )
}

export default PrivacyPolicy
