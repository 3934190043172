import { jwtDecode } from 'jwt-decode'
import { useNavigate } from 'react-router-dom'

import { apiLogOut } from './apiLogging'

/**
 * Sends the request to load the testers from the server.
 * @param {*} url
 * @param {*} token
 * @param {*} testers
 * @param {*} resolve
 * @param {*} reject
 */
export function getTesterData(token, props, url, testers, successFunc, errorFunc) {
    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + token
        }
    })
        .then((res) => {
            if (res.status === 401) {
                apiLogOut(props)
            } else if (res.status !== 200) {
                errorFunc(res)
            } else {
                return res.json()
            }
        })
        .then((json) => {
            const retrieved = testers.concat(json.results)
            if (json.next !== null) {
                const nextJSON =
                    process.env.REACT_APP_NAME === 'PROD'
                        ? json.next.replace('http', 'https')
                        : json.next.replace('https', 'http')
                getTesterData(nextJSON, token, retrieved, successFunc, errorFunc)
            } else {
                successFunc(retrieved)
            }
        })
        .catch((err) => console.log(err))
}

/**
 * Refreshes user access token (to keep logged in under the hood)
 * @param { the props passed to the overview component } props
 * @param { promise fulfilled } resolve
 * @param { promise unfulfilled } reject
 */
export function refreshToken(props, resolve) {
    const currPath = window.location.pathname
    const publicRoute =
        currPath.includes('login') ||
        currPath.includes('signup') ||
        currPath.includes('reset') ||
        currPath.includes('update')

    if (publicRoute) return

    fetch(process.env.REACT_APP_URL_BASE + 'token/refresh/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ refresh: localStorage.getItem('refresh') })
    })
        .then((res) => {
            if (res.status === 400) {
                console.log('Failed to refresh user permission')
                apiLogOut(props)
            } else if (res.status === 401) {
                apiLogOut(props)
            } else if (res.status !== 200) {
                throw new Error('Failed to refresh user permission')
            } else {
                return res.json()
            }
        })
        .then((resJSON) => {
            const newAccessToken = resJSON.access
            const decodedToken = jwtDecode(newAccessToken)
            localStorage.setItem('token', newAccessToken)
            localStorage.setItem('tokenExp', decodedToken.exp)
            resolve(newAccessToken)
        })
        .catch((err) => {
            console.log('There was an error:' + err)
        })
}

/**
 *
 * @param {*} props
 * @param {*} requestFunction
 * @param {*} requestFunctionArgs
 */
export function wrapRequestInRefreshToken(props, requestFunction, requestFunctionArgs) {
    const currTime = new Date().getTime() / 1000
    if (currTime > localStorage.getItem('tokenExp') - 60) {
        new Promise((resolve, reject) => {
            refreshToken(props, resolve, reject)
        }).then((token) => requestFunction(token, props, ...requestFunctionArgs))
    } else {
        requestFunction(localStorage.getItem('token'), props, ...requestFunctionArgs)
    }
}

/**
 *
 * @param {*} token
 * @param {*} props
 * @param {*} endpoint
 * @param {*} id
 * @param {*} successFunc
 * @param {*} errorFunc
 */
export function sendDeleteRequest(token, props, endpoint, id, successFunc, errorFunc) {
    fetch(process.env.REACT_APP_URL + `${endpoint}/${id}/`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + token
        }
    })
        .then((res) => {
            if (res.status === 401) {
                apiLogOut(props)
            } else if (res.status !== 200 && res.status !== 204) {
                errorFunc(res)
            } else if (res.status === 204) {
                return null
            } else {
                return res.json()
            }
        })
        .then((json) => successFunc(json))
        .catch((err) => console.log(err))
}

/**
 *
 * @param {*} token
 * @param {*} props
 * @param {*} endpoint
 * @param {*} successFunc
 * @param {*} errorFunc
 */
export function sendGetRequest(token, props, endpoint, successFunc, errorFunc) {
    fetch(process.env.REACT_APP_URL + `${endpoint}/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + token
        }
    })
        .then((res) => {
            if (res.status === 401) {
                apiLogOut(props)
            } else if (res.status !== 200) {
                errorFunc(res)
            } else {
                return res.json()
            }
        })
        .then((json) => successFunc(json))
        .catch((err) => console.log(err))
}

/**
 *
 * @param {*} token
 * @param {*} props
 * @param {*} endpoint
 * @param {*} successFunc
 * @param {*} errorFunc
 */
export function sendGetRequestWithFilter(token, props, endpointWithFilter, successFunc, errorFunc) {
    fetch(process.env.REACT_APP_URL + `${endpointWithFilter}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + token
        }
    })
        .then((res) => {
            if (res.status === 401) {
                apiLogOut(props)
            } else if (res.status !== 200) {
                errorFunc(res)
            } else {
                return res.json()
            }
        })
        .then((json) => successFunc(json))
        .catch((err) => console.log(err))
}

/**
 *
 * @param {*} token
 * @param {*} props
 * @param {*} endpoint
 * @param {*} id
 * @param {*} successFunc
 * @param {*} errorFunc
 */
export function sendGetRequestByID(token, props, endpoint, id, successFunc, errorFunc) {
    fetch(process.env.REACT_APP_URL + `${endpoint}/${id}/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + token
        }
    })
        .then((res) => {
            if (res.status === 401) {
                apiLogOut(props)
            } else if (res.status === 404) {
                const navigate = useNavigate()
                navigate('/fourohfour')
            } else if (res.status !== 200) {
                errorFunc(res)
            } else {
                return res.json()
            }
        })
        .then((json) => successFunc(json))
        .catch((err) => console.log(err))
}

/**
 *
 * @param {*} token
 * @param {*} props
 * @param {*} endpoint
 * @param {*} id
 * @param {*} callbackFunc
 */
export function sendPatchRequest(token, props, endpoint, id, data, successFunc, errorFunc, isJson) {
    const headers = { Authorization: 'JWT ' + token }
    if (isJson) {
        headers['Content-Type'] = 'application/json'
    }
    fetch(process.env.REACT_APP_URL + `${endpoint}/${id}/`, {
        method: 'PATCH',
        headers,
        body: data
    })
        .then((res) => {
            if (res.status === 401) {
                apiLogOut(props)
            } else if (res.status !== 200) {
                errorFunc(res)
            } else {
                return res.json()
            }
        })
        .then((json) => successFunc(json))
        .catch((err) => console.log(err))
}

/**
 *
 * @param {*} token
 * @param {*} props
 * @param {*} endpoint
 * @param {*} data
 * @param {*} successFunc
 * @param {*} errorFunc
 */
export function sendPostRequest(token, props, endpoint, data, successFunc, errorFunc, isJson) {
    const headers = { Authorization: 'JWT ' + token }
    if (isJson) {
        headers['Content-Type'] = 'application/json'
    }
    fetch(process.env.REACT_APP_URL + `${endpoint}/`, {
        method: 'POST',
        headers,
        body: data
    })
        .then((res) => {
            if (res.status === 401) {
                apiLogOut(props)
            } else if (res.status !== 201 && res.status !== 200 && res.status !== 204) {
                errorFunc(res)
            } else {
                return res.json()
            }
        })
        .then((json) => successFunc(json))
        .catch((err) => console.log(err))
}

/**
 *
 * @param {*} token
 * @param {*} props
 * @param {*} endpoint
 * @param {*} data
 * @param {*} successFunc
 * @param {*} errorFunc
 */
export function sendPostRequestNoJSON(
    token,
    props,
    endpoint,
    data,
    successFunc,
    errorFunc,
    isJson
) {
    const headers = { Authorization: 'JWT ' + token }
    if (isJson) {
        headers['Content-Type'] = 'application/json'
    }
    fetch(process.env.REACT_APP_URL + `${endpoint}/`, {
        method: 'POST',
        headers,
        body: data
    })
        .then((res) => {
            if (res.status === 401) {
                apiLogOut(props)
            } else if (res.status !== 201 && res.status !== 200 && res.status !== 204) {
                errorFunc(res)
            } else {
                return res
            }
        })
        .then((json) => successFunc(json))
        .catch((err) => console.log(err))
}

/**
 *
 * @param {*} token
 * @param {*} props
 * @param {*} endpoint
 * @param {*} id
 * @param {*} callbackFunc
 */
export function sendAuthPatchRequest(token, props, endpoint, data, successFunc, errorFunc, isJson) {
    const headers = { Authorization: 'JWT ' + token }
    if (isJson) {
        headers['Content-Type'] = 'application/json'
    }
    fetch(process.env.REACT_APP_URL + `${endpoint}`, {
        method: 'PATCH',
        headers,
        body: data
    })
        .then((res) => {
            if (res.status === 401) {
                apiLogOut(props)
            } else if (res.status !== 200) {
                errorFunc(res)
            } else {
                return res.json()
            }
        })
        .then((json) => successFunc(json))
        .catch((err) => console.log(err))
}
