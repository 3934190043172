import { isValidEmail, isValidRestrictedURL, isValidURL } from '../../utils/functions'

const useErrorsOnSave = (
    name,
    taskErrors,
    screeners,
    testNames,
    currentPrivacyPolicy,
    activePrivacyInput,
    emailContact,
    tasks,
    errors
) => {
    // Test Error States
    let testWithBadEmail = false

    // Task Error States
    let taskWithNoName = false
    let taskWithNoDescription = false
    let taskWithBadUrl = false
    let taskWithEmptyTol = false
    let taskWithEmptyRating = false
    let taskWithNoMedia = false

    // Screener Error States
    let screenerWithNoQuestion = false
    let screenerWithEmptyOption = false

    // Privacy Error States
    let privacyWithBadEmail = false
    let privacyEmpty = false

    /**
     * Errors for test
     */
    if (emailContact) {
        // BAD EMAIL
        if (isValidEmail(emailContact)) {
            testWithBadEmail = false
        } else {
            testWithBadEmail = true
        }
    }

    /**
     * Errors for tasks
     */
    const taskNames = []
    const taskDescriptions = []
    const taskTOLs = []
    const taskRatings = []
    const taskMedia = []
    const taskUrl = []

    if (tasks) {
        tasks.forEach((task, idx) => {
            // NAME
            if (!task.name) {
                taskNames.push(idx)
            }

            // DESCRIPTION
            if (!task.description) {
                taskDescriptions.push(idx)
            }

            // TOL
            if (!task.tolInstructions || task.tolInstructions === '') {
                taskTOLs.push(idx)
            }

            // RATING
            if (!task.ratingText || task.ratingText === '') {
                taskRatings.push(idx)
            }

            if (task.isABTest) {
                const mediaA = task.media[0]
                const mediaB = task.media[1]
                const noMediaAB = mediaA.value === null || mediaB.value === null
                const badURLAB = mediaA.key === 'url' && mediaA.value !== null

                if (noMediaAB) {
                    taskMedia.push(idx)
                }

                if (badURLAB) {
                    if (!isValidURL(mediaA.value)) {
                        taskUrl.push(idx)
                    }
                } else if (mediaB.key === 'url' && mediaB.value !== null) {
                    if (!isValidURL(mediaB.value)) {
                        taskUrl.push(idx)
                    }
                }
            } else {
                const noMedia = !task.file && !task.url

                if (noMedia) {
                    taskMedia.push(idx)
                }
            }

            return task
        })

        if (taskErrors) {
            taskErrors.map((error, idx) => {
                if (error.url) {
                    taskUrl.push(idx)
                }

                return error
            })
        }
    }

    /**
     * Errors for screeners
     */
    const screenerQuestion = []
    const screenerOption = []

    if (screeners) {
        screeners.map((screener, idx) => {
            // QUESTION
            if (screener.question === '') {
                screenerQuestion.push(idx)
            }

            if (screener.options) {
                // EMPTY OPTION
                screener.options.map((option, idx) => {
                    if (option === '') {
                        screenerOption.push(idx)
                    }

                    return option
                })
            }

            return screener
        })
    }

    /**
     * Errors for privacy
     */
    if (currentPrivacyPolicy) {
        // EMAIL INVALID
        if (activePrivacyInput) {
            if (activePrivacyInput === 'link' && !isValidRestrictedURL(currentPrivacyPolicy.body)) {
                privacyWithBadEmail = true
            } else {
                privacyWithBadEmail = false
            }
        }

        // NO POLICY
        if (!currentPrivacyPolicy.body || currentPrivacyPolicy.body === '<p><br></p>') {
            privacyEmpty = true
        }
    }

    let testNameIsValid = true
    if (testNames) {
        testNameIsValid = testNames.indexOf(name) === -1
    }

    // Assign error states for Arrays
    if (taskNames.length > 0) {
        taskWithNoName = true
    } else {
        taskWithNoName = false
    }
    if (taskDescriptions.length > 0) {
        taskWithNoDescription = true
    } else {
        taskWithNoDescription = false
    }
    if (taskTOLs.length > 0) {
        taskWithEmptyTol = true
    } else {
        taskWithEmptyTol = false
    }
    if (taskRatings.length > 0) {
        taskWithEmptyRating = true
    } else {
        taskWithEmptyRating = false
    }
    if (taskMedia.length > 0) {
        taskWithNoMedia = true
    } else {
        taskWithNoMedia = false
    }
    if (taskUrl.length > 0) {
        taskWithBadUrl = true
    } else {
        taskWithBadUrl = false
    }

    if (screenerQuestion.length > 0) {
        screenerWithNoQuestion = true
    } else {
        screenerWithNoQuestion = false
    }
    if (screenerOption.length > 0) {
        screenerWithEmptyOption = true
    } else {
        screenerWithEmptyOption = false
    }

    /**
     * Conditions for Save and Continue Buttons
     */
    const errorsToResolve =
        !testNameIsValid ||
        name === null ||
        name === '' ||
        !emailContact ||
        testWithBadEmail ||
        tasks.length === 0 ||
        errors === !null ||
        taskWithNoName ||
        taskWithNoDescription ||
        taskWithEmptyTol ||
        taskWithEmptyRating ||
        taskWithNoMedia ||
        taskWithBadUrl ||
        screenerWithNoQuestion ||
        screenerWithEmptyOption ||
        privacyWithBadEmail ||
        privacyEmpty

    const backButtonDisabled = name == null || name === ''

    return [
        errorsToResolve,
        backButtonDisabled,
        testWithBadEmail,
        taskWithBadUrl,
        taskWithNoName,
        taskWithNoDescription,
        taskWithEmptyRating,
        taskWithEmptyTol,
        taskWithNoMedia,
        screenerWithNoQuestion,
        screenerWithEmptyOption,
        privacyWithBadEmail,
        privacyEmpty
    ]
}

export default useErrorsOnSave
