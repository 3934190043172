import './testertablehead.css'

import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Styled version of Material-UI tooltip (used for all the Table Header tooltips).
 */
const HTMLTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        border: '1px solid white',
        color: 'white',
        fontSize: theme.typography.pxToRem(14),
        maxWidth: 200
    }
}))(Tooltip)

/**
 *
 * @param {*} props
 */
const HeaderCell = (props) => {
    const { classes, className, cell, order, orderBy } = props
    return (
        <div className={className}>
            {cell.tooltip !== '' ? (
                <HTMLTooltip
                    placement="top"
                    title={<React.Fragment>{cell.tooltip}</React.Fragment>}>
                    <span
                        style={{
                            textDecorationLine: 'underline',
                            textDecorationStyle: 'dotted',
                            textUnderlinePosition: 'under',
                            cursor: 'help'
                        }}>
                        {cell.label}
                    </span>
                </HTMLTooltip>
            ) : (
                cell.label
            )}
            {orderBy === cell.id ? (
                <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
            ) : null}
        </div>
    )
}

/**
 * Function Component to render table header with tooltips
 * @param {*} props various properties passed by parent component (Dashboard) to control rendering
 */
const TesterTableHead = (props) => {
    const {
        classes,
        headerCells,
        numSelected,
        onSelectAllRows,
        onSortTable,
        order,
        orderBy,
        rowCount,
        tableView
    } = props
    const createSortHandler = (property) => () => onSortTable(property)

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" style={{ backgroundColor: '#ededed' }}>
                    <Checkbox
                        checked={rowCount > 0 && numSelected === rowCount}
                        id="check-all-checkbox"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        inputProps={{ 'aria-label': 'select all testers' }}
                        onClick={onSelectAllRows}
                    />
                </TableCell>

                {headerCells.map((cell) => (
                    <TableCell
                        align={cell.id === 'status' ? 'center' : 'left'}
                        className={classes.tablecell}
                        key={cell.id}
                        padding={cell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === cell.id ? order : false}
                        style={{
                            backgroundColor: '#ededed',
                            whiteSpace: 'nowrap',
                            paddingRight: '20px'
                        }}>
                        {cell.sortable ? (
                            <TableSortLabel
                                active={orderBy === cell.id}
                                direction={orderBy === cell.id ? order : 'asc'}
                                onClick={createSortHandler(cell.id)}>
                                <HeaderCell
                                    classes={classes}
                                    className={`${cell.id === 'index' ? 'index__header__cell' : ''} ${tableView === 3 ? 'wide' : ''} ${cell.id === 'meanFixationDuration' ? 'mfd__header__cell' : ''}`}
                                    cell={cell}
                                    order={order}
                                    orderBy={orderBy}
                                />
                            </TableSortLabel>
                        ) : (
                            <HeaderCell
                                classes={classes}
                                cell={cell}
                                order={order}
                                orderBy={orderBy}
                            />
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

TesterTableHead.propTypes = {
    headerCells: PropTypes.array.isRequired,
    numSelected: PropTypes.number.isRequired,
    onSelectAllRows: PropTypes.func.isRequired,
    onSortTable: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
}

/**
 * Styling for header cells.
 */
const headerStyles = {
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    tablecell: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '13px',
        letterSpacing: '0.266667px',
        lineHeight: '16px'
    }
}

export default withStyles(headerStyles)(TesterTableHead)
