import '../../components/solitaire/solitaire.css'

import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Userpilot } from 'userpilot'

import Dropdown from '../../components/molecules/DropDownMenu/Dropdown'
import SolitaireColumnWrapper from '../../components/solitaire/SolitaireColumnWrapper'
import { sendGetRequestByID, wrapRequestInRefreshToken } from '../../components/utils/api'
import { objectSnakeToCamelCase } from '../../components/utils/functions'
import LoaderSpinner from '../../components/utils/LoaderSpinner'
import emptySolitaireImage from '../../images/empty_solitaire_image.svg'
import pauseRecordingIconBlack from '../../images/pause_recording_icon_black.svg'
import { NavigationContext, NavigationContextItem, testIdFromUrl } from '../../navigation/navigate'

/**
 * Component to display the Solitaire View for a single test.
 * @param {*} props
 */
const SolitairePage = (props) => {
    // data state variables
    const [aggregateData, setAggregateData] = useState(null)
    const [testerData, setTesterData] = useState(null)
    const [heatmapData, setHeatmapData] = useState(null)
    const [test, setTest] = useState(null)

    // dropdown state variables
    const [selectedTasks, setSelectedTasks] = useState([])
    const [showingTaskDropdown, setShowingTaskDropdown] = useState(false)

    const location = useLocation()

    Userpilot.initialize('NX-45f0b349')

    const { setNavState } = React.useContext(NavigationContext)

    React.useEffect(() => {
        Userpilot.reload()
        setNavState(NavigationContextItem.TEST_SIDE_BAR)
    }, [location])

    /**
     * Close everything that would be closed when the user presses the escape key.
     */
    useEffect(() => {
        sessionStorage.removeItem('draftID')
        document.addEventListener(
            'keydown',
            (event) => {
                if (event.key === 'Escape') {
                    if (showingTaskDropdown) {
                        setShowingTaskDropdown(false)
                    }
                }
            },
            false
        )
    }, [showingTaskDropdown])

    /**
     * Load test from backend server.
     */
    useEffect(() => {
        sessionStorage.removeItem('draftID')
        let isMounted = true
        if (test == null) {
            // const testID = props.location.pathname.substring(
            //    props.location.pathname.lastIndexOf('/') + 1
            // )
            const testID = testIdFromUrl()
            const successFunc = (json) => {
                if (typeof json !== 'undefined') {
                    // sort tasks (need to blend AB tasks --> a single task)
                    const loadedTasks =
                        json.tasks.length > 0 ? objectSnakeToCamelCase(json.tasks) : []
                    const taskIndices = [...new Set(loadedTasks.map((task) => task.taskIndex))]
                    const sortedLoadedTasks = []

                    for (const i in taskIndices) {
                        const taskIndex = taskIndices[i]
                        const thisIndexTasks = loadedTasks.filter(
                            (task) => task.taskIndex === taskIndex
                        )

                        // if we have a regular task, do corrections for possible media + append to list
                        if (thisIndexTasks.length === 1) {
                            const thisIndexTask = thisIndexTasks[0]
                            thisIndexTask.isABTest = false
                            if (thisIndexTask.url != null) {
                                thisIndexTask.mediaType = 'url'
                            } else if (thisIndexTask.image != null) {
                                thisIndexTask.mediaType = 'image'
                                thisIndexTask.url = thisIndexTask.image.split('?')[0]
                            } else if (thisIndexTask.video != null) {
                                thisIndexTask.mediaType = 'video'
                                thisIndexTask.url = thisIndexTask.video.split('?')[0]
                            }
                            sortedLoadedTasks.push(thisIndexTask)

                            // if we have an AB test, we need to blend the two tasks into one
                        } else {
                            const aTask = thisIndexTasks[0]
                            const bTask = thisIndexTasks[1]
                            const abTask = {
                                description: aTask.description,
                                duration: aTask.duration,
                                isABTest: true,
                                media: [
                                    { key: null, value: null },
                                    { key: null, value: null }
                                ],
                                name: aTask.name,
                                rating: aTask.rating,
                                ratingText: aTask.ratingText,
                                taskIndex,
                                tol: aTask.tol,
                                tolInstructions: aTask.tolInstructions
                            }

                            // add media --> media list
                            if (aTask.url) {
                                abTask.media[0] = { key: 'url', value: aTask.url }
                            } else if (aTask.image) {
                                abTask.media[0] = { key: 'image', value: aTask.image.split('?')[0] }
                            } else if (aTask.video) {
                                abTask.media[0] = { key: 'video', value: aTask.video.split('?')[0] }
                            }
                            if (bTask.url) {
                                abTask.media[1] = { key: 'url', value: bTask.url }
                            } else if (bTask.image) {
                                abTask.media[1] = { key: 'image', value: bTask.image.split('?')[0] }
                            } else if (bTask.video) {
                                abTask.media[1] = { key: 'video', value: bTask.video.split('?')[0] }
                            }

                            sortedLoadedTasks.push(abTask)
                        }
                    }

                    // work out which tasks are displayable (those with only Image URLs)
                    const newTest = json
                    newTest.sortedTasks = sortedLoadedTasks
                    const newSelectedTasks = []
                    newTest.sortedTasks.forEach((task, idx) => {
                        let taskType = 'URL'
                        let taskDisplayable = false
                        if (task.isABTest) {
                            taskType = 'A/B'
                            if (task.media[0].key === 'image' && task.media[1].key === 'image')
                                taskDisplayable = true
                        } else if (task.image != null) {
                            taskType = 'Image'
                            taskDisplayable = true
                        } else if (task.video != null) {
                            taskType = 'Video'
                        }
                        if (task.pauseRecording) taskDisplayable = false
                        newTest.sortedTasks[idx].isDisplayable = taskDisplayable
                        newTest.sortedTasks[idx].taskType = taskType
                        if (taskDisplayable) newSelectedTasks.push(task.taskIndex)
                    })
                    newTest.numDisplayableTasks = newSelectedTasks.length
                    if (newSelectedTasks.length > 0) newSelectedTasks.unshift(-1)

                    if (isMounted) {
                        setTest(newTest)
                        setSelectedTasks(newSelectedTasks)
                    }
                }
            }
            const errorFunc = (err) => err.json().then((body) => console.log(body))
            wrapRequestInRefreshToken(props, sendGetRequestByID, [
                'test',
                testID,
                successFunc,
                errorFunc
            ])
        }
        return () => {
            isMounted = false
        }
    }, [props, test])

    /**
     * Load all test solitaire data from backend server.
     */
    useEffect(() => {
        sessionStorage.removeItem('draftID')
        let isMounted = true
        if (test != null) {
            // const testID = props.location.pathname.substring(
            //    props.location.pathname.lastIndexOf('/') + 1
            // )
            const testID = testIdFromUrl()
            const successFunc = (json) => {
                // aggregate data stores the elements & access to the screenshots for the aggregate view
                const aggregate = {}
                for (const idx in json.aggregate) {
                    const urlAggData = json.aggregate[idx]
                    // urlAggData.elements = JSON.parse(urlAggData.elements);
                    if (urlAggData.elements != null) {
                        aggregate[urlAggData.url] = urlAggData
                    }
                }

                const landscapeArr = []
                const portraitArr = []
                const squareArr = []

                const findMeanVal = (Arr) => {
                    let combinedVals = 0
                    const itemsWithVal = []
                    Arr.forEach((i) => {
                        if (!i.heightActual) return
                        itemsWithVal.push(i.heightActual)
                        combinedVals = combinedVals + i.heightActual
                    })
                    return combinedVals / itemsWithVal.length
                }

                // solitaire data stores the AOI metrics for individual testers for both their views + aggregate
                const solitaireData = json.testers.map((tester) => {
                    // Map aggregate data to tester data to send heightActual vals to either
                    // landscape or portrait arrays
                    json.aggregate.map((media) => {
                        const heightActual = media.screenshot.height_actual
                        if (heightActual === null) return null
                        const mediaUrl = media.url
                        const mediaFromTesterList = tester.screenshots_with_images
                        if (mediaFromTesterList[mediaUrl] === undefined) return null
                        const task = {
                            id: mediaFromTesterList[mediaUrl].id,
                            width: mediaFromTesterList[mediaUrl].width,
                            height: mediaFromTesterList[mediaUrl].height,
                            heightActual: mediaFromTesterList[mediaUrl].height_actual
                        }
                        if (task.id === null) return null
                        const imageIsLandscape = task.width > task.height
                        const imageIsPortrait = task.width < task.height
                        if (imageIsLandscape) {
                            return landscapeArr.push({
                                id: task.id,
                                heightActual: task.heightActual
                            })
                        } else if (imageIsPortrait) {
                            return portraitArr.push({
                                id: task.id,
                                heightActual: task.heightActual
                            })
                        } else {
                            return squareArr.push({
                                id: task.id,
                                heightActual: task.heightActual
                            })
                        }
                    })

                    const testerData = {
                        aggregateMetrics: JSON.parse(tester.aggregate_metrics),
                        deviceParams: tester.device_params,
                        testerID: tester.tester,
                        testSpecificTesterID: tester.test_specific_tester_id,

                        dataByURL: {}
                    }

                    const picsByURL = JSON.parse(tester.pics_by_url)
                    for (const url in picsByURL) {
                        const individualMetrics = JSON.parse(tester.individual_metrics)[url]

                        const testerElements = {}
                        if (individualMetrics != null) {
                            for (const xpath in individualMetrics.elts) {
                                testerElements[xpath] = individualMetrics.elts[xpath].elt
                            }
                        }

                        testerData.dataByURL[url] = {
                            browserParams: JSON.parse(tester.browser_params),
                            elements: testerElements,
                            metrics: individualMetrics,
                            pics: picsByURL[url],
                            screenshot: tester.screenshots_with_images[url]
                        }
                        testerData.dataByURL[url].browserParams.webviewWidth =
                            testerData.dataByURL[url].browserParams.webview_width
                    }
                    return testerData
                })

                // Assign correct mean phone value to image media and update the sortedTasks array with new vals
                const testWithMeanVal = test

                testWithMeanVal.sortedTasks.map((task, idx) => {
                    if (task.isABTest) {
                        const aMedia = task.media[0]
                        const bMedia = task.media[1]

                        if (aMedia.key === 'image') {
                            landscapeArr.map((obj) => {
                                if (obj.id === idx) {
                                    return (testWithMeanVal.sortedTasks[idx].media[0] = {
                                        ...aMedia,
                                        meanHeightActual: findMeanVal(landscapeArr)
                                    })
                                } else {
                                    return null
                                }
                            })
                            portraitArr.map((obj) => {
                                if (obj.id === idx) {
                                    return (testWithMeanVal.sortedTasks[idx].media[0] = {
                                        ...aMedia,
                                        meanHeightActual: findMeanVal(portraitArr)
                                    })
                                } else {
                                    return null
                                }
                            })
                            squareArr.map((obj) => {
                                if (obj.id === idx) {
                                    return (testWithMeanVal.sortedTasks[idx].media[0] = {
                                        ...aMedia,
                                        meanHeightActual: findMeanVal(squareArr)
                                    })
                                } else {
                                    return null
                                }
                            })
                        } else {
                            return null
                        }

                        if (bMedia.key === 'image') {
                            landscapeArr.map((obj) => {
                                if (obj.id === idx) {
                                    return (testWithMeanVal.sortedTasks[idx].media[1] = {
                                        ...bMedia,
                                        meanHeightActual: findMeanVal(landscapeArr)
                                    })
                                } else {
                                    return null
                                }
                            })
                            portraitArr.map((obj) => {
                                if (obj.id === idx) {
                                    return (testWithMeanVal.sortedTasks[idx].media[1] = {
                                        ...bMedia,
                                        meanHeightActual: findMeanVal(portraitArr)
                                    })
                                } else {
                                    return null
                                }
                            })
                            squareArr.map((obj) => {
                                if (obj.id === idx) {
                                    return (testWithMeanVal.sortedTasks[idx].media[1] = {
                                        ...bMedia,
                                        meanHeightActual: findMeanVal(squareArr)
                                    })
                                } else {
                                    return null
                                }
                            })
                        } else {
                            return null
                        }
                    } else {
                        const taskIsNotAnImage = task.mediaType !== 'image'
                        if (taskIsNotAnImage) return null
                        landscapeArr.map((obj) => {
                            if (obj.id === idx) {
                                return (testWithMeanVal.sortedTasks[idx] = {
                                    ...task,
                                    meanHeightActual: findMeanVal(landscapeArr)
                                })
                            } else {
                                return null
                            }
                        })
                        portraitArr.map((obj) => {
                            if (obj.id === idx) {
                                return (testWithMeanVal.sortedTasks[idx] = {
                                    ...task,
                                    meanHeightActual: findMeanVal(portraitArr)
                                })
                            } else {
                                return null
                            }
                        })
                        squareArr.map((obj) => {
                            if (obj.id === idx) {
                                return (testWithMeanVal.sortedTasks[idx] = {
                                    ...task,
                                    meanHeightActual: findMeanVal(squareArr)
                                })
                            } else {
                                return null
                            }
                        })
                    }

                    return null
                })

                setTest(testWithMeanVal)

                // organise all data structures needed to display aggregate view (i.e. do the aggregation)
                for (const url in aggregate) {
                    // create dictionary for storing aggregate data for this URL
                    const urlAggregateMetrics = {}
                    let urlPics = []
                    let urlAggregateCount = 0 // number of testers who saw this URL
                    const metricCounts = {} // number of testers with valid value for each metric

                    // iterate through testers, adding their metrics for both overall & per-element + their pics
                    for (const solitaireDataIdx in solitaireData) {
                        if (url in solitaireData[solitaireDataIdx].dataByURL) {
                            urlAggregateCount += 1 // increment count by one (counting number of testers that saw this URL)

                            // add tester pics for current URL --> the aggregate data (we want one long list with all pics).
                            urlPics = urlPics.concat(
                                solitaireData[solitaireDataIdx].dataByURL[url].pics
                            )

                            // add each tester's value of each metric to total (will divide by counts later)
                            const testerAggData =
                                solitaireData[solitaireDataIdx].aggregateMetrics[url]
                            for (const metricName in testerAggData.overall) {
                                const testerMetricValue = testerAggData.overall[metricName].overall
                                if (testerMetricValue >= 0) {
                                    if (metricName in metricCounts) {
                                        metricCounts[metricName] += 1
                                    } else {
                                        metricCounts[metricName] = 1
                                    }
                                    if (metricName in urlAggregateMetrics) {
                                        urlAggregateMetrics[metricName] += testerMetricValue
                                    } else {
                                        urlAggregateMetrics[metricName] = testerMetricValue
                                    }
                                }
                            }
                        }
                    }

                    for (const metricName in urlAggregateMetrics) {
                        if (metricCounts[metricName] === 0) {
                            urlAggregateMetrics[metricName] = null
                        } else {
                            urlAggregateMetrics[metricName] /= metricCounts[metricName]
                        }
                    }

                    aggregate[url].count = urlAggregateCount
                    aggregate[url].browserParams = {
                        webviewWidth: aggregate[url].screenshot_url.width
                    }
                    aggregate[url].metrics = urlAggregateMetrics
                    aggregate[url].pics = urlPics
                    aggregate[url].screenshot = aggregate[url].screenshot_url
                }

                // create mapping from testSpecificTesterID --> testerIdx (i.e. index of the tester object in our data array)
                const testSpecificTesterIDToTesterIdx = {}
                for (const testerIdx in solitaireData) {
                    testSpecificTesterIDToTesterIdx[solitaireData[testerIdx].testSpecificTesterID] =
                        testerIdx
                }

                // set all state variables
                if (isMounted) {
                    setAggregateData(aggregate)
                    setTesterData(solitaireData)
                    setHeatmapData(aggregate)
                }
            }
            const errorFunc = (err) => err.json().then((body) => console.log(body))
            wrapRequestInRefreshToken(props, sendGetRequestByID, [
                'testsolitairedata',
                testID,
                successFunc,
                errorFunc
            ])
        }
        return () => {
            isMounted = false
        }
    }, [props, test])

    /**
     * Callback function to handle deselecting a task column using lower button
     * @param {*} task
     */
    const onCloseTaskColumn = (task) => {
        const newSelectedTasks = [...selectedTasks]
        const idxIndex = newSelectedTasks.indexOf(task.taskIndex)
        newSelectedTasks.splice(idxIndex, 1)
        setSelectedTasks([...newSelectedTasks])
    }

    /**
     * Callback function to handle selecting different task filter.
     */
    const onSelectTaskOption = (e) => {
        let liElt = e.target
        let nodeIsDiv = true
        while (nodeIsDiv) {
            if (liElt.nodeName === 'DIV') {
                liElt = liElt.parentElement
            } else {
                nodeIsDiv = false
            }
        }
        const newValue = liElt.getAttribute('value')
        const newTaskDropdownValue = parseInt(newValue)

        if (newTaskDropdownValue === -1) {
            setShowingTaskDropdown(false)
            if (selectedTasks.length === test.numDisplayableTasks + 1) {
                setSelectedTasks([])
            } else {
                setSelectedTasks([
                    -1,
                    ...test.sortedTasks
                        .filter((task) => task.isDisplayable)
                        .map((task) => task.taskIndex)
                ])
            }
        } else {
            if (selectedTasks.includes(newTaskDropdownValue)) {
                const indexOfNewTaskDropdownValue = selectedTasks.indexOf(newTaskDropdownValue)
                const newSelectedTasks = [...selectedTasks]
                newSelectedTasks.splice(indexOfNewTaskDropdownValue, 1)
                setSelectedTasks(newSelectedTasks)
            } else {
                setSelectedTasks([...selectedTasks, newTaskDropdownValue])
            }
        }
    }

    // Create: array with AB listed as single task (+ only support image tasks for now).
    const sortedTasks = []
    if (test) {
        for (const taskIdx in test.sortedTasks) {
            const task = test.sortedTasks[taskIdx]
            if (!task.isABTest) {
                if (task.image != null) {
                    sortedTasks.push({ ...task })
                }
            } else {
                if (task.media[0].key === 'image' || task.media[1].key === 'image') {
                    sortedTasks.push({ ...task, ab: 'A', url: task.media[0].value })
                    sortedTasks.push({ ...task, ab: 'B', url: task.media[1].value })
                }
            }
        }
    }

    // Create: menu options for task dropdown (incl. one bonus option to clear/select all rows)
    let taskMenuOptions = []
    let selectedRowsForDropdown = null

    if (test) {
        taskMenuOptions = [
            {
                text: `${selectedTasks.length === test.numDisplayableTasks + 1 ? 'Clear' : 'Select'} all tasks`,
                value: -1
            }
        ]
        selectedRowsForDropdown =
            selectedTasks.length === test.numDisplayableTasks + 1
                ? selectedTasks
                : selectedTasks.slice(1)
        test.sortedTasks.forEach((task, idx) => {
            let taskText
            if (task.pauseRecording) {
                taskText = (
                    <div className="inline">
                        <div>{`${parseInt(task.taskIndex) + 1}`}</div>
                        <img
                            alt="pause_recording_icon"
                            className="icon"
                            src={pauseRecordingIconBlack}
                        />
                        <div>{task.name}</div>
                    </div>
                )
            } else {
                taskText = `${parseInt(task.taskIndex) + 1} ${task.name}`
            }
            taskMenuOptions.push({
                text: taskText,
                text2: task.taskType,
                value: task.taskIndex,
                disabled: !task.isDisplayable
            })
        })
    }

    return (
        <div className="solitaire__wrapper">
            <div className="solitaire__container" data-test="component-solitaire">
                <div className="info__and__controls">
                    <div className="title">{test ? test.name : ''}</div>

                    <div className="description">
                        {test && test.description != null ? test.description : ''}
                    </div>

                    {test && (
                        <Dropdown
                            className="task__dropdown"
                            disabled={!(aggregateData && testerData)}
                            menuOptions={taskMenuOptions}
                            noDropdownIcon={false}
                            onSelectMenuOption={onSelectTaskOption}
                            selectedRowCheckboxes={true}
                            selectedValues={selectedRowsForDropdown}
                            setShowingDropdownMenu={setShowingTaskDropdown}
                            showingDropdownMenu={showingTaskDropdown}
                            triggerText={'All tasks'}
                        />
                    )}
                </div>

                {/* Solitaire game board */}
                {heatmapData != null && heatmapData ? (
                    selectedTasks.length === 0 || Object.keys(heatmapData).length === 0 ? (
                        <div className="solitaire__board empty">
                            <img alt="empty_solitaire_image" src={emptySolitaireImage} />
                            {Object.keys(heatmapData).length === 0 ? (
                                <div className="text">
                                    You have no tester data. Once some testers complete your test,
                                    their data will show up here.
                                </div>
                            ) : (
                                <div className="text">
                                    Set the heatmap view from the task dropdown.
                                    <br />
                                    (BETA feature: Only image tasks are currently supported)
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="solitaire__board">
                            {test.sortedTasks.map(
                                (task, idx) =>
                                    selectedTasks.includes(task.taskIndex) && (
                                        <SolitaireColumnWrapper
                                            aggregateData={aggregateData}
                                            heatmapData={heatmapData}
                                            idx={idx}
                                            key={idx}
                                            onCloseTaskColumn={onCloseTaskColumn}
                                            selectedTasks={selectedTasks}
                                            task={task}
                                        />
                                    )
                            )}
                        </div>
                    )
                ) : (
                    <div className="solitaireLoaderSpinner">
                        <LoaderSpinner />
                    </div>
                )}
            </div>
        </div>
    )
}

export default SolitairePage
