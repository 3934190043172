import { Userpilot } from 'userpilot'

import { navigate } from '../../navigation/navigate'
import { sendPatchRequest, sendPostRequest, wrapRequestInRefreshToken } from '../utils/api'
import { objectToFormData } from '../utils/functions'
import { formatTest } from './FormatterFunctions'

/**
 * Helper function used when user clicks on either button.
 * @param {*} draft
 */
export function saveAndExit(
    props,
    active,
    companyLogo,
    currentConsentForm,
    description,
    emailContact,
    stage,
    numTesters,
    usingWelcomeMessage,
    welcomeMessage,
    usingThankyouMessage,
    thankyouMessage,
    name,
    currentPrivacyPolicy,
    formatTasks,
    taskScheme,
    tasks,
    screeners
) {
    const draftID = sessionStorage.getItem('draftID')
    const errorFunc = (err) =>
        err.json().then((body) => {
            console.log('save and exit error, ', body)
        })

    const successFunc = () => {
        sessionStorage.removeItem('draftID')
        navigate('/overview')
    }
    const testObject = formatTest(
        active,
        companyLogo,
        currentConsentForm,
        description,
        emailContact,
        stage,
        numTesters,
        usingWelcomeMessage,
        welcomeMessage,
        usingThankyouMessage,
        thankyouMessage,
        name,
        currentPrivacyPolicy,
        formatTasks,
        taskScheme,
        tasks,
        screeners
    )
    testObject.draft = true
    testObject.stage = 'draft'
    const formData = objectToFormData(testObject)

    if (draftID != null) {
        wrapRequestInRefreshToken(props, sendPatchRequest, [
            'test',
            draftID,
            formData,
            successFunc,
            errorFunc
        ])
    } else {
        wrapRequestInRefreshToken(props, sendPostRequest, [
            'test',
            formData,
            successFunc,
            errorFunc
        ])
    }
}

/**
 * Function for Continue
 * @param {*} draft
 * @param {*} props
 * @param {*} formatTest
 */
export function saveAndReview(
    props,
    active,
    companyLogo,
    currentConsentForm,
    description,
    emailContact,
    stage,
    numTesters,
    usingWelcomeMessage,
    welcomeMessage,
    usingThankyouMessage,
    thankyouMessage,
    name,
    currentPrivacyPolicy,
    formatTasks,
    taskScheme,
    tasks,
    screeners
) {
    const draftID = sessionStorage.getItem('draftID')
    const errorFunc = (err) =>
        err.json().then((body) => {
            console.log('save and exit error, ', body)
        })

    const successFunc = () => {
        sessionStorage.setItem('open_modal_for_test', name)
        sessionStorage.removeItem('draftID')
        navigate('/overview')
        Userpilot.track(`test created ${name}`)
    }
    const testObject = formatTest(
        active,
        companyLogo,
        currentConsentForm,
        description,
        emailContact,
        stage,
        numTesters,
        usingWelcomeMessage,
        welcomeMessage,
        usingThankyouMessage,
        thankyouMessage,
        name,
        currentPrivacyPolicy,
        formatTasks,
        taskScheme,
        tasks,
        screeners
    )
    testObject.draft = false
    testObject.stage = 'review'
    testObject.max_num_testers = 3
    const formData = objectToFormData(testObject)
    if (draftID != null) {
        wrapRequestInRefreshToken(props, sendPatchRequest, [
            'test',
            draftID,
            formData,
            successFunc,
            errorFunc
        ])
    } else {
        wrapRequestInRefreshToken(props, sendPostRequest, [
            'test',
            formData,
            successFunc,
            errorFunc
        ])
    }
}

/**
 * Update Draft For all data except tasks
 * @param {*} key
 * @param {*} value
 */
export function updateDraft(key, value, draft, props, setDraftJsonFunc) {
    if (draft) {
        const errorFunc = (err) =>
            err.json().then((body) => console.log('update draft error, ', body))
        const testObj = {
            draft: true,
            stage: 'draft',
            [key]: value
        }

        const formData = objectToFormData(testObj)
        const draftID = sessionStorage.getItem('draftID')
        if (draftID != null) {
            const successFunc = (json) => {
                if (json) {
                    setDraftJsonFunc(json)
                }
            }
            wrapRequestInRefreshToken(props, sendPatchRequest, [
                'test',
                draftID,
                formData,
                successFunc,
                errorFunc
            ])
        } else {
            const successFunc = (json) => {
                sessionStorage.setItem('draftID', json.id)
                if (json) {
                    setDraftJsonFunc(json)
                }
            }
            wrapRequestInRefreshToken(props, sendPostRequest, [
                'test',
                formData,
                successFunc,
                errorFunc
            ])
        }
    }
}

/**
 * Update Consent
 * @param {*} key
 * @param {*} value
 */
export function updateConsent(
    obj,
    draft,
    props,
    id,
    setDraftJsonFunc,
    currentConsentForm,
    setCurrentConsentForm
) {
    const errorFunc = () => console.log('error')

    if (id === null) {
        const successFunc = (json) => {
            console.log('json consent form', json, sessionStorage.getItem('draftID'))
            setCurrentConsentForm({ ...currentConsentForm, id: json.id })
            updateDraft('consent_form', json.id, draft, props, setDraftJsonFunc)
        }
        wrapRequestInRefreshToken(props, sendPostRequest, [
            'users/consentform',
            objectToFormData(obj),
            successFunc,
            errorFunc
        ])
    } else {
        const successFunc = () => {
            updateDraft('consent_form', id, draft, props, setDraftJsonFunc)
        }
        wrapRequestInRefreshToken(props, sendPatchRequest, [
            'users/consentform',
            id,
            objectToFormData(obj),
            successFunc,
            errorFunc
        ])
    }
}

/**
 * Update Privacy
 * @param {*} obj
 * @param {*} draft
 * @param {*} props
 * @param {string|*|FormData|successFunc|(function(): void)} id
 * @param {setDraftJsonFunc} setDraftJsonFunc
 * @param {{}} currentPrivacyPolicy
 * @param {(value: (((prevState: {}) => {}) | {})) => void} setCurrentPrivacyPolicy
 * @param {boolean} shouldContinue
 */
export function updatePrivacy(
    obj,
    draft,
    props,
    id,
    setDraftJsonFunc,
    currentPrivacyPolicy,
    setCurrentPrivacyPolicy,
    shouldContinue
) {
    try {
        const draftID = sessionStorage.getItem('draftID')

        const patchError = (err) =>
            err.json().then((body) => console.log('update draft error, ', body))
        const patchSuccess = (json) => {
            if (json) {
                setDraftJsonFunc(json)
            }
        }

        const errorFunc = () => console.log('error')

        if (id === null) {
            const successFunc = (json) => {
                setCurrentPrivacyPolicy({ ...currentPrivacyPolicy, id: json.id })
                if (draft) {
                    wrapRequestInRefreshToken(props, sendPatchRequest, [
                        'test',
                        draftID,
                        objectToFormData({
                            privacy_policy: json.id,
                            draft: !shouldContinue,
                            stage: 'draft'
                        }),
                        patchSuccess,
                        patchError
                    ])
                }
            }
            wrapRequestInRefreshToken(props, sendPostRequest, [
                'users/privacypolicy',
                objectToFormData(obj),
                successFunc,
                errorFunc
            ])
        } else {
            const successFunc = () => {
                if (draft) {
                    wrapRequestInRefreshToken(props, sendPatchRequest, [
                        'test',
                        draftID,
                        objectToFormData({
                            privacy_policy: id,
                            draft: !shouldContinue,
                            stage: 'draft'
                        }),
                        patchSuccess,
                        patchError
                    ])
                }
            }
            wrapRequestInRefreshToken(props, sendPatchRequest, [
                'users/privacypolicy',
                id,
                objectToFormData(obj),
                successFunc,
                errorFunc
            ])
        }
    } catch (e) {
        console.log('issue in updating tests function', e)
    }
}
