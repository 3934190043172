import React, { useState } from 'react'

import AddTestIcon from '../../atoms/icons/AddTestIcon'
import ArrowBackIcon from '../../atoms/icons/ArrowBackIcon'
import ChartIcon from '../../atoms/icons/ChartIcon'
import HeatMapIcon from '../../atoms/icons/HeatMapIcon'
import HomeIcon from '../../atoms/icons/HomeIcon'
import PlayIcon from '../../atoms/icons/PlayIcon'

const SideBarItem = ({ title, iconTitle, isActive, onClick, isMinimal, isDisabled }) => {
    const [isHovered, setIsHovered] = useState(false)
    const setHovered = (value) => () => {
        if (isActive) {
            return
        }

        setIsHovered(value)
    }

    const isItemActive = () => {
        if (isDisabled) {
            return false
        }

        return isHovered || isActive
    }

    const renderIcon = () => {
        switch (iconTitle) {
            case 'addTestIcon':
                return <AddTestIcon isActive={isItemActive()} />
            case 'homeIcon':
                return <HomeIcon isActive={isItemActive()} />
            case 'backIcon':
                return <ArrowBackIcon />
            case 'playIcon':
                return <PlayIcon isActive={isItemActive()} />
            case 'heatMapIcon':
                return <HeatMapIcon isActive={isItemActive()} />
            case 'chartIcon':
                return <ChartIcon isActive={isItemActive()} />

            default:
                return null
        }
    }

    return (
        <button
            className={`side-bar-item ${isActive ? 'active' : ''} ${isDisabled ? 'disabled-item' : ''}`}
            onClick={isDisabled ? null : onClick}
            onMouseOver={setHovered(true)}
            onMouseOut={setHovered(false)}
            disabled={isDisabled}>
            {renderIcon()}
            {!isMinimal && title}
            {isMinimal && <span className={'floating-title'}>{title}</span>}
        </button>
    )
}

export default SideBarItem
