import { useCallback, useEffect, useState } from 'react'

import {
    sendGetRequest,
    sendGetRequestByID,
    sendPostRequest,
    wrapRequestInRefreshToken
} from '../../utils/api'
import { objectToFormData } from '../../utils/functions'
import { loadDraftSuccessFunc } from '../LoadDraft'

const useLoadTest = (
    props,
    userVal,
    setUserVal,
    usingWelcomeMessage,
    setUsingWelcomeMessage,
    usingThankyouMessage,
    setUsingThankyouMessage,
    setDraftJson,
    setScreenerErrors,
    setTaskErrors,
    draftJson,
    setCurrentConsentForm,
    setDefaultFormOnMount,
    setCurrentPrivacyPolicy,
    taskErrors,
    setErrors,
    V,
    name,
    setName
) => {
    const [haveLoadedDraft, setHaveLoadedDraft] = useState(false)

    // state variables containing user's existing params
    localStorage.removeItem('allowedTestParams') // TODO: do I want to bring this back somehow?
    const storedTestParams =
        localStorage.getItem('allowedTestParams') !== 'null'
            ? JSON.parse(localStorage.getItem('allowedTestParams'))
            : false
    const [allowedTestParams, setAllowedTestParams] = useState(storedTestParams || null)
    const [testNames, setTestNames] = useState(props.testNames ? props.testNames : null)

    // state variables that define test
    const active = true
    const draft = true

    const [description, setDescription] = useState(null)
    const [emailContact, setEmailContact] = useState(null)
    const [companyLogo, setCompanyLogo] = useState({})
    const [logoImage, setLogoImage] = useState(null)
    const [welcomeMessage, setWelcomeMessage] = useState(
        storedTestParams ? storedTestParams.welcome_message_default : null
    )
    const [thankyouMessage, setThankyouMessage] = useState(
        storedTestParams ? storedTestParams.thankyou_message_default : null
    )
    const [taskGroupShowing, setTaskGroupShowing] = useState('a')
    const [taskScheme, setTaskScheme] = useState('none')
    const [screeners, setScreeners] = useState(null)
    const [tasks, setTasks] = useState([])

    // state for new business model
    const [stage, setStage] = useState('draft')
    const [numTesters, setNumTesters] = useState(
        stage === 'review' ? 3 : storedTestParams ? storedTestParams.max_num_testers_max : null
    )
    const [creditSistem, setCreditSistem] = useState(false)

    const userValCallback = useCallback(
        (id) => {
            setUserVal(id)
        },
        [setUserVal]
    )

    /**
     * Set value of user
     */
    useEffect(() => {
        if (allowedTestParams == null) {
            const successFunc = (json) => {
                userValCallback(json.id)
            }
            const errorFunc = (err) =>
                err.json().then((body) => console.log('user val err, ', body))
            wrapRequestInRefreshToken(props, sendGetRequest, [
                'current_user',
                successFunc,
                errorFunc
            ])
        }
    }, [allowedTestParams, props, userVal, userValCallback])

    /**
     * Load allowed test params which details config options specific to user's payment plan.
     */
    useEffect(() => {
        let isMounted = true
        if (allowedTestParams == null) {
            const successFunc = (json) => {
                if (welcomeMessage == null) {
                    if (isMounted) setWelcomeMessage(json.welcome_message_default)
                } else if (
                    welcomeMessage !== json.welcome_message_default &&
                    !usingWelcomeMessage
                ) {
                    if (isMounted) setUsingWelcomeMessage(true)
                }
                if (thankyouMessage == null) {
                    if (isMounted) setThankyouMessage(json.thankyou_message_default)
                } else if (
                    thankyouMessage !== json.thankyou_message_default &&
                    !usingThankyouMessage
                ) {
                    if (isMounted) setUsingThankyouMessage(true)
                }
                if (numTesters == null) {
                    if (isMounted) setNumTesters(json.max_num_testers_max)
                }
                localStorage.setItem('allowedTestParams', JSON.stringify(json))
                if (isMounted) setAllowedTestParams(json)
            }
            const errorFunc = (err) =>
                err.json().then((body) => console.log('payment plan err, ', body))
            wrapRequestInRefreshToken(props, sendGetRequest, ['params', successFunc, errorFunc])
        }

        return () => {
            isMounted = false
        }
    }, []) // eslint-disable-line

    /**
     * Load test names (if we weren't passed them from the overview)
     */
    useEffect(() => {
        let isMounted = true
        if (testNames == null) {
            const successFunc = (json) => {
                if (isMounted) setTestNames(json.test_names.filter((tn) => !tn.draft))
            }
            const draftID = sessionStorage.getItem('draftID')
            const data = objectToFormData({ test_id: draftID })
            const errorFunc = (err) =>
                err.json().then((body) => console.log('get test names err, ', body))
            wrapRequestInRefreshToken(props, sendPostRequest, [
                'gettestnames',
                data,
                successFunc,
                errorFunc
            ])
        }

        return () => {
            isMounted = false
        }
    }, []) // eslint-disable-line

    /**
     * Load draft
     */
    useEffect(() => {
        const draftID = sessionStorage.getItem('draftID')
        let isMounted = true
        if (draftID != null) {
            const successFunc = (json) => {
                setDraftJson(json)

                // Load Draft
                loadDraftSuccessFunc(
                    json,
                    description,
                    setDescription,
                    isMounted,
                    name,
                    setName,
                    numTesters,
                    setNumTesters,
                    usingWelcomeMessage,
                    setUsingWelcomeMessage,
                    setWelcomeMessage,
                    usingThankyouMessage,
                    setUsingThankyouMessage,
                    setThankyouMessage,
                    setScreenerErrors,
                    setScreeners,
                    setTaskScheme,
                    V,
                    allowedTestParams,
                    setTaskErrors,
                    setTasks,
                    setHaveLoadedDraft,
                    logoImage,
                    setLogoImage,
                    emailContact,
                    setEmailContact,
                    draftJson,
                    setDraftJson
                )

                // Load Consent
                if (json.consent_form) {
                    setCurrentConsentForm({ ...json.consent_form, user: userVal })
                    if (json.consent_form.default) {
                        setDefaultFormOnMount(true)
                    }
                }

                // Load Privacy
                if (json.privacy_policy) {
                    setCurrentPrivacyPolicy({ ...json.privacy_policy, user: userVal })
                }
            }
            const errorFunc = (err) =>
                err.json().then((body) => console.log('load draft err, ', body))
            wrapRequestInRefreshToken(props, sendGetRequestByID, [
                'test',
                draftID,
                successFunc,
                errorFunc
            ])
        }
        return () => {
            isMounted = false
        }
    }, []) // eslint-disable-line

    /**
     * Validates data loaded by draft
     */
    useEffect(() => {
        if (allowedTestParams != null && testNames != null && haveLoadedDraft) {
            const newErrors = {}
            let isValid, error
            ;[isValid, error] = V.validateTestAttributeValue(
                'description',
                description,
                testNames,
                allowedTestParams
            )
            if (!isValid) {
                newErrors.description = error
            }
            ;[isValid, error] = V.validateTestAttributeValue(
                'name',
                name,
                testNames,
                allowedTestParams
            )
            if (!isValid) {
                newErrors.name = error
            }
            ;[isValid, error] = V.validateTestAttributeValue(
                'numTesters',
                numTesters,
                testNames,
                allowedTestParams
            )
            if (!isValid) {
                newErrors.numTesters = error
            }
            ;[isValid, error] = V.validateTestAttributeValue(
                'taskScheme',
                taskScheme,
                testNames,
                allowedTestParams
            )
            if (!isValid) {
                newErrors.taskScheme = error
            }
            ;[isValid, error] = V.validateTestAttributeValue(
                'thankyouMessage',
                thankyouMessage,
                testNames,
                allowedTestParams
            )
            if (!isValid) {
                newErrors.thankyouMessage = error
            }
            ;[isValid, error] = V.validateTestAttributeValue(
                'welcomeMessage',
                welcomeMessage,
                testNames,
                allowedTestParams
            )
            if (!isValid) {
                newErrors.welcomeMessage = error
            }

            const newTaskErrors = []
            for (const i in tasks) {
                const taskErrorDict = { ...taskErrors[i] }
                const task = tasks[i]
                ;[isValid, error] = V.validateTaskAttributeValue(
                    'duration',
                    task.duration,
                    task.tol,
                    allowedTestParams
                )
                if (!isValid) {
                    taskErrorDict.duration = error
                }
                newTaskErrors.push(taskErrorDict)
            }

            if (tasks.length < 1) {
                newErrors.taskList = 'Your test must have at least one task'
            }

            if (newErrors) {
                setErrors(newErrors)
            }
            if (newTaskErrors) {
                setTaskErrors(newTaskErrors)
            }
        }
    }, [allowedTestParams, haveLoadedDraft, testNames]) // eslint-disable-line

    return [
        draft,
        screeners,
        setScreeners,
        testNames,
        allowedTestParams,
        tasks,
        stage,
        active,
        companyLogo,
        setCompanyLogo,
        description,
        emailContact,
        setEmailContact,
        numTesters,
        setNumTesters,
        welcomeMessage,
        setWelcomeMessage,
        thankyouMessage,
        taskScheme,
        setTaskScheme,
        setStage,
        setDescription,
        logoImage,
        setLogoImage,
        setTasks,
        creditSistem,
        setCreditSistem,
        taskGroupShowing,
        setTaskGroupShowing,
        setThankyouMessage
    ]
}

export default useLoadTest
