import React from 'react'

import { BackgroundType, ContentAlign } from '../../../data/enums/BackgroundType'

const ContentContainer = ({
    children,
    className,
    backType = BackgroundType.white,
    contentAlign = ContentAlign.CENTER
}) => {
    return (
        <div
            className={`content-container ${className != null ? className : ''} ${backType}-background ${contentAlign}-align`}>
            {children}
        </div>
    )
}

export default ContentContainer
