import { NavigationContextItem } from '../../navigation/navigate'
import { privacyPolicyLink, termsAndConditionsLink } from '../enums/externalLinks'

export const menuItems = (isAdmin = false, currentPath) => [
    {
        url: '/overview',
        title: 'Overview',
        iconTitle: 'homeIcon',
        navState: NavigationContextItem.MAIN_SIDE_BAR
    },
    isAdmin
        ? {
              url: '/admin',
              title: 'Admin',
              navState: NavigationContextItem.MAIN_SIDE_BAR
          }
        : null,
    {
        url: '/create',
        title: 'Create Test',
        iconTitle: 'addTestIcon',
        navState: NavigationContextItem.MAIN_SIDE_BAR
    }
]

export const testMenuItems = (testID) => {
    return [
        {
            url: '/overview',
            title: 'Overview',
            iconTitle: 'backIcon',
            navState: NavigationContextItem.MAIN_SIDE_BAR
        },
        {
            url: `/video/${testID}`,
            title: 'Video replay',
            iconTitle: 'playIcon',
            navState: NavigationContextItem.TEST_SIDE_BAR
        },
        {
            url: `/solitaire/${testID}`,
            title: 'HeatMaps',
            iconTitle: 'heatMapIcon',
            navState: NavigationContextItem.TEST_SIDE_BAR
        },
        {
            url: `/test/${testID}`,
            title: 'Analytics',
            iconTitle: 'chartIcon',
            navState: NavigationContextItem.TEST_SIDE_BAR
        }
    ]
}

export const activeTestMenuItems = () => [
    {
        url: '/overview',
        title: 'Overview',
        iconTitle: 'backIcon'
    },
    {
        url: '/overview',
        title: 'Overview',
        iconTitle: 'backIcon'
    },
    {
        url: '/overview',
        title: 'Overview',
        iconTitle: 'backIcon'
    }
]

export const footerMenuItems = [
    {
        url: termsAndConditionsLink,
        title: 'Terms of use'
    },
    {
        url: privacyPolicyLink,
        title: 'Privacy policy'
    },
    {
        title: '© Tobii' + new Date().getFullYear()
    }
]
