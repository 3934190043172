import './auth.scss'

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import tobiiLogo from '../../images/tobii-logo-darkblue.svg'
import { navigate, routeAttribute } from '../../navigation/navigate'
import PrimaryButton from '../atoms/buttons/PrimaryButton'
import BottomLink from '../atoms/links/BottomLink'
import { apiConfirmSignUp } from '../utils/apiLogging'
import CodeInput from './CodeInput'

const Background = styled.div.attrs({ className: 'background' })``
const LoginContainer = styled.div.attrs({ className: 'container' })`
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

const LoginBox = styled.div.attrs({ className: 'row' })`
    border: 1px solid #ebecf0;
    border-radius: 16px;
    width: 428px;
    background-color: white;
    box-shadow: 0px 5px 20px -8px rgb(0 0 0 / 14%);
`

const SmallContainer = styled.div`
    transform: scale(0.9);
`
const Form = styled.form`
    margin: 0px;
    min-width: 320px;
    padding: ${(props) =>
        props.viewPort === 'DESKTOP' ? '40px' : props.viewPort === 'MOBILE' ? '16px' : '0px'};
`

const ImgCont = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
const Header = styled.div`
    font-weight: 800;
    margin: 34px 0px 24px 0px;
    font-size: 1.75rem;
`

const SignUpCodeForm = (props) => {
    const [code, setCode] = useState('')
    const [codeError, setCodeError] = useState('')
    const [inputVals, setInputVals] = useState({
        inputVals1: '',
        inputVals2: '',
        inputVals3: '',
        inputVals4: '',
        inputVals5: '',
        inputVals6: ''
    })

    const verifier = props.tokenParts.verifier
    const email = props.tokenParts.email

    const isANumber = (val) => {
        if (
            val === '0' ||
            val === '1' ||
            val === '2' ||
            val === '3' ||
            val === '4' ||
            val === '5' ||
            val === '6' ||
            val === '7' ||
            val === '8' ||
            val === '9'
        ) {
            return true
        } else {
            return false
        }
    }

    /**
     * Update "Code" Var with combination of input vals
     */
    useEffect(() => {
        const codeVal =
            inputVals.inputVals1 +
            inputVals.inputVals2 +
            inputVals.inputVals3 +
            inputVals.inputVals4 +
            inputVals.inputVals5 +
            inputVals.inputVals6
        setCode(codeVal)
    }, [inputVals])

    /**
     * Assign copied 6-digit code to input fields if copied
     */
    document.addEventListener('paste', (event) => {
        event.preventDefault()

        async function fetchClipboard() {
            const code = await navigator.clipboard.readText()
            const sanitizedCode = code.replace(/[\u200B-\u200D\uFEFF]/g, '')
            setInputVals({
                inputVals1: sanitizedCode[0],
                inputVals2: sanitizedCode[1],
                inputVals3: sanitizedCode[2],
                inputVals4: sanitizedCode[3],
                inputVals5: sanitizedCode[4],
                inputVals6: sanitizedCode[5]
            })
        }

        fetchClipboard()
    })

    return (
        <Form
            onSubmit={(e) => {
                apiConfirmSignUp(e, code, setCodeError, verifier, () => {
                    navigate('/overview')
                })
            }}
            viewPort={props.viewPort}
            className="verify-form-container">
            <ImgCont>
                <img src={tobiiLogo} alt="icon" width="140px" height="auto" />
            </ImgCont>

            <Header> Check your email! </Header>

            <div style={{ marginBottom: '24px' }}>
                We have sent a code to {email} to validate your account:
            </div>

            <CodeInput
                label={''}
                error={codeError}
                onChange={(e, id) => {
                    setCodeError('')
                    setInputVals({ ...inputVals, [`inputVals${id}`]: e.target.value })
                    if (!isANumber(e.target.value)) return
                    if (id > 5) return
                    document.getElementById(`input${id + 1}`).focus()
                }}
                inputVals={inputVals}
                inputArr={[1, 2, 3, 4, 5, 6]}
            />

            <PrimaryButton type="submit">Sign Up</PrimaryButton>

            <BottomLink message={'Already a member? '} link={'/login'} linkText={'Login'} />
        </Form>
    )
}

const SignUpCode = (props) => {
    const width = props.windowWidth

    const tokenParts = { verifier: routeAttribute(2), email: routeAttribute(3) }

    if (width < 340) {
        return (
            <SmallContainer>
                <SignUpCodeForm viewPort={'SMALL'} tokenParts={tokenParts} />
            </SmallContainer>
        )
    } else if (width < 428) {
        return <SignUpCodeForm viewPort={'MOBILE'} tokenParts={tokenParts} />
    } else {
        return (
            <>
                <Background />
                <LoginContainer id="loginContainer">
                    <LoginBox>
                        <SignUpCodeForm viewPort={'DESKTOP'} tokenParts={tokenParts} />
                    </LoginBox>
                </LoginContainer>
            </>
        )
    }
}

export default SignUpCode
