import './privacy.css'

import Button from '@material-ui/core/Button'
import M from 'materialize-css'
import React, { useEffect, useState } from 'react'

/**
 * Component to render oculid's privacy policy for both app and website.
 */
const PrivacyPage = () => {
    const [language, setLanguage] = useState('english')

    /**
     * Initialize the materialize collapsible component.
     */
    useEffect(() => {
        sessionStorage.removeItem('draftID')
        M.Collapsible.init(document.querySelectorAll('.collapsible'))
    }, [language])

    return (
        <div id="privacyContainer">
            <div
                className="language__switch"
                onClick={() =>
                    language === 'english' ? setLanguage('german') : setLanguage('english')
                }>
                <Button
                    className={`language__switch__button ${language === 'english' ? 'selected' : ''}`}
                    disableElevation={true}
                    disableFocusRipple={true}
                    disableRipple={true}
                    variant="contained">
                    {' '}
                    English{' '}
                </Button>
                <Button
                    className={`language__switch__button ${language === 'german' ? 'selected' : ''}`}
                    disableElevation={true}
                    disableFocusRipple={true}
                    disableRipple={true}
                    variant="contained">
                    {' '}
                    Deutsch{' '}
                </Button>
            </div>

            {language === 'english' ? (
                <section className="container content-section text-left">
                    <ul className="collapsible privacy-collapsible" data-collapsible="expandable">
                        <li>
                            <div className="collapsible-header web-pp-title">
                                <h2>Web Policy</h2>
                            </div>
                            <div className="collapsible-body web-pp-body">
                                <h4>
                                    Responsible
                                    <br />
                                </h4>

                                <p>
                                    Information according to § 5 TMG
                                    <br />
                                </p>

                                <p>
                                    oculid GmbH (limited liability)
                                    <br />
                                    Charlottenburg Innovation Center CHIC
                                    <br />
                                    Bismarckstraße 10 - 12
                                    <br />
                                    10625 Berlin
                                    <br />
                                </p>

                                <p>
                                    Seat: Germany, Berlin, HRB 195722B, local court Charlottenburg
                                    <br />
                                    VAT ID No. DE317615858
                                    <br />
                                    Managing directors: Dr. Stefan Ruff, Dr. Antje Venjakob, Klaas
                                    Filler
                                    <br />
                                </p>

                                <p>
                                    Contact:
                                    <br />
                                    phone: +49 152 22392823
                                    <br />
                                    email: <a href="mailto:info@oculid.com">info@oculid.com</a>
                                </p>

                                <h4>Liability for contents</h4>
                                <p>
                                    The contents of our pages were created with the greatest care.
                                    However, we cannot assume any liability for the correctness,
                                    completeness and topicality of the contents. As a service
                                    provider, we are responsible for our own content on these pages
                                    in accordance with § 7 para. 1 TMG (German Telemedia Act) and
                                    general laws. According to §§ 8 to 10 TMG we are not obliged to
                                    monitor transmitted or stored information from third parties or
                                    to investigate circumstances that indicate illegal activity.
                                    Obligations to remove or block the use of information according
                                    to general laws remain unaffected. However, liability in this
                                    respect is only possible from the time of knowledge of a
                                    concrete infringement. If we become aware of any such
                                    violations, we will remove the content in question immediately.
                                </p>

                                <h4>Liability for links</h4>
                                <p>
                                    Our offer contains links to external websites of third parties,
                                    on whose contents we have no influence. Therefore we cannot
                                    assume any liability for these external contents. The respective
                                    provider or operator of the sites is always responsible for the
                                    contents of the linked sites. The linked pages were checked for
                                    possible legal violations at the time of linking. Illegal
                                    contents were not identified at the time of linking. However, a
                                    permanent control of the contents of the linked pages is not
                                    reasonable without concrete evidence of a violation of the law.
                                    If we become aware of any infringements, we will remove such
                                    links immediately.
                                </p>

                                <h4>Copyright</h4>
                                <p>
                                    The contents and works on these pages created by the site
                                    operators are subject to German copyright law. The duplication,
                                    editing, distribution and any kind of utilization outside the
                                    limits of copyright law require the written consent of the
                                    respective author or creator. Downloads and copies of these
                                    pages are only permitted for private, non-commercial use.
                                    Insofar as the content on this site was not created by the
                                    operator, the copyrights of third parties are observed. In
                                    particular, third-party content is identified as such. Should
                                    you nevertheless become aware of a copyright infringement,
                                    please inform us accordingly. If we become aware of any
                                    infringements, we will remove such contents immediately.
                                </p>

                                <h4>Privacy policy</h4>
                                <p>
                                    We are very pleased about your interest in our company. Data
                                    protection has a particularly high value for the management of
                                    oculid GmbH. A use of the internet pages of oculid GmbH is
                                    basically possible without any indication of personal data.
                                    However, if a person concerned wishes to use special services of
                                    our company via our website, a processing of personal data might
                                    be necessary. If the processing of personal data is necessary
                                    and there is no legal basis for such processing, we generally
                                    obtain the consent of the person concerned.
                                </p>
                                <p>
                                    The processing of personal data, for example the name, address,
                                    e-mail address or telephone number of a person concerned, is
                                    always in accordance with the basic data protection regulation
                                    and in compliance with the country-specific data protection
                                    regulations applicable to oculid GmbH. By means of this privacy
                                    policy, our company would like to inform the public about the
                                    type, scope and purpose of the personal data we collect, use and
                                    process. Furthermore, this privacy policy informs affected
                                    persons about their rights.
                                </p>
                                <p>
                                    oculid GmbH, as the person responsible for processing, has
                                    implemented numerous technical and organizational measures to
                                    ensure the most complete protection possible of the personal
                                    data processed via this website. Nevertheless, Internet-based
                                    data transmissions can generally have security gaps, so that
                                    absolute protection cannot be guaranteed. For this reason, every
                                    person concerned is free to transmit personal data to us by
                                    alternative means, such as by telephone.
                                </p>

                                <h4>1. Definition of terms</h4>
                                <p>
                                    The privacy policy of oculid GmbH is based on the terms used by
                                    the European Directive and Regulation Giver when the Basic Data
                                    Protection Regulation (DS-GVO) was issued. Our privacy policy
                                    should be easy to read and understand for the public as well as
                                    for our customers and business partners. To ensure this, we
                                    would like to explain the terms used in advance.
                                </p>
                                <p>
                                    We use the following terms, among others, in this data
                                    protection declaration:
                                </p>

                                <ul>
                                    <li>
                                        <h4 className="indented1">a) Personal data </h4>
                                        <p>
                                            Personal data is any information relating to an
                                            identified or identifiable natural person (hereinafter
                                            {`"data subject"`}). An identifiable natural person is
                                            one who can be identified, directly or indirectly, in
                                            particular by reference to an identifier such as a name,
                                            an identification number, location data, an online
                                            identifier, or one or more factors specific to the
                                            physical, physiological, genetic, mental, economic,
                                            cultural or social identity of that natural person.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1"> b) Data subject</h4>
                                        <p>
                                            Data subject means any identified or identifiable
                                            natural person whose personal data are processed by the
                                            controller.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">c) Processing</h4>
                                        <p>
                                            Processing is any operation or set of operations,
                                            performed with or without the aid of automated means,
                                            concerning personal data, such as collection, recording,
                                            organization, organization, filing, storage, adaptation
                                            or alteration, retrieval, consultation, use, disclosure
                                            by transmission, dissemination or otherwise making
                                            available, alignment or combination, restriction,
                                            erasure or destruction.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">d) Restriction of processing</h4>
                                        <p>
                                            Restriction of processing is the marking of stored
                                            personal data with the aim of restricting their future
                                            processing.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">e) Profiling</h4>
                                        <p>
                                            Profiling is any automated processing of personal data
                                            consisting of the use of such personal data to evaluate
                                            certain personal aspects relating to a natural person,
                                            in particular to analyse or predict aspects relating to
                                            the job performance, economic situation, health,
                                            personal preferences, interests, reliability, conduct,
                                            whereabouts or change of location of that natural
                                            person.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">f) Pseudonymization</h4>
                                        <p>
                                            Pseudonymization is the processing of personal data in
                                            such a way that the personal data can no longer be
                                            assigned to a specific data subject without the use of
                                            additional information, provided that this additional
                                            information is kept separately and is subject to
                                            technical and organizational measures that ensure that
                                            the personal data is not assigned to an identified or
                                            identifiable natural person.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">
                                            g) Controller or data controller
                                        </h4>
                                        <p>
                                            Controller or data controller is the natural or legal
                                            person, public authority, agency or any other body which
                                            alone or jointly with others determines the purposes and
                                            means of the processing of personal data. Where the
                                            purposes and means of such processing are determined by
                                            Union law or by the law of the Member States, the
                                            controller or the specific criteria for its designation
                                            may be provided for by Union law or by the law of the
                                            Member States.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">h) Processor</h4>
                                        <p>
                                            Processor is a natural or legal person, public
                                            authority, agency or other body that processes personal
                                            data on behalf of the controller.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">i) Recipient</h4>
                                        <p>
                                            The recipient is a natural or legal person, authority,
                                            institution or other body to whom personal data is
                                            disclosed, whether or not it is a third party. However,
                                            public authorities which may receive personal data in
                                            the course of a specific investigation mandate under
                                            Union or national law shall not be considered as
                                            recipients.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">j) Third party</h4>
                                        <p>
                                            A third party is a natural or legal person, public
                                            authority, agency or body other than the data subject,
                                            the controller, the processor and the persons who, under
                                            the direct authority of the controller or the processor,
                                            are authorized to process the personal data.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">k) Consent</h4>
                                        <p>
                                            Consent is any freely given, informed and unequivocal
                                            expression of the data {`subject's`} will in a specific
                                            case, in the form of a statement or other unequivocal
                                            affirmative act by which the data subject signifies his
                                            or her consent to the processing of personal data
                                            relating to him or her.
                                        </p>
                                    </li>
                                </ul>

                                <h4>2. The name and address of the controller</h4>
                                <p>
                                    The person responsible within the meaning of the Basic Data
                                    Protection Regulation, other data protection laws applicable in
                                    the member states of the European Union and other regulations of
                                    a data protection nature is
                                </p>

                                <p>
                                    oculid GmbH (limited liability)
                                    <br />
                                    Charlottenburg Innovation Center CHIC
                                    <br />
                                    Bismarckstraße 10 - 12
                                    <br />
                                    10625 Berlin
                                    <br />
                                    <br />
                                </p>

                                <p>
                                    phone: +49152/22392823
                                    <br />
                                    email: info@oculid.com
                                    <br />
                                    website: www.oculid.com
                                </p>

                                <h4>3. Collection of general data and information</h4>
                                <p>
                                    The website of oculid GmbH collects a number of general data and
                                    information with each visit of the website by a person concerned
                                    or an automated system. These general data and information are
                                    stored in the log files of the server. The (1) used browser
                                    types and versions, (2) the operating system used by the
                                    accessing system, (3) the website from which an accessing system
                                    reaches our website (so-called referrer), (4) the sub-websites,
                                    which are accessed via an accessing system on our website, (5)
                                    the date and time of an access to the Internet site, (6) an
                                    Internet protocol address (IP address), (7) the Internet service
                                    provider of the accessing system and (8) other similar data and
                                    information which serve to prevent danger in the event of
                                    attacks on our information technology systems.
                                </p>
                                <p>
                                    When using these general data and information, oculid GmbH does
                                    not draw any conclusions about the person concerned. This
                                    information is rather required to (1) deliver the contents of
                                    our website correctly, (2) optimize the contents of our website
                                    as well as the advertising for it, (3) to ensure the permanent
                                    functionality of our information technology systems and the
                                    technology of our website, and (4) to provide law enforcement
                                    agencies in case of a cyber attack the information necessary for
                                    prosecution. These anonymously collected data and information
                                    are therefore statistically evaluated by oculid GmbH on the one
                                    hand and furthermore with the goal of increasing data protection
                                    and data security in our company, in order to ultimately ensure
                                    an optimal level of protection for the personal data processed
                                    by us. The anonymous data of the server log files are stored
                                    separately from all personal data provided by a person
                                    concerned.
                                </p>

                                <h4>4. Routine deletion and blocking of personal data</h4>
                                <p>
                                    The data controller processes and stores personal data of the
                                    data subject only for the time necessary to achieve the purpose
                                    of storage or if this is provided for by the European Directive
                                    and Regulation Giver or another legislator in laws or
                                    regulations to which the data controller is subject.
                                </p>
                                <p>
                                    If the purpose of storage ceases to apply or if a storage period
                                    prescribed by the European Directive and Regulation Giver or any
                                    other competent legislator expires, the personal data will be
                                    blocked or deleted as a matter of routine and in accordance with
                                    the law.
                                </p>

                                <h4>5. Rights of the data subject</h4>
                                <ul>
                                    <li>
                                        <h4 className="indented1">a) Right to confirmation</h4>
                                        <p>
                                            Every data subject has the right, granted by the
                                            European Directives and Regulations, to obtain
                                            confirmation from the controller as to whether personal
                                            data relating to him are being processed. If a data
                                            subject wishes to exercise this right of confirmation,
                                            he or she may at any time contact an employee of the
                                            data controller.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">b) Right of access</h4>
                                        <p>
                                            Every person affected by the processing of personal data
                                            has the right, granted by the European Directive and
                                            Regulation Giver, to obtain at any time and free of
                                            charge information from the data controller about the
                                            personal data stored about him and a copy of this
                                            information. Furthermore, the European Directive and
                                            Regulation Giver has granted the data subject access to
                                            the following information:
                                        </p>

                                        <ul className="indented2">
                                            <li className="list-group-item">
                                                the processing purposes
                                            </li>
                                            <li className="list-group-item">
                                                the categories of personal data processed
                                            </li>
                                            <li className="list-group-item">
                                                the recipients or categories of recipients to whom
                                                the personal data have been or will be disclosed, in
                                                particular to recipients in third countries or to
                                                international organizations
                                            </li>
                                            <li className="list-group-item">
                                                if possible, the planned duration for which the
                                                personal data will be stored or, if this is not
                                                possible, the criteria for determining this duration
                                            </li>
                                            <li className="list-group-item">
                                                the existence of a right of rectification or erasure
                                                of personal data concerning him or her or of a right
                                                of opposition to or limitation of the processing by
                                                the controller
                                            </li>
                                            <li className="list-group-item">
                                                the existence of a right of appeal to a supervisory
                                                authority
                                            </li>
                                            <li className="list-group-item">
                                                if the personal data are not collected from the data
                                                subject: All available information about the origin
                                                of the data
                                            </li>
                                            <li className="list-group-item"></li>
                                            the existence of automated decision-making, including
                                            profiling, in accordance with Article 22(1) and (4) of
                                            the DPA and, at least in these cases, meaningful
                                            information about the logic involved and the scope and
                                            intended impact of such processing on the data subject
                                            <br />
                                        </ul>

                                        <p>
                                            Furthermore, the data subject has the right of
                                            information as to whether personal data has been
                                            transferred to a third country or to an international
                                            organization. If this is the case, the data subject
                                            shall also have the right to obtain information on the
                                            appropriate safeguards in connection with the transfer.
                                        </p>
                                        <p>
                                            If a data subject wishes to exercise this right of
                                            access, he or she may at any time contact an employee of
                                            the controller.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">c) Right of rectification</h4>
                                        <p>
                                            Every person affected by the processing of personal data
                                            has the right, granted by the European Directives and
                                            Regulations, to request the immediate rectification of
                                            incorrect personal data concerning him. Furthermore, the
                                            data subject has the right to request the completion of
                                            incomplete personal data, also by means of a
                                            supplementary declaration, taking into account the
                                            purposes of the processing.
                                        </p>
                                        <p>
                                            If a data subject wishes to exercise this right of
                                            rectification, he or she may at any time contact an
                                            employee of the data controller.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">
                                            d) Right of cancellation (right to be forgotten)
                                        </h4>
                                        <p>
                                            Any person affected by the processing of personal data
                                            has the right, granted by the European Directives and
                                            Regulations, to obtain from the controller the immediate
                                            deletion of personal data concerning him/her, if one of
                                            the following reasons applies and if the processing is
                                            not necessary:
                                        </p>

                                        <ul className="indented2">
                                            <li className="list-group-item">
                                                The personal data have been collected or otherwise
                                                processed for purposes for which they are no longer
                                                necessary.
                                            </li>
                                            <li className="list-group-item">
                                                the data subject withdraws the consent on which the
                                                processing was based pursuant to Article 6(1)(a) of
                                                the DPA or Article 9(2)(a) of the DPA, and there is
                                                no other legal basis for the processing
                                            </li>
                                            <li className="list-group-item">
                                                The data subject lodges an objection to the
                                                processing pursuant to Art. 21(1) DPA and there are
                                                no overriding legitimate reasons for the processing,
                                                or the data subject lodges an objection to the
                                                processing pursuant to Art. 21(2) DPA.
                                            </li>
                                            <li className="list-group-item">
                                                The personal data were processed unlawfully.
                                            </li>
                                            <li className="list-group-item">
                                                The deletion of the personal data is necessary to
                                                comply with a legal obligation under Union law or
                                                the law of the Member States to which the controller
                                                is subject.
                                            </li>
                                            <li className="list-group-item">
                                                The personal data was collected in relation to
                                                information society services offered in accordance
                                                with Art. 8 Paragraph 1 DS-GVO.
                                            </li>
                                            <br />
                                        </ul>

                                        <p>
                                            If one of the above mentioned reasons applies and a data
                                            subject wishes to have personal data stored at oculid
                                            GmbH deleted, he/she can contact an employee of the data
                                            controller at any time. The employee of oculid GmbH will
                                            ensure that the request for deletion will be complied
                                            with immediately.
                                        </p>
                                        <p>
                                            If the personal data has been made public by oculid GmbH
                                            and our company as the responsible person is obliged to
                                            delete the personal data according to art. 17 para. 1
                                            DS-GVO, oculid GmbH will take reasonable measures, also
                                            of a technical nature, taking into account the available
                                            technology and the implementation costs, to inform other
                                            data controllers who process the published personal
                                            data, that the data subject has requested from these
                                            other data controllers the deletion of all links to
                                            these personal data or of copies or replications of
                                            these personal data, unless the processing is necessary.
                                            The employee of oculid GmbH will arrange the necessary
                                            in individual cases.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">
                                            {' '}
                                            e) Right to limit the processing
                                        </h4>
                                        <p>
                                            Any person affected by the processing of personal data
                                            has the right, granted by the European Directives and
                                            Regulations, to request the controller to limit the
                                            processing if one of the following conditions is met:
                                        </p>

                                        <ul className="indented2">
                                            <li className="list-group-item">
                                                The accuracy of the personal data is contested by
                                                the data subject, for a period of time that allows
                                                the controller to verify the accuracy of the
                                                personal data.
                                            </li>
                                            <li className="list-group-item">
                                                The processing is unlawful, the data subject refuses
                                                to have the personal data deleted and instead
                                                requests the restriction of the use of the personal
                                                data.
                                            </li>
                                            <li className="list-group-item">
                                                The controller no longer needs the personal data for
                                                the purposes of the processing, but the data subject
                                                needs them in order to assert, exercise or defend
                                                legal claims.
                                            </li>
                                            <li className="list-group-item">
                                                The data subject has lodged an objection to the
                                                processing in accordance with Art. 21 (1) DS-GVO and
                                                it is not yet clear whether the justified reasons of
                                                the controller outweigh those of the data subject.
                                            </li>
                                            <br />
                                        </ul>
                                        <p>
                                            If one of the above-mentioned conditions is met and a
                                            data subject wishes to request the restriction of
                                            personal data stored at oculid GmbH, he or she may
                                            contact an employee of the data controller at any time.
                                            The employee of oculid GmbH will initiate the
                                            restriction of the processing.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">
                                            f) Right to data transferability
                                        </h4>
                                        <p>
                                            Any person affected by the processing of personal data
                                            has the right, granted by the European Directives and
                                            Regulations, to receive the personal data concerning
                                            him/her, which have been provided by the data subject to
                                            a data controller, in a structured, common and
                                            machine-readable format. He/she also has the right to
                                            have this data communicated to another controller
                                            without hindrance by the controller to whom the personal
                                            data has been made available, provided that the
                                            processing is based on the consent pursuant to Art. 6
                                            paragraph 1 letter a DPA or Art. 9 paragraph 2 letter a
                                            DPA or on a contract pursuant to Art. 6 paragraph 1
                                            letter b DPA and that the processing is carried out with
                                            the aid of automated procedures, unless the processing
                                            is necessary for the performance of a task carried out
                                            in the public interest or in the exercise of official
                                            authority vested in the controller.
                                        </p>
                                        <p>
                                            In addition, when exercising their right to data
                                            transfer pursuant to Art. 20 Paragraph 1 DS-GVO, the
                                            data subject has the right to obtain that personal data
                                            be transferred directly from one controller to another
                                            controller, insofar as this is technically feasible and
                                            provided that this does not adversely affect the rights
                                            and freedoms of other persons.
                                        </p>
                                        <p>
                                            In order to assert the right to data transferability,
                                            the person concerned can contact an employee of oculid
                                            GmbH at any time.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">g) Right of objection</h4>
                                        <p>
                                            Every person affected by the processing of personal data
                                            has the right, granted by the European Directive and
                                            Regulation Giver, to object at any time, for reasons
                                            arising from his or her particular situation, to the
                                            processing of personal data concerning him or her that
                                            is carried out on the basis of Art. 6(1)(e) or (f) of
                                            the DPA. This also applies to profiling based on these
                                            provisions.
                                        </p>
                                        <p>
                                            In the event of an objection, oculid GmbH will no longer
                                            process the personal data, unless we can prove
                                            compelling reasons for processing worthy of protection
                                            that outweigh the interests, rights and freedoms of the
                                            person concerned, or the processing serves the
                                            assertion, exercise or defense of legal claims.
                                        </p>
                                        <p>
                                            If oculid GmbH processes personal data in order to carry
                                            out direct advertising, the person concerned has the
                                            right to object at any time to the processing of
                                            personal data for the purpose of such advertising. This
                                            also applies to profiling, as far as it is related to
                                            such direct advertising. If the data subject objects to
                                            oculid GmbH to the processing for the purpose of direct
                                            marketing, oculid GmbH will no longer process the
                                            personal data for these purposes.
                                        </p>
                                        <p>
                                            In addition, the data subject has the right to object,
                                            for reasons arising from his or her particular
                                            situation, to the processing of personal data concerning
                                            him or her that is carried out at oculid GmbH for
                                            scientific or historical research purposes or for
                                            statistical purposes in accordance with Art. 89 para. 1
                                            DS-GVO, unless such processing is necessary for the
                                            performance of a task in the public interest.
                                        </p>
                                        <p>
                                            In order to exercise the right of objection, the data
                                            subject may directly contact any employee of oculid GmbH
                                            or any other employee. The data subject is also free to
                                            exercise his right of objection in connection with the
                                            use of information society services, notwithstanding
                                            Directive 2002/58/EC, by means of automated procedures
                                            involving technical specifications.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">
                                            h) Automated case-by-case decisions including profiling
                                        </h4>
                                        <p>
                                            Every person concerned by the processing of personal
                                            data has the right, granted by the European Directives
                                            and Regulations, not to be subject to a decision based
                                            solely on automated processing, including profiling,
                                            which produces legal effects concerning him or her or
                                            significantly affects him or her in a similar way,
                                            provided that the decision (1) is not necessary for the
                                            conclusion or performance of a contract between the data
                                            subject and the controller, or (2) is authorised by
                                            Union or national legislation to which the controller is
                                            subject and such legislation provides for adequate
                                            safeguards of the rights and freedoms and legitimate
                                            interests of the data subject, or (3) is taken with the
                                            explicit consent of the data subject.
                                        </p>
                                        <p>
                                            If the decision is (1) necessary for the conclusion or
                                            performance of a contract between the data subject and
                                            the controller, or (2) is taken with the express consent
                                            of the data subject, oculid GmbH shall take appropriate
                                            measures to safeguard the rights and freedoms as well as
                                            the legitimate interests of the data subject, which
                                            shall include at least the right to obtain the
                                            intervention of a person from the controller, to express
                                            his point of view and to challenge the decision.
                                        </p>
                                        <p>
                                            If the data subject wishes to exercise rights relating
                                            to automated decisions, he or she may at any time
                                            contact an employee of the controller.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">
                                            i) Right to revoke a data protection consent
                                        </h4>
                                        <p>
                                            Every person affected by the processing of personal data
                                            has the right granted by the European Directive and
                                            Regulation Giver to revoke his or her consent to the
                                            processing of personal data at any time.
                                        </p>
                                        <p>
                                            If the data subject wishes to exercise his or her right
                                            to withdraw consent, he or she may contact an employee
                                            of the data controller at any time.
                                        </p>
                                    </li>
                                </ul>

                                <h4>
                                    6. privacy policy on the use and application of Google AdWords
                                </h4>

                                <p>
                                    The data controller has integrated Google AdWords on this
                                    website. Google AdWords is an Internet advertising service that
                                    allows advertisers to place ads in {`Google's`} search engine
                                    results as well as in the Google advertising network. Google
                                    AdWords allows an advertiser to specify pre-defined keywords
                                    that will cause an ad to appear in {`Google's`} search engine
                                    results only when the user uses the search engine to retrieve a
                                    keyword relevant search result. In the Google advertising
                                    network, the ads are distributed to topic-relevant Internet
                                    pages by means of an automatic algorithm and in accordance with
                                    the previously defined keywords.
                                </p>

                                <p>
                                    The operating company of the Google AdWords services is Google
                                    Inc, 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
                                </p>

                                <p>
                                    The purpose of Google AdWords is to advertise our website by
                                    displaying interest-relevant advertising on the websites of
                                    third-party companies and in the search engine results of the
                                    Google search engine and by displaying third-party advertising
                                    on our website.
                                </p>

                                <p>
                                    If a person concerned reaches our website via a Google
                                    advertisement, a so-called conversion cookie is stored on the
                                    information technology system of the person concerned by Google.
                                    What cookies are has already been explained above. A conversion
                                    cookie loses its validity after thirty days and does not serve
                                    to identify the data subject. If the cookie has not expired, the
                                    conversion cookie is used to determine whether certain
                                    sub-pages, such as the shopping cart from an online store
                                    system, have been accessed on our website. The conversion cookie
                                    enables both we and Google to track whether a person who reached
                                    our website via an AdWords ad generated sales, i.e. whether he
                                    or she completed or abandoned a purchase.
                                </p>

                                <p>
                                    The data and information collected through the use of the
                                    conversion cookie is used by Google to create visit statistics
                                    for our website. These visit statistics are in turn used by us
                                    to determine the total number of users that were referred to us
                                    via AdWords ads, i.e. to determine the success or failure of the
                                    respective AdWords ad and to optimize our AdWords ads for the
                                    future. Neither our company nor other Google AdWords advertisers
                                    receive any information from Google that could be used to
                                    identify the individual.
                                </p>

                                <p>
                                    By means of the conversion cookie, personal information, such as
                                    the websites visited by the person concerned, is stored.
                                    Whenever our website is visited, personal data, including the IP
                                    address of the Internet connection used by the person concerned,
                                    is transmitted to Google in the United States of America. This
                                    personal data is stored by Google in the United States of
                                    America. Google may pass on this personal data collected via the
                                    technical process to third parties.
                                </p>

                                <p>
                                    The person concerned can prevent the setting of cookies by our
                                    website, as described above, at any time by means of a
                                    corresponding setting in the Internet browser used and thus
                                    permanently object to the setting of cookies. Such a setting of
                                    the Internet browser used would also prevent Google from setting
                                    a conversion cookie on the information technology system of the
                                    person concerned. Furthermore, a cookie already set by Google
                                    AdWords can be deleted at any time via the Internet browser or
                                    other software programs.
                                </p>

                                <p>
                                    Furthermore, the person concerned has the possibility to object
                                    to interest-based advertising by Google. To do so, the person
                                    concerned must access www.google.de/settings/ads from any of the
                                    Internet browsers he or she uses and make the desired settings
                                    there.
                                </p>

                                <p>
                                    Further information and the applicable data protection
                                    regulations of Google can be found at
                                    https://www.google.de/intl/de/policies/privacy/.
                                </p>

                                <h4>7. Legal basis of the processing</h4>
                                <p>
                                    Art. 6 I lit. a DS-GVO serves our company as a legal basis for
                                    processing operations for which we obtain consent for a specific
                                    processing purpose. If the processing of personal data is
                                    necessary for the performance of a contract to which the data
                                    subject is a party, as is the case, for example, with processing
                                    operations necessary for the supply of goods or the provision of
                                    another service or consideration, the processing is based on
                                    Art. 6 I lit. b DS-GVO. The same applies to such processing
                                    operations which are necessary for the implementation of
                                    pre-contractual measures, for example in cases of inquiries
                                    about our products or services. If our company is subject to a
                                    legal obligation which makes it necessary to process personal
                                    data, for example to fulfil tax obligations, the processing is
                                    based on Art. 6 I lit. c DS-GVO. In rare cases, the processing
                                    of personal data may be necessary to protect the vital interests
                                    of the data subject or another natural person. This would be the
                                    case, for example, if a visitor to our company was injured and
                                    his name, age, health insurance details or other vital
                                    information had to be passed on to a doctor, hospital or other
                                    third party. In this case the processing would be based on Art.
                                    6 I lit. d DS-GVO. Finally, processing operations could be based
                                    on Art. 6 I lit. f DS-GVO. Processing operations which are not
                                    covered by any of the above legal bases are based on this legal
                                    basis if the processing is necessary to safeguard a legitimate
                                    interest of our company or of a third party, provided that the
                                    interests, fundamental rights and freedoms of the data subject
                                    do not prevail. We are permitted to carry out such processing
                                    operations in particular because they have been specifically
                                    mentioned by the European legislator. In this respect, it took
                                    the view that a legitimate interest could be assumed if the data
                                    subject is a customer of the person responsible (Recital 47
                                    Sentence 2 DS-GVO).
                                </p>

                                <h4>
                                    8. Legitimate interests in processing pursued by the controller
                                    or a third party
                                </h4>
                                <p>
                                    If the processing of personal data is based on Article 6 I lit.
                                    f DS-GVO, our legitimate interest is to carry out our business
                                    activities for the benefit of the well-being of all our
                                    employees and our shareholders.
                                </p>

                                <h4>9. The duration for which personal data are stored</h4>
                                <p>
                                    The criterion for the duration of storage of personal data is
                                    the respective legal retention period. After this period has
                                    expired, the corresponding data is routinely deleted if it is no
                                    longer required for the fulfillment or initiation of a contract.
                                </p>

                                <h4>
                                    10. Legal or contractual provisions on the provision of personal
                                    data; necessity for the conclusion of the contract; obligation
                                    of the data subject to provide the personal data; possible
                                    consequences of not providing the data
                                </h4>
                                <p>
                                    We would like to inform you that the provision of personal data
                                    is partly required by law (e.g. tax regulations) or can also
                                    result from contractual regulations (e.g. information on the
                                    contractual partner). Sometimes it may be necessary for a
                                    contract to be concluded that a data subject provides us with
                                    personal data, which must subsequently be processed by us. For
                                    example, the person concerned is obliged to provide us with
                                    personal data if our company concludes a contract with him/her.
                                    Failure to provide the personal data would mean that the
                                    contract with the person concerned could not be concluded.
                                    Before the person concerned makes personal data available, the
                                    person concerned must contact one of our employees. Our employee
                                    will inform the data subject on a case-by-case basis whether the
                                    provision of the personal data is required by law or contract or
                                    is necessary for the conclusion of the contract, whether there
                                    is an obligation to provide the personal data and what the
                                    consequences would be if the personal data were not provided.{' '}
                                </p>

                                <h4>11. Existence of automated decision making</h4>
                                <p>
                                    As a responsible company, we avoid automatic decision making or
                                    profiling.
                                </p>

                                <p>
                                    This privacy statement was created by the{' '}
                                    <a
                                        href="https://dg-datenschutz.de/datenschutzerklaerung/"
                                        rel="nofollow noopener noreferrer"
                                        target="_blank">
                                        Privacy Statement Generator{' '}
                                    </a>{' '}
                                    of the German Society for Data Protection, in cooperation with
                                    the{' '}
                                    <a
                                        href="https://www.wbs-law.de/taetigkeitsgebiete/medienrecht/"
                                        rel="nofollow noopener noreferrer"
                                        target="_blank">
                                        media law
                                    </a>{' '}
                                    attorneys WILDE BEUGER SOLMECKE | Rechtsanwälte.
                                </p>
                                <br />
                                <br />
                            </div>
                        </li>

                        <li>
                            <div className="collapsible-header app-pp-title">
                                <h2>App Policy</h2>
                            </div>
                            <div className="collapsible-body app-pp-body">
                                <h4>
                                    Responsible
                                    <br />
                                </h4>

                                <p>
                                    Information according to § 5 TMG
                                    <br />
                                </p>

                                <p>
                                    oculid GmbH (limited liability)
                                    <br />
                                    Charlottenburg Innovation Center CHIC
                                    <br />
                                    Bismarckstraße 10 - 12
                                    <br />
                                    10625 Berlin
                                    <br />
                                </p>

                                <p>
                                    Seat: Germany, Berlin, HRB 195722B, local court Charlottenburg
                                    <br />
                                    VAT ID No. DE317615858
                                    <br />
                                    Managing directors: Dr. Stefan Ruff, Dr. Antje Venjakob, Klaas
                                    Filler
                                    <br />
                                </p>

                                <p>
                                    Contact:
                                    <br />
                                    phone: +49 152 22392823
                                    <br />
                                    email: <a href="mailto:info@oculid.com">info@oculid.com</a>
                                </p>

                                <h4>Purposes of processing</h4>
                                <p>
                                    During the interaction, video data of your face is recorded by a
                                    near infrared or RGB camera as well as audio recordings from the
                                    microphone. In addition, we measure the quality of the task
                                    processing and record the screen contents as well as your touch
                                    inputs in terms of number and localisation during the
                                    interaction. Furthermore we collect demographic data (e.g. Age,
                                    Gender, Location) before the task processing. With your consent,
                                    the data will be stored securely for 5 years.
                                </p>
                                <p>
                                    We will then use your data to improve our eye movement detection
                                    algorithms, which allow us to deduce your eye and pupil position
                                    or pupil geometry from video data.
                                </p>
                                <p>
                                    Furthermore we record information about the device, such as
                                    Device ID, model and device type, screen size, name and version
                                    of the operating system, settings and language of the device in
                                    order to optimise the performance of our algorithms and for user
                                    management.
                                </p>
                                <p>
                                    All collected data will be used to analyze the usability of the
                                    application you are testing as well as to analyze attention on
                                    display content and will be made available to oculid’s clients
                                    anonymously for analysis and visualisation. Demographic data
                                    will be used to allow oculid’s clients to interpret the results
                                    with respect to the demographic groups collected.
                                </p>
                                <p>
                                    oculid GmbH will store your video, gaze and pupil data, your
                                    personal information and all data that can be derived from the
                                    images in an access-protected manner. For presentations, the
                                    data will be displayed anonymously. The data is stored
                                    exclusively with a consecutive tester ID in an access-protected
                                    database.
                                </p>

                                <h4>Legal basis for the processing</h4>
                                <p>
                                    Processing will be carried out if you have expressly agreed to
                                    the type and scope of data processing, Art. 6 para. 1 a) Art. 9
                                    para. 2 a) GDPR. You can revoke your consent at any time with
                                    effect for the future. However, the processing that has taken
                                    place up to this point will not be affected by this. To exercise
                                    your right of revocation, you can contact privacy@oculid.com at
                                    any time, stating your UUID (Universally Unique Identifier).
                                </p>

                                <h4>Duration of storage</h4>
                                <p>
                                    The data collected by us is stored securely for 5 years
                                    according to the latest state of the art.
                                </p>

                                <h4>Right to deletion, restriction of use</h4>
                                <p>
                                    You can revoke your declaration of consent at any time and have
                                    your data deleted by us or prohibit us from using your data for
                                    certain purposes. Please send us your TesterID and
                                    privacy@oculid.com. We will then delete all personal data that
                                    we have collected from you in the course of this data
                                    collection. A deletion of the data without indication of the
                                    TesterID cannot be carried out, since we cannot assign the
                                    recorded data directly to your name.
                                </p>

                                <h4>Right to information</h4>
                                <p>
                                    If you send us your UUID, we will provide you with all data
                                    stored about you upon request.
                                </p>

                                <h4>Right to correction</h4>
                                <p>
                                    You have the right to demand the immediate correction of
                                    incorrect personal data and the completion of incorrect personal
                                    data.
                                </p>

                                <h4>Right to limit processing</h4>
                                <p>
                                    You have the right to request the restriction of the processing
                                    of your personal data if one of the reasons mentioned in art. 18
                                    GDPR intervenes, in particular, at your request, instead of
                                    having the data deleted.
                                </p>

                                <h4>Right to data transferability</h4>
                                <p>
                                    You have the right to request that all personal data stored by
                                    us about you be surrendered in a structured, common,
                                    machine-readable format and that this data be transferred to
                                    another responsible party without hindrance by the responsible
                                    party to whom the personal data was provided, provided that this
                                    is technically possible.
                                </p>

                                <h4>Right to complain to the competent supervisory authority</h4>
                                <p>
                                    Under Art. 77 GDPR, you have the right to lodge a complaint with
                                    the supervisory authority responsible for you.
                                </p>

                                <h4>Recipient of data</h4>
                                <p>
                                    The processing of your personal data is also partly carried out
                                    by contract processors. These are included exclusively on the
                                    basis of an agreement to contract in accordance with Art. 28
                                    Para. 3 GDPR.
                                </p>

                                <h4>Data transfer to third countries</h4>
                                <p>
                                    The personal data we collect from you will not be transferred
                                    [B,M|H2] to third countries outside the European Economic Area.
                                </p>

                                <h4>List of external processors</h4>

                                <h4>Amazon Web Services (AWS)</h4>
                                <p>
                                    oculid GmbH uses the service Amazon Web Services {`("AWS")`} of
                                    Amazon Web Services, Inc., P.O. Box 81226, Seattle, WA
                                    98108-1226, USA. The data is stored exclusively in a German data
                                    center (Frankfurt/Main), which is certified according to ISO
                                    27001, 27017 and 2018 as well as PCI DSS Level 1. There are
                                    strictly limited access rights on our part and the data is
                                    automatically encrypted. You can find more information about AWS
                                    and data protection at
                                    https://aws.amazon.com/de/compliance/eu-data-protection/ and at
                                    https://aws.amazon.com/de/privacy/.
                                    <br />
                                </p>

                                <h4>Google Firebase</h4>
                                <p>
                                    We use Firebase - a service offered by Google Inc. 1600
                                    Amphitheatre Parkway, Mountain View, CA 94043, USA. Firebase
                                    provides us with information about the use of the applications.
                                    The following information is transmitted to Firebase:
                                    <br />
                                </p>

                                <ul>
                                    <li className="defaultlist">
                                        Informationabout the device, such as: Device ID, model and
                                        type of device, operating system name and version
                                    </li>
                                    <li className="defaultlist">
                                        Usage time and dwell time in individual application areas
                                    </li>
                                    <li className="defaultlist">
                                        Status of calibration and test progress
                                    </li>
                                    <li className="defaultlist">Location (country) of use</li>
                                    <li className="defaultlist">Version of the application</li>
                                </ul>

                                <p>
                                    {`Google's`} privacy policy can be found at
                                    https://policies.google.com/privacy.
                                    <br />
                                </p>

                                <h4>Sentry</h4>
                                <p>
                                    We use Sentry - a service provided by Functional Software, Inc.
                                    45 Fremont Street, 8th Floor San Francisco, CA 94105. Sentry
                                    provides us with information about crashes and malfunctions in
                                    applications. Sentry receives the following information in the
                                    event of a malfunction or failure: IP address of the device;
                                    Information about the device, such as Device ID, model and
                                    device type, name and version of the operating system, settings
                                    and language of the device; Version and functionality of the
                                    application; time and date of the failure. Data transmitted to
                                    Sentry will not be stored or transferred to other services or
                                    resources. The Sentry Privacy Policy can be found at
                                    https://sentry.io/privacy/.
                                    <br />
                                </p>

                                <ul>
                                    <li className="defaultlist">IP address of the device;</li>
                                    <li className="defaultlist">
                                        Information about the device, such as Device ID, model and
                                        device type, name and version of the operating system,
                                        settings and language of the device;
                                    </li>
                                    <li className="defaultlist">
                                        Version and functionality of the application;
                                    </li>
                                    <li className="defaultlist">time of the failure.</li>
                                </ul>

                                <p>
                                    Data transmitted to Sentry will not be stored or transferred to
                                    other services or resources. The Sentry Privacy Policy can be
                                    found at
                                    <a
                                        className="green__link"
                                        href="https://policies.google.com/privacy"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        {' '}
                                        https://sentry.io/privacy/{' '}
                                    </a>
                                    .
                                </p>
                            </div>
                        </li>
                    </ul>
                </section>
            ) : (
                <section className="container content-section text-left">
                    <ul className="collapsible privacy-collapsible" data-collapsible="expandable">
                        <li>
                            <div className="collapsible-header web-pp-title">
                                <h2>Web Policy</h2>
                            </div>
                            <div className="collapsible-body web-pp-body">
                                <h4>
                                    Verantwortlicher
                                    <br />
                                </h4>

                                <p>
                                    Angaben gemäß § 5 TMG
                                    <br />
                                </p>

                                <p>
                                    oculid GmbH (haftungsbeschränkt)
                                    <br />
                                    Charlottenburger Innovations-Centrum CHIC
                                    <br />
                                    Bismarckstraße 10 - 12
                                    <br />
                                    10625 Berlin
                                    <br />
                                </p>

                                <p>
                                    Sitz: Berlin, HRB 195722B, Amtsgericht Charlottenburg
                                    <br />
                                    USt-IdNr. DE317615858
                                    <br />
                                    Geschäftsführer: Dr. Stefan Ruff, Dr. Antje Venjakob, Klaas
                                    Filler
                                    <br />
                                </p>

                                <p>
                                    Kontakt:
                                    <br />
                                    Telefon: +49 152 22392823
                                    <br />
                                    E-Mail: <a href="mailto:info@oculid.com">info@oculid.com</a>
                                </p>

                                <h4>Haftung für Inhalte</h4>
                                <p>
                                    Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
                                    Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
                                    können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
                                    sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
                                    Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
                                    bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                                    verpflichtet, übermittelte oder gespeicherte fremde
                                    Informationen zu überwachen oder nach Umständen zu forschen, die
                                    auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                                    Entfernung oder Sperrung der Nutzung von Informationen nach den
                                    allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                                    diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                                    Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                                    Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
                                    diese Inhalte umgehend entfernen.
                                </p>

                                <h4>Haftung für Links</h4>
                                <p>
                                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                                    deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
                                    diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                                    Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                                    oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                                    wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                                    überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                                    Verlinkung nicht erkennbar. Eine permanente inhaltliche
                                    Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                                    Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                                    Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                                    umgehend entfernen.
                                </p>

                                <h4>Urheberrecht</h4>
                                <p>
                                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                                    diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                                    Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                                    Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                                    schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                                    Downloads und Kopien dieser Seite sind nur für den privaten,
                                    nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                                    dieser Seite nicht vom Betreiber erstellt wurden, werden die
                                    Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                                    Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                                    entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                                    werden wir derartige Inhalte umgehend entfernen.
                                </p>

                                <h4>Datenschutzerklärung</h4>
                                <p>
                                    Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen.
                                    Datenschutz hat einen besonders hohen Stellenwert für die
                                    Geschäftsleitung der oculid GmbH. Eine Nutzung der
                                    Internetseiten der oculid GmbH ist grundsätzlich ohne jede
                                    Angabe personenbezogener Daten möglich. Sofern eine betroffene
                                    Person besondere Services unseres Unternehmens über unsere
                                    Internetseite in Anspruch nehmen möchte, könnte jedoch eine
                                    Verarbeitung personenbezogener Daten erforderlich werden. Ist
                                    die Verarbeitung personenbezogener Daten erforderlich und
                                    besteht für eine solche Verarbeitung keine gesetzliche
                                    Grundlage, holen wir generell eine Einwilligung der betroffenen
                                    Person ein.
                                </p>
                                <p>
                                    Die Verarbeitung personenbezogener Daten, beispielsweise des
                                    Namens, der Anschrift, E-Mail-Adresse oder Telefonnummer einer
                                    betroffenen Person, erfolgt stets im Einklang mit der
                                    Datenschutz-Grundverordnung und in Übereinstimmung mit den für
                                    die oculid GmbH geltenden landesspezifischen
                                    Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung
                                    möchte unser Unternehmen die Öffentlichkeit über Art, Umfang und
                                    Zweck der von uns erhobenen, genutzten und verarbeiteten
                                    personenbezogenen Daten informieren. Ferner werden betroffene
                                    Personen mittels dieser Datenschutzerklärung über die ihnen
                                    zustehenden Rechte aufgeklärt.
                                </p>
                                <p>
                                    Die oculid GmbH hat als für die Verarbeitung Verantwortlicher
                                    zahlreiche technische und organisatorische Maßnahmen umgesetzt,
                                    um einen möglichst lückenlosen Schutz der über diese
                                    Internetseite verarbeiteten personenbezogenen Daten
                                    sicherzustellen. Dennoch können Internetbasierte
                                    Datenübertragungen grundsätzlich Sicherheitslücken aufweisen,
                                    sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus
                                    diesem Grund steht es jeder betroffenen Person frei,
                                    personenbezogene Daten auch auf alternativen Wegen,
                                    beispielsweise telefonisch, an uns zu übermitteln.
                                </p>

                                <h4>1. Begriffsbestimmungen</h4>
                                <p>
                                    Die Datenschutzerklärung der oculid GmbH beruht auf den
                                    Begrifflichkeiten, die durch den Europäischen Richtlinien- und
                                    Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung
                                    (DS-GVO) verwendet wurden. Unsere Datenschutzerklärung soll
                                    sowohl für die Öffentlichkeit als auch für unsere Kunden und
                                    Geschäftspartner einfach lesbar und verständlich sein. Um dies
                                    zu gewährleisten, möchten wir vorab die verwendeten
                                    Begrifflichkeiten erläutern.
                                </p>
                                <p>
                                    Wir verwenden in dieser Datenschutzerklärung unter anderem die
                                    folgenden Begriffe:
                                </p>

                                <ul>
                                    <li>
                                        <h4 className="indented1">a) personenbezogene Daten</h4>
                                        <p>
                                            Personenbezogene Daten sind alle Informationen, die sich
                                            auf eine identifizierte oder identifizierbare natürliche
                                            Person (im Folgenden „betroffene Person“) beziehen. Als
                                            identifizierbar wird eine natürliche Person angesehen,
                                            die direkt oder indirekt, insbesondere mittels Zuordnung
                                            zu einer Kennung wie einem Namen, zu einer Kennnummer,
                                            zu Standortdaten, zu einer Online-Kennung oder zu einem
                                            oder mehreren besonderen Merkmalen, die Ausdruck der
                                            physischen, physiologischen, genetischen, psychischen,
                                            wirtschaftlichen, kulturellen oder sozialen Identität
                                            dieser natürlichen Person sind, identifiziert werden
                                            kann.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1"> b) betroffene Person</h4>
                                        <p>
                                            Betroffene Person ist jede identifizierte oder
                                            identifizierbare natürliche Person, deren
                                            personenbezogene Daten von dem für die Verarbeitung
                                            Verantwortlichen verarbeitet werden.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">c) Verarbeitung</h4>
                                        <p>
                                            Verarbeitung ist jeder mit oder ohne Hilfe
                                            automatisierter Verfahren ausgeführte Vorgang oder jede
                                            solche Vorgangsreihe im Zusammenhang mit
                                            personenbezogenen Daten wie das Erheben, das Erfassen,
                                            die Organisation, das Ordnen, die Speicherung, die
                                            Anpassung oder Veränderung, das Auslesen, das Abfragen,
                                            die Verwendung, die Offenlegung durch Übermittlung,
                                            Verbreitung oder eine andere Form der Bereitstellung,
                                            den Abgleich oder die Verknüpfung, die Einschränkung,
                                            das Löschen oder die Vernichtung.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">
                                            d) Einschränkung der Verarbeitung
                                        </h4>
                                        <p>
                                            Einschränkung der Verarbeitung ist die Markierung
                                            gespeicherter personenbezogener Daten mit dem Ziel, ihre
                                            künftige Verarbeitung einzuschränken.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">e) Profiling</h4>
                                        <p>
                                            Profiling ist jede Art der automatisierten Verarbeitung
                                            personenbezogener Daten, die darin besteht, dass diese
                                            personenbezogenen Daten verwendet werden, um bestimmte
                                            persönliche Aspekte, die sich auf eine natürliche Person
                                            beziehen, zu bewerten, insbesondere, um Aspekte
                                            bezüglich Arbeitsleistung, wirtschaftlicher Lage,
                                            Gesundheit, persönlicher Vorlieben, Interessen,
                                            Zuverlässigkeit, Verhalten, Aufenthaltsort oder
                                            Ortswechsel dieser natürlichen Person zu analysieren
                                            oder vorherzusagen.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">f) Pseudonymisierung</h4>
                                        <p>
                                            Pseudonymisierung ist die Verarbeitung personenbezogener
                                            Daten in einer Weise, auf welche die personenbezogenen
                                            Daten ohne Hinzuziehung zusätzlicher Informationen nicht
                                            mehr einer spezifischen betroffenen Person zugeordnet
                                            werden können, sofern diese zusätzlichen Informationen
                                            gesondert aufbewahrt werden und technischen und
                                            organisatorischen Maßnahmen unterliegen, die
                                            gewährleisten, dass die personenbezogenen Daten nicht
                                            einer identifizierten oder identifizierbaren natürlichen
                                            Person zugewiesen werden.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">
                                            g) Verantwortlicher oder für die Verarbeitung
                                            Verantwortlicher
                                        </h4>
                                        <p>
                                            Verantwortlicher oder für die Verarbeitung
                                            Verantwortlicher ist die natürliche oder juristische
                                            Person, Behörde, Einrichtung oder andere Stelle, die
                                            allein oder gemeinsam mit anderen über die Zwecke und
                                            Mittel der Verarbeitung von personenbezogenen Daten
                                            entscheidet. Sind die Zwecke und Mittel dieser
                                            Verarbeitung durch das Unionsrecht oder das Recht der
                                            Mitgliedstaaten vorgegeben, so kann der Verantwortliche
                                            beziehungsweise können die bestimmten Kriterien seiner
                                            Benennung nach dem Unionsrecht oder dem Recht der
                                            Mitgliedstaaten vorgesehen werden.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">h) Auftragsverarbeiter</h4>
                                        <p>
                                            Auftragsverarbeiter ist eine natürliche oder juristische
                                            Person, Behörde, Einrichtung oder andere Stelle, die
                                            personenbezogene Daten im Auftrag des Verantwortlichen
                                            verarbeitet.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">i) Empfänger</h4>
                                        <p>
                                            Empfänger ist eine natürliche oder juristische Person,
                                            Behörde, Einrichtung oder andere Stelle, der
                                            personenbezogene Daten offengelegt werden, unabhängig
                                            davon, ob es sich bei ihr um einen Dritten handelt oder
                                            nicht. Behörden, die im Rahmen eines bestimmten
                                            Untersuchungsauftrags nach dem Unionsrecht oder dem
                                            Recht der Mitgliedstaaten möglicherweise
                                            personenbezogene Daten erhalten, gelten jedoch nicht als
                                            Empfänger.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">j) Dritter</h4>
                                        <p>
                                            Dritter ist eine natürliche oder juristische Person,
                                            Behörde, Einrichtung oder andere Stelle außer der
                                            betroffenen Person, dem Verantwortlichen, dem
                                            Auftragsverarbeiter und den Personen, die unter der
                                            unmittelbaren Verantwortung des Verantwortlichen oder
                                            des Auftragsverarbeiters befugt sind, die
                                            personenbezogenen Daten zu verarbeiten.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="indented1">k) Einwilligung</h4>
                                        <p>
                                            Einwilligung ist jede von der betroffenen Person
                                            freiwillig für den bestimmten Fall in informierter Weise
                                            und unmissverständlich abgegebene Willensbekundung in
                                            Form einer Erklärung oder einer sonstigen eindeutigen
                                            bestätigenden Handlung, mit der die betroffene Person zu
                                            verstehen gibt, dass sie mit der Verarbeitung der sie
                                            betreffenden personenbezogenen Daten einverstanden ist.
                                        </p>
                                    </li>
                                </ul>

                                <h4>
                                    2. Name und Anschrift des für die Verarbeitung Verantwortlichen
                                </h4>
                                <p>
                                    Verantwortlicher im Sinne der Datenschutz-Grundverordnung,
                                    sonstiger in den Mitgliedstaaten der Europäischen Union
                                    geltenden Datenschutzgesetze und anderer Bestimmungen mit
                                    datenschutzrechtlichem Charakter ist die:
                                </p>

                                <p>
                                    oculid GmbH (haftungsbeschränkt)
                                    <br />
                                    Charlottenburger Innovations-Centrum CHIC
                                    <br />
                                    Bismarckstraße 10 - 12
                                    <br />
                                    10625 Berlin
                                    <br />
                                    <br />
                                </p>

                                <p>
                                    {' '}
                                    Tel.: +49152/22392823
                                    <br />
                                    E-Mail: info@oculid.com
                                    <br />
                                    Website: www.oculid.com
                                </p>

                                <h4>3. Erfassung von allgemeinen Daten und Informationen</h4>
                                <p>
                                    Die Internetseite der oculid GmbH erfasst mit jedem Aufruf der
                                    Internetseite durch eine betroffene Person oder ein
                                    automatisiertes System eine Reihe von allgemeinen Daten und
                                    Informationen. Diese allgemeinen Daten und Informationen werden
                                    in den Logfiles des Servers gespeichert. Erfasst werden können
                                    die (1) verwendeten Browsertypen und Versionen, (2) das vom
                                    zugreifenden System verwendete Betriebssystem, (3) die
                                    Internetseite, von welcher ein zugreifendes System auf unsere
                                    Internetseite gelangt (sogenannte Referrer), (4) die
                                    Unterwebseiten, welche über ein zugreifendes System auf unserer
                                    Internetseite angesteuert werden, (5) das Datum und die Uhrzeit
                                    eines Zugriffs auf die Internetseite, (6) eine
                                    Internet-Protokoll-Adresse (IP-Adresse), (7) der
                                    Internet-Service-Provider des zugreifenden Systems und (8)
                                    sonstige ähnliche Daten und Informationen, die der
                                    Gefahrenabwehr im Falle von Angriffen auf unsere
                                    informationstechnologischen Systeme dienen.
                                </p>
                                <p>
                                    Bei der Nutzung dieser allgemeinen Daten und Informationen zieht
                                    die oculid GmbH keine Rückschlüsse auf die betroffene Person.
                                    Diese Informationen werden vielmehr benötigt, um (1) die Inhalte
                                    unserer Internetseite korrekt auszuliefern, (2) die Inhalte
                                    unserer Internetseite sowie die Werbung für diese zu optimieren,
                                    (3) die dauerhafte Funktionsfähigkeit unserer
                                    informationstechnologischen Systeme und der Technik unserer
                                    Internetseite zu gewährleisten sowie (4) um
                                    Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur
                                    Strafverfolgung notwendigen Informationen bereitzustellen. Diese
                                    anonym erhobenen Daten und Informationen werden durch die oculid
                                    GmbH daher einerseits statistisch und ferner mit dem Ziel
                                    ausgewertet, den Datenschutz und die Datensicherheit in unserem
                                    Unternehmen zu erhöhen, um letztlich ein optimales Schutzniveau
                                    für die von uns verarbeiteten personenbezogenen Daten
                                    sicherzustellen. Die anonymen Daten der Server-Logfiles werden
                                    getrennt von allen durch eine betroffene Person angegebenen
                                    personenbezogenen Daten gespeichert.
                                </p>

                                <h4>
                                    4. Routinemäßige Löschung und Sperrung von personenbezogenen
                                    Daten
                                </h4>
                                <p>
                                    Der für die Verarbeitung Verantwortliche verarbeitet und
                                    speichert personenbezogene Daten der betroffenen Person nur für
                                    den Zeitraum, der zur Erreichung des Speicherungszwecks
                                    erforderlich ist oder sofern dies durch den Europäischen
                                    Richtlinien- und Verordnungsgeber oder einen anderen Gesetzgeber
                                    in Gesetzen oder Vorschriften, welchen der für die Verarbeitung
                                    Verantwortliche unterliegt, vorgesehen wurde.
                                </p>
                                <p>
                                    Entfällt der Speicherungszweck oder läuft eine vom Europäischen
                                    Richtlinien- und Verordnungsgeber oder einem anderen zuständigen
                                    Gesetzgeber vorgeschriebene Speicherfrist ab, werden die
                                    personenbezogenen Daten routinemäßig und entsprechend den
                                    gesetzlichen Vorschriften gesperrt oder gelöscht.
                                </p>

                                <h4>5. Rechte der betroffenen Person</h4>
                                <ul>
                                    <li>
                                        <h4 className="indented1">a) Recht auf Bestätigung</h4>
                                        <p>
                                            Jede betroffene Person hat das vom Europäischen
                                            Richtlinien- und Verordnungsgeber eingeräumte Recht, von
                                            dem für die Verarbeitung Verantwortlichen eine
                                            Bestätigung darüber zu verlangen, ob sie betreffende
                                            personenbezogene Daten verarbeitet werden. Möchte eine
                                            betroffene Person dieses Bestätigungsrecht in Anspruch
                                            nehmen, kann sie sich hierzu jederzeit an einen
                                            Mitarbeiter des für die Verarbeitung Verantwortlichen
                                            wenden.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">b) Recht auf Auskunft</h4>
                                        <p>
                                            Jede von der Verarbeitung personenbezogener Daten
                                            betroffene Person hat das vom Europäischen Richtlinien-
                                            und Verordnungsgeber gewährte Recht, jederzeit von dem
                                            für die Verarbeitung Verantwortlichen unentgeltliche
                                            Auskunft über die zu seiner Person gespeicherten
                                            personenbezogenen Daten und eine Kopie dieser Auskunft
                                            zu erhalten. Ferner hat der Europäische Richtlinien- und
                                            Verordnungsgeber der betroffenen Person Auskunft über
                                            folgende Informationen zugestanden:
                                        </p>

                                        <ul className="indented2">
                                            <li className="list-group-item">
                                                die Verarbeitungszwecke
                                            </li>
                                            <li className="list-group-item">
                                                die Kategorien personenbezogener Daten, die
                                                verarbeitet werden
                                            </li>
                                            <li className="list-group-item">
                                                die Empfänger oder Kategorien von Empfängern,
                                                gegenüber denen die personenbezogenen Daten
                                                offengelegt worden sind oder noch offengelegt
                                                werden, insbesondere bei Empfängern in Drittländern
                                                oder bei internationalen Organisationen
                                            </li>
                                            <li className="list-group-item">
                                                falls möglich die geplante Dauer, für die die
                                                personenbezogenen Daten gespeichert werden, oder,
                                                falls dies nicht möglich ist, die Kriterien für die
                                                Festlegung dieser Dauer
                                            </li>
                                            <li className="list-group-item">
                                                das Bestehen eines Rechts auf Berichtigung oder
                                                Löschung der sie betreffenden personenbezogenen
                                                Daten oder auf Einschränkung der Verarbeitung durch
                                                den Verantwortlichen oder eines Widerspruchsrechts
                                                gegen diese Verarbeitung
                                            </li>
                                            <li className="list-group-item">
                                                das Bestehen eines Beschwerderechts bei einer
                                                Aufsichtsbehörde
                                            </li>
                                            <li className="list-group-item">
                                                wenn die personenbezogenen Daten nicht bei der
                                                betroffenen Person erhoben werden: Alle verfügbaren
                                                Informationen über die Herkunft der Daten
                                            </li>
                                            <li className="list-group-item">
                                                das Bestehen einer automatisierten
                                                Entscheidungsfindung einschließlich Profiling gemäß
                                                Artikel 22 Abs.1 und 4 DS-GVO und — zumindest in
                                                diesen Fällen — aussagekräftige Informationen über
                                                die involvierte Logik sowie die Tragweite und die
                                                angestrebten Auswirkungen einer derartigen
                                                Verarbeitung für die betroffene Person
                                            </li>
                                            <br />
                                        </ul>

                                        <p>
                                            Ferner steht der betroffenen Person ein Auskunftsrecht
                                            darüber zu, ob personenbezogene Daten an ein Drittland
                                            oder an eine internationale Organisation übermittelt
                                            wurden. Sofern dies der Fall ist, so steht der
                                            betroffenen Person im Übrigen das Recht zu, Auskunft
                                            über die geeigneten Garantien im Zusammenhang mit der
                                            Übermittlung zu erhalten.
                                        </p>
                                        <p>
                                            Möchte eine betroffene Person dieses Auskunftsrecht in
                                            Anspruch nehmen, kann sie sich hierzu jederzeit an einen
                                            Mitarbeiter des für die Verarbeitung Verantwortlichen
                                            wenden.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">c) Recht auf Berichtigung</h4>
                                        <p>
                                            Jede von der Verarbeitung personenbezogener Daten
                                            betroffene Person hat das vom Europäischen Richtlinien-
                                            und Verordnungsgeber gewährte Recht, die unverzügliche
                                            Berichtigung sie betreffender unrichtiger
                                            personenbezogener Daten zu verlangen. Ferner steht der
                                            betroffenen Person das Recht zu, unter Berücksichtigung
                                            der Zwecke der Verarbeitung, die Vervollständigung
                                            unvollständiger personenbezogener Daten — auch mittels
                                            einer ergänzenden Erklärung — zu verlangen.
                                        </p>
                                        <p>
                                            Möchte eine betroffene Person dieses Berichtigungsrecht
                                            in Anspruch nehmen, kann sie sich hierzu jederzeit an
                                            einen Mitarbeiter des für die Verarbeitung
                                            Verantwortlichen wenden.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">
                                            d) Recht auf Löschung (Recht auf Vergessen werden)
                                        </h4>
                                        <p>
                                            Jede von der Verarbeitung personenbezogener Daten
                                            betroffene Person hat das vom Europäischen Richtlinien-
                                            und Verordnungsgeber gewährte Recht, von dem
                                            Verantwortlichen zu verlangen, dass die sie betreffenden
                                            personenbezogenen Daten unverzüglich gelöscht werden,
                                            sofern einer der folgenden Gründe zutrifft und soweit
                                            die Verarbeitung nicht erforderlich ist:
                                        </p>

                                        <ul className="indented2">
                                            <li className="list-group-item">
                                                Die personenbezogenen Daten wurden für solche Zwecke
                                                erhoben oder auf sonstige Weise verarbeitet, für
                                                welche sie nicht mehr notwendig sind.
                                            </li>
                                            <li className="list-group-item">
                                                Die betroffene Person widerruft ihre Einwilligung,
                                                auf die sich die Verarbeitung gemäß Art. 6 Abs. 1
                                                Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a
                                                DS-GVO stützte, und es fehlt an einer anderweitigen
                                                Rechtsgrundlage für die Verarbeitung.
                                            </li>
                                            <li className="list-group-item">
                                                Die betroffene Person legt gemäß Art. 21 Abs. 1
                                                DS-GVO Widerspruch gegen die Verarbeitung ein, und
                                                es liegen keine vorrangigen berechtigten Gründe für
                                                die Verarbeitung vor, oder die betroffene Person
                                                legt gemäß Art. 21 Abs. 2 DS-GVO Widerspruch gegen
                                                die Verarbeitung ein.
                                            </li>
                                            <li className="list-group-item">
                                                Die personenbezogenen Daten wurden unrechtmäßig
                                                verarbeitet.
                                            </li>
                                            <li className="list-group-item">
                                                Die Löschung der personenbezogenen Daten ist zur
                                                Erfüllung einer rechtlichen Verpflichtung nach dem
                                                Unionsrecht oder dem Recht der Mitgliedstaaten
                                                erforderlich, dem der Verantwortliche unterliegt.
                                            </li>
                                            <li className="list-group-item">
                                                Die personenbezogenen Daten wurden in Bezug auf
                                                angebotene Dienste der Informationsgesellschaft
                                                gemäß Art. 8 Abs. 1 DS-GVO erhoben.
                                            </li>
                                            <br />
                                        </ul>

                                        <p>
                                            Sofern einer der oben genannten Gründe zutrifft und eine
                                            betroffene Person die Löschung von personenbezogenen
                                            Daten, die bei der oculid GmbH gespeichert sind,
                                            veranlassen möchte, kann sie sich hierzu jederzeit an
                                            einen Mitarbeiter des für die Verarbeitung
                                            Verantwortlichen wenden. Der Mitarbeiter der oculid GmbH
                                            wird veranlassen, dass dem Löschverlangen unverzüglich
                                            nachgekommen wird.
                                        </p>
                                        <p>
                                            Wurden die personenbezogenen Daten von der oculid GmbH
                                            öffentlich gemacht und ist unser Unternehmen als
                                            Verantwortlicher gemäß Art. 17 Abs. 1 DS-GVO zur
                                            Löschung der personenbezogenen Daten verpflichtet, so
                                            trifft die oculid GmbH unter Berücksichtigung der
                                            verfügbaren Technologie und der Implementierungskosten
                                            angemessene Maßnahmen, auch technischer Art, um andere
                                            für die Datenverarbeitung Verantwortliche, welche die
                                            veröffentlichten personenbezogenen Daten verarbeiten,
                                            darüber in Kenntnis zu setzen, dass die betroffene
                                            Person von diesen anderen für die Datenverarbeitung
                                            Verantwortlichen die Löschung sämtlicher Links zu diesen
                                            personenbezogenen Daten oder von Kopien oder
                                            Replikationen dieser personenbezogenen Daten verlangt
                                            hat, soweit die Verarbeitung nicht erforderlich ist. Der
                                            Mitarbeiter der oculid GmbH wird im Einzelfall das
                                            Notwendige veranlassen.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">
                                            {' '}
                                            e)Recht auf Einschränkung der Verarbeitung
                                        </h4>
                                        <p>
                                            Jede von der Verarbeitung personenbezogener Daten
                                            betroffene Person hat das vom Europäischen Richtlinien-
                                            und Verordnungsgeber gewährte Recht, von dem
                                            Verantwortlichen die Einschränkung der Verarbeitung zu
                                            verlangen, wenn eine der folgenden Voraussetzungen
                                            gegeben ist:
                                        </p>

                                        <ul className="indented2">
                                            <li className="list-group-item">
                                                Die Richtigkeit der personenbezogenen Daten wird von
                                                der betroffenen Person bestritten, und zwar für eine
                                                Dauer, die es dem Verantwortlichen ermöglicht, die
                                                Richtigkeit der personenbezogenen Daten zu
                                                überprüfen.
                                            </li>
                                            <li className="list-group-item">
                                                Die Verarbeitung ist unrechtmäßig, die betroffene
                                                Person lehnt die Löschung der personenbezogenen
                                                Daten ab und verlangt stattdessen die Einschränkung
                                                der Nutzung der personenbezogenen Daten.
                                            </li>
                                            <li className="list-group-item">
                                                Der Verantwortliche benötigt die personenbezogenen
                                                Daten für die Zwecke der Verarbeitung nicht länger,
                                                die betroffene Person benötigt sie jedoch zur
                                                Geltendmachung, Ausübung oder Verteidigung von
                                                Rechtsansprüchen.
                                            </li>
                                            <li className="list-group-item">
                                                Die betroffene Person hat Widerspruch gegen die
                                                Verarbeitung gem. Art. 21 Abs. 1 DS-GVO eingelegt
                                                und es steht noch nicht fest, ob die berechtigten
                                                Gründe des Verantwortlichen gegenüber denen der
                                                betroffenen Person überwiegen.
                                            </li>
                                            <br />
                                        </ul>
                                        <p>
                                            Sofern eine der oben genannten Voraussetzungen gegeben
                                            ist und eine betroffene Person die Einschränkung von
                                            personenbezogenen Daten, die bei der oculid GmbH
                                            gespeichert sind, verlangen möchte, kann sie sich hierzu
                                            jederzeit an einen Mitarbeiter des für die Verarbeitung
                                            Verantwortlichen wenden. Der Mitarbeiter der oculid GmbH
                                            wird die Einschränkung der Verarbeitung veranlassen.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">
                                            f) Recht auf Datenübertragbarkeit
                                        </h4>
                                        <p>
                                            Jede von der Verarbeitung personenbezogener Daten
                                            betroffene Person hat das vom Europäischen Richtlinien-
                                            und Verordnungsgeber gewährte Recht, die sie
                                            betreffenden personenbezogenen Daten, welche durch die
                                            betroffene Person einem Verantwortlichen bereitgestellt
                                            wurden, in einem strukturierten, gängigen und
                                            maschinenlesbaren Format zu erhalten. Sie hat außerdem
                                            das Recht, diese Daten einem anderen Verantwortlichen
                                            ohne Behinderung durch den Verantwortlichen, dem die
                                            personenbezogenen Daten bereitgestellt wurden, zu
                                            übermitteln, sofern die Verarbeitung auf der
                                            Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder
                                            Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf einem Vertrag
                                            gemäß Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die
                                            Verarbeitung mithilfe automatisierter Verfahren erfolgt,
                                            sofern die Verarbeitung nicht für die Wahrnehmung einer
                                            Aufgabe erforderlich ist, die im öffentlichen Interesse
                                            liegt oder in Ausübung öffentlicher Gewalt erfolgt,
                                            welche dem Verantwortlichen übertragen wurde.
                                        </p>
                                        <p>
                                            Ferner hat die betroffene Person bei der Ausübung ihres
                                            Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1
                                            DS-GVO das Recht, zu erwirken, dass die
                                            personenbezogenen Daten direkt von einem
                                            Verantwortlichen an einen anderen Verantwortlichen
                                            übermittelt werden, soweit dies technisch machbar ist
                                            und sofern hiervon nicht die Rechte und Freiheiten
                                            anderer Personen beeinträchtigt werden.
                                        </p>
                                        <p>
                                            Zur Geltendmachung des Rechts auf Datenübertragbarkeit
                                            kann sich die betroffene Person jederzeit an einen
                                            Mitarbeiter der oculid GmbH wenden.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">g) Recht auf Widerspruch</h4>
                                        <p>
                                            Jede von der Verarbeitung personenbezogener Daten
                                            betroffene Person hat das vom Europäischen Richtlinien-
                                            und Verordnungsgeber gewährte Recht, aus Gründen, die
                                            sich aus ihrer besonderen Situation ergeben, jederzeit
                                            gegen die Verarbeitung sie betreffender
                                            personenbezogener Daten, die aufgrund von Art. 6 Abs. 1
                                            Buchstaben e oder f DS-GVO erfolgt, Widerspruch
                                            einzulegen. Dies gilt auch für ein auf diese
                                            Bestimmungen gestütztes Profiling.
                                        </p>
                                        <p>
                                            Die oculid GmbH verarbeitet die personenbezogenen Daten
                                            im Falle des Widerspruchs nicht mehr, es sei denn, wir
                                            können zwingende schutzwürdige Gründe für die
                                            Verarbeitung nachweisen, die den Interessen, Rechten und
                                            Freiheiten der betroffenen Person überwiegen, oder die
                                            Verarbeitung dient der Geltendmachung, Ausübung oder
                                            Verteidigung von Rechtsansprüchen.
                                        </p>
                                        <p>
                                            Verarbeitet die oculid GmbH personenbezogene Daten, um
                                            Direktwerbung zu betreiben, so hat die betroffene Person
                                            das Recht, jederzeit Widerspruch gegen die Verarbeitung
                                            der personenbezogenen Daten zum Zwecke derartiger
                                            Werbung einzulegen. Dies gilt auch für das Profiling,
                                            soweit es mit solcher Direktwerbung in Verbindung steht.
                                            Widerspricht die betroffene Person gegenüber der oculid
                                            GmbH der Verarbeitung für Zwecke der Direktwerbung, so
                                            wird die oculid GmbH die personenbezogenen Daten nicht
                                            mehr für diese Zwecke verarbeiten.
                                        </p>
                                        <p>
                                            Zudem hat die betroffene Person das Recht, aus Gründen,
                                            die sich aus ihrer besonderen Situation ergeben, gegen
                                            die sie betreffende Verarbeitung personenbezogener
                                            Daten, die bei der oculid GmbH zu wissenschaftlichen
                                            oder historischen Forschungszwecken oder zu
                                            statistischen Zwecken gemäß Art. 89 Abs. 1 DS-GVO
                                            erfolgen, Widerspruch einzulegen, es sei denn, eine
                                            solche Verarbeitung ist zur Erfüllung einer im
                                            öffentlichen Interesse liegenden Aufgabe erforderlich.
                                        </p>
                                        <p>
                                            Zur Ausübung des Rechts auf Widerspruch kann sich die
                                            betroffene Person direkt jeden Mitarbeiter der oculid
                                            GmbH oder einen anderen Mitarbeiter wenden. Der
                                            betroffenen Person steht es ferner frei, im Zusammenhang
                                            mit der Nutzung von Diensten der
                                            Informationsgesellschaft, ungeachtet der Richtlinie
                                            2002/58/EG, ihr Widerspruchsrecht mittels
                                            automatisierter Verfahren auszuüben, bei denen
                                            technische Spezifikationen verwendet werden.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">
                                            h) Automatisierte Entscheidungen im Einzelfall
                                            einschließlich Profiling
                                        </h4>
                                        <p>
                                            Jede von der Verarbeitung personenbezogener Daten
                                            betroffene Person hat das vom Europäischen Richtlinien-
                                            und Verordnungsgeber gewährte Recht, nicht einer
                                            ausschließlich auf einer automatisierten Verarbeitung —
                                            einschließlich Profiling — beruhenden Entscheidung
                                            unterworfen zu werden, die ihr gegenüber rechtliche
                                            Wirkung entfaltet oder sie in ähnlicher Weise erheblich
                                            beeinträchtigt, sofern die Entscheidung (1) nicht für
                                            den Abschluss oder die Erfüllung eines Vertrags zwischen
                                            der betroffenen Person und dem Verantwortlichen
                                            erforderlich ist, oder (2) aufgrund von
                                            Rechtsvorschriften der Union oder der Mitgliedstaaten,
                                            denen der Verantwortliche unterliegt, zulässig ist und
                                            diese Rechtsvorschriften angemessene Maßnahmen zur
                                            Wahrung der Rechte und Freiheiten sowie der berechtigten
                                            Interessen der betroffenen Person enthalten oder (3) mit
                                            ausdrücklicher Einwilligung der betroffenen Person
                                            erfolgt.
                                        </p>
                                        <p>
                                            Ist die Entscheidung (1) für den Abschluss oder die
                                            Erfüllung eines Vertrags zwischen der betroffenen Person
                                            und dem Verantwortlichen erforderlich oder (2) erfolgt
                                            sie mit ausdrücklicher Einwilligung der betroffenen
                                            Person, trifft die oculid GmbH angemessene Maßnahmen, um
                                            die Rechte und Freiheiten sowie die berechtigten
                                            Interessen der betroffenen Person zu wahren, wozu
                                            mindestens das Recht auf Erwirkung des Eingreifens einer
                                            Person seitens des Verantwortlichen, auf Darlegung des
                                            eigenen Standpunkts und auf Anfechtung der Entscheidung
                                            gehört.
                                        </p>
                                        <p>
                                            Möchte die betroffene Person Rechte mit Bezug auf
                                            automatisierte Entscheidungen geltend machen, kann sie
                                            sich hierzu jederzeit an einen Mitarbeiter des für die
                                            Verarbeitung Verantwortlichen wenden.
                                        </p>
                                    </li>

                                    <li>
                                        <h4 className="indented1">
                                            i) Recht auf Widerruf einer datenschutzrechtlichen
                                            Einwilligung
                                        </h4>
                                        <p>
                                            Jede von der Verarbeitung personenbezogener Daten
                                            betroffene Person hat das vom Europäischen Richtlinien-
                                            und Verordnungsgeber gewährte Recht, eine Einwilligung
                                            zur Verarbeitung personenbezogener Daten jederzeit zu
                                            widerrufen.
                                        </p>
                                        <p>
                                            Möchte die betroffene Person ihr Recht auf Widerruf
                                            einer Einwilligung geltend machen, kann sie sich hierzu
                                            jederzeit an einen Mitarbeiter des für die Verarbeitung
                                            Verantwortlichen wenden.
                                        </p>
                                    </li>
                                </ul>

                                <h4>
                                    6. Datenschutzbestimmungen zu Einsatz und Verwendung von
                                    Google-AdWords
                                </h4>

                                <p>
                                    Der für die Verarbeitung Verantwortliche hat auf dieser
                                    Internetseite Google AdWords integriert. Google AdWords ist ein
                                    Dienst zur Internetwerbung, der es Werbetreibenden gestattet,
                                    sowohl Anzeigen in den Suchmaschinenergebnissen von Google als
                                    auch im Google-Werbenetzwerk zu schalten. Google AdWords
                                    ermöglicht es einem Werbetreibenden, vorab bestimmte
                                    Schlüsselwörter festzulegen, mittels derer eine Anzeige in den
                                    Suchmaschinenergebnissen von Google ausschließlich dann
                                    angezeigt wird, wenn der Nutzer mit der Suchmaschine ein
                                    schlüsselwortrelevantes Suchergebnis abruft. Im
                                    Google-Werbenetzwerk werden die Anzeigen mittels eines
                                    automatischen Algorithmus und unter Beachtung der zuvor
                                    festgelegten Schlüsselwörter auf themenrelevanten Internetseiten
                                    verteilt.
                                </p>

                                <p>
                                    Betreibergesellschaft der Dienste von Google AdWords ist die
                                    Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA
                                    94043-1351, USA.
                                </p>

                                <p>
                                    Der Zweck von Google AdWords ist die Bewerbung unserer
                                    Internetseite durch die Einblendung von interessenrelevanter
                                    Werbung auf den Internetseiten von Drittunternehmen und in den
                                    Suchmaschinenergebnissen der Suchmaschine Google und eine
                                    Einblendung von Fremdwerbung auf unserer Internetseite.
                                </p>

                                <p>
                                    Gelangt eine betroffene Person über eine Google-Anzeige auf
                                    unsere Internetseite, wird auf dem informationstechnologischen
                                    System der betroffenen Person durch Google ein sogenannter
                                    Conversion-Cookie abgelegt. Was Cookies sind, wurde oben bereits
                                    erläutert. Ein Conversion-Cookie verliert nach dreißig Tagen
                                    seine Gültigkeit und dient nicht zur Identifikation der
                                    betroffenen Person. Über den Conversion-Cookie wird, sofern das
                                    Cookie noch nicht abgelaufen ist, nachvollzogen, ob bestimmte
                                    Unterseiten, beispielsweise der Warenkorb von einem
                                    Online-Shop-System, auf unserer Internetseite aufgerufen wurden.
                                    Durch den Conversion-Cookie können sowohl wir als auch Google
                                    nachvollziehen, ob eine betroffene Person, die über eine
                                    AdWords-Anzeige auf unsere Internetseite gelangt ist, einen
                                    Umsatz generierte, also einen Warenkauf vollzogen oder
                                    abgebrochen hat.
                                </p>

                                <p>
                                    Die durch die Nutzung des Conversion-Cookies erhobenen Daten und
                                    Informationen werden von Google verwendet, um Besuchsstatistiken
                                    für unsere Internetseite zu erstellen. Diese Besuchsstatistiken
                                    werden durch uns wiederum genutzt, um die Gesamtanzahl der
                                    Nutzer zu ermitteln, welche über AdWords-Anzeigen an uns
                                    vermittelt wurden, also um den Erfolg oder Misserfolg der
                                    jeweiligen AdWords-Anzeige zu ermitteln und um unsere
                                    AdWords-Anzeigen für die Zukunft zu optimieren. Weder unser
                                    Unternehmen noch andere Werbekunden von Google-AdWords erhalten
                                    Informationen von Google, mittels derer die betroffene Person
                                    identifiziert werden könnte.
                                </p>

                                <p>
                                    Mittels des Conversion-Cookies werden personenbezogene
                                    Informationen, beispielsweise die durch die betroffene Person
                                    besuchten Internetseiten, gespeichert. Bei jedem Besuch unserer
                                    Internetseiten werden demnach personenbezogene Daten,
                                    einschließlich der IP-Adresse des von der betroffenen Person
                                    genutzten Internetanschlusses, an Google in den Vereinigten
                                    Staaten von Amerika übertragen. Diese personenbezogenen Daten
                                    werden durch Google in den Vereinigten Staaten von Amerika
                                    gespeichert. Google gibt diese über das technische Verfahren
                                    erhobenen personenbezogenen Daten unter Umständen an Dritte
                                    weiter.
                                </p>

                                <p>
                                    Die betroffene Person kann die Setzung von Cookies durch unsere
                                    Internetseite, wie oben bereits dargestellt, jederzeit mittels
                                    einer entsprechenden Einstellung des genutzten Internetbrowsers
                                    verhindern und damit der Setzung von Cookies dauerhaft
                                    widersprechen. Eine solche Einstellung des genutzten
                                    Internetbrowsers würde auch verhindern, dass Google einen
                                    Conversion-Cookie auf dem informationstechnologischen System der
                                    betroffenen Person setzt. Zudem kann ein von Google AdWords
                                    bereits gesetzter Cookie jederzeit über den Internetbrowser oder
                                    andere Softwareprogramme gelöscht werden.
                                </p>

                                <p>
                                    Ferner besteht für die betroffene Person die Möglichkeit, der
                                    interessenbezogenen Werbung durch Google zu widersprechen.
                                    Hierzu muss die betroffene Person von jedem der von ihr
                                    genutzten Internetbrowser aus den Link
                                    www.google.de/settings/ads aufrufen und dort die gewünschten
                                    Einstellungen vornehmen.
                                </p>

                                <p>
                                    Weitere Informationen und die geltenden Datenschutzbestimmungen
                                    von Google können unter
                                    https://www.google.de/intl/de/policies/privacy/ abgerufen
                                    werden.
                                </p>

                                <h4>7. Rechtsgrundlage der Verarbeitung</h4>
                                <p>
                                    Art. 6 I lit. a DS-GVO dient unserem Unternehmen als
                                    Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine
                                    Einwilligung für einen bestimmten Verarbeitungszweck einholen.
                                    Ist die Verarbeitung personenbezogener Daten zur Erfüllung eines
                                    Vertrags, dessen Vertragspartei die betroffene Person ist,
                                    erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen
                                    der Fall ist, die für eine Lieferung von Waren oder die
                                    Erbringung einer sonstigen Leistung oder Gegenleistung notwendig
                                    sind, so beruht die Verarbeitung auf Art. 6 I lit. b DS-GVO.
                                    Gleiches gilt für solche Verarbeitungsvorgänge die zur
                                    Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa
                                    in Fällen von Anfragen zur unseren Produkten oder Leistungen.
                                    Unterliegt unser Unternehmen einer rechtlichen Verpflichtung
                                    durch welche eine Verarbeitung von personenbezogenen Daten
                                    erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher
                                    Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c
                                    DS-GVO. In seltenen Fällen könnte die Verarbeitung von
                                    personenbezogenen Daten erforderlich werden, um lebenswichtige
                                    Interessen der betroffenen Person oder einer anderen natürlichen
                                    Person zu schützen. Dies wäre beispielsweise der Fall, wenn ein
                                    Besucher in unserem Betrieb verletzt werden würde und daraufhin
                                    sein Name, sein Alter, seine Krankenkassendaten oder sonstige
                                    lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder
                                    sonstige Dritte weitergegeben werden müssten. Dann würde die
                                    Verarbeitung auf Art. 6 I lit. d DS-GVO beruhen. Letztlich
                                    könnten Verarbeitungsvorgänge auf Art. 6 I lit. f DS-GVO
                                    beruhen. Auf dieser Rechtsgrundlage basieren
                                    Verarbeitungsvorgänge, die von keiner der vorgenannten
                                    Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur
                                    Wahrung eines berechtigten Interesses unseres Unternehmens oder
                                    eines Dritten erforderlich ist, sofern die Interessen,
                                    Grundrechte und Grundfreiheiten des Betroffenen nicht
                                    überwiegen. Solche Verarbeitungsvorgänge sind uns insbesondere
                                    deshalb gestattet, weil sie durch den Europäischen Gesetzgeber
                                    besonders erwähnt wurden. Er vertrat insoweit die Auffassung,
                                    dass ein berechtigtes Interesse anzunehmen sein könnte, wenn die
                                    betroffene Person ein Kunde des Verantwortlichen ist
                                    (Erwägungsgrund 47 Satz 2 DS-GVO).
                                </p>

                                <h4>
                                    8. Berechtigte Interessen an der Verarbeitung, die von dem
                                    Verantwortlichen oder einem Dritten verfolgt werden
                                </h4>
                                <p>
                                    Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I
                                    lit. f DS-GVO ist unser berechtigtes Interesse die Durchführung
                                    unserer Geschäftstätigkeit zugunsten des Wohlergehens all
                                    unserer Mitarbeiter und unserer Anteilseigner.
                                </p>

                                <h4>
                                    9. Dauer, für die die personenbezogenen Daten gespeichert werden
                                </h4>
                                <p>
                                    Das Kriterium für die Dauer der Speicherung von
                                    personenbezogenen Daten ist die jeweilige gesetzliche
                                    Aufbewahrungsfrist. Nach Ablauf der Frist werden die
                                    entsprechenden Daten routinemäßig gelöscht, sofern sie nicht
                                    mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich
                                    sind.
                                </p>

                                <h4>
                                    10. Gesetzliche oder vertragliche Vorschriften zur
                                    Bereitstellung der personenbezogenen Daten; Erforderlichkeit für
                                    den Vertragsabschluss; Verpflichtung der betroffenen Person, die
                                    personenbezogenen Daten bereitzustellen; mögliche Folgen der
                                    Nichtbereitstellung
                                </h4>
                                <p>
                                    Wir klären Sie darüber auf, dass die Bereitstellung
                                    personenbezogener Daten zum Teil gesetzlich vorgeschrieben ist
                                    (z.B. Steuervorschriften) oder sich auch aus vertraglichen
                                    Regelungen (z.B. Angaben zum Vertragspartner) ergeben kann.
                                    Mitunter kann es zu einem Vertragsschluss erforderlich sein,
                                    dass eine betroffene Person uns personenbezogene Daten zur
                                    Verfügung stellt, die in der Folge durch uns verarbeitet werden
                                    müssen. Die betroffene Person ist beispielsweise verpflichtet
                                    uns personenbezogene Daten bereitzustellen, wenn unser
                                    Unternehmen mit ihr einen Vertrag abschließt. Eine
                                    Nichtbereitstellung der personenbezogenen Daten hätte zur Folge,
                                    dass der Vertrag mit dem Betroffenen nicht geschlossen werden
                                    könnte. Vor einer Bereitstellung personenbezogener Daten durch
                                    den Betroffenen muss sich der Betroffene an einen unserer
                                    Mitarbeiter wenden. Unser Mitarbeiter klärt den Betroffenen
                                    einzelfallbezogen darüber auf, ob die Bereitstellung der
                                    personenbezogenen Daten gesetzlich oder vertraglich
                                    vorgeschrieben oder für den Vertragsabschluss erforderlich ist,
                                    ob eine Verpflichtung besteht, die personenbezogenen Daten
                                    bereitzustellen, und welche Folgen die Nichtbereitstellung der
                                    personenbezogenen Daten hätte.
                                </p>

                                <h4>11. Bestehen einer automatisierten Entscheidungsfindung</h4>
                                <p>
                                    Als verantwortungsbewusstes Unternehmen verzichten wir auf eine
                                    automatische Entscheidungsfindung oder ein Profiling.
                                </p>

                                <p>
                                    Diese Datenschutzerklärung wurde durch den{' '}
                                    <a
                                        href="https://dg-datenschutz.de/datenschutzerklaerung/"
                                        rel="nofollow noopener noreferrer"
                                        target="_blank">
                                        Datenschutzerklärungs-Generator
                                    </a>{' '}
                                    der Deutschen Gesellschaft für Datenschutz, in Kooperation mit
                                    den{' '}
                                    <a
                                        href="https://www.wbs-law.de/taetigkeitsgebiete/medienrecht/"
                                        rel="nofollow noopener noreferrer"
                                        target="_blank">
                                        Medienrecht
                                    </a>{' '}
                                    Anwälten WILDE BEUGER SOLMECKE | Rechtsanwälte erstellt.
                                </p>
                                <br />
                                <br />
                            </div>
                        </li>

                        <li>
                            <div className="collapsible-header app-pp-title">
                                <h2>App Policy</h2>
                            </div>
                            <div className="collapsible-body app-pp-body">
                                <h4>
                                    Verantwortlicher
                                    <br />
                                </h4>

                                <p>
                                    Angaben gemäß § 5 TMG
                                    <br />
                                </p>

                                <p>
                                    oculid GmbH (haftungsbeschränkt)
                                    <br />
                                    Charlottenburger Innovations-Centrum CHIC
                                    <br />
                                    Bismarckstraße 10 - 12
                                    <br />
                                    10625 Berlin
                                    <br />
                                </p>

                                <p>
                                    Sitz: Berlin, HRB 195722B, Amtsgericht Charlottenburg
                                    <br />
                                    USt-IdNr. DE317615858
                                    <br />
                                    Geschäftsführer: Dr. Stefan Ruff, Dr. Antje Venjakob, Klaas
                                    Filler
                                    <br />
                                </p>

                                <p>
                                    Kontakt:
                                    <br />
                                    Telefon: +49 152 22392823
                                    <br />
                                    E-Mail: <a href="mailto:info@oculid.com">info@oculid.com</a>
                                </p>

                                <h4>Zwecke der Verarbeitung</h4>
                                <p>
                                    Während der Interaktion werden Videodaten Ihres Gesichts mittels
                                    Nahinfrarot- oder RGB Kamera aufgezeichnet sowie Audioaufnahmen
                                    mittels des Mikrofons in ihrem Smartphone. Zudem messen wir die
                                    Güte der Aufgabenbearbeitung und zeichnen die Bildschirminhalte
                                    sowie ihre Toucheingaben hinsichtlich Anzahl und Lokalisation
                                    während der Interaktion auf. Außerdem erheben wir vor der
                                    Aufgabenbearbeitung demografische Daten (z.B. Alter, Geschlecht,
                                    Wohnort). Mit Ihrer Zustimmung werden die Daten für 5 Jahre
                                    sicher gespeichert.
                                </p>
                                <p>
                                    Wir werden Ihre Daten anschließend nutzen, um unsere Algorithmik
                                    zur Blickbewegungserfassung zu verbessern, die es uns ermöglicht
                                    von Videodaten auf Ihre Blick- und Pupillenposition bzw.
                                    Pupillengeometrie zu schließen.
                                </p>
                                <p>
                                    Darüber hinaus erheben wir Informationen über das genutzte
                                    Endgerät, wie Geräte ID, Modell, Art & Typ des Gerätes, Display
                                    Größe, Name und Version des Betriebssystems, Einstellungen und
                                    Sprache des Gerätes, für die Optimierung unserer Algorithmen und
                                    für das Nutzermanagement.
                                </p>
                                <p>
                                    Alle erhobenen Daten werden zur Analyse der
                                    Benutzerfreundlichkeit der von Ihnen getesteten Anwendung und
                                    zur Analyse der Aufmerksamkeit auf Bildschirminhalten genutzt
                                    und den Testerstellern anonymisiert für die Analyse und
                                    Visualisierung zur Verfügung gestellt. Die demographischen Daten
                                    werden verwendet, um den Kunden von oculid die Interpretation
                                    der Ergebnisse in Bezug auf die erhobenen demographischen
                                    Gruppen zu ermöglichen.
                                </p>
                                <p>
                                    oculid GmbH (haftungsbeschränkt) wird Ihre Video, Blick- und
                                    Pupillendaten, Ihre persönlichen Angaben und alle Daten, die von
                                    den Bildern abgeleitet werden können, zugriffsgeschützt
                                    speichern. Bei Präsentationen werden die Daten anonymisiert
                                    dargestellt. Die Daten werden ausschließlich mit einer
                                    fortlaufenden Tester ID in einer zugriffsgeschützten Datenbank
                                    abgelegt.
                                </p>

                                <h4>Rechtsgrundlage für die Verarbeitung</h4>
                                <p>
                                    Eine Verarbeitung erfolgt, soweit Sie hierzu ausdrücklich der
                                    Art und dem Umfang der Datenverarbeitung zugestimmt haben, Art.
                                    6 Abs. 1 a) Art. 9 Abs. 2 a) DSGVO. Ihre Einwilligung können Sie
                                    jederzeit mit Wirkung für die Zukunft widerrufen. Die bis zu
                                    diesem Zeitpunkt erfolgte Verarbeitung wird davon aber nicht
                                    betroffen. Sie können sich jederzeit zur Ausübung Ihres
                                    Widerrufsrechts an privacy@oculid.com unter Angabe Ihrer UUID
                                    (Universally Unique Identifier) wenden.
                                </p>

                                <h4>Dauer der Speicherung</h4>
                                <p>
                                    Die von uns erhobenen Daten werden auf 5 Jahre sicher nach
                                    aktuellstem Stand der Technik gespeichert.
                                </p>

                                <h4>Recht auf Löschung, Nutzungseinschränkung</h4>
                                <p>
                                    Sie können Ihre Einverständniserklärung jederzeit widerrufen und
                                    Ihre Daten durch uns löschen lassen oder uns die Nutzung ihrer
                                    Daten zu bestimmten Verwendungszwecken untersagen. Bitte
                                    übersenden Sie uns hierfür Ihre UUID and privacy@oculid.com. Wir
                                    werden dann alle personenbezogenen Daten, die wir von Ihnen im
                                    Zuge dieser Datenerhebung erhoben haben, löschen. Eine Löschung
                                    der Daten ohne Angabe der UUID kann nicht durchgeführt werden,
                                    da wir die aufgenommenen Daten nicht direkt Ihrem Namen zuordnen
                                    können.{' '}
                                </p>

                                <h4>Recht auf Auskunft</h4>
                                <p>
                                    Wenn Sie uns ihre UUID übersenden werden wir Ihnen auf Anfrage
                                    alle über Sie gespeicherten Daten zur Verfügung stellen.{' '}
                                </p>

                                <h4>Recht auf Berichtigung</h4>
                                <p>
                                    Sie haben das Recht, die unverzügliche Berichtigung falscher
                                    sowie die Vervollständigung unrichtiger personenbezogener Daten
                                    zu verlangen.
                                </p>

                                <h4>Recht auf Einschränkung der Verarbeitung</h4>
                                <p>
                                    Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
                                    personenbezogenen Daten zu verlangen, wenn einer der in Art. 18
                                    DSGVO genannten Gründe eingreift, insbesondere, auf Ihren Wunsch
                                    hin, statt einer Löschung der Daten.{' '}
                                </p>

                                <h4>Recht auf Datenübertragbarkeit</h4>
                                <p>
                                    Sie haben das Recht, alle bei uns über Sie gespeicherten
                                    personenbezogenen Daten in einem strukturierten, gängigen und
                                    maschinenlesbaren Format herauszuverlangen und diese Daten einem
                                    anderen Verantwortlichen ohne Behinderung durch den
                                    Verantwortlichen, dem die personenbezogenen Daten bereitgestellt
                                    wurden, zu übermitteln, sofern dies technisch möglich ist.
                                </p>

                                <h4>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h4>
                                <p>
                                    Sie haben gemäß Art. 77 DSGVO das Recht, eine Beschwerde bei der
                                    für Sie zuständigen Aufsichtsbehörde einzureichen.
                                </p>

                                <h4>Empfänger von Daten</h4>
                                <p>
                                    Die Verarbeitung Ihrer personenbezogenen Daten erfolgt zum Teil
                                    auch durch Auftragsverarbeiter. Diese werden ausschließlich auf
                                    der Grundlage einer Vereinbarung zu Auftragsvereinbarung nach
                                    Maßgabe von Art. 28 Abs. 3 DSGVO einbezogen.
                                </p>

                                <h4>Datenübermittlung in Drittländer</h4>
                                <p>
                                    Die personenbezogenen Daten, die wir von Ihnen erheben, werden
                                    nicht [B,M|H2] in Drittländer außerhalb des Europäischen
                                    Wirtschaftsraumes übermittelt.{' '}
                                </p>

                                <h4>Liste externer Auftragsdatenverarbeiter</h4>

                                <h4>Amazon Web Services</h4>
                                <p>
                                    oculid GmbH nutzt für die Speicherung der erhobenen Daten den
                                    Dienst Amazon Web Services („AWS“) der Amazon Web Services,
                                    Inc., P.O. Box 81226, Seattle, WA 98108-1226, USA. Die Daten
                                    werden ausschließlich in einem deutschen Rechenzentrum
                                    (Frankfurt/Main) gespeichert, das nach ISO 27001, 27017 und 2018
                                    sowie PCI DSS Level 1 zertifiziert ist. Es bestehen unsererseits
                                    eng begrenzte Zugriffsrechte und die Daten werden automatisch
                                    verschlüsselt. Nähere Informationen zu AWS und zum Datenschutz
                                    finden Sie unter
                                    https://aws.amazon.com/de/compliance/eu-data-protection/ sowie
                                    unter https://aws.amazon.com/de/privacy/
                                    <br />
                                </p>

                                <h4>Google Firebase</h4>
                                <p>
                                    Wir verwenden Firebase - einen von Google Inc. angebotenen
                                    Dienst. 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA,
                                    angeboten wird. Firebase liefert uns Informationen über die
                                    Nutzung der Anwendungen. An Firebase werden die folgenden
                                    Informationen übermittelt:
                                    <br />
                                </p>

                                <ul>
                                    <li className="defaultlist">
                                        Informationen über das Gerät, wie z.B.: Geräte-ID, Modell
                                        und Gerätetyp, Name und Version des Betriebssystems
                                    </li>
                                    <li className="defaultlist">
                                        Nutzungsdauer und Verweildauer in einzelnen
                                        Anwendungsbereichen
                                    </li>
                                    <li className="defaultlist">
                                        Status der Kalibrierung und des Testfortschritts
                                    </li>
                                    <li className="defaultlist">Ort (Land) der Nutzung</li>
                                    <li className="defaultlist">Version der Anwendung</li>
                                </ul>

                                <p>
                                    Die Datenschutzbestimmungen von Google finden Sie unter
                                    https://policies.google.com/privacy.
                                    <br />
                                </p>

                                <h4>Sentry</h4>
                                <p>
                                    Wir verwenden Sentry - einen von Functional Software, Inc. 45
                                    Fremont Street, 8th Floor San Francisco, CA 94105, USA
                                    angebotenen Dienst. Sentry liefert uns Informationen über
                                    Abstürze und Fehlfunktionen in Anwendungen. An Sentry werden im
                                    Falle einer Fehlfunktion oder eines Ausfalls die folgenden
                                    Informationen übermittelt:
                                    <br />
                                </p>

                                <ul>
                                    <li className="defaultlist">IP-Adresse des Gerätes;</li>
                                    <li className="defaultlist">
                                        Informationen über das Gerät, wie z.B.: Geräte-ID, Modell
                                        und Gerätetyp, Name und Version des Betriebssystems,
                                        Einstellungen und Sprache des Geräts;
                                    </li>
                                    <li className="defaultlist">
                                        Version und Funktionalität der Anwendung;
                                    </li>
                                    <li className="defaultlist">Zeitpunkt des Ausfalls.</li>
                                </ul>

                                <p>
                                    An Sentry übermittelte Daten werden weder gespeichert oder an
                                    andere Dienste oder Ressourcen übertragen. Die
                                    Datenschutzbestimmungen von Sentry finden Sie unter
                                    <a
                                        className="green__link"
                                        href="https://policies.google.com/privacy"
                                        rel="noopener noreferrer"
                                        target="_blank">
                                        {' '}
                                        https://sentry.io/privacy/
                                    </a>
                                    .
                                </p>
                            </div>
                        </li>
                    </ul>
                </section>
            )}
        </div>
    )
}

export default React.memo(PrivacyPage)
