import './faq.css'

import React, { useState } from 'react'

import Tracking from '../../Documents/Eye_tracking.csv'
import FAQ_UX from '../../Documents/FAQ_UX_testing.csv'
import GettingStarting from '../../Documents/Getting_Started.csv'
import TermsModal from '../terms/Terms.js'
import FAQCsvList from './FAQCsvList'
import { useCsv } from './useCsv'

const csvPaths = [FAQ_UX, GettingStarting, Tracking]

const FAQ = () => {
    sessionStorage.removeItem('draftID')
    const [showing, setShowing] = useState(0)
    const [showingTermsModal, setShowingTermsModal] = useState(false)
    const csv = useCsv(csvPaths)

    return (
        <div className="faq-page-flex">
            <div className="container" id="faqContainer">
                <div className="faq-header-box">
                    <div className="faq-header-title">
                        Hi
                        <span className="name-caps">{` ${localStorage.getItem('username')}`}</span>
                        ,
                        <br />
                        How can we help you?
                    </div>
                    <div className="faq-header-subtitle">
                        Explore our Frequently Asked Questions (FAQ) below
                        <br />
                        Write us at
                        <a href="mailto:support@oculid.com"> support@oculid.com </a>
                        in case you can’t find what you are looking for:
                    </div>
                </div>
                <div className="content-flex">
                    <div>
                        <ul className="content-options">
                            <div
                                className={`faq-card testing ${showing === 0 ? 'active' : ''}`}
                                onClick={() => setShowing(0)}>
                                UX testing with oculid
                            </div>
                            <div
                                className={`faq-card ${showing === 1 ? 'active' : ''}`}
                                onClick={() => setShowing(1)}>
                                Getting started
                            </div>
                            <div
                                className={`faq-card ${showing === 2 ? 'active' : ''}`}
                                onClick={() => setShowing(2)}>
                                Eye tracking
                            </div>
                        </ul>
                    </div>
                    <div className="content-details">
                        {showing === 0 && (
                            <>
                                <FAQCsvList csv={csv[FAQ_UX]} />
                                <div className="faq-body-text">
                                    <div className="faq-body-text-header">Data Privacy</div>
                                    <div className="faq-body-text-body">
                                        Data privacy of customers and testers is very important to
                                        us. For this reason customers will only be provided with a
                                        subject ID but not with personal information about the
                                        tester. Similarly, video data of the testers will be deleted
                                        right after the extraction of the gaze position. Please
                                        check out our
                                        <span
                                            className="green__link"
                                            onClick={() => setShowingTermsModal(true)}>
                                            Terms of Use
                                        </span>
                                        <span style={{ padding: '0px 4px' }}>and</span>
                                        <a
                                            className="green__link"
                                            href="/privacy"
                                            rel="noopener noreferrer"
                                            target="_blank">
                                            Privacy policy
                                        </a>
                                        . for more details.
                                    </div>
                                    <TermsModal
                                        onCloseTermsModal={() => setShowingTermsModal(false)}
                                        showingTermsModal={showingTermsModal}
                                    />
                                    <div className="faq-body-text-header">
                                        How do I contact oculid?
                                    </div>
                                    <div className="faq-body-text-body">
                                        Send us an email at{' '}
                                        <a
                                            className="green__link"
                                            href="mailto:sales@oculid.com"
                                            rel="noopener noreferrer"
                                            target="_blank">
                                            sales@oculid.com{' '}
                                        </a>
                                        to learn more about our tool or at{' '}
                                        <a
                                            className="green__link"
                                            href="mailto:support@oculid.com"
                                            rel="noopener noreferrer"
                                            target="_blank">
                                            support@oculid.com{' '}
                                        </a>
                                        if you are in need of support with the use of the platform
                                        or the app.
                                    </div>
                                </div>
                            </>
                        )}
                        {showing === 1 && <FAQCsvList csv={csv[GettingStarting]} />}
                        {showing === 2 && <FAQCsvList csv={csv[Tracking]} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(FAQ)
