import React from 'react'

const SecondaryButton = ({ className, children, title, onClick, color }) => {
    return (
        <button
            className={`secondary-button ${className} secondary-button-${color}-color`}
            onClick={onClick}>
            {title || children || 'Action'}
        </button>
    )
}

export default SecondaryButton
