/**
 * Helper function that formats the screener list as required by the backend API.
 */
export function formatScreeners(screeners) {
    return screeners != null
        ? screeners.map((screener) => {
              const retval = {
                  name: screener.name,
                  text: screener.question,
                  type: screener.type
              }
              if (screener.type === 'dropdown') {
                  retval.options = JSON.stringify(screener.options)
              }
              return retval
          })
        : null
}

/**
 * Helper function that formats the task list as required by the backend API.
 */
export function formatId(tasks) {
    let tasksToReturn = []

    tasks.forEach((task, i) => {
        if (!task.isEmpty) {
            const taskObj = {
                description: task.description,
                duration: task.duration,
                name: task.name,
                pause_recording: task.pauseRecording,
                rating: task.rating,
                rating_text: task.ratingText,
                task_index: i,
                task_id: tasksToReturn.length,
                tol: task.tol,
                tol_instructions: task.tolInstructions
            }

            if (task.isABTest) {
                const twoTasks = [{ ...taskObj }, { ...taskObj }]
                twoTasks[0].task_group = 1
                twoTasks[1].task_group = 2
                twoTasks[1].task_id += 1

                tasksToReturn = tasksToReturn.concat(twoTasks)
            } else {
                taskObj.task_group = 0

                tasksToReturn.push(taskObj)
            }
        }
    })

    return tasksToReturn
}

/**
 * Helper function that formats the task list as required by the backend API.
 */
export function formatTasks(tasks) {
    let tasksToReturn = []

    tasks.forEach((task, i) => {
        if (!task.isEmpty) {
            const taskObj = {
                description: task.description,
                duration: task.duration,
                name: task.name,
                pause_recording: task.pauseRecording,
                rating: task.rating,
                rating_text: task.ratingText,
                task_index: i,
                task_id: tasksToReturn.length,
                tol: task.tol,
                tol_instructions: task.tolInstructions
            }

            if (task.isABTest) {
                const twoTasks = [{ ...taskObj }, { ...taskObj }]
                twoTasks[0].task_group = 1
                twoTasks[1].task_group = 2
                twoTasks[1].task_id += 1
                for (const idx in task.media) {
                    const key = task.media[idx].key
                    const value = task.media[idx].value
                    if (key === 'url') {
                        twoTasks[idx].url = value
                    } else if (key === 'file') {
                        if (value == null) {
                            twoTasks[idx].image = null
                            twoTasks[idx].video = null
                        } else {
                            if (typeof value !== 'string') {
                                twoTasks[idx][value.type.startsWith('image') ? 'image' : 'video'] =
                                    value
                            } else {
                                let newValue = value
                                if (value.startsWith('http')) {
                                    const splitStr = newValue
                                        .substring(0, newValue.indexOf('?'))
                                        .split('/')
                                    newValue = splitStr[splitStr.length - 1]
                                }
                                twoTasks[idx][newValue.endsWith('.mp4') ? 'video' : 'image'] =
                                    newValue
                            }
                        }
                    }
                }
                tasksToReturn = tasksToReturn.concat(twoTasks)
            } else {
                taskObj.task_group = 0
                taskObj.url = task.url
                if (task.file != null) {
                    if (typeof task.file !== 'string') {
                        taskObj[task.file.type.startsWith('image') ? 'image' : 'video'] = task.file
                    } else {
                        let newTaskFile = task.file
                        if (newTaskFile.startsWith('http')) {
                            const splitStr = newTaskFile
                                .substring(0, newTaskFile.indexOf('?'))
                                .split('/')
                            newTaskFile = splitStr[splitStr.length - 1]
                        }
                        taskObj[newTaskFile.endsWith('.mp4') ? 'video' : 'image'] = newTaskFile
                    }
                } else {
                    taskObj.image = null
                    taskObj.video = null
                }
                tasksToReturn.push(taskObj)
            }
        }
    })

    return tasksToReturn
}

/**
 * Helper function that formats a test object as required by the backend API.
 */
export function formatTest(
    active,
    companyLogo,
    currentConsentForm,
    description,
    emailContact,
    stage,
    numTesters,
    usingWelcomeMessage,
    welcomeMessage,
    usingThankyouMessage,
    thankyouMessage,
    name,
    currentPrivacyPolicy,
    formatTasks,
    taskScheme,
    tasks,
    screeners
) {
    const testObj = {
        active,
        company_logo: companyLogo,
        consent_form: currentConsentForm.id,
        description,
        contact_email: emailContact,
        max_num_testers: stage === 'review' ? 3 : numTesters,
        message_welcome: usingWelcomeMessage ? welcomeMessage : '',
        message_thankyou: usingThankyouMessage ? thankyouMessage : '',
        name,
        privacy_policy: currentPrivacyPolicy.id,
        stage,
        tasks: formatTasks(tasks),
        task_scheme: taskScheme
    }
    if (screeners != null) {
        testObj.screener = formatScreeners(screeners)
    }
    return testObj
}
