import React from 'react'

const WiredButton = ({ children, onPress, onClick, isSecondary = false }) => {
    return (
        <button
            className={`wired-button ${isSecondary ? 'secondary-wired' : ''}`}
            onClick={onPress || onClick}>
            {children}
        </button>
    )
}

export default WiredButton
