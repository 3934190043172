import './_overview.scss'

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { fullName, getUserFromStorage } from '../../data/entities/user'
import dashboardIllustration from '../../images/dashboard-illustration.svg'
import { NavigationContext, NavigationContextItem } from '../../navigation/navigate'
import { apiGetTestData, apiGetTestParams } from '../../services/api/apiTests'
import { userPilotIdentify } from '../../services/api/userPilot'
import { addToLocalStorage, loadFromStorage, storageUserKey } from '../../utilities/storage'
import { areTestsEmpty, filterTests, isTestCreationAllowed } from '../../utilities/uxTest'
import PrimaryButton from '../atoms/buttons/PrimaryButton'
import useGetCredits from '../layout/custom_hooks/useGetCredits'
import InvitationModal from '../organisms/modals/InvitationModal'
import OverviewTestsTable from '../organisms/OverviewTestsTable'
import { refreshToken } from '../utils/api'
import { getComparator, stableSort } from '../utils/functions'
import LoaderSpinner from '../utils/LoaderSpinner'
import { testStatus } from '../utils/uxTest'
import SubscriptionNeededModal from './SubscriptionNeededModal'

const EmptyOverviewCont = styled.div`
    ${{ width: '280px', textAlign: 'center' }}
`
const EmptyButtonCont = styled.div`
    ${{ width: '100%', display: 'flex', justifyContent: 'center' }}
`

/**
 * Functional component for Overview.js page (root page for logged-in user showing overview of their tests).
 */
const OverviewPage = ({ setPlan, plan, setExpires, setRenews, ...props }) => {
    let resizeTimer

    const tableCellWidth = {
        test: '30%',
        date: '25%',
        participants: '13%',
        status: '12%',
        actions: '20%'
    }
    const navigate = useNavigate()
    const { navState, setNavState } = React.useContext(NavigationContext)

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [allowedTestParams, setAllowedTestParams] = useState({})
    const [invitationModalOpen, setInvitationModalOpen] = useState(null)
    const [searchQuery, setSearchQuery] = useState('')
    const [tests, setTests] = useState(null)
    const [filteredTests, setFilteredTests] = useState(null)
    const [isLoaded, setIsLoaded] = useState(null)
    const [shouldOpenSubscriptionNeededModal, setShouldOpenSubscriptionNeededModal] =
        useState(false)

    useGetCredits(props, setPlan, setExpires, setRenews)

    /**
     * Connect with Userpilot
     */
    useEffect(() => {
        userPilotIdentify(props)

        if (!tests || tests?.length < 1) {
            const storedTests = loadFromStorage(storageUserKey(storageUserKey('tests')))
            if (storedTests && storedTests.length > 0) {
                setTests(storedTests)
            }
        }
    })

    /**
     * Create window resize listener to determine which breakpoint we are in for rendering.
     */
    useEffect(() => {
        window &&
            window.addEventListener('resize', () => {
                clearTimeout(resizeTimer)
                resizeTimer = setTimeout(() => {
                    setWindowWidth(window.innerWidth)
                }, 150)
            })
    })

    /**
     * Compute derivative values to do with which tests are visible (vs which are filtered)
     */
    useEffect(() => {
        if (tests !== null) {
            if (searchQuery !== '') {
                setFilteredTests(
                    tests.filter((test) =>
                        (test.name != null ? test.name : '').toLowerCase().includes(searchQuery)
                    )
                )
            } else {
                setFilteredTests(tests)
            }
        }
    }, [tests, searchQuery])

    /**
     * Load tests & test params from server on page load.
     */
    useEffect(() => {
        sessionStorage.removeItem('draftID')
        const isMounted = true

        // refresh access token
        new Promise((resolve, reject) => {
            refreshToken(props, resolve, reject)
        }).then((token) => {
            // load params/test bounds the current user is permitted to use (depending on payment plan etc.)
            new Promise((resolve, reject) => {
                if (tests === null) {
                    apiGetTestParams(token, resolve, reject).then((response) => {
                        const loadedTests = response && response.results ? response.results : []
                        if (loadedTests.length > 0) {
                            setTests(filterTests(loadedTests, searchQuery))
                            addToLocalStorage(storageUserKey('tests'), loadedTests)
                            if (
                                !isTestCreationAllowed(loadedTests, allowedTestParams) &&
                                navState !== NavigationContextItem.DISABLED_TEST_CREATION
                            ) {
                                setNavState(NavigationContextItem.DISABLED_TEST_CREATION)
                            }
                        }
                    })
                }
            }).then((res) => {
                if (isMounted) {
                    setAllowedTestParams(res)
                }
            })

            // load all tests
            new Promise((resolve, reject) => {
                if (tests === null) {
                    apiGetTestData(process.env.REACT_APP_URL + 'test/', token, [], resolve, reject)
                }
            })
                .then((res) => {
                    const newTests = stableSort(res, getComparator('desc', 'id')) // order tests newest first
                    for (const testIdx in newTests) {
                        newTests[testIdx].status = testStatus(newTests[testIdx])
                    }
                    if (isMounted) {
                        if (newTests.length > 0) {
                            addToLocalStorage(storageUserKey('tests'), newTests)
                        }
                        setTests(filterTests(newTests, searchQuery))
                        if (
                            !isTestCreationAllowed(newTests, allowedTestParams) &&
                            navState !== NavigationContextItem.DISABLED_TEST_CREATION
                        ) {
                            setNavState(NavigationContextItem.DISABLED_TEST_CREATION)
                        }
                        setIsLoaded(true)
                    }
                })
                .catch((err) => {
                    console.log('Error loading tests', err, setIsLoaded)
                    setIsLoaded(true)
                })
        })
    })

    /**
     * If open modal val in sessionStorage is true, open the last created test
     */
    useEffect(() => {
        if (!tests) return
        const openModal = sessionStorage.getItem('open_modal_for_test')
        if (!openModal) {
            updateTestCreationLimit()
            return
        }
        let idOfMostRecentTest
        tests.forEach((test) => {
            if (test.name === openModal) {
                idOfMostRecentTest = test.id
            }
        })
        setInvitationModalOpen(idOfMostRecentTest)
    }, [tests])

    const updateTestCreationLimit = () => {
        console.log('navState', navState)

        if (
            tests?.length < 1 &&
            plan !== 'free' &&
            navState === NavigationContextItem.DISABLED_TEST_CREATION
        ) {
            return
        }

        if (plan !== 'free' && navState !== NavigationContextItem.MAIN_SIDE_BAR) {
            return setNavState(NavigationContextItem.MAIN_SIDE_BAR)
        }
    }

    const onCreateTest = () => {
        if (plan === 'free' && tests.length >= 1) {
            setNavState(NavigationContextItem.TEST_CREATION_LIMIT)
            setShouldOpenSubscriptionNeededModal(true)
            return
        }

        navigate('/create')
    }

    return (
        <div id="overviewContainer" className={'overview-container'}>
            {/* If ID from opening invitation matches test ID, open correct modal */}
            {tests &&
                tests.map((test, idx) => {
                    if (test && test.id === invitationModalOpen) {
                        return (
                            <InvitationModal
                                key={idx}
                                setInvitationModalOpen={setInvitationModalOpen}
                                test={tests[idx]}
                            />
                        )
                    }
                    return null
                })}
            {/* Header */}
            {!areTestsEmpty(tests) && (
                <div className="overview__header">
                    <div className="overview__header__title">
                        Hi {fullName(getUserFromStorage(), true)},
                    </div>
                </div>
            )}
            {/* are we still loading the tests? */}
            {!isLoaded === true && (
                <div className="overviewLoaderSpinner">
                    <LoaderSpinner />
                </div>
            )}
            {/* {isLoaded === true && tests && tests.length < 1 && ( */}
            {isLoaded === true && areTestsEmpty(tests) && (
                <div className="empty-overview">
                    <EmptyOverviewCont>
                        <h2
                            style={{
                                fontWeight: '800'
                            }}>{`Hi ${localStorage.getItem('username')}`}</h2>
                        <div style={{ height: '8px' }} />
                        <div>
                            {
                                'Start creating a new test for mobile phones, and review it (try it) with up to 3 people for free'
                            }
                        </div>
                        <div style={{ height: '24px' }} />
                        <img
                            style={{ zoom: '0.8' }}
                            alt="empty_overview_icon"
                            src={dashboardIllustration}
                        />
                        <div style={{ height: '40px' }} />
                        <EmptyButtonCont>
                            <PrimaryButton onClick={() => navigate('/create')}>
                                {'+ Create test'}
                            </PrimaryButton>
                        </EmptyButtonCont>
                    </EmptyOverviewCont>
                </div>
            )}

            {isLoaded === true && !areTestsEmpty(tests) && (
                <OverviewTestsTable
                    tableCellWidth={tableCellWidth}
                    windowWidth={windowWidth}
                    tests={tests}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    filteredTests={filteredTests}
                    setInvitationModalOpen={setInvitationModalOpen}
                    props={props}
                    setTests={setTests}
                    plan={plan}
                    onCreateTest={onCreateTest}
                />
            )}

            {shouldOpenSubscriptionNeededModal && (
                <SubscriptionNeededModal
                    subscriptionNeededModalOpen={shouldOpenSubscriptionNeededModal}
                    onClickCancel={() => setShouldOpenSubscriptionNeededModal(false)}
                    onClickClose={() => setShouldOpenSubscriptionNeededModal(false)}
                />
            )}
        </div>
    )
}

export default OverviewPage
