import React, { useEffect } from 'react'

import emptyCommentsImg from '../../images/empty_comments_image.svg'
import moreIcon from '../../images/more_icon.svg'
import ClickOutside from '../utils/ClickOutside'
import {
    onAddNewComment,
    onAddNewCommentEnter,
    onBlurEditingComment,
    onClickCommentTime,
    onClickDeleteCommentInMenu,
    onClickEditCommentInMenu,
    onClickExistingComment,
    onClickFlagIconInMenu,
    onEditExistingComment,
    onEditNewComment,
    onKeyDownEditingComment
} from './CommentEventHandlers'

/** TODO: Clear formatting issues and enable eslint */
/* eslint-disable */
const CommentSection = (props) => {
    const {
        comments,
        setComments,
        commenting,
        setCommenting,
        commentBeingEdited,
        setCommentBeingEdited,
        videoRef,
        secondsToMinutesAndSeconds,
        currEditingCommentText,
        setCurrEditingCommentText,
        flagMap,
        commentActionMenuOpen,
        setCommentActionMenuOpen,
        newCommentRef,
        newCommentText,
        setNewCommentText,
        testerID,
        stableSort,
        getComparator,
        commentHover,
        setCommentHover,
        setPlaybackOptionsVisible,
        windowHeight
    } = props

    /**
     * Comment Input Field focusses when comment button selected
     */
    useEffect(() => {
        if (commenting) {
            document.getElementById('commentInputField').focus()
        }
    }, [commenting])

    return (
        <div>
            <div
                className={`comment__section ${comments.length === 0 ? 'empty' : ''}`}
                style={{
                    height: 'calc(50vh - 32px)',
                    padding: '0px 25px'
                }}>
                <div className="comments__header"> Comments</div>

                {comments.length === 0 ? (
                    <div className="empty__comments__image">
                        {windowHeight > 720 && (
                            <img alt="empty_comments_img" src={emptyCommentsImg} />
                        )}
                        <div className="empty__comments__text">
                            To create a note for this participant, pause the video at the selected
                            time, write your comment and add it by clicking on the button below.
                        </div>
                    </div>
                ) : (
                    <div className="comments__list__wrapper">
                        <div
                            className="comments__list"
                            style={{ height: 'calc((50vh - 32px) - 32px)' }}>
                            <ul style={{ margin: '0px' }}>
                                {comments.map((comment, idx) => (
                                    <div key={idx}>
                                        <li
                                            className={`comment ${idx === comments.length - 1 ? 'last' : ''} ${idx === commentBeingEdited ? 'editable' : ''}`}
                                            onClick={() =>
                                                onClickCommentTime(comment.time, videoRef)
                                            }
                                            onMouseOver={() => {
                                                setCommentHover(idx)
                                                setPlaybackOptionsVisible(true)
                                            }}
                                            onMouseLeave={() => {
                                                setCommentHover(null)
                                                if (
                                                    videoRef.current &&
                                                    videoRef.current.state.videoPlaying
                                                ) {
                                                    setPlaybackOptionsVisible(false)
                                                }
                                            }}
                                            style={{
                                                filter:
                                                    commentHover === idx ? 'brightness(0.9)' : '',
                                                border: '1px solid rgb(235, 236, 240)',
                                                borderRadius: '30px',
                                                overflow: 'hidden'
                                            }}>
                                            <div
                                                id={'time' + comment.time}
                                                className="comment__time">
                                                {secondsToMinutesAndSeconds(comment.time)}
                                            </div>
                                            {idx === commentBeingEdited ? (
                                                <div
                                                    className="comment__text editable"
                                                    contentEditable={true}
                                                    id={`commentText${idx}`}
                                                    onBlur={() =>
                                                        onBlurEditingComment(
                                                            currEditingCommentText,
                                                            comments,
                                                            commentBeingEdited,
                                                            setComments,
                                                            props,
                                                            setCommentBeingEdited,
                                                            setCurrEditingCommentText
                                                        )
                                                    }
                                                    onInput={(e) =>
                                                        onEditExistingComment(
                                                            e,
                                                            setCurrEditingCommentText
                                                        )
                                                    }
                                                    onKeyDown={(e) =>
                                                        onKeyDownEditingComment(
                                                            e,
                                                            setCommentBeingEdited,
                                                            setCurrEditingCommentText
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <div
                                                    className="comment__text"
                                                    id={`commentText${idx}`}
                                                    onClick={() =>
                                                        onClickExistingComment(
                                                            idx,
                                                            commentBeingEdited,
                                                            setCommentBeingEdited,
                                                            setCurrEditingCommentText,
                                                            comments
                                                        )
                                                    }>
                                                    {comment.text}
                                                </div>
                                            )}

                                            {comment.flag !== 0 && (
                                                <img
                                                    alt="flag__icon"
                                                    className="comment__flag"
                                                    src={flagMap[comment.flag]}
                                                />
                                            )}

                                            <img
                                                alt="more_icon"
                                                className="more__icon"
                                                onClick={() => setCommentActionMenuOpen(idx)}
                                                src={moreIcon}
                                            />
                                            {commentActionMenuOpen === idx && (
                                                <ClickOutside
                                                    onClick={() => setCommentActionMenuOpen(null)}>
                                                    <div className="comment__action__menu">
                                                        <div className="text__row">
                                                            <span
                                                                className="text"
                                                                onClick={() =>
                                                                    onClickEditCommentInMenu(
                                                                        idx,
                                                                        setCommentActionMenuOpen
                                                                    )
                                                                }>
                                                                edit
                                                            </span>
                                                        </div>
                                                        <div className="text__row">
                                                            <span
                                                                className="text"
                                                                onClick={() =>
                                                                    onClickDeleteCommentInMenu(
                                                                        idx,
                                                                        setCommentActionMenuOpen,
                                                                        comments,
                                                                        setComments,
                                                                        props
                                                                    )
                                                                }>
                                                                delete
                                                            </span>
                                                        </div>
                                                        <div className="flag__row">
                                                            {Object.keys(flagMap).map((flagIdx) => (
                                                                <img
                                                                    alt="flag_icon"
                                                                    key={flagIdx}
                                                                    onClick={() =>
                                                                        onClickFlagIconInMenu(
                                                                            idx,
                                                                            flagIdx,
                                                                            setCommentActionMenuOpen,
                                                                            comments,
                                                                            setComments,
                                                                            props
                                                                        )
                                                                    }
                                                                    src={flagMap[flagIdx]}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </ClickOutside>
                                            )}
                                        </li>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
            <div className="new__comment__section" style={{ height: 'calc(20vh - 32px)' }}>
                <div style={{ width: '100%', height: '32px' }}></div>
                <div
                    style={{
                        width: '100%',
                        height: 'calc(100% - 32px)',
                        padding: '16px 25px',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                    <div
                        id="commentInputField"
                        className="input__field new__comment"
                        style={{ width: '69%', outline: commenting ? '2px solid #4B8CF7' : '' }}
                        contentEditable={true}
                        onClick={() => {
                            setCommenting(true)
                            setPlaybackOptionsVisible(true)
                        }}
                        onKeyDown={(e) => onAddNewCommentEnter(e, newCommentRef)}
                        onInput={(e) => onEditNewComment(e, videoRef, setNewCommentText)}
                        ref={newCommentRef}
                    />
                    <button
                        className="button primary add__comment__button"
                        style={{ width: '29%' }}
                        disabled={newCommentText === ''}
                        onClick={() => {
                            onAddNewComment(
                                newCommentText,
                                testerID,
                                videoRef,
                                props,
                                comments,
                                setComments,
                                stableSort,
                                getComparator,
                                setNewCommentText,
                                newCommentRef
                            )
                            setCommenting(false)
                        }}>
                        <div className="button__text">Add comment</div>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CommentSection
