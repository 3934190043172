import './testertable.scss'

import { ThemeProvider } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import { createTheme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'

import TesterTableHead from './TesterTableHead'

/**
 *
 * @param {*} props
 */
const TesterTableTabs = (props) => {
    const { isAbTesting, onChangeTableView, tableView } = props
    return (
        <div className={`table__tabs ${isAbTesting ? 'ab' : ''}`}>
            <div className="tabs" style={{ display: 'flex' }}>
                <div className="tab">
                    <div
                        className={`tab__text ${tableView === 0 ? 'active' : ''}`}
                        onClick={() => onChangeTableView(0)}>
                        Detailed
                    </div>
                </div>
                <div className="tab">
                    <div
                        className={`tab__text ${tableView === 1 ? 'active' : ''}`}
                        onClick={() => onChangeTableView(1)}>
                        Tasks
                    </div>
                </div>
                <div className="tab">
                    <div
                        className={`tab__text ${tableView === 2 ? 'active' : ''}`}
                        onClick={() => onChangeTableView(2)}>
                        Participants
                    </div>
                </div>
                {isAbTesting && (
                    <div className="tab">
                        <div
                            className={`tab__text ${tableView === 3 ? 'active' : ''}`}
                            onClick={() => onChangeTableView(3)}>
                            A/B
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

// not sure how this works to create rows of height 64px, but it does...
const theme = createTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '4px 8px'
            }
        }
    }
})

/**
 * Dashboard table containing attributes of the complete list of testers in a single test.
 */
const TesterTable = (props) => {
    const {
        headerCells,
        isAbTest,
        onChangeRowSelection,
        onChangeTableView,
        onSelectAllRows,
        onSortTable,
        order,
        orderBy,
        selectedRows,
        tableView,
        tableViewData
    } = props
    const rowIsSelected = (rowIdx) => selectedRows.indexOf(rowIdx) !== -1
    const emptyRows = tableViewData.length < 5 ? Array(5 - tableViewData.length).fill(0) : []

    return (
        <div style={{ marginTop: '24px' }}>
            <TesterTableTabs
                isAbTesting={isAbTest}
                onChangeTableView={onChangeTableView}
                tableView={tableView}
            />

            <div className="tester__table">
                <ThemeProvider theme={theme}>
                    <Table
                        aria-label="sticky table"
                        aria-labelledby="tableTitle"
                        id="testerTable"
                        size="medium"
                        stickyHeader
                        style={{ backgroundColor: '#ededed' }}
                        className="test-results-table">
                        <TesterTableHead
                            headerCells={headerCells}
                            numSelected={selectedRows.length}
                            onSelectAllRows={onSelectAllRows}
                            onSortTable={onSortTable}
                            order={order}
                            orderBy={orderBy}
                            rowCount={tableViewData.length}
                            tableView={tableView}
                        />

                        <TableBody>
                            {tableViewData.map((rowData) => {
                                const rowSelected = rowIsSelected(rowData.index)
                                const labelId = `enhanced-table-checkbox-${rowData.index}`
                                const rowStatus = rowData.pauseRecording
                                    ? 'disabled'
                                    : rowData.status
                                      ? 'complete'
                                      : 'incomplete'
                                const rowStatusCaps = rowData.pauseRecording
                                    ? 'Not Recorded'
                                    : rowData.status
                                      ? 'Complete'
                                      : 'Incomplete'
                                return (
                                    <TableRow
                                        aria-checked={rowData.pauseRecording ? false : rowSelected}
                                        className={`result-table-row ${rowData.pauseRecording ? 'disabled' : ''}`}
                                        hover
                                        key={rowData.index}
                                        onClick={() =>
                                            !rowData.pauseRecording && onChangeRowSelection(rowData)
                                        }
                                        role="checkbox"
                                        selected={rowData.pauseRecording ? false : rowSelected}
                                        tabIndex={-1}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={
                                                    rowData.pauseRecording ? false : rowSelected
                                                }
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </TableCell>

                                        {tableView !== 3 && (
                                            <TableCell align="left">
                                                <div className={`row__status ${rowStatus}`}>
                                                    {rowStatusCaps}
                                                </div>
                                            </TableCell>
                                        )}

                                        <TableCell
                                            className="index__cell"
                                            component="th"
                                            id={labelId}
                                            scope="row">
                                            {tableView === 3
                                                ? rowData.index
                                                : tableView === 1 && isAbTest
                                                  ? rowData.task + 1
                                                  : rowData.index + 1}
                                        </TableCell>

                                        {/* The body of the thing */}
                                        {headerCells
                                            .slice(tableView === 3 ? 1 : 2, headerCells.length)
                                            .map((headerCell, idx) => (
                                                <TableCell
                                                    align="left"
                                                    className={`${headerCell.whitespaceStringCell ? 'whitespace__string__cell' : ''}`}
                                                    key={idx}
                                                    style={{ width: '0px' }}>
                                                    {rowData[`${headerCell.id}Str`]}
                                                </TableCell>
                                            ))}
                                    </TableRow>
                                )
                            })}
                            {emptyRows.map((elt, idx) => (
                                <TableRow
                                    aria-checked={false}
                                    key={`empty__row__${idx}`}
                                    role="checkbox"
                                    selected={false}
                                    tabIndex={-1}>
                                    <TableCell padding="checkbox"> &nbsp; </TableCell>
                                    <TableCell component="th" scope="row" />
                                    {headerCells.slice(2, headerCells.length).map((_, idx) => (
                                        <TableCell align="left" key={idx} />
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </ThemeProvider>
            </div>
        </div>
    )
}

export default React.memo(TesterTable)
