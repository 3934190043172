import './LoaderSpinner.css'

import React from 'react'

import pageLoadIcon from '../../images/page_loading_icon.svg'

export default function LoaderSpinner() {
    return (
        <div style={{ zoom: '0.8' }}>
            <div className="loading-div">
                <img className="loading-img" alt="page_loading_icon" src={pageLoadIcon} />
            </div>
        </div>
    )
}
