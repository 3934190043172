import './screenercard.css'

import Modal from '@material-ui/core/Modal'
import * as d3 from 'd3'
import React from 'react'

import contractViewIcon from '../../images/contract_view_icon.svg'
import emptyScreenerIcon from '../../images/empty_screener_icon.svg'
import expandViewIcon from '../../images/expand_view_icon.svg'
import { getComparator, stableSort, upperCaseFirstChar } from '../utils/functions'
import Tooltip from '../utils/Tooltip'
import PieChart from './PieChart'

let pieChartColours = [
    '#4B8CF7',
    '#FFF78F',
    '#E1999F',
    '#FF62B0',
    '#74D780',
    '#FF444F',
    '#4C36D3',
    '#E2E8F2',
    '#000A64',
    '#74FBD8'
]
// eslint-disable-next-line import/namespace
pieChartColours = pieChartColours.concat(d3.schemeCategory10)

/**
 *
 * @param {*} props
 */
const ScreenerCardControls = (props) => {
    const { disabled, viewIcon, setShowingExpandedScreener } = props
    return (
        <div className={`screener__card__controls ${disabled ? 'disabled' : ''}`}>
            <img
                alt={`${viewIcon}_view_icon`}
                className="control__button"
                onClick={() => !disabled && setShowingExpandedScreener(viewIcon === 'expand')}
                src={viewIcon === 'expand' ? expandViewIcon : contractViewIcon}
            />
        </div>
    )
}

/**
 *
 * @param {*} myArray
 * @param {*} chunks
 */
function breakArrayIntoEvenChunks(myArray, chunks = 5) {
    const result = Array.from(Array(chunks), (i) => [])
    return myArray.reduce((accum, item, index) => {
        accum[index % chunks].push(item)
        return accum
    }, result)
}

/**
 * Handle names of screener data in expanded view
 */
const screenerNameFunc = (name, customText) => {
    switch (name) {
        case 'location':
            return upperCaseFirstChar('country')
        case 'custom':
            return upperCaseFirstChar(customText)
        default:
            return upperCaseFirstChar(name)
    }
}

/**
 *
 * @param {*} props
 */
const ScreenerCard = (props) => {
    let tvIdx
    const {
        isNarrowScreen,
        screeners,
        screenerVisible,
        selectedRows,
        setScreenerVisible,
        setShowingExpandedScreener,
        setSurveyTabActive,
        showingExpandedScreener,
        surveyTabActive,
        tableView,
        tableViewData,
        test
    } = props

    const weAreAGo = surveyTabActive === 'screeners' && tableView === 2 && selectedRows.length !== 0

    // make sure all testers screener questions are ordered the same + rename location --> country...
    if (weAreAGo) {
        for (const sIdx3 in screeners) {
            if (screeners[sIdx3].name === 'location') {
                screeners[sIdx3].name = 'country'
            }
        }
        for (tvIdx in tableViewData) {
            for (const sIdx2 in tableViewData[tvIdx].screeners) {
                if (tableViewData[tvIdx].screeners[sIdx2].name === 'location') {
                    tableViewData[tvIdx].screeners[sIdx2].name = 'country'
                }
            }
            if (tableViewData[tvIdx].screeners !== null) {
                tableViewData[tvIdx].screeners = stableSort(
                    tableViewData[tvIdx].screeners,
                    getComparator('asc', 'name')
                )
            }
        }
    }

    const sortedScreeners = stableSort(screeners, getComparator('asc', 'name'))

    const screenerData = []
    const dataPoints = []
    if (weAreAGo) {
        for (const sIdx in sortedScreeners) {
            screenerData[sIdx] = {}
            dataPoints[sIdx] = []
            let key = null

            const screener = sortedScreeners[sIdx]
            for (const i in selectedRows) {
                const selectedRowIndex = selectedRows[i]
                if (tableViewData[tvIdx] !== null) {
                    const screenersArr = tableViewData.find(
                        (t) => t.index === selectedRowIndex
                    ).screeners
                    if (screenersArr) {
                        key = screenersArr[sIdx].value
                        if (screener.name === 'age') {
                            key = key.replace(' years old', '')
                        } else if (screener.name === 'gender' && key === 'I prefer not to share') {
                            key = 'Undisclosed'
                        }
                        screenerData[sIdx][key] = screenerData[sIdx][key]
                            ? screenerData[sIdx][key] + 1
                            : 1
                    }
                }
            }

            for (const response in screenerData[sIdx]) {
                dataPoints[sIdx].push({ value: screenerData[sIdx][key], label: response })
            }

            dataPoints[sIdx] = stableSort(dataPoints[sIdx], getComparator('desc', 'value')).slice(
                0,
                20
            )
        }
    }

    // only for non-expanded view (i.e. only for one piechart)
    const visibleDataPoints = dataPoints[screenerVisible]
    let numLegendColumns = null
    let legendColumns = []
    if (weAreAGo) {
        if (!showingExpandedScreener) {
            if (visibleDataPoints.length > 4) {
                const halfIndex = Math.ceil(visibleDataPoints.length / 2)
                legendColumns = [
                    visibleDataPoints.slice(0, halfIndex),
                    visibleDataPoints.slice(halfIndex, visibleDataPoints.length)
                ]
            } else {
                legendColumns.push(visibleDataPoints)
            }
        } else {
            for (const sIdx in sortedScreeners) {
                const screener = sortedScreeners[sIdx]
                if (screener.name === 'location') {
                    if (dataPoints[sIdx].length > 20) {
                        legendColumns = breakArrayIntoEvenChunks(dataPoints[sIdx], 5)
                    } else {
                        const locations = [...dataPoints[sIdx]]
                        while (locations.length) {
                            legendColumns.push(locations.splice(0, 4))
                        }
                    }
                    numLegendColumns = legendColumns.length
                }
            }
        }
    }

    return (
        <div className="card screener__card">
            <div className="screener__card__content">
                <ScreenerCardControls
                    disabled={
                        tableView !== 2 ||
                        surveyTabActive !== 'screeners' ||
                        selectedRows.length === 0
                    }
                    setShowingExpandedScreener={setShowingExpandedScreener}
                    viewIcon="expand"
                />

                <div className="tabs">
                    <div
                        className={`tab ${surveyTabActive === 'screeners' ? 'active' : ''}`}
                        onClick={() => {
                            if (test.survey === null) return
                            surveyTabActive !== 'screeners' && setSurveyTabActive('screeners')
                        }}>
                        Survey
                    </div>
                    {test.survey != null && (
                        <div
                            className={`tab ${surveyTabActive === 'survey' ? 'active' : ''}`}
                            onClick={() =>
                                surveyTabActive !== 'survey' && setSurveyTabActive('survey')
                            }>
                            Questionnaire
                        </div>
                    )}
                </div>

                <Tooltip className="screener__card__tooltip" id="screenerCardTooltipScreener">
                    Get the the demographic data of your participants. Select one or more
                    participants in the table to get a single or an aggregate view.
                </Tooltip>
                {test.survey != null && (
                    <Tooltip
                        className="screener__card__tooltip questionnaire"
                        id="screenerCardTooltipQuestionnaire">
                        Check the results of your own external questionnaire.
                    </Tooltip>
                )}

                {surveyTabActive === 'screeners' ? (
                    tableView !== 2 || selectedRows.length === 0 ? (
                        <div className="empty__screener">
                            <img
                                alt="empty_screener_icon"
                                className="empty__screener__icon"
                                src={emptyScreenerIcon}
                            />
                            <div className="empty__screener__text">
                                To see and compare survey results, please select any number of
                                participants in the participant aggregate table view.
                            </div>
                        </div>
                    ) : (
                        <div className="screener__results" id="screenersImageToDownload">
                            <div className="left__links__scroll__space">
                                <div className="left__links">
                                    {sortedScreeners.map((screener, screenerIdx) => (
                                        <div
                                            className={`link__text ${screenerVisible === screenerIdx ? 'visible' : ''}`}
                                            key={screenerIdx}
                                            onClick={() =>
                                                screenerVisible !== screenerIdx &&
                                                setScreenerVisible(screenerIdx)
                                            }>
                                            {screener.name === 'custom'
                                                ? screener.text
                                                : upperCaseFirstChar(
                                                      screener.name === 'location'
                                                          ? 'country'
                                                          : screener.name
                                                  )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div
                                className={`screener__value__card ${selectedRows.length > 1 && visibleDataPoints.length > 4 ? 'wide' : ''}`}>
                                {selectedRows.length === 1 ? (
                                    <div className="screener__value">
                                        <div className="text">
                                            {tableViewData[tvIdx].screeners &&
                                                tableViewData.find(
                                                    (t) => t.index === selectedRows[0]
                                                ).screeners[screenerVisible].value}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="screener__pie__chart">
                                        <PieChart
                                            colours={pieChartColours}
                                            data={screenerData[screenerVisible]}
                                            id="pieChart"
                                            innerRadius={0}
                                            outerRadius={isNarrowScreen ? 35 : 40}
                                        />
                                        <div className="pie__chart__legend">
                                            {legendColumns.map((columnData, columnIndex) => (
                                                <div className="legend__column" key={columnIndex}>
                                                    {columnData.map((dataPoint, dataIndex) => (
                                                        <div
                                                            className="legend__item"
                                                            key={`${columnIndex}${dataIndex}`}>
                                                            <div
                                                                className="colour"
                                                                style={{
                                                                    background:
                                                                        pieChartColours[
                                                                            columnIndex === 0
                                                                                ? dataIndex
                                                                                : legendColumns[1]
                                                                                      .length +
                                                                                  dataIndex
                                                                        ]
                                                                }}
                                                            />
                                                            <div className="text">
                                                                {dataPoint.label}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                ) : screeners.length > 0 ? (
                    <div className="questionnaire__text">
                        <div>
                            The following is the questionnaire URL you provided for your
                            participants:
                        </div>
                        <a
                            className="green__link"
                            href={`${test.survey.url}`}
                            rel="noopener noreferrer"
                            target="_blank">
                            {' '}
                            {test.survey.url}{' '}
                        </a>
                    </div>
                ) : (
                    <div className="questionnaire__text">
                        <div>You have not collected any survey data</div>
                    </div>
                )}
            </div>

            <Modal
                aria-labelledby="expanded-screener-modal"
                className="expanded__screener__modal"
                open={showingExpandedScreener}
                onClose={() => setShowingExpandedScreener(false)}>
                <div className="inner__div">
                    <div className="modal__content">
                        {showingExpandedScreener && (
                            <div className="screener__card__content">
                                <ScreenerCardControls
                                    disabled={
                                        tableView !== 2 ||
                                        surveyTabActive !== 'screeners' ||
                                        selectedRows.length === 0
                                    }
                                    setShowingExpandedScreener={setShowingExpandedScreener}
                                    viewIcon="contract"
                                />

                                <div className="card__title">Survey</div>
                                <Tooltip
                                    className="screener__card__tooltip"
                                    id="screenerCardTooltipScreener">
                                    Get the the demographic data of your participants. Select one or
                                    more participants in the table to get a single or an aggregate
                                    view.
                                </Tooltip>
                                <div id="screenersImageToDownload">
                                    {sortedScreeners.map((screener, screenerIdx) => (
                                        <div
                                            className={`screener__pie__chart ${screener.name === 'location' ? 'bottom' : 'top'} ${sortedScreeners.length === 1 || (sortedScreeners.length === 2 && legendColumns.length === 0) ? 'only__one' : ''}`}
                                            key={screenerIdx}>
                                            <div className="chart__name">
                                                {screenerNameFunc(screener.name, screener.text)}
                                            </div>
                                            <PieChart
                                                className={`${screener.name === 'location' ? `doot${numLegendColumns}` : ''}`}
                                                colours={pieChartColours}
                                                data={screenerData[screenerIdx]}
                                                id="pieChart"
                                                innerRadius={0}
                                                outerRadius={80}
                                            />
                                            <div className={'pie__chart__legend'}>
                                                {(screener.name === 'location'
                                                    ? legendColumns
                                                    : [dataPoints[screenerIdx]]
                                                ).map((columnData, columnIndex) => (
                                                    <div
                                                        className="legend__column"
                                                        key={columnIndex}>
                                                        {columnData.map((dataPoint, dataIndex) => (
                                                            <div
                                                                className="legend__item"
                                                                key={`${columnIndex}${dataIndex}`}>
                                                                <div
                                                                    className="colour"
                                                                    style={{
                                                                        background:
                                                                            pieChartColours[
                                                                                columnIndex === 0
                                                                                    ? dataIndex
                                                                                    : legendColumns[1]
                                                                                          .length +
                                                                                      dataIndex
                                                                            ]
                                                                    }}
                                                                />
                                                                <div className="text">
                                                                    {dataPoint.label}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default React.memo(ScreenerCard)
