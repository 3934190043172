import { useNavigate } from 'react-router-dom'

/**
 * Functional component for LogOut endpoint.
 */
const LogOut = () => {
    const navigate = useNavigate()
    const url = `${process.env.REACT_APP_URL_BASE}token/expire/`
    const refreshToken = { refresh: `${localStorage.getItem('refresh')}` }
    const token = localStorage.getItem('token')
    const tokenExp = localStorage.getItem('tokenExp')

    const newToken = async () => {
        try {
            const res = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `JWT ${token}`
                },
                body: JSON.stringify(refreshToken)
            })

            const shouldRememberMe = localStorage.getItem('rememberMe') ?? false
            console.log('shouldRememberMe: ', shouldRememberMe)

            if (tokenExp * 1000 < Date.now()) {
                if (!shouldRememberMe) {
                    localStorage.removeItem('email')
                }
                localStorage.removeItem('token')
                localStorage.removeItem('username')
                navigate('/login')
            } else if (res.status === 205) {
                if (!shouldRememberMe) {
                    localStorage.removeItem('email')
                }
                localStorage.removeItem('token')
                localStorage.removeItem('username')
                navigate('/login')
            }
        } catch (e) {
            console.log('Error during logout: ', e.message)
        }
    }

    newToken()

    return ''
}

export default LogOut
