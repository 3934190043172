// eslint-disable-next-line import/named
import { csv } from 'd3'
import { useEffect, useState } from 'react'

export const useCsv = (requestedCsvPaths) => {
    const [fetchCsv, setFetchCsv] = useState({})
    useEffect(() => {
        Promise.all(requestedCsvPaths.map((path) => csv(path))).then((csvs) =>
            csvs.forEach((csv, idx) =>
                setFetchCsv((fetchCsv) => ({
                    ...fetchCsv,
                    [requestedCsvPaths[idx]]: csv
                }))
            )
        )
    }, [requestedCsvPaths])
    return fetchCsv
}
