import React, { useState } from 'react'

import { navigate } from '../../navigation/navigate'
import CloseButton from '../atoms/buttons/CloseButton'
import PrimaryButton from '../atoms/buttons/PrimaryButton'
import SecondaryButton from '../atoms/buttons/SecondaryButton'
import PasswordInput from '../molecules/forms/PasswordInput'
import { sendChangePasswordRequest } from '../utils/apiLogging'

/**
 * Component to render user account page
 * @param {*} props
 */
const AccountOverviewPage = (props) => {
    sessionStorage.removeItem('draftID')
    const [activePassword, setActivePassword] = useState(null)
    const [curPassword, setCurPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [currError, setCurrError] = useState('')
    const [newError, setNewError] = useState('')

    const token = localStorage.getItem('token')

    const isFormValid = () => {
        let hasErrors = false

        if (curPassword.length < 1) {
            setCurrError('Please enter your current password')
            hasErrors = true
        }
        if (newPassword.length < 1) {
            setNewError('Please enter your new password')
            hasErrors = true
        }
        if (newPassword.length < 8) {
            setNewError('Password must be at least 8 characters long')
            hasErrors = true
        }

        return !hasErrors
    }

    return (
        <div id="accountOverview" className="account-overview">
            <div className="account-card">
                <CloseButton onClick={() => navigate('/overview')} />
                <h1>My account</h1>

                <div className="info__row">
                    <div>Name:</div>
                    <div>{localStorage.getItem('username')}</div>
                </div>

                <div className="info__row">
                    <div>Email:</div>
                    <div>{localStorage.getItem('email')}</div>
                </div>

                <form className={'account-form'}>
                    <div className="password__change__row">
                        <PasswordInput
                            active={activePassword}
                            id="curPassword"
                            labelText="Current password"
                            onChange={(e) => {
                                setCurPassword(e.target.value)
                                setCurrError(null)
                            }}
                            error={currError}
                            placeholder=""
                            setActive={setActivePassword}
                            value={curPassword}
                            charCheck={false}
                            icon={true}
                            autoComplete={'new-password'}
                        />
                        <PasswordInput
                            active={activePassword}
                            id="newPassword"
                            labelText="New password"
                            onChange={(e) => {
                                setNewPassword(e.target.value)
                                setNewError(null)
                            }}
                            error={newError}
                            placeholder=""
                            setActive={setActivePassword}
                            value={newPassword}
                            charCheck={true}
                            icon={true}
                            autoComplete={'new-password'}
                        />
                    </div>

                    <div className="button__row">
                        <SecondaryButton
                            onClick={(e) => {
                                e.preventDefault()
                                navigate('/overview')
                            }}>
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton
                            onClick={(e) => {
                                e.preventDefault()
                                if (isFormValid()) {
                                    sendChangePasswordRequest(
                                        e,
                                        props,
                                        token,
                                        curPassword,
                                        setCurPassword,
                                        setCurrError,
                                        newPassword,
                                        setNewPassword,
                                        setNewError
                                    )
                                }
                            }}
                            style={{ width: '65%' }}>
                            Save New Password
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AccountOverviewPage
