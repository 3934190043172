import React from 'react'

const ForewardArrow = ({ isActive = false, size = 24, color = '#177268' }) => {
    return (
        <svg
            width={size}
            height={size}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.3094 3.69064C10.1385 3.51979 9.8615 3.51979 9.69064 3.69064C9.51979 3.8615 9.51979 4.1385 9.69064 4.30936L12.9441 7.56281H2C1.75838 7.56281 1.5625 7.75868 1.5625 8.00031C1.5625 8.24193 1.75838 8.43781 2 8.43781H12.9435L9.69064 11.6906C9.51979 11.8615 9.51979 12.1385 9.69064 12.3094C9.8615 12.4802 10.1385 12.4802 10.3094 12.3094L14.3094 8.30936C14.4802 8.1385 14.4802 7.8615 14.3094 7.69064L10.3094 3.69064Z"
                fill={color}
            />
        </svg>
    )
}
export default ForewardArrow
