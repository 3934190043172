import {
    sendDeleteRequest,
    sendPatchRequest,
    sendPostRequest,
    wrapRequestInRefreshToken
} from '../components/utils/api'
import { objectToFormData } from '../components/utils/functions'
import { navigate } from '../navigation/navigate'
import { loadFromStorage, storageUserKey } from './storage'
/**
 * Callback function to handle the user deleting a test.
 * @param {*} testID the id of the test to delete.
 * @param {*} testIdx the idx of the test to delete.
 */
export const onDeleteTest = (testID, testIdx, tests, setTests, props) => {
    const successFunc = () => {
        const newTests = [...tests]
        newTests.splice(testIdx, 1)
        setTests(newTests)
    }
    const errorFunc = (err) => err.json().then((body) => console.log(body))
    wrapRequestInRefreshToken(props, sendDeleteRequest, ['test', testID, successFunc, errorFunc])
}

/**
 *
 * @param {*} testID the id of the test to duplicate.
 * @param testName
 * @param setTestDuplicating
 * @param tests
 * @param setTests
 * @param props
 */
export const onDuplicateTest = (testID, testName, setTestDuplicating, tests, setTests, props) => {
    setTestDuplicating(testID)
    const formData = objectToFormData({ id: testID, name: `${testName} copy` }, null)

    const successFunc = (res) => {
        res.stage = 'draft'
        res.draft = true
        res.count = 0
        setTests([res, ...tests])
        setTestDuplicating(null)
    }

    const errorFunc = (err) => err.json().then((body) => console.log(body))
    wrapRequestInRefreshToken(props, sendPostRequest, [
        `test/${testID}/duplicate`,
        formData,
        successFunc,
        errorFunc
    ])
}

/**
 * Edit Test
 * @param {*} testID
 */
export const onEditTest = (testID) => {
    sessionStorage.setItem('draftID', testID)
    navigate('/create')
}

/**
 * Pause Test
 * @param {*} testID the id of the test to pause.
 * @param {*} testIdx the idx of the test to pause.
 * @param tests
 * @param setTests
 * @param setUpdating
 * @param props
 */
export const onPauseTest = (testID, testIdx, tests, setTests, setUpdating, props) => {
    setUpdating(testIdx)
    const data = objectToFormData({ stage: 'paused' })
    const successFunc = (res) => {
        const newTests = [...tests]
        newTests[testIdx] = res
        setTests(newTests)
        setUpdating(null)
    }
    const errorFunc = (err) => err.json().then((body) => console.log(body))
    wrapRequestInRefreshToken(props, sendPatchRequest, [
        'test',
        testID,
        data,
        successFunc,
        errorFunc
    ])
}

/**
 *  * @param {string} testID - The ID of the test to launch
 *  * @param {number} testIdx - The index of the test
 *  * @param {function} setUpdating - Function to set updating state
 *  * @param {string} multiPersonDevice - The ID of the multi-person device
 *  * @param {Array} filteredTests - Array of filtered tests
 *  * @param {function} setFilteredTests - Function to set filtered tests
 *  * @param {object} props - Additional props
 */
export const onLaunchLiveTest = (
    testID,
    testIdx,
    setUpdating,
    multiPersonDevice,
    filteredTests,
    setFilteredTests,
    props
) => {
    const data = objectToFormData({
        stage: 'live',
        max_num_testers: 10000,
        allow_multiple_participants: multiPersonDevice === testID
    })

    const successFunc = (res) => {
        setUpdating(null)

        if (res) {
            sessionStorage.removeItem('draftID')
            const newFilteredTests = [...filteredTests]
            const newRes = { ...res, count: 0 }
            newFilteredTests[testIdx] = newRes
            setFilteredTests(newFilteredTests)
        }
    }
    const errorFunc = async (body) => {
        const errorMessage = await body.json()
        console.log('launch test error, ', errorMessage)
        alert('Cannot launch: \n' + errorMessage.detail || '')
    }
    wrapRequestInRefreshToken(props, sendPatchRequest, [
        'test',
        testID,
        data,
        successFunc,
        errorFunc
    ])
}

/**
 * Resume Test from 'paused' state
 * @param {*} testID the id of the test to resume.
 * @param {*} testIdx the idx of the test to resume.
 * @param setUpdating
 * @param tests
 * @param setTests
 * @param props
 */
export const onResumeTest = (testID, testIdx, setUpdating, tests, setTests, props) => {
    setUpdating(testIdx)
    const data = objectToFormData({
        stage: tests[testIdx].max_num_testers === 3 ? 'review' : 'live'
    })
    const successFunc = (res) => {
        const newTests = [...tests]
        newTests[testIdx] = res
        setTests(newTests)
        setUpdating(null)
    }
    const errorFunc = (err) => err.json().then((body) => console.log('error func', body))
    wrapRequestInRefreshToken(props, sendPatchRequest, [
        'test',
        testID,
        data,
        successFunc,
        errorFunc
    ])
}

export const filterTests = (tests, searchQuery) => {
    if (searchQuery && searchQuery.length > 0) {
        const filtered = tests.filter((test) => {
            return test.name.toLowerCase().includes(searchQuery.toLowerCase())
        })
        return filtered
    }
    return tests
}

export const areTestsEmpty = (tests) => {
    if (!tests) {
        if (loadFromStorage(storageUserKey('tests'))?.length > 0) {
            return false
        }

        return true
    }
    return tests.length < 1
}

export const isTestCreationAllowed = (tests, params) => {
    const { max_num_tests: maxAllowedTests = 0 } = params || {}

    if (tests && tests.length >= maxAllowedTests) {
        return false
    }

    return true
}

export const isTestContentChanged = (test, originalTest) => {
    return JSON.stringify(test) !== JSON.stringify(originalTest)
}
