import '../../components/auth/auth.scss'

import React, { useState } from 'react'
import styled from 'styled-components'

import PrimaryButton from '../../components/atoms/buttons/PrimaryButton'
import Separator from '../../components/atoms/layout/Separator'
import BottomLink from '../../components/atoms/links/BottomLink'
import BrandTitle from '../../components/atoms/text/BrandTitle'
import TextInput from '../../components/molecules/forms/TextInput'
import { requestReset } from '../../components/utils/apiLogging'

const Background = styled.div.attrs({ className: 'background' })``
const LoginContainer = styled.div.attrs({ className: 'container' })`
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`
const LoginBox = styled.div.attrs({ className: 'row' })`
    border: 1px solid #ebecf0;
    border-radius: 16px;
    width: 428px;
    background-color: white;
    box-shadow: 0 5px 20px -8px rgb(0 0 0 / 14%);
`

const SmallContainer = styled.div`
    transform: scale(0.9);
`
const Form = styled.form`
    margin: 0;
    //min-width: 320px;
    padding: ${(props) =>
        props.viewPort === 'DESKTOP' ? '40px' : props.viewPort === 'MOBILE' ? '16px' : '0px'};
    padding-top: 2rem;
    padding: 1rem EXTERNAL_FRAGMENT_0 EXTERNAL_FRAGMENT_0;
`

const Header = styled.div`
    font-weight: 800;
    margin: 14px 0 24px 0;
    font-size: 1.75rem;
`
const SubHeader = styled.div`
    margin-bottom: 1rem;
    font-size: 16px;
`

const ResetForm = (props) => {
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [emailSuccess, setEmailSuccess] = useState(false)

    return (
        <Form
            onSubmit={(e) => requestReset(e, email, setEmailError, setEmailSuccess)}
            viewPort={props.viewPort}>
            <BrandTitle isVertical={true} />

            {!emailSuccess ? (
                <>
                    <Header> Reset password </Header>
                    <SubHeader>
                        Please enter the email adress that you used to create your Tobii UX Explore
                        account.
                    </SubHeader>
                    <TextInput
                        label={'Email'}
                        error={emailError}
                        onChange={(e) => {
                            setEmail(e.target.value)
                            setEmailError('')
                        }}
                        placeholder="Type your email"
                        value={email}
                        icon={props.viewPort === 'DESKTOP'}
                        autoComplete={'email'}
                    />
                    <Separator height={32} />
                    <PrimaryButton style={{ width: '100%' }} type="submit">
                        Reset Password
                    </PrimaryButton>
                </>
            ) : (
                <>
                    <Header> Check your inbox! </Header>
                    <SubHeader style={{ fontSize: '18px' }}>
                        We have sent an email to {email} to reset your password.
                    </SubHeader>
                </>
            )}
            <Separator height={32} />
            <BottomLink message={'Here by mistake?'} link={'/login'} linkText={'Login'} />
        </Form>
    )
}

const ResetPage = (props) => {
    const width = props.windowWidth

    if (width < 340) {
        return (
            <SmallContainer>
                <ResetForm viewPort={'SMALL'} />
            </SmallContainer>
        )
    } else if (width < 428) {
        return <ResetForm viewPort={'MOBILE'} />
    } else {
        return (
            <>
                <Background />
                <LoginContainer id="loginContainer">
                    <LoginBox>
                        <ResetForm viewPort={'DESKTOP'} />
                    </LoginBox>
                </LoginContainer>
            </>
        )
    }
}

export default ResetPage
