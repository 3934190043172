import './taskcard.css'

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import durationIconGreen from '../../images/timer_icon_green.svg'
import { checkABTaskKeyword, checkSingleTaskKeyword } from './TaskCardFunctions'

const Height8px = styled.div`
    height: 8px;
`

const timeTooltip = 'Set a maximum duration for this task. In seconds.'

const TaskCardDuration = ({
    idx,
    onBlurTaskAttribute,
    onChangeTaskAttribute,
    task,
    taskErrors
}) => {
    const [appStimulus, setAppStimulus] = useState(false)

    const isDurationError =
        'duration' in taskErrors && (!task.isABTest || taskErrors.duration[0 || 1]) != null

    /**
     * Read formatting of URL to see if is actually an App
     */
    useEffect(() => {
        if (task.isABTest === true) {
            checkABTaskKeyword(0, 'packagename.', task, setAppStimulus, onChangeTaskAttribute, idx)
            checkABTaskKeyword(1, 'packagename.', task, setAppStimulus, onChangeTaskAttribute, idx)
        } else {
            checkSingleTaskKeyword('packagename.', task, setAppStimulus, onChangeTaskAttribute, idx)
        }
    }, [task, idx, onChangeTaskAttribute])

    return (
        <div
            style={{
                opacity: appStimulus ? '0.5' : '1',
                pointerEvents: appStimulus ? 'none' : ''
            }}>
            <h5>{'Duration (seconds)'}</h5>
            <Height8px />
            <div className="duration">
                <img
                    alt="duration icon"
                    src={durationIconGreen}
                    style={{
                        position: 'absolute',
                        margin: '5px 8px',
                        filter: `saturate(${!task.duration ? '0' : '1'})`
                    }}
                />
                {/* <div className={`seconds__text ${task.duration === "" ? 'placeholder' : ''}`}>s</div> */}
                <input
                    className={`duration__button task__duration task__attr__input ${isDurationError ? 'error' : ''}`}
                    maxLength={4}
                    minLength={0}
                    name={'task-duration'}
                    onBlur={(e) => {
                        if (e.target.value === '') {
                            onChangeTaskAttribute(idx, 'duration', null, null)
                            onBlurTaskAttribute(idx, 'duration', null, null)
                        } else {
                            onBlurTaskAttribute(idx, 'duration', e.target.value, null)
                        }
                    }}
                    onChange={(e) => {
                        const stringValue = e.target.value
                        if (stringValue === '') {
                            onChangeTaskAttribute(idx, 'duration', stringValue, null)
                        } else {
                            const numValue = Number(stringValue)
                            if (stringValue.length < 4 && !isNaN(numValue)) {
                                onChangeTaskAttribute(idx, 'duration', numValue, null)
                            }
                        }
                    }}
                    placeholder={''}
                    type="text"
                    value={task.duration ? task.duration : ''}
                    style={{ height: '28px', width: '80px', paddingLeft: '32px' }}
                />
                <div className="duration__tooltip"> {timeTooltip} </div>
            </div>
        </div>
    )
}

export default TaskCardDuration
