// import '../../components/auth/auth.scss'

import { getCode, getNames } from 'country-list'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ContentContainer from '../../components/atoms/layout/ContentContainer'
import FormContainer from '../../components/atoms/layout/FormContainer'
import Separator from '../../components/atoms/layout/Separator'
import BottomLink from '../../components/atoms/links/BottomLink'
import BrandTitle from '../../components/atoms/text/BrandTitle'
import Checkbox from '../../components/auth/Checkbox'
import DropdownInput from '../../components/auth/DropdownInput'
import PasswordInput from '../../components/molecules/forms/PasswordInput'
import TextInput from '../../components/molecules/forms/TextInput'
import { signUp } from '../../components/utils/apiLogging'
import { BackgroundType } from '../../data/enums/BackgroundType'
import { privacyPolicyLink, termsAndConditionsLink } from '../../data/enums/externalLinks'

// const FormContainer = styled.div.attrs({ className: 'row' })``

const SmallContainer = styled.div`
    transform: scale(0.9);
`
const Form = styled.form`
    margin: 0px;
    min-width: 320px;
    padding: ${(props) =>
        props.viewPort === 'DESKTOP' ? '40px' : props.viewPort === 'MOBILE' ? '16px' : '0px'};
    padding-top: 1rem;
    padding: 1rem EXTERNAL_FRAGMENT_0 EXTERNAL_FRAGMENT_0;
`

const Header = styled.div`
    font-weight: 800;
    margin: 24px 0px 24px 0px;
    font-size: 1.75rem;
`

const CheckBoxContainer = styled.div`
    display: flex;
`
const CheckBoxText = styled.div`
    margin-left: 8px;
    font-size: 14px;
`
const Button = styled.button.attrs({ className: 'primary auth' })`
    width: 100%;
    height: 48px;
    margin-top: 16px;
`
const ButtonText = styled.div.attrs({ className: 'button__text' })`
    padding: 0px 24px 0px 24px;
`
const HelperText = styled.div`
    color: #ff6262;
    font-size: 13px;
    padding-left: 20px;
`
//
// const BottomLink = styled.div`
//    width: 100%;
//    text-align: center;
//    font-size: 14px;
// `
// const BottomLinkText = styled.a`
//    color: #4b8cf7;
//    text-decoration: underline;
//
//    &:hover {
//        color: #6ecbc8;
//    }
// `

const options = [
    'Market Researcher',
    'User Experience Researcher',
    'Education (Teacher/Student)',
    'Other'
]
const formattedOptions = ['market_researcher', 'user_experience_researcher', 'education', 'other']

const countries = getNames()
const countriesToExclude = [
    'Belarus',
    'Cuba',
    'Iran, Islamic Republic of',
    "Korea, Democratic People's Republic of",
    'Russian Federation',
    'Sudan',
    'South Sudan',
    'Syrian Arab Republic'
]
const filteredCountries = countries.filter((country) => !countriesToExclude.includes(country))
const filteredAndSortedCountries = filteredCountries.slice().sort((a, b) => a.localeCompare(b))

const SignUpForm = (props) => {
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const [username, setUsername] = useState('')
    const [usernameError, setUsernameError] = useState('')

    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')

    const [company, setCompany] = useState('')
    const [companyError, setCompanyError] = useState('')

    const [occupation, setOccupation] = useState('')
    const [occupationError, setOccupationError] = useState('')

    const [country, setCountry] = useState('')
    const [countryCode, setCountryCode] = useState('')
    const [countryError, setCountryError] = useState('')

    const [termsStatus, setTermsStatus] = useState('unchecked')

    const [detailError, setDetailError] = useState('')

    const signupFunc = (e) => {
        signUp(
            e,
            password,
            setPasswordError,
            username,
            setUsernameError,
            email,
            setEmailError,
            company,
            setCompanyError,
            occupation,
            setOccupationError,
            occupationFormatter,
            countryCode,
            setCountryError,
            termsStatus,
            setTermsStatus,
            setDetailError
        )
    }

    /**
     * Take Occupation Val and format for backend
     * @param {*} occ
     * @returns
     */
    const occupationFormatter = (occ) => {
        let formatted
        options.map((option, idx) => {
            if (option === occ) {
                formatted = formattedOptions[idx]
            }
            return formattedOptions[idx]
        })
        return formatted
    }

    useEffect(() => {
        if (!country) return
        setCountryCode(getCode(country))
    }, [country])

    return (
        <Form onSubmit={(e) => signupFunc(e)} viewPort={props.viewPort}>
            <BrandTitle />

            <Header> Start testing for free </Header>

            <TextInput
                label={'Full Name'}
                error={usernameError}
                onChange={(e) => {
                    setUsername(e.target.value)
                    setUsernameError(null)
                }}
                placeholder="Enter your full name"
                value={username}
                icon={props.viewPort === 'DESKTOP'}
                autoComplete={'full-name'}
            />
            <Separator height={16} />
            <DropdownInput
                label={'Occupation'}
                error={occupationError}
                setError={setOccupationError}
                onChange={(value) => {
                    setOccupation(value)
                    setOccupationError(null)
                }}
                placeholder="Select your main occupation"
                value={occupation}
                icon={props.viewPort === 'DESKTOP'}
                options={options}
            />

            <Separator height={72} />

            <TextInput
                label={'Work email'}
                error={emailError}
                onChange={(e) => {
                    setEmail(e.target.value)
                    setEmailError(null)
                }}
                placeholder="Type your work email"
                value={email}
                icon={props.viewPort === 'DESKTOP'}
                autoComplete={'email'}
            />
            <Separator />

            <TextInput
                label={'Company'}
                error={companyError}
                onChange={(e) => {
                    setCompany(e.target.value)
                    setCompanyError(null)
                }}
                placeholder="The name of your company"
                value={company}
                icon={props.viewPort === 'DESKTOP'}
                autoComplete={'off'}
            />

            <div style={{ height: '16px' }} />

            <PasswordInput
                id="password"
                error={passwordError}
                labelText="Password"
                onChange={(e) => {
                    setPassword(e.target.value)
                    setPasswordError(null)
                }}
                placeholder="Type password"
                value={password}
                icon={props.viewPort === 'DESKTOP'}
                charCheck={true}
                autoComplete={'new-password'}
            />

            <div style={{ height: '16px' }} />

            <DropdownInput
                label={'Country'}
                error={countryError}
                setError={setCountryError}
                onChange={(value) => {
                    setCountry(value)
                    setCountryError(null)
                }}
                placeholder="Select your country"
                value={country}
                icon={props.viewPort === 'DESKTOP'}
                options={filteredAndSortedCountries}
            />

            <div style={{ height: '72px' }} />

            <CheckBoxContainer>
                <Checkbox
                    id={'terms'}
                    status={termsStatus}
                    onClick={() => {
                        termsStatus === 'unchecked' || termsStatus === 'error'
                            ? setTermsStatus('checked')
                            : setTermsStatus('unchecked')
                        termsStatus === 'error' && setDetailError('')
                    }}
                />
                <CheckBoxText>
                    <label
                        className={'inline'}
                        htmlFor={'terms'}
                        onClick={() =>
                            setTermsStatus(termsStatus === 'checked' ? 'unchecked' : 'checked')
                        }>
                        I accept the
                    </label>
                    <a
                        href={termsAndConditionsLink}
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{ color: '#4B8CF7' }}>
                        {' terms of service'}
                    </a>
                </CheckBoxText>
            </CheckBoxContainer>

            <div style={{ height: '4px' }} />

            {detailError && <HelperText className="helper-text">{detailError}</HelperText>}

            <div style={{ height: '4px' }} />

            <Button>
                <ButtonText>Sign Up</ButtonText>
            </Button>

            <BottomLink message={'Already a member? '} link={'/login'} linkText={'Login'} />
            <a
                href={privacyPolicyLink}
                rel="noopener noreferrer"
                target="_blank"
                className={'policy-link'}>
                <span>See our</span>
                <span>Privacy policy</span>
            </a>
        </Form>
    )
}

const SignUpPage = (props) => {
    const width = props.windowWidth

    if (width < 340) {
        return (
            <SmallContainer>
                <SignUpForm viewPort={'SMALL'} />
            </SmallContainer>
        )
    } else if (width < 428) {
        return <SignUpForm viewPort={'MOBILE'} />
    } else {
        return (
            <ContentContainer className={'background1'} backType={BackgroundType.BLUE}>
                <FormContainer>
                    <SignUpForm viewPort={'DESKTOP'} />
                </FormContainer>
            </ContentContainer>
        )
    }
}

export default SignUpPage
