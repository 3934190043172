/**
 * Check if stimulus is app if this media field in an AB task has a value
 * @param {*} thisABMedia
 * @param {*} otherABMedia
 */
export function checkIfABTaskMediaHasValueTrue(
    thisABMedia,
    otherABMedia,
    keyword,
    task,
    setAppStimulus,
    clearDurationFunc,
    idx
) {
    if (typeof task.media[thisABMedia].value === 'string') {
        if (task.media[thisABMedia].value.includes(keyword)) {
            setAppStimulus(true)
            if (task.duration !== null) {
                clearDurationFunc(idx, 'duration', null, null)
            }
        } else {
            // This logic is broken but only applies to hidden app feature and should be revisited

            // if (task.media[otherABMedia].value) { if (!task.media[otherABMedia].value.includes(keyword)) { setAppStimulus(false) } }
            // else { setAppStimulus(false) }
            setAppStimulus(false)
        }
    }
}

/**
 * Check if other media field in AB task has no value
 * @param {*} otherABMedia
 * @param {*} keyword
 */
export function checkIfABTaskMediaHasValueFalse(otherABMedia, keyword, task, setAppStimulus) {
    if (task.media[otherABMedia].value) {
        if (typeof task.media[otherABMedia].value === 'string') {
            if (!task.media[otherABMedia].value.includes(keyword)) {
                setAppStimulus(false)
            }
        }
    } else {
        setAppStimulus(false)
    }
}

/**
 * Checker to see if this media in the AB task is an app
 * @param {*} thisABMedia
 * @param {*} keyword
 */
export function checkABTaskKeyword(
    thisABMedia,
    keyword,
    task,
    setAppStimulus,
    clearDurationFunc,
    idx
) {
    const otherABMedia = thisABMedia === 0 ? 1 : 0
    if (task.media[thisABMedia].value) {
        checkIfABTaskMediaHasValueTrue(
            thisABMedia,
            otherABMedia,
            keyword,
            task,
            setAppStimulus,
            clearDurationFunc,
            idx
        )
    } else {
        checkIfABTaskMediaHasValueFalse(otherABMedia, keyword, task, setAppStimulus)
    }
}

/**
 * Checker to see if this media in the single task is an app
 * @param {*} keyword
 * @param {*} task
 * @param {*} setAppStimulus
 * @param {*} clearDurationFunc
 * @param {*} idx
 */
export function checkSingleTaskKeyword(keyword, task, setAppStimulus, clearDurationFunc, idx) {
    if (task.url !== null) {
        if (task.url.includes(keyword)) {
            setAppStimulus(true)
            if (task.duration !== null) {
                clearDurationFunc(idx, 'duration', null, null)
            }
        } else {
            setAppStimulus(false)
        }
    } else {
        setAppStimulus(false)
    }
}
