import './taskcard.css'

import React from 'react'
import styled from 'styled-components'

import imageIconBlue from '../../images/image_icon/image_icon_blue.svg'
import linkIconBlue from '../../images/link_icon_blue.svg'
import CloseButton from '../atoms/buttons/CloseButton'
import Separator from '../atoms/layout/Separator'

const Height8px = styled.div`
    height: 8px;
`

const urlTooltip = 'Add the link to any prototype or URL that you want to test.'
const imageTooltip =
    'Add an image or video that you want to test. The maximum file size is 2 MB for images and 20 MB for videos.'

const TaskCardMedia = (props) => {
    const { idx, onBlurTaskAttribute, onChangeTaskAttribute, onChangeTaskFile, task, taskErrors } =
        props

    let showingUrl = false
    let showingFile = false
    let mediaObj = null

    if (task) {
        if (task.file == null) {
            showingUrl = true
            if (mediaObj == null) {
                mediaObj = task.url
            }
        }
        if (task.url == null) {
            showingFile = true
            if (mediaObj == null) {
                if (typeof task.file === 'string') {
                    const splitStr = task.file.substring(0, task.file.indexOf('?')).split('/')
                    mediaObj = splitStr[splitStr.length - 1]
                } else {
                    mediaObj = task.file
                }
            }
        }
    }

    const onClickMediaIcon = () => {
        document.body.onfocus = function () {
            setTimeout(() => {
                document.body.onfocus = null
            }, 100)
        }
    }

    return (
        <div className="bottom__row" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="left__side">
                <h5>{'Media'}</h5>
                <Height8px />
                {console.log('task url', taskErrors[idx].url)}
                <div className={'media-container'}>
                    {showingUrl &&
                        (mediaObj != null ? (
                            <div className="input__wrapper url">
                                <input
                                    name={'task-url'}
                                    className={`task__url task__attr__input ${taskErrors[idx].url ? 'error' : ''}`}
                                    onBlur={(e) =>
                                        onBlurTaskAttribute(
                                            idx,
                                            'url',
                                            e.target.value === '' ? null : e.target.value,
                                            null
                                        )
                                    }
                                    onChange={(e) =>
                                        onChangeTaskAttribute(idx, 'url', e.target.value, null)
                                    }
                                    placeholder="Specify url link"
                                    type="text"
                                    defaultValue={mediaObj || ''}
                                />
                                <CloseButton
                                    onClick={() => {
                                        onChangeTaskAttribute(idx, 'url', null, null)
                                        onBlurTaskAttribute(idx, 'url', null, null)
                                    }}
                                    top={-15}
                                    right={0}
                                />
                            </div>
                        ) : (
                            <div>
                                <button
                                    className="secondary"
                                    onClick={() => {
                                        onChangeTaskAttribute(idx, 'url', '', null)
                                        onBlurTaskAttribute(idx, 'url', '', null)
                                    }}
                                    style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        alt="url_icon"
                                        src={linkIconBlue}
                                        style={{ height: '16px' }}
                                    />
                                    <div style={{ marginLeft: '8px' }}>{'URL'}</div>
                                </button>
                                <div className="media__tooltip"> {urlTooltip} </div>
                            </div>
                        ))}

                    <Separator width={8} />

                    {showingFile && (
                        <div className={`${mediaObj ? 'input__wrapper file' : ''}`}>
                            <button
                                className={`secondary ${mediaObj ? 'task__file task__attr__input' : 'file icon__button'}`}
                                id={`fileUploadButtonDiv${idx}`}
                                onClick={() => {
                                    document.getElementById(`fileUploadButton${idx}`).click()
                                }}
                                style={{ display: 'flex', alignItems: 'center' }}>
                                <img alt="image_icon" src={imageIconBlue} />

                                <div style={{ marginLeft: '8px', color: '#5995F8' }}>{'Media'}</div>

                                <input
                                    className="file__upload__button"
                                    id={`fileUploadButton${idx}`}
                                    onClick={() => onClickMediaIcon()}
                                    onChange={(e) => onChangeTaskFile(e, idx, '', null)}
                                    type="file"
                                    value=""
                                />

                                {mediaObj && (
                                    <div className="text">
                                        {typeof mediaObj === 'string' ? mediaObj : mediaObj.name}
                                    </div>
                                )}
                            </button>

                            <div className="media__tooltip"> {imageTooltip} </div>

                            {mediaObj && (
                                <CloseButton
                                    onClick={(e) => {
                                        onChangeTaskFile(e, idx, null, null)
                                        onBlurTaskAttribute(idx, 'file', null, null)
                                    }}
                                    top={-15}
                                    right={-15}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default React.memo(TaskCardMedia)
