import './header.scss'

import React, { useState } from 'react'
import styled from 'styled-components'

import { fullName } from '../../data/entities/user'
import userIcon from '../../images/user_icon.svg'
import { navigate } from '../../navigation/navigate'
import CreditsModal from '../credits/CreditsModal'
import Dropdown from '../molecules/DropDownMenu/Dropdown'
import { apiLogOut } from '../utils/apiLogging'
import useGetCredits from './custom_hooks/useGetCredits'

const HeaderWrapper = styled.div.attrs({ className: 'header-wrapper' })``

const CreditsContainer = styled.div`
    display: flex;
    justify-content: right;
    padding-top: 20px;
`

const dropdownMenuOptions = [
    { text: 'My Account', value: 'myAccount' },
    { text: 'Plan & Billing', value: 'billing' },
    { text: 'Log out', value: 'logout' }
]

/**
 * Component for the custom Oculid header (displayed at the top of the screen on every page).
 */
const Header = (props) => {
    const { setPlan, setExpires, setRenews, creditsModalVisible, setCreditsModalVisible, user } =
        props

    const [showingDropdownMenu, setShowingDropdownMenu] = useState(false)

    const [privateRoute] = useGetCredits(props, setPlan, setExpires, setRenews)

    /**
     * Event handler when user clicks on option in user account dropdown.
     */
    const onSelectMenuOption = (e) => {
        // setShowingDropdownMenu(false)
        const liElt = e.target.nodeName === 'DIV' ? e.target.parentElement : e.target
        const menuOption = liElt.getAttribute('value')

        switch (menuOption) {
            case 'myAccount':
                return navigate('/account')

            case 'billing':
                return navigate('/billing')

            case 'logout':
                return apiLogOut({ ...props })

            default:
                console.log('houston, we have a problem')
        }
    }

    return (
        <HeaderWrapper>
            <div className="header">
                {privateRoute && (
                    <div className="header__inner">
                        <CreditsContainer>
                            <div className="user__dropdown__wrapper">
                                <button
                                    className="user-avatar-icon"
                                    onClick={() => {
                                        setShowingDropdownMenu(!showingDropdownMenu)
                                    }}>
                                    <img
                                        alt="account_icon"
                                        className="account__icon"
                                        src={userIcon}
                                    />
                                </button>
                                <Dropdown
                                    className="user__dropdown"
                                    menuOptions={dropdownMenuOptions}
                                    noDropdownIcon={false}
                                    onSelectMenuOption={onSelectMenuOption}
                                    selectedRowCheckboxes={false}
                                    selectedValues={[]}
                                    setShowingDropdownMenu={(shouldOpen) => {
                                        if (shouldOpen === false && showingDropdownMenu === true) {
                                            setShowingDropdownMenu(false)
                                            return
                                        }
                                        if (shouldOpen !== false && showingDropdownMenu !== true) {
                                            setShowingDropdownMenu(true)
                                        }
                                        // if (shouldOpen === false && showingDropdownMenu === true) {
                                        //    setShowingDropdownMenu(false)
                                        //    return
                                        // }
                                        // if (
                                        //    showingDropdownMenu === false &&
                                        //    typeof shouldOpen === 'object'
                                        // ) {
                                        //    setShowingDropdownMenu(true)
                                        //    return
                                        // }
                                        // if (showingDropdownMenu === true) {
                                        //    setShowingDropdownMenu(shouldOpen)
                                        // }
                                    }}
                                    showingDropdownMenu={showingDropdownMenu}
                                    triggerText={fullName(user)}
                                />
                            </div>
                        </CreditsContainer>
                    </div>
                )}

                {creditsModalVisible && (
                    <CreditsModal
                        creditsModalVisible={creditsModalVisible}
                        setCreditsModalVisible={setCreditsModalVisible}
                    />
                )}
            </div>
        </HeaderWrapper>
    )
}

export default Header
