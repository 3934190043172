import '../../create/taskcard.css'

import React, { useEffect, useState } from 'react'
import { useDrag } from 'react-dnd'
import styled from 'styled-components'

import deleteIcon from '../../../images/delete_icon_black.svg'
import Separator from '../../atoms/layout/Separator'
import useAutoGrow from '../../create/custom_hooks/useAutoGrow'
import TaskCardDuration from '../../create/TaskCardDuration'
import TaskCardMedia from '../../create/TaskCardMedia'
import TaskCardMediaAB from '../../create/TaskCardMediaAB'
import TaskCardMediaErrors from '../../create/TaskCardMediaErrors'
import TaskCardTools from '../../create/TaskCardTools'
import CharacterCounter from '../../utils/CharacterCounter'

const Height8px = styled.div`
    height: 8px;
`
const getDragging = (props) => (props.isDragging ? '0.01' : '1')
const TaskCardWrapper = styled.div.attrs({ className: 'task__card feedback' })`
    ${{
        opacity: (props) => getDragging(props),
        backgroundColor: '#EEF4FE',
        border: '1px solid #D7D8DB'
    }}
`
const InputWrapper = styled.div`
    width: 100%;
`
const DeleteButton = styled.div.attrs({ className: 'delete__button' })`
    transform: translateY(-6px);
`
const DeleteIcon = styled.img`
    ${{ filter: 'invert(1) brightness(2)' }}
`
const getDescriptionClass = (props) => (props.description ? 'error' : '')
const DescriptionInput = styled.textarea.attrs((props) => ({
    className: `text__box description ${getDescriptionClass(props)}`
}))``
const TaskStimulusWrapper = styled.div`
    ${{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '.5rem'
    }}
`

const TaskCard = (props) => {
    const {
        idx,
        onBlurTaskAttribute,
        onChangeTaskAttribute,
        onChangeTaskBoolean,
        onChangeTaskFile,
        onDeleteTask,
        task,
        taskErrors,
        imageFormatError,
        videoFormatError,
        mediaFileSizeError,
        unrecognizedMediaError,
        moreThanOneTask
    } = props

    const [setCardLoaded, autoGrow] = useAutoGrow(task, idx)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [collected, drag] = useDrag({
        item: { idx },
        type: 'taskCard'
    })

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }
        setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <TaskCardWrapper
            id={`taskCard${idx}`}
            ref={drag}
            onLoad={() => setCardLoaded(idx)}
            isDragging={collected.isDragging}>
            <InputWrapper>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <h5>{'Task Name*'}</h5>
                    <DeleteButton onClick={() => onDeleteTask(idx)}>
                        <DeleteIcon alt="delete_icon" src={deleteIcon} />
                    </DeleteButton>
                </div>
                <Height8px />
                <input
                    id={`card-name${idx}`}
                    className={`small test-input ${taskErrors[idx].name && 'error'}`}
                    maxLength={30}
                    onBlur={(e) => onBlurTaskAttribute(idx, 'name', e.target.value)}
                    onChange={(e) => onChangeTaskAttribute(idx, 'name', e.target.value)}
                    placeholder={`${task.isABTest ? 'AB' : ''} Task name`}
                    type="text"
                    value={task.name ? task.name : ''}
                />
                <CharacterCounter
                    charLimit={30}
                    className="name"
                    numChars={task.name ? task.name.length : 0}
                />
            </InputWrapper>

            <Separator height={16} />

            <h5>{'Task Instructions'}</h5>
            <Separator height={8} />
            <DescriptionInput
                id={`taskDescription${idx}`}
                maxLength={400}
                onChange={(e) => onChangeTaskAttribute(idx, 'description', e.target.value)}
                // onInput={e => autoGrow(e.target)}
                placeholder="Please enter the instructions for this task.&#10;To best allow us to analyse the users’ behavior, provide a goal for the tester to achieve in one or more steps."
                value={task.description ? task.description : ''}
                data-gramm_editor="false"
                description={taskErrors[idx].description}
            />
            <CharacterCounter
                charLimit={400}
                className="description"
                numChars={task.description ? task.description.length : 0}
            />

            <TaskStimulusWrapper>
                {task.media ? (
                    <TaskCardMediaAB
                        idx={idx}
                        onBlurTaskAttribute={onBlurTaskAttribute}
                        onChangeTaskAttribute={onChangeTaskAttribute}
                        onChangeTaskBoolean={onChangeTaskBoolean}
                        onChangeTaskFile={onChangeTaskFile}
                        task={task}
                        taskErrors={taskErrors}
                        windowWidth={windowWidth}
                    />
                ) : (
                    <TaskCardMedia
                        idx={idx}
                        onBlurTaskAttribute={onBlurTaskAttribute}
                        onChangeTaskAttribute={onChangeTaskAttribute}
                        onChangeTaskBoolean={onChangeTaskBoolean}
                        onChangeTaskFile={onChangeTaskFile}
                        task={task}
                        taskErrors={taskErrors}
                    />
                )}

                <TaskCardTools
                    idx={idx}
                    onChangeTaskBoolean={onChangeTaskBoolean}
                    task={task}
                    windowWidth={windowWidth}
                />

                <TaskCardDuration
                    idx={idx}
                    onBlurTaskAttribute={onBlurTaskAttribute}
                    onChangeTaskAttribute={onChangeTaskAttribute}
                    task={task}
                    taskErrors={taskErrors}
                />
            </TaskStimulusWrapper>

            <TaskCardMediaErrors
                idx={idx}
                task={task}
                imageFormatError={imageFormatError}
                videoFormatError={videoFormatError}
                mediaFileSizeError={mediaFileSizeError}
                unrecognizedMediaError={unrecognizedMediaError}
                moreThanOneTask={moreThanOneTask}
            />
            <Separator height={8} />
            {task.tol && (
                <>
                    <textarea
                        id={`taskTolInstructions${idx}`}
                        className={`small test-input ${'tolInstructions' in taskErrors || !task.tolInstructions || task.tolInstructions === '' ? 'error' : ''}`}
                        maxLength={200}
                        onBlur={(e) => {
                            if (task.tolInstructions && task.tolInstructions !== '') {
                                onBlurTaskAttribute(idx, 'tolInstructions', e.target.value)
                            }
                        }}
                        onChange={(e) =>
                            onChangeTaskAttribute(idx, 'tolInstructions', e.target.value)
                        }
                        onInput={(e) => autoGrow(e.target)}
                        placeholder="Please set the think out-loud instructions"
                        value={task.tolInstructions ? task.tolInstructions : ''}
                    />
                    <CharacterCounter
                        charLimit={200}
                        className="tol__instructions"
                        numChars={task.tolInstructions ? task.tolInstructions.length : 0}
                    />
                </>
            )}

            {task.rating && (
                <>
                    <textarea
                        id={`taskRatingText${idx}`}
                        className={`small test-input ${'ratingText' in taskErrors || !task.ratingText || task.ratingText === '' ? 'error' : ''}`}
                        maxLength={200}
                        onBlur={(e) => {
                            if (task.ratingText && task.ratingText !== '') {
                                onBlurTaskAttribute(idx, 'ratingText', e.target.value)
                            }
                        }}
                        onChange={(e) => onChangeTaskAttribute(idx, 'ratingText', e.target.value)}
                        onInput={(e) => autoGrow(e.target)}
                        /* eslint-disable-next-line react/no-unknown-property */
                        onLoad={(e) => autoGrow(e.target)}
                        placeholder="Please set the rating instructions"
                        value={task.ratingText ? task.ratingText : ''}
                    />
                    <CharacterCounter
                        charLimit={200}
                        className="rating__text"
                        numChars={task.ratingText ? task.ratingText.length : 0}
                        styles={{ right: task.ratingText.length > 46 ? '30px' : '48px' }}
                    />
                </>
            )}
        </TaskCardWrapper>
    )
}

export default React.memo(TaskCard)
