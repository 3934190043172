import React, { useState } from 'react'

import { NavigationContext, NavigationContextItem } from '../../navigation/navigate'
import {
    onDeleteTest,
    onDuplicateTest,
    onEditTest,
    onLaunchLiveTest,
    onPauseTest,
    onResumeTest
} from '../../utilities/uxTest'
import PrimaryButton from '../atoms/buttons/PrimaryButton'
import SearchIcon from '../atoms/icons/SearchIcon'
import OverviewRow from '../overview/OverviewRow'

const OverviewTestsTable = ({
    tests,
    searchQuery,
    filteredTests,
    setSearchQuery,
    tableCellWidth,
    windowWidth,
    setTests,
    setInvitationModalOpen,
    invitationModalOpen,
    plan,
    onCreateTest,
    ...props
}) => {
    const [alertModalOpen, setAlertModalOpen] = useState(null)
    const [updating, setUpdating] = useState(null)
    const [launchModalOpen, setLaunchModalOpen] = useState(null)
    const [multiParticipantModalOpen, setMultiParticipantModalOpen] = useState(null)
    const [subscriptionNeededModalOpen, setSubscriptionNeededModalOpen] = useState(null)
    const [multiPersonDevice, setMultiPersonDevice] = useState(null)
    const [testDuplicating, setTestDuplicating] = useState(null)

    const { setNavState } = React.useContext(NavigationContext)

    const openAlert = (alertObject = null) => {
        setAlertModalOpen(alertObject)
        setNavState(
            alertObject
                ? NavigationContextItem.DISABLED_SIDE_BAR
                : NavigationContextItem.MAIN_SIDE_BAR
        )
    }

    return (
        <>
            <div
                className="toolbar"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    margin: '24px 0px'
                }}>
                <div className="toolbar__searchbar">
                    <input
                        onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                        placeholder="Type test name"
                        value={searchQuery}
                        style={{ paddingRight: '48px', borderRadius: '40px', fontSize: 14 }}
                    />
                    <SearchIcon
                        style={{
                            transform: 'translateX(-34px) translateY(13px)',
                            height: 24,
                            opacity: '0.4'
                        }}
                    />
                </div>

                <PrimaryButton onClick={onCreateTest}>{'+ Create test'}</PrimaryButton>
            </div>

            <div id="table__container">
                <div>
                    {/* Test table header */}
                    <div
                        className="table__head"
                        style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: tableCellWidth.test }} className="table__head__cell">
                            <div style={{ marginLeft: '24px' }}>{'Test'}</div>
                        </div>
                        <div
                            style={{
                                width: tableCellWidth.date,
                                display: windowWidth < 1140 ? 'none' : ''
                            }}
                            className="table__head__cell">
                            {'Creation Date - Expiration Date'}
                        </div>
                        <div
                            style={{
                                width: tableCellWidth.participants,
                                display: windowWidth < 1140 ? 'none' : ''
                            }}
                            className="table__head__cell">
                            {'Participants'}
                        </div>
                        <div style={{ width: tableCellWidth.status }} className="table__head__cell">
                            {'Status'}
                        </div>
                        <div
                            style={{ width: tableCellWidth.actions }}
                            className="table__head__cell">
                            {'Action'}
                        </div>
                    </div>

                    <div
                        style={{
                            height: 'calc(100vh - 400px)',
                            overflowX: 'scroll',
                            marginTop: '8px',
                            animationName: 'OUT',
                            animationDuration: '1s'
                        }}>
                        {filteredTests &&
                            filteredTests.map((test, testIdx) => (
                                <OverviewRow
                                    key={testIdx}
                                    updating={updating}
                                    plan={plan}
                                    windowWidth={windowWidth}
                                    tableCellWidth={tableCellWidth}
                                    testDuplicating={testDuplicating}
                                    onCloseAlertModal={openAlert}
                                    onCloseInvitationModal={() => {
                                        setNavState(NavigationContextItem.MAIN_SIDE_BAR)
                                        setInvitationModalOpen(null)
                                    }}
                                    onDeleteTest={(testID, testIdx) => {
                                        setNavState(NavigationContextItem.DISABLED_SIDE_BAR)
                                        onDeleteTest(testID, testIdx, tests, setTests, props)
                                    }}
                                    onDuplicateTest={(testID) =>
                                        onDuplicateTest(
                                            testID,
                                            test.name,
                                            setTestDuplicating,
                                            tests,
                                            setTests,
                                            props
                                        )
                                    }
                                    onEditTest={onEditTest}
                                    onPauseTest={(testID, testIdx) =>
                                        onPauseTest(
                                            testID,
                                            testIdx,
                                            tests,
                                            setTests,
                                            setUpdating,
                                            props
                                        )
                                    }
                                    onResumeTest={(testID, testIdx) =>
                                        onResumeTest(
                                            testID,
                                            testIdx,
                                            setUpdating,
                                            tests,
                                            setTests,
                                            props
                                        )
                                    }
                                    onLaunchLiveTest={(testID, testIdx) =>
                                        onLaunchLiveTest(
                                            testID,
                                            testIdx,
                                            setUpdating,
                                            multiPersonDevice,
                                            tests,
                                            setTests,
                                            props
                                        )
                                    }
                                    alertModalOpen={alertModalOpen}
                                    setAlertModalOpen={openAlert}
                                    launchModalOpen={launchModalOpen}
                                    setLaunchModalOpen={setLaunchModalOpen}
                                    multiParticipantModalOpen={multiParticipantModalOpen}
                                    setMultiParticipantModalOpen={setMultiParticipantModalOpen}
                                    subscriptionNeededModalOpen={subscriptionNeededModalOpen}
                                    setSubscriptionNeededModalOpen={setSubscriptionNeededModalOpen}
                                    invitationModalOpen={invitationModalOpen}
                                    setInvitationModalOpen={setInvitationModalOpen}
                                    multiPersonDevice={multiPersonDevice}
                                    setMultiPersonDevice={setMultiPersonDevice}
                                    setCreditsModalVisible={props.setCreditsModalVisible}
                                    test={test}
                                    testIdx={testIdx}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default OverviewTestsTable
