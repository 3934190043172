import React from 'react'

const SubscribeButton = ({ onClick, plan }) => {
    const title = () => {
        switch (plan) {
            case 'free':
                return 'Subscribe'
            case 'premium':
                return 'Upgrade'
            default:
                return 'Subscribe'
        }
    }

    return plan !== 'professional' ? (
        <button className={'subscribe-button'} onClick={onClick}>
            {title()}
        </button>
    ) : null
}
export default SubscribeButton
