import React from 'react'
import { CSVLink } from 'react-csv'

import contractViewIcon from '../../images/contract_view_icon.svg'
import downloadIcon from '../../images/download_icon.svg'
import expandViewIcon from '../../images/expand_view_icon.svg'

const ExploreCardControls = (props) => {
    const {
        headerCells,
        setShowingExpandedTable,
        tableView,
        test,
        tableViewData,
        viewIcon,
        testers
    } = props

    const csvData = []
    const csvHeaders = []
    const screenerData = []

    /**
     * HEADER DATA
     */
    headerCells.map((h) => {
        csvHeaders.push({ label: h.label, key: h.id })
        return h
    })

    test.screener.map((h) => {
        tableView === 2 && csvHeaders.push({ label: h.text, key: h.text })
        return h
    })

    /**
     * SCREENERS DATA
     */
    testers.map((tester) => {
        const obj = {}

        test.screener.map((h, idx) => {
            let screenerFeedbackVal

            if (tester.screeners) {
                screenerFeedbackVal = tester.screeners[idx].value
            } else {
                screenerFeedbackVal = 'N/A'
            }

            const key = h.text
            obj[key] = screenerFeedbackVal

            return h
        })
        screenerData.push(obj)

        return tester
    })

    /**
     * CSV DATA
     */
    JSON.parse(JSON.stringify(tableViewData)).map((row, idx) => {
        /**
         * DETAILED VIEW
         */
        tableView === 0 &&
            csvData.push({
                status: row.status === true ? 'Complete' : 'Incomplete',
                index: row.index + 1,
                task: row.taskStr,
                testSpecificTesterID: row.testSpecificTesterIDStr,
                testerID: row.testerIDStr,
                ab: row.abStr,
                ratingRate: row.ratingRateStr,
                duration: row.durationStr,
                device: row.deviceStr,
                numTaps: row.numTapsStr,
                numFixations: row.numFixationsStr,
                meanFixationDuration:
                    row.meanFixationDuration != null ? row.meanFixationDuration : '-',
                created: row.createdStr
            })

        /**
         * TASK VIEW
         */
        tableView === 1 &&
            csvData.push({
                status: row.status === true ? 'Complete' : 'Incomplete',
                index: row.task + 1,
                ab: row.abStr,
                completionRate: row.completionRateStr,
                ratingRate: row.ratingRateStr,
                duration: row.durationStr,
                numTaps: row.numTapsStr,
                numFixations: row.numFixationsStr,
                meanFixationDuration:
                    row.meanFixationDuration != null ? row.meanFixationDuration : '-'
            })

        /**
         * PARTICIPANT VIEW
         */
        tableView === 2 &&
            csvData.push({
                ...screenerData[idx],
                status: row.status === true ? 'Complete' : 'Incomplete',
                testerID: row.testerIDStr,
                testSpecificTesterID: row.index + 1,
                ab: row.abStr,
                completionRate: row.completionRateStr,
                duration: row.durationStr,
                numTaps: row.numTapsStr,
                numFixations: row.numFixationsStr,
                meanFixationDuration:
                    row.meanFixationDuration != null ? row.meanFixationDuration : '-'
            })

        /**
         * AB VIEW
         */
        tableView === 3 &&
            csvData.push({
                index: row.index,
                numTesters: row.numTesters,
                completionRate: row.completionRateStr,
                ratingRate: row.ratingRateStr,
                duration: row.durationStr,
                numTaps: row.numTapsStr,
                numFixations: row.numFixationsStr,
                meanFixationDuration:
                    row.meanFixationDuration != null ? row.meanFixationDuration : '-'
            })

        return row
    })

    let filename
    if (tableView === 0) {
        headerCells[3].id = 'testSpecificTesterID'
        filename = `${test.name} (detailed-view).csv`
    } else {
        filename = `${test.name} (${tableView === 1 ? 'task' : tableView === 2 ? 'participant' : 'ab'}-view).csv`
    }

    return (
        <div className="explore__card__controls">
            <CSVLink data={csvData} filename={filename} headers={csvHeaders}>
                <img alt="download_icon" className="control__button download" src={downloadIcon} />
            </CSVLink>
            <img
                alt={`${viewIcon}_view_icon`}
                className={`control__button ${viewIcon}`}
                onClick={() => setShowingExpandedTable(viewIcon === 'expand')}
                src={viewIcon === 'expand' ? expandViewIcon : contractViewIcon}
            />
        </div>
    )
}

export default ExploreCardControls
