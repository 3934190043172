import './auth.scss'

import React from 'react'
import styled from 'styled-components'

import { termsAndConditionsLink } from '../../data/enums/externalLinks'
import infoIcon from '../../images/info_icon/icon-info-header.svg'
import { agreeToTobiiTerms } from '../utils/apiLogging'

const UpdateCopy = () => (
    <>
        <div>
            The changes are minor and legal in nature, and do not affect our privacy standards or
            the way you use our service.
        </div>
        <br />
        <div>
            Please read the{' '}
            <a href={termsAndConditionsLink} rel="noopener noreferrer" target="_blank">
                Terms and Conditions{' '}
            </a>
            and click “I agree” to continue using Tobii
        </div>
    </>
)

const Background = styled.div.attrs({ className: 'background' })``
const LoginContainer = styled.div.attrs({ className: 'container' })`
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`
const LoginBox = styled.div.attrs({ className: 'row' })`
    border: 1px solid #ebecf0;
    border-radius: 16px;
    width: 428px;
    background-color: white;
    box-shadow: 0px 5px 20px -8px rgb(0 0 0 / 14%);
`

const SmallContainer = styled.div`
    transform: scale(0.9);
`
const Body = styled.div`
    margin: 0px;
    min-width: 320px;
    padding: ${(props) =>
        props.viewPort === 'DESKTOP' ? '40px' : props.viewPort === 'MOBILE' ? '16px' : '0px'};
`

const ImgCont = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
const Header = styled.div`
    font-weight: 800;
    margin: 34px 0px 24px 0px;
    font-size: 22px;
`
const Button = styled.button.attrs({ className: 'primary auth' })`
    width: 100%;
    height: 48px;
    margin-top: 48px;
`
const ButtonText = styled.div.attrs({ className: 'button__text' })`
    padding: 0px 24px 0px 24px;
`

const TobiiUpdate = (props) => {
    return (
        <Body viewPort={props.viewPort}>
            <ImgCont>
                <img src={infoIcon} alt="icon" style={{ zoom: '2' }} />
            </ImgCont>
            <Header> We have updated our terms due to our acquisition by Tobii. </Header>
            <UpdateCopy />
            <Button onClick={() => agreeToTobiiTerms()}>
                <ButtonText>I agree</ButtonText>
            </Button>
        </Body>
    )
}

const TobiiModal = (props) => {
    const width = props.windowWidth

    if (width < 340) {
        return (
            <SmallContainer>
                <TobiiUpdate viewPort={'SMALL'} />
            </SmallContainer>
        )
    } else if (width < 428) {
        return <TobiiUpdate viewPort={'MOBILE'} />
    } else {
        return (
            <>
                <Background />
                <LoginContainer id="loginContainer">
                    <LoginBox>
                        <TobiiUpdate viewPort={'DESKTOP'} />
                    </LoginBox>
                </LoginContainer>
            </>
        )
    }
}

export default TobiiModal
