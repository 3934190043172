import * as d3 from 'd3'
import React from 'react'
import { Element } from 'react-faux-dom'

/**
 *
 * @param {*} props
 */
const PieChart = (props) => {
    const { className, colours, data, id, outerRadius, innerRadius } = props
    const margin = { top: 0, right: 2, bottom: 10, left: 2 }
    const width = 2 * outerRadius + margin.left + margin.right
    const height = 2 * outerRadius + margin.top + margin.bottom
    const domain = []
    for (let i = 0; i <= colours.length; i++) {
        domain.push(i)
    }
    // eslint-disable-next-line import/namespace
    const colourScale = d3.scaleOrdinal().domain(domain).range(colours)

    const el = new Element('div')
    const svg = d3
        // eslint-disable-next-line import/namespace
        .select(el)
        .append('svg')
        .attr('id', id)
        .attr('height', height)
        .attr('width', width)
        .append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2})`)
    // eslint-disable-next-line import/namespace
    const pie = d3.pie().value((d) => d.value)
    // eslint-disable-next-line import/namespace
    const dataReady = pie(d3.entries(data))

    svg.selectAll('whatever')
        .data(dataReady)
        .enter()
        .append('path')
        // eslint-disable-next-line import/namespace
        .attr('d', d3.arc().innerRadius(innerRadius).outerRadius(outerRadius))
        .style('fill', (d) => colourScale(d.index))
        .style('stroke', 'black')
        .style('stroke-width', 0)

    return <div className={`pie__chart__wrapper ${className}`}>{el.toReact()}</div>
}

export default React.memo(PieChart)
