import './tooltip.css'

import React from 'react'

import tooltipIconGreen from '../../images/tooltip_icon_exclamation_green.svg'

/**
 *
 * @param {*} props
 */
const Tooltip = (props) => {
    // eslint-disable-next-line react/prop-types
    const { children, className, id } = props
    return (
        <div className={`tooltip ${className}`} id={id}>
            <img
                alt="tooltip_icon_black"
                className="tooltip__icon"
                onMouseEnter={() => document.getElementById(id).classList.add('active')}
                onMouseLeave={() => document.getElementById(id).classList.remove('active')}
                src={tooltipIconGreen}
            />
            <div className="tooltip__text">{children}</div>
        </div>
    )
}

export default React.memo(Tooltip)
