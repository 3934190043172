import React from 'react'

const HomeIcon = ({ isActive = false, size = 24, color = '#177268' }) => {
    return (
        <svg
            width={size}
            height={size}
            fill={'none'}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 14">
            <g clipPath="url(#clip0_4806_16312)">
                <path
                    d="M11.3333 13V11.6667C11.3333 10.9594 11.0524 10.2811 10.5523 9.78105C10.0522 9.28095 9.37391 9 8.66667 9H3.33333C2.62609 9 1.94781 9.28095 1.44772 9.78105C0.94762 10.2811 0.666668 10.9594 0.666668 11.6667V13"
                    stroke={color}
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6 6.33333C7.47276 6.33333 8.66667 5.13943 8.66667 3.66667C8.66667 2.19391 7.47276 1 6 1C4.52724 1 3.33333 2.19391 3.33333 3.66667C3.33333 5.13943 4.52724 6.33333 6 6.33333Z"
                    stroke={color}
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.3333 13V11.6667C15.3329 11.0758 15.1362 10.5019 14.7742 10.0349C14.4122 9.56791 13.9054 9.23438 13.3333 9.08667"
                    stroke={color}
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.6667 1.08667C11.2403 1.23354 11.7487 1.56714 12.1118 2.03488C12.4748 2.50262 12.6719 3.07789 12.6719 3.67C12.6719 4.26212 12.4748 4.83739 12.1118 5.30513C11.7487 5.77287 11.2403 6.10647 10.6667 6.25334"
                    stroke={color}
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_4806_16312">
                    <rect width="16" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default HomeIcon
