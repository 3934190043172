import '../../auth/auth.scss'

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import iconGreen from '../../../images/check_icon_green.svg'
import exclamationIcon from '../../../images/exclamation_icon.svg'
import { useOuterClick } from '../../utils/ClickOutside'

const ConditionBlock = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 13px;
`

const ConditionWrapper = styled.div`
    display: flex;
    color: #6dc3c2;
    margin-top: 4px;
`
const ConditionIconWrapper = styled.div`
    height: 18px;
    width: 18px;
    border-radius: 100%;
    overflow: hidden;
    transform: scale(0.5);
`

const ConditionName = styled.div`
    padding-top: 0px;
`

const upperCase = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
]
const lowerCase = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z'
]
const number = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

/**
 * Small checker for password conditions.
 * @param {*} props
 */
const ConditionCheck = ({ type, conditionMet }) => (
    <ConditionWrapper style={{ filter: `saturate(${conditionMet ? '1' : '0'})` }}>
        <ConditionIconWrapper>
            <img src={iconGreen} style={{ transform: 'scale(2)' }} alt="icon" />
        </ConditionIconWrapper>
        <ConditionName>{type}</ConditionName>
    </ConditionWrapper>
)

/**
 * Helper component to render a password entry box (including icon to change password visibility).
 */
const PasswordInput = (props) => {
    const {
        id,
        error,
        onChange,
        placeholder,
        value,
        icon,
        charCheck,
        autoComplete,
        labelText,
        tabIndex
    } = props

    const iconID = `pwordVeIcon_${id}`
    const [showPassword, setShowPassword] = useState(0)
    const [active, setActive] = useState(null)

    const [eightCharCondition, setEightCharCondition] = useState(false)
    const [lowerCaseCondition, setLowerCaseCondition] = useState(false)
    const [upperCaseCondition, setUpperCaseCondition] = useState(false)
    const [numberCondition, setNumberCondition] = useState(false)

    const route = {}

    const inputStyle = { outline: error && '1px solid #FF6262' }
    const imgStyle = {
        background: error && 'none',
        paddingLeft: error && '0px',
        cursor: error && 'unset',
        height: error ? '18px' : 0,
        marginTop: '2.6rem'
    }

    /**
     * Set conditions for password security
     */
    useEffect(() => {
        const valueAsArray = value?.split('')
        valueAsArray?.some((r) => lowerCase.includes(r))
            ? setLowerCaseCondition(true)
            : setLowerCaseCondition(false)
        valueAsArray?.some((r) => upperCase.includes(r))
            ? setUpperCaseCondition(true)
            : setUpperCaseCondition(false)
        valueAsArray?.some((r) => number.includes(r))
            ? setNumberCondition(true)
            : setNumberCondition(false)
        value?.length > 7 ? setEightCharCondition(true) : setEightCharCondition(false)
    }, [value])

    /**
     * Password Icon Toggle
     */
    const innerRef = useOuterClick(() => {
        document.getElementById(id).classList.remove('active')
        if (active === iconID) setActive(null)
    })

    return (
        <div
            className="input-container password-input-row"
            style={{ marginBottom: error ? '24px' : '0px' }}>
            <div className="password__input" id={id} ref={innerRef}>
                {icon && (
                    <img
                        alt="password_vis_icon"
                        className={`postfix ${route.pathname === '/account' ? 'acc' : 'login'} ${showPassword ? 'hide__password' : ''} ${active === iconID ? 'active' : ''}`}
                        id={iconID}
                        onClick={() => {
                            !error && setShowPassword(!showPassword)
                        }}
                        src={exclamationIcon}
                        style={imgStyle}
                    />
                )}
                <label> {labelText} </label>
                <input
                    onChange={onChange}
                    placeholder={placeholder}
                    defaultValue={value}
                    type={showPassword ? 'text' : 'password'}
                    style={inputStyle}
                    autoComplete={autoComplete}
                    tabIndex={tabIndex || 0}
                />

                {charCheck && (
                    <ConditionBlock>
                        <ConditionCheck type={'8 Characters'} conditionMet={eightCharCondition} />
                        <ConditionCheck type={'Lowercase'} conditionMet={lowerCaseCondition} />
                        <ConditionCheck type={'Uppercase'} conditionMet={upperCaseCondition} />
                        <ConditionCheck type={'Number'} conditionMet={numberCondition} />
                    </ConditionBlock>
                )}
                {error && (
                    <div className={'form-validation-error'}>
                        <span className="helper-text">{error}</span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default React.memo(PasswordInput)
