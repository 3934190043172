import './terms.css'

import Modal from '@material-ui/core/Modal'
import React from 'react'

import termsGeneralEN from '../../Documents/Terms_and_Conditions_Oculid_GmbH_2022.English.pdf'
import termsGeneralDE from '../../Documents/Terms_and_Conditions_Oculid_GmbH_2022.German.pdf'

/**
 * Small component to show oculid's two terms pdfs available for viewing.
 */
const TermsModal = (props) => {
    return (
        <Modal
            aria-labelledby="terms-modal"
            className="terms__modal"
            open={props.showingTermsModal}
            onClose={props.onCloseTermsModal}>
            <div className="modal__content">
                <h5>Terms of use</h5>
                <div className="first__row">
                    General (DE):{' '}
                    <a
                        className="green__link"
                        href={termsGeneralDE}
                        rel="noopener noreferrer"
                        target="_blank">
                        AGB_oculid_GmbH_112021.pdf
                    </a>
                </div>
                <div className="first__row">
                    General (EN):{' '}
                    <a
                        className="green__link"
                        href={termsGeneralEN}
                        rel="noopener noreferrer"
                        target="_blank">
                        GTC_oculid_GmbH_english_112021.pdf
                    </a>
                </div>
            </div>
        </Modal>
    )
}

export default React.memo(TermsModal)
