import './taskscolumn.css'

import React, { useEffect, useState } from 'react'

import TaskCard from '../organisms/uxTests/TaskCard'
import DropSpace from './DropSpace'

/**
 *
 * @param {*} props
 */
const TaskList = (props) => {
    const {
        canDropTaskCardIntoSpace,
        onBlurTaskAttribute,
        onChangeTaskAttribute,
        onChangeTaskBoolean,
        onChangeTaskFile,
        onDeleteTask,
        onHoverTaskCardOverTaskCard,
        onMoveTaskCardIntoSpace,
        setTaskGroupShowing,
        setTaskMenuShowing,
        taskErrors,
        taskGroupShowing,
        taskMenuShowing,
        tasks,
        shiftingDown,
        imageFormatError,
        videoFormatError,
        mediaFileSizeError,
        unrecognizedMediaError
    } = props

    const [moreThanOneTask, setMoreThanOneTask] = useState(false)

    /**
     * Check if there is more than one task in tasklist for the privacy button
     */
    useEffect(() => {
        if (tasks.length > 1) {
            setMoreThanOneTask(true)
        } else {
            setMoreThanOneTask(false)
        }
    }, [tasks])

    return (
        <div className="task__card__list" id="taskList">
            {tasks.map((task, idx) => (
                <div key={idx}>
                    {idx !== shiftingDown - 1 && (
                        <DropSpace
                            className="between__tasks"
                            canDropTaskCardIntoSpace={canDropTaskCardIntoSpace}
                            id={`dropSpace${idx - 1}`}
                            idxAbove={idx - 1}
                            idxBelow={idx}
                            onMoveTaskCardIntoSpace={onMoveTaskCardIntoSpace}
                            spaceIsInABTest={false}
                        />
                    )}
                    {/* Animation to handle cards shifting back if space created above old card idx */}
                    {shiftingDown === idx && shiftingDown < tasks.length - 1 && (
                        <div className="shift__up" />
                    )}
                    <TaskCard
                        canDropTaskCardIntoSpace={canDropTaskCardIntoSpace}
                        idx={idx}
                        isFirstTaskInGroup={idx === 0}
                        isLastTaskInGroup={idx === tasks.length - 1}
                        key={idx}
                        onBlurTaskAttribute={onBlurTaskAttribute}
                        onChangeTaskAttribute={onChangeTaskAttribute}
                        onChangeTaskBoolean={onChangeTaskBoolean}
                        onChangeTaskFile={onChangeTaskFile}
                        onDeleteTask={onDeleteTask}
                        onHoverTaskCardOverTaskCard={onHoverTaskCardOverTaskCard}
                        onMoveTaskCardIntoSpace={onMoveTaskCardIntoSpace}
                        setTaskGroupShowing={setTaskGroupShowing}
                        setTaskMenuShowing={setTaskMenuShowing}
                        task={tasks[idx]}
                        taskErrors={taskErrors}
                        taskGroupShowing={taskGroupShowing}
                        taskMenuShowing={taskMenuShowing}
                        imageFormatError={imageFormatError}
                        videoFormatError={videoFormatError}
                        mediaFileSizeError={mediaFileSizeError}
                        unrecognizedMediaError={unrecognizedMediaError}
                        moreThanOneTask={moreThanOneTask}
                    />
                    {/* Same animation but for final card */}
                    {shiftingDown === idx && shiftingDown === tasks.length - 1 && (
                        <div className="shift__up" />
                    )}
                    {idx !== shiftingDown - 1 && (
                        <DropSpace
                            canDropTaskCardIntoSpace={canDropTaskCardIntoSpace}
                            className="between__task__groups"
                            id={`dropSpace${idx + 1}`}
                            idxAbove={idx}
                            idxBelow={idx + 1}
                            onMoveTaskCardIntoSpace={onMoveTaskCardIntoSpace}
                            spaceIsInABTest={false}
                        />
                    )}
                </div>
            ))}
        </div>
    )
}

export default React.memo(TaskList)
