import React, { useEffect, useState } from 'react'

import { menuItems, testMenuItems } from '../../../data/entities/sideMenuItems'
import uxExploreLogo from '../../../images/uxexplore-logo.svg'
import {
    activePath,
    navigate,
    NavigationContext,
    NavigationContextItem,
    testIdFromUrl
} from '../../../navigation/navigate'
import { isLinkActive } from '../../../utilities/link'
import SubscribeButton from '../../atoms/buttons/SubscribeButton'
import SideBarItem from '../../molecules/sidebar/SideBarItem'
import { apiLogOut } from '../../utils/apiLogging'

const SideBar = (props) => {
    const { plan, setCreditsModalVisible } = props || {}
    const [testID, setTestID] = useState(null)
    const [activeLink, setActiveLink] = useState('')

    /**
     * If Session expired, logout before opening modal
     */
    const onClickSubscribe = () => {
        const sessionExpires = localStorage.getItem('tokenExp') || 0
        const currentTime = Date.now() / 1000
        if (currentTime < sessionExpires) {
            setCreditsModalVisible(true)
        } else {
            apiLogOut(props)
        }
    }

    const miniClass = () => {
        return isMinimalMode() ? 'minimal' : ''
    }

    useEffect(() => {
        setTestID(testIdFromUrl())
    })

    const isDisabled = (item) => {
        if (
            item.title === 'Create Test' &&
            [
                NavigationContextItem.DISABLED_SIDE_BAR,
                NavigationContextItem.DISABLED_TEST_CREATION,
                NavigationContextItem.TEST_CREATION_LIMIT
            ].includes(navState)
        ) {
            return true
        }
        if (item.iconTitle === 'playIcon') {
            return !props.participantFeedback
        }
        return false
    }

    const { navState, setNavState } = React.useContext(NavigationContext)

    if (activeLink !== activePath()) {
        setActiveLink(activePath())
    }

    const isMinimalMode = () => {
        if (navState === NavigationContextItem.MAIN_SIDE_BAR) {
            return false
        }

        return ['test', 'solitaire', 'video'].includes(activePath())
    }

    // Setup testID if sidebar is in minimal mode
    if (!isMinimalMode()) {
        const testIdCandidate = testIdFromUrl()
        if (testID !== testIdCandidate) {
            setTestID(testIdFromUrl())
        }
    }

    const onClickSideBarItem = (item) => {
        if (isLinkActive(item.url)) {
            return
        }

        if (navState === NavigationContextItem.TEST_CHANGED) {
            setNavState(NavigationContextItem.CONFIRM_TEST_EXIT)
            return
        }

        if (
            [
                NavigationContextItem.DISABLED_SIDE_BAR,
                NavigationContextItem.DISABLED_TEST_CREATION,
                NavigationContextItem.TEST_CREATION_LIMIT
            ].includes(navState)
        ) {
            setNavState(NavigationContextItem.CONFIRM_TEST_EXIT)
            return
        }
        navigate(item.url)

        setTimeout(() => {
            setNavState(item.navState)
        }, 1000)
    }

    return (
        <div
            className={`sidebar-wrapper ${navState === NavigationContextItem.DISABLED_SIDE_BAR ? 'disabled-side-bar' : ''}`}>
            <div className={`sidebar ${miniClass()}`}>
                <div className="logo-container">
                    <img src={uxExploreLogo} alt="UX Explore Logo" />
                    {navState !== NavigationContextItem.TEST_SIDE_BAR && <p>UX Explore</p>}
                </div>
                <div className="menu">
                    {!isMinimalMode() &&
                        menuItems().map(
                            (item = {}, index) =>
                                (item !== null && (
                                    <SideBarItem
                                        key={item.url + item.title}
                                        isActive={isLinkActive(item.url)}
                                        icon={item.icon}
                                        isDisabled={isDisabled(item)}
                                        {...item}
                                        onClick={() => onClickSideBarItem(item)}
                                    />
                                )) ||
                                null
                        )}

                    {isMinimalMode() &&
                        testMenuItems(testID).map((item = {}, index) => (
                            <SideBarItem
                                key={item.url + item.title}
                                isActive={isLinkActive(item.url)}
                                icon={item.icon}
                                isMinimal={isMinimalMode()}
                                itemID={item.id}
                                isDisabled={isDisabled(item)}
                                {...item}
                                onClick={() => onClickSideBarItem(item)}
                            />
                        ))}
                </div>
                <div className="plan-container">
                    {!isMinimalMode() && plan && (
                        <SubscribeButton onClick={onClickSubscribe} plan={plan} />
                    )}
                </div>
                <div className="footer">
                    {/*    {footerMenuItems.map(({ url, title }, index) => */}
                    {/*        url ? ( */}
                    {/*            <a href={url} key={index}> */}
                    {/*                {title} */}
                    {/*            </a> */}
                    {/*        ) : ( */}
                    {/*            <p className="sidebar__footer__text">{title}</p> */}
                    {/*        ) */}
                    {/*    )} */}
                </div>
            </div>
            {/* <ul className={`sidebar ${isMinimal ? 'minimal' : ''}`}> */}
            {/*    <SideNavCell */}
            {/*        className={ */}
            {/*            endpoint === 'overview' ? 'overview onpage' : 'overview notonpage' */}
            {/*        } */}
            {/*        icon={overviewSidebarIcon} */}
            {/*        isMinimal={isMinimal} */}
            {/*        route="overview" */}
            {/*        text="Overview" */}
            {/*        index={1} */}
            {/*        plan={plan} */}
            {/*    /> */}

            {/*    {isMinimal && ( */}
            {/*        <div> */}
            {/*            <SideBarItem */}
            {/*                thisEndpoint={'test'} */}
            {/*                activeIcon={analyticsSidebarIconGreen} */}
            {/*                inactiveIcon={analyticsSidebarIconWhite} */}
            {/*                endpointSplit={endpointSplit} */}
            {/*                endpoint={endpoint} */}
            {/*                disabled={false} */}
            {/*                userGuidingCopy={'Analytics'} */}
            {/*            /> */}
            {/*            <SideBarItem */}
            {/*                thisEndpoint={'solitaire'} */}
            {/*                activeIcon={solitaireSidebarIconGreen} */}
            {/*                inactiveIcon={solitaireSidebarIconWhite} */}
            {/*                endpointSplit={endpointSplit} */}
            {/*                endpoint={endpoint} */}
            {/*                disabled={false} */}
            {/*                userGuidingCopy={'Heat Maps'} */}
            {/*            /> */}
            {/*            <SideBarItem */}
            {/*                thisEndpoint={'video'} */}
            {/*                activeIcon={videoSidebarIconGreen} */}
            {/*                inactiveIcon={videoSidebarIconWhite} */}
            {/*                endpointSplit={endpointSplit} */}
            {/*                endpoint={endpoint} */}
            {/*                disabled={!participantFeedback} */}
            {/*                userGuidingCopy={'Video Replay'} */}
            {/*            /> */}
            {/*        </div> */}
            {/*    )} */}

            {/*    {!isMinimal && ( */}
            {/*        <SideNavCell */}
            {/*            className={ */}
            {/*                endpoint === 'create' || endpoint === 'preview' */}
            {/*                    ? 'create onpage' */}
            {/*                    : 'create notonpage' */}
            {/*            } */}
            {/*            icon={createSidebarIcon} */}
            {/*            isMinimal={isMinimal} */}
            {/*            route="create" */}
            {/*            text="Create test" */}
            {/*            index={0} */}
            {/*            plan={plan} */}
            {/*        /> */}
            {/*    )} */}

            {/*    <div */}
            {/*        className={'bottom-section'} */}
            {/*        // isMinimal={isMinimal} */}
            {/*    > */}
            {/*        {location.pathname !== '/create' && ( */}
            {/*            <> */}
            {/*                {plan && ( */}
            {/*                    <div */}
            {/*                        className={'subscription-space'} */}
            {/*                        // isMinimal={isMinimal} */}
            {/*                    > */}
            {/*                        {plan === 'professional' ? ( */}
            {/*                            <div className={'premium-tag'}>{'Professional'}</div> */}
            {/*                        ) : plan === 'trial' ? ( */}
            {/*                            <div className={'premium-tag'}> */}
            {/*                                {'Professional Trial'} */}
            {/*                            </div> */}
            {/*                        ) : ( */}
            {/*                            <SubscribeButton */}
            {/*                                onClick={onClickSubscribe} */}
            {/*                                title={'Upgrade Plan'} */}
            {/*                            /> */}
            {/*                        )} */}
            {/*                    </div> */}
            {/*                )} */}
            {/*                <SmallSideNavCell */}
            {/*                    isMinimal={isMinimal} */}
            {/*                    route={termsAndConditionsLink} */}
            {/*                    text="Terms of use" */}
            {/*                /> */}
            {/*                <SmallSideNavCell */}
            {/*                    isMinimal={isMinimal} */}
            {/*                    route={privacyPolicyLink} */}
            {/*                    text="Privacy policy" */}
            {/*                /> */}
            {/*            </> */}
            {/*        )} */}

            {/*        <div className={`footer ${miniClass()}`}> */}
            {/*            {' '} */}
            {/*            {'\u00A9 tobii '} {new Date().getFullYear()} */}
            {/*        </div> */}
            {/*    </div> */}
            {/* </ul> */}
        </div>
    )
}

export default SideBar
