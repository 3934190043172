import './auth.scss'

import React, { useState } from 'react'
import styled from 'styled-components'

import { privacyPolicyLink, termsAndConditionsLink } from '../../data/enums/externalLinks'
import infoIcon from '../../images/info_icon/icon-info-banner.svg'
import tobiiLogo from '../../images/tobii-logo-darkblue.svg'
import PasswordInput from '../molecules/forms/PasswordInput'
import TextInput from '../molecules/forms/TextInput'
import { update } from '../utils/apiLogging'
import DropdownInput from './DropdownInput'

const Background = styled.div.attrs({ className: 'background' })``
const LoginContainer = styled.div.attrs({ className: 'container' })`
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`
const LoginBox = styled.div.attrs({ className: 'row' })`
    border: 1px solid #ebecf0;
    border-radius: 16px;
    width: 428px;
    background-color: white;
    box-shadow: 0px 5px 20px -8px rgb(0 0 0 / 14%);
`

const SmallContainer = styled.div`
    transform: scale(0.9);
`
const Form = styled.form`
    margin: 0px;
    min-width: 320px;
    padding: ${(props) =>
        props.viewPort === 'DESKTOP' ? '40px' : props.viewPort === 'MOBILE' ? '16px' : '0px'};
`

const ImgCont = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
const Header = styled.h6.attrs({ className: 'grey-text text-darken-3' })`
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    margin: 34px 0px 24px 0px;
`

const Button = styled.button.attrs({ className: 'primary auth' })`
    width: 100%;
    height: 48px;
    margin-top: 16px;
`
const ButtonText = styled.div.attrs({ className: 'button__text' })`
    padding: 0px 24px 0px 24px;
`
const HelperText = styled.div`
    color: #ff6262;
    font-size: 13px;
`

const InfoBanner = styled.div`
    width: 100%;
    padding: 16px;
    background-color: #f4faff;
    border: 1px solid #8fb3ea;
    border-radius: 5px;
    display: flex;
`

const InfoCopy = styled.div`
    width: calc(100% - 36px);
    color: #387fea;
`

const BottomLink = styled.div`
    width: 100%;
    text-align: center;
    font-size: 14px;
`

const options = [
    'Market Researcher',
    'User Experience Researcher',
    'Education (Teacher/Student)',
    'Other'
]
const formattedOptions = ['market_researcher', 'user_experience_researcher', 'education', 'other']

const bannerCopy =
    'We have updated our terms and conditions due to our acquisition by Tobii. The changes are minor legal details that do not affect our privacy standards or the way you use Oculid.'

const UpdateForm = (props) => {
    const userID = localStorage.getItem('userID')

    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const [username, setUsername] = useState('')
    const [usernameError, setUsernameError] = useState('')

    const [email, setEmail] = useState(localStorage.getItem('email'))
    const [emailError, setEmailError] = useState('')

    const [occupation, setOccupation] = useState('')
    const [occupationError, setOccupationError] = useState('')

    const [detailError, setDetailError] = useState('')

    /**
     * Take Occupation Val and format for backend
     * @param {*} occ
     * @returns
     */
    const occupationFormatter = (occ) => {
        let formatted
        options.map((option, idx) => {
            if (option === occ) {
                formatted = formattedOptions[idx]
            }
            return formattedOptions[idx]
        })
        return formatted
    }

    return (
        <Form
            onSubmit={(e) => {
                update(
                    e,
                    userID,
                    password,
                    setPasswordError,
                    username,
                    setUsernameError,
                    email,
                    setEmailError,
                    occupation,
                    setOccupationError,
                    occupationFormatter,
                    setDetailError
                )
            }}
            viewPort={props.viewPort}>
            <ImgCont>
                <img src={tobiiLogo} alt="icon" width="140px" height="auto" />
            </ImgCont>

            <Header>
                {' '}
                Please update your information to get a more secure and better service!{' '}
            </Header>

            <TextInput
                label={'Full Name'}
                error={usernameError}
                onChange={(e) => {
                    setUsername(e.target.value)
                    setUsernameError(null)
                }}
                placeholder="Enter your full name"
                value={username}
                icon={props.viewPort === 'DESKTOP'}
                autoComplete={'full-name'}
                hideHelpers
            />

            <div style={{ height: '16px' }} />

            <TextInput
                label={'Email'}
                error={emailError}
                onChange={(e) => {
                    setEmail(e.target.value)
                    setEmailError(null)
                }}
                placeholder="Type your email"
                value={email}
                icon={props.viewPort === 'DESKTOP'}
                autoComplete={'email'}
                hideHelpers
            />

            <div style={{ height: '16px' }} />

            <PasswordInput
                id="password"
                error={passwordError}
                labelText="New Password"
                onChange={(e) => {
                    setPassword(e.target.value)
                    setPasswordError(null)
                }}
                placeholder="Type password"
                value={password}
                icon={props.viewPort === 'DESKTOP'}
                charCheck={true}
                autoComplete={'new-password'}
            />

            <div style={{ height: '16px' }} />

            <DropdownInput
                label={'Occupation'}
                error={occupationError}
                setError={setOccupationError}
                onChange={(value) => {
                    setOccupation(value)
                    setOccupationError(null)
                }}
                placeholder="Select your main occupation"
                value={occupation}
                icon={props.viewPort === 'DESKTOP'}
                options={options}
            />

            <div style={{ height: '72px' }} />

            <InfoBanner id={'infoBanner'}>
                <div style={{ width: '24px' }}>
                    <img src={infoIcon} alt={'info icon'} />
                </div>
                <div style={{ width: '12px' }} />
                <InfoCopy>{bannerCopy}</InfoCopy>
            </InfoBanner>

            {detailError && <HelperText className="helper-text">{detailError}</HelperText>}

            <Button>
                <ButtonText>Update & agree to continue</ButtonText>
            </Button>

            <BottomLink>
                By clicking this, you agree to our
                <a
                    href={termsAndConditionsLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ color: '#4B8CF7' }}>
                    {' Terms '}
                </a>
                and
                <a
                    href={privacyPolicyLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ color: '#4B8CF7' }}>
                    {' Privacy policy '}
                </a>
            </BottomLink>
        </Form>
    )
}

const Update = (props) => {
    const width = props.windowWidth

    if (width < 340) {
        return (
            <SmallContainer>
                <UpdateForm viewPort={'SMALL'} />
            </SmallContainer>
        )
    } else if (width < 428) {
        return <UpdateForm viewPort={'MOBILE'} />
    } else {
        return (
            <>
                <Background />
                <LoginContainer id="loginContainer">
                    <LoginBox style={{ zoom: '0.9' }}>
                        <UpdateForm viewPort={'DESKTOP'} />
                    </LoginBox>
                </LoginContainer>
            </>
        )
    }
}

export default Update
