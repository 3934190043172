import './auth.scss'

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import tobiiLogo from '../../images/tobii-logo-darkblue.svg'
import PasswordInput from '../molecules/forms/PasswordInput'
import { passwordReset } from '../utils/apiLogging'

const Background = styled.div.attrs({ className: 'background' })``
const LoginContainer = styled.div.attrs({ className: 'container' })`
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

const LoginBox = styled.div.attrs({ className: 'row' })`
    border: 1px solid #ebecf0;
    border-radius: 16px;
    width: 428px;
    background-color: white;
    box-shadow: 0px 5px 20px -8px rgb(0 0 0 / 14%);
`

const SmallContainer = styled.div`
    transform: scale(0.9);
`
const Form = styled.form`
    margin: 0px;
    min-width: 320px;
    padding: ${(props) =>
        props.viewPort === 'DESKTOP' ? '40px' : props.viewPort === 'MOBILE' ? '16px' : '0px'};
`

const ImgCont = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
const Header = styled.div`
    font-weight: 800;
    margin: 34px 0px 24px 0px;
    font-size: 1.75rem;
`
const SubHeader = styled.div`
    margin-bottom: 24px;
    font-size: 16px;
`
const Button = styled.button.attrs({ className: 'primary auth' })`
    width: 100%;
    height: 48px;
    margin-top: 68px;
`
const ButtonText = styled.div.attrs({ className: 'button__text' })`
    padding: 0px 24px 0px 24px;
`

const FakeButton = styled.div.attrs({ className: 'primary button' })`
    width: 100%;
    height: 48px;
    margin-top: 40px;
`

const BottomLink = styled.div`
    width: 100%;
    text-align: center;
    font-size: 14px;
`
const BottomLinkText = styled.a`
    color: #4b8cf7;
    text-decoration: underline;
    &:hover {
        color: #6ecbc8;
    }
`

const ResetForm = (props) => {
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [resetSuccess, setResetSuccess] = useState(false)
    const [linkExpired, setLinkExpired] = useState(false)

    const navigate = useNavigate()

    return (
        <Form
            onSubmit={(e) =>
                passwordReset(
                    e,
                    password,
                    setPasswordError,
                    props.tokenParts,
                    setResetSuccess,
                    setLinkExpired
                )
            }
            viewPort={props.viewPort}>
            <ImgCont>
                <img src={tobiiLogo} alt="icon" width="150" height="150" />
            </ImgCont>
            {!resetSuccess ? (
                !linkExpired ? (
                    <>
                        <Header> Reset password </Header>
                        <SubHeader>Please choose a new password for your account.</SubHeader>
                        <PasswordInput
                            id="password"
                            error={passwordError}
                            labelText="New password"
                            onChange={(e) => {
                                setPassword(e.target.value)
                                setPasswordError('')
                            }}
                            placeholder="Type password"
                            value={password}
                            icon={props.viewPort === 'DESKTOP'}
                            charCheck={true}
                            autoComplete={'current-password'}
                        />
                        <Button>
                            <ButtonText>Reset Password</ButtonText>
                        </Button>
                        <BottomLink>
                            Here by mistake? <BottomLinkText href="/login">Login</BottomLinkText>
                        </BottomLink>
                    </>
                ) : (
                    <>
                        <Header> This Link Has Expired </Header>
                        <SubHeader>Please request a new one.</SubHeader>
                        <FakeButton onClick={() => navigate('/reset')}>
                            <ButtonText>Request New Reset Link</ButtonText>
                        </FakeButton>
                    </>
                )
            ) : (
                <>
                    <Header> Your password has been changed </Header>
                    <SubHeader>Login with it to access your account.</SubHeader>
                    <FakeButton onClick={() => navigate('/login')}>
                        <ButtonText>Log In</ButtonText>
                    </FakeButton>
                </>
            )}
        </Form>
    )
}

const ResetNew = (props) => {
    const width = props.windowWidth

    const path = props.location.pathname
    const authToken = path.substring(path.lastIndexOf('reset/') + 6)
    const splitToken = authToken.split('/')
    const tokenParts = { uidb64: splitToken[0], token: splitToken[1], id: splitToken[2] }

    if (width < 340) {
        return (
            <SmallContainer>
                <ResetForm viewPort={'SMALL'} tokenParts={tokenParts} />
            </SmallContainer>
        )
    } else if (width && width < 428) {
        return <ResetForm viewPort={'MOBILE'} tokenParts={tokenParts} />
    } else {
        return (
            <>
                <Background />
                <LoginContainer id="loginContainer">
                    <LoginBox>
                        <ResetForm viewPort={'DESKTOP'} tokenParts={tokenParts} />
                    </LoginBox>
                </LoginContainer>
            </>
        )
    }
}

export default ResetNew
