import { jwtDecode } from 'jwt-decode'

import { fullName } from '../../data/entities/user'
import { navigate } from '../../navigation/navigate'
import { clearStorage } from '../../utilities/storage'
import { isEmailValid } from '../../utilities/userValidator'

const apiRouteUserFromLogin = async (resJSON) => {
    const accessToken = resJSON.access
    const refreshToken = resJSON.refresh

    try {
        const { user_id: userID, exp } = jwtDecode(accessToken)

        // Check current_user to see if user needs an update
        const result = await fetch(process.env.REACT_APP_URL_BASE + 'user-research/current_user/', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', Authorization: 'JWT ' + accessToken }
        })

        const responseJson = await result.json()
        const { date_joined: dateJoined, full_name: name, ...userData } = responseJson

        localStorage.setItem('tokenExp', `${exp}`)
        localStorage.setItem('userID', userID)
        localStorage.setItem('token', accessToken)
        localStorage.setItem('refresh', refreshToken)
        localStorage.setItem('email', resJSON.email)
        localStorage.setItem('company_name', resJSON.company_name)
        localStorage.setItem('username', fullName({ ...userData, name }))
        localStorage.setItem('full_name', name)
        localStorage.setItem('date_joined', dateJoined)
        localStorage.setItem('occupation', userData.occupation)

        if (!responseJson.occupation) {
            navigate('/update')
            return
        }
        if (userData.category !== null) {
            navigate('/consent')
        }

        navigate('/overview')
    } catch (error) {
        console.log('apiRouteUserFromLogin == ', error)
    }
}

/**
 * Function called when the user makes a login attempt.
 * @param {*} e login button click event
 * @param setPasswordError
 * @param setErrors
 * @param password
 * @param username
 */
export const apiLogIn = async (e, setErrors, { password, username }) => {
    console.log('password, username', password, username)

    try {
        e.preventDefault()
        const url = process.env.REACT_APP_URL_BASE + 'token/'
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: '',
                password,
                username
            })
        })

        const responseJSON = await response.json()

        if (response.status === 429) {
            const detail = responseJSON.detail || 'Too many login attempts, please try again later'
            const waitingTime = detail.match(/(\d+)/)[0]
            if (waitingTime > 0) {
                setErrors({
                    password: `Too many login attempts, please try again in ${Math.round(waitingTime / 60)} minutes`,
                    username: ''
                })
                return
            }

            setErrors({
                password: detail,
                username: ''
            })
            return
        }

        if (response.status >= 300) {
            const { detail, password, username } = responseJSON

            if (typeof detail !== 'string') {
                setErrors({
                    password: password || 'Invalid password',
                    username: username || 'Invalid email'
                })
                return
            }

            setErrors({
                password: detail,
                username: ''
            })

            return
        }
        await apiRouteUserFromLogin(responseJSON)
    } catch (error) {
        setErrors({
            password: error.password || 'Invalid password',
            username: error.username || 'Invalid email'
        })
        // setUsernameError(' ')
        // setPasswordError('Please enter a valid email and password combination')
    }
}

/**
 * Logout Function
 * @param {*} props
 * @returns
 */
export function apiLogOut() {
    const url = `${process.env.REACT_APP_URL_BASE}token/expire/`
    const refreshToken = { refresh: `${localStorage.getItem('refresh')}` }
    const token = localStorage.getItem('token')
    const tokenExp = localStorage.getItem('tokenExp')

    const logOutFunc = () => {
        const shouldRememberMe = localStorage.getItem('rememberMe')
        localStorage.removeItem('refresh')
        localStorage.removeItem('tokenExp')
        localStorage.removeItem('username')
        localStorage.removeItem('token')
        if (!shouldRememberMe) {
            localStorage.removeItem('email')
            localStorage.removeItem('password')
        }
        localStorage.removeItem('userID')
        localStorage.removeItem('full_name')
        clearStorage()

        navigate('/login', { replace: true })
    }

    const newToken = async () => {
        try {
            await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `JWT ${token}`
                },
                body: JSON.stringify(refreshToken)
            })
        } catch (e) {
            console.log('Error during logout: ', e.message)
        }
    }

    if (tokenExp * 1000 < Date.now()) {
        logOutFunc()
    } else {
        newToken().then(() => {
            logOutFunc()
        })
    }
}

/**
 * Request Password Reset Email
 * @param {*} e
 */
export const requestReset = async (e, email, setEmailError, setEmailSuccess) => {
    try {
        e.preventDefault()
        const url = process.env.REACT_APP_URL_BASE + 'user-research/requestresetemail/'

        if (!isEmailValid(email)) {
            setEmailError('Please type a valid email address')
            return
        }

        const result = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email
            })
        })

        const resultJson = await result.json()
        if (result.status >= 400) {
            setEmailError(resultJson.email)
        }
        setEmailSuccess(true)
    } catch (error) {
        setEmailError(error.message)
        console.log('requestReset == ', error)
    }
}

/**
 * Request Password Reset Email
 * @param {*} e
 */
export function passwordReset(
    e,
    password,
    setPasswordError,
    tokenParts,
    setResetSuccess,
    setLinkExpired
) {
    e.preventDefault()

    if (!password) {
        setPasswordError('Please enter a password to continue')
        return
    }

    const url = process.env.REACT_APP_URL_BASE + `user-research/passwordreset/${tokenParts.id}/`
    let status
    fetch(url, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            password,
            token: tokenParts.token,
            uidb64: tokenParts.uidb64
        })
    })
        .then((res) => {
            if (res.status === 400) {
                status = res.status
                return res.json()
            } else {
                setResetSuccess(true)
            }
        })
        .then((resJSON) => {
            const tokenExpired =
                resJSON.error.non_field_errors[0] ===
                'The token is invalid, try to generate a new token'

            if (status === 400) {
                if (tokenExpired) {
                    setLinkExpired(true)
                    return
                }
                setPasswordError('Please enter a valid password')
            }
        })
        .catch((err) => {
            console.log('err: ', err)
        })
}

/**
 * Sign up New User
 * @param {*} e
 * @param password
 * @param setPasswordError
 * @param username
 * @param setUsernameError
 * @param email
 * @param setEmailError
 * @param companyName
 * @param setCompanyError
 * @param occupation
 * @param setOccupationError
 * @param occupationFormatter
 * @param countryCode
 * @param setCountryError
 * @param termsStatus
 * @param setTermsStatus
 * @param history
 * @param setDetailError
 */
export function signUp(
    e,
    password,
    setPasswordError,
    username,
    setUsernameError,
    email,
    setEmailError,
    companyName,
    setCompanyError,
    occupation,
    setOccupationError,
    occupationFormatter,
    countryCode,
    setCountryError,
    termsStatus,
    setTermsStatus,
    setDetailError
) {
    e.preventDefault()

    if (termsStatus === 'unchecked' || termsStatus === 'error') {
        setDetailError('Please accept our Terms and Privacy Policy')
        setTermsStatus('error')
        return
    }

    const url = process.env.REACT_APP_URL_BASE + 'user-research/verifyuser/'
    let status
    fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            full_name: username,
            occupation: occupationFormatter(occupation),
            country: countryCode,
            password,
            email,
            company_name: companyName
        })
    })
        .then((res) => {
            status = res.status
            return res.json()
        })
        .then((resJSON) => {
            const clientSideError = status === 400 || !occupation

            const initTagManager = () => {
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({ event: 'formSubmitSuccess' })
            }

            if (clientSideError) {
                setUsernameError(resJSON.full_name)
                setEmailError(
                    resJSON.detail === 'User with this email address already exists.'
                        ? resJSON.detail
                        : resJSON.email
                )
                setPasswordError(resJSON.password)
                setOccupationError(!occupation && 'You must include a response for occupation')
                setCountryError(countryCode === '' ? 'You must select a country' : resJSON.country)
                setCompanyError(resJSON.company_name)
            } else if (status === 500) {
                setDetailError(
                    'There has been an internal error, please refresh the page and try again.'
                )
            } else {
                initTagManager()
                localStorage.setItem('full_name', username)

                navigate(`/signup/${resJSON.verifier}/${resJSON.email}`)
            }
        })
        .catch((err) => {
            console.log(err)
        })
}

/**
 * Confirm sign up
 * @param {*} e
 */
export function apiConfirmSignUp(e, code, setCodeError, verifier, onComplete) {
    e.preventDefault()

    const url = process.env.REACT_APP_URL_BASE + 'user-research/confirmuser/'
    let status
    fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            code,
            verifier
        })
    })
        .then((res) => {
            status = res.status
            return res.json()
        })
        .then((resJSON) => {
            if (status === 400) {
                setCodeError('This code isn’t correct, please check it again')
            } else if (status === 500) {
                setCodeError(
                    'There has been an internal error, please refresh the page and try again.'
                )
            } else {
                const userNameArr = resJSON?.full_name?.split(' ')
                const displayName = userNameArr[0]

                const accessToken = resJSON.access
                const refreshToken = resJSON.refresh
                const decodedToken = jwtDecode(accessToken)
                localStorage.setItem('tokenExp', decodedToken.exp)
                localStorage.setItem('userID', decodedToken.user_id)
                localStorage.setItem('token', accessToken)
                localStorage.setItem('refresh', refreshToken)
                localStorage.setItem('email', resJSON.email)
                localStorage.setItem('username', displayName)
                localStorage.setItem('date_joined', resJSON.date_joined)
                localStorage.setItem('occupation', resJSON.occupation)
                localStorage.setItem('company_name', resJSON.company_name)
                navigate('/overview')
                onComplete()
            }
        })
        .catch((err) => {
            console.log('verification token request error: ', err)
        })
}

/**
 * Update user without occupation details
 * @param {*} e
 */
export function update(
    e,
    userID,
    password,
    setPasswordError,
    username,
    setUsernameError,
    email,
    setEmailError,
    occupation,
    setOccupationError,
    occupationFormatter,
    setDetailError
) {
    e.preventDefault()

    const url = process.env.REACT_APP_URL_BASE + `user-research/users/${userID}/`
    const token = localStorage.getItem('token')
    const userNameArr = username?.split(' ')
    const displayName = userNameArr[0]
    let status
    fetch(url, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `JWT ${token}`
        },
        body: JSON.stringify({
            username: email,
            full_name: username,
            occupation: occupationFormatter(occupation),
            password,
            email,
            category: null
        })
    })
        .then((res) => {
            status = res.status
            return res.json()
        })
        .then((resJSON) => {
            const clientSideError = status === 400 || !occupation

            if (clientSideError) {
                setPasswordError(
                    resJSON.error.non_field_errors
                        ? resJSON.error.non_field_errors
                        : resJSON.error.password
                )
                setUsernameError(resJSON.error.username)
                setUsernameError(resJSON.error.full_name)
                setEmailError(resJSON.error.email)
                !occupation && setOccupationError('You must include a response for occupation')
            } else if (status === 500) {
                setDetailError(
                    'There has been an internal error, please refresh the page and try again.'
                )
            } else {
                localStorage.setItem('email', email)
                localStorage.setItem('username', displayName)

                navigate('/overview')
            }
        })
        .catch((err) => {
            console.log(err)
        })
}

/**
 * Sends password changing request --> the backend.
 * @param {*} token
 */
export function sendChangePasswordRequest(
    e,
    props,
    token,
    curPassword,
    setCurPassword,
    setCurrError,
    newPassword,
    setNewPassword,
    setNewError
) {
    e.preventDefault()
    let status
    fetch(process.env.REACT_APP_URL + 'changepsw/', {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + token
        },
        body: JSON.stringify({ old_password: curPassword, new_password: newPassword })
    })
        .then((res) => {
            status = res.status
            return res.json()
        })
        .then((resJSON) => {
            if (status === 401) {
                apiLogOut(props)
            } else if (status !== 200) {
                if (resJSON.detail) {
                    setCurrError(resJSON.detail)
                } else if (resJSON.error) {
                    resJSON.error.non_field_errors && setNewError(resJSON.error.non_field_errors[0])
                    resJSON.error.old_password && setCurrError(resJSON.error.old_password[0])
                }
            } else {
                setCurPassword('')
                setNewPassword('')

                navigate('/')
            }
        })
}

/**
 * Agrees to new terms including details of oculid's transition to Tobii
 * backend flagged with change
 */
export function agreeToTobiiTerms() {
    const userID = localStorage.getItem('userID')
    const token = localStorage.getItem('token')

    fetch(process.env.REACT_APP_URL + `users/${userID}/?partial=True`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `JWT ${token}`
        },
        body: JSON.stringify({ category: null })
    })
        .then((res) => res.json())
        .then(() => {
            navigate('/overview')
        })
        .catch((err) => console.log(err))
}
