/**
 * Loads the user's allowed test params.
 */
import { useNavigate } from 'react-router-dom'

export const apiGetTestParams = async (token, resolve) => {
    try {
        const response = await fetch(process.env.REACT_APP_URL_BASE + 'user-research/params/', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'JWT ' + token
            }
        })
        if (response.status === 401) {
            alert('Sorry! You have been logged out for security reasons. Please log in again')
            const navigate = useNavigate()
            navigate('/login')
        } else if (response.status !== 200) {
            throw new Error(`Error getting test params: ${response.status}, ${response}`)
        } else {
            resolve(response.json())
        }
    } catch (error) {
        console.log('There was an error:', error)
    }
}

apiGetTestParams.propTypes = {
    token: String,
    resolve: Function,
    reject: Function
}

/**
 * Fetches a user's complete list of tests from the server.
 * @param url string - url of tests endpoint
 * @param token - the jwt access token
 * @param tests - a local array to store the returned tests from the server
 * @param resolve - promise fulfilled
 * @param reject - promise unfulfilled
 */
export const apiGetTestData = (url, token, tests, resolve, reject) => {
    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'JWT ' + token
        }
    })
        .then((res) => {
            if (res.status === 401) {
                alert('Sorry! You have been logged out for security reasons. Please log in again')
                const navigate = useNavigate()
                navigate('/login')
            } else if (res.status !== 200) {
                throw new Error(`Error getting tests: ${res.status}, ${res}`)
            } else {
                return res.json()
            }
        })
        .then((resJSON) => {
            const retrieved = tests.concat(resJSON.results)
            if (resJSON.next !== null) {
                const nextJSON =
                    process.env.REACT_APP_NAME === 'PROD'
                        ? resJSON.next.replace('http', 'https')
                        : resJSON.next.replace('https', 'http')
                apiGetTestData(nextJSON, token, retrieved, resolve, reject)
            } else {
                resolve(retrieved)
            }
        })
        .catch((err) => {
            console.log('There was an error:' + err)
        })
}
