import { objectSnakeToCamelCase } from '../utils/functions'

export function loadDraftSuccessFunc(
    json,
    description,
    setDescription,
    isMounted,
    name,
    setName,
    numTesters,
    setNumTesters,
    usingWelcomeMessage,
    setUsingWelcomeMessage,
    setWelcomeMessage,
    usingThankyouMessage,
    setUsingThankyouMessage,
    setThankyouMessage,
    setScreenerErrors,
    setScreeners,
    setTaskScheme,
    V,
    allowedTestParams,
    setTaskErrors,
    setTasks,
    setHaveLoadedDraft,
    logoImage,
    setLogoImage,
    emailContact,
    setEmailContact,
    draftJson,
    setDraftJson
) {
    let i
    // set all test attributes + associated error values if there are any
    if (!draftJson) {
        setDraftJson(json)
    }
    if (json.description !== description) {
        if (isMounted) setDescription(json.description)
    }
    if (json.name !== name) {
        if (isMounted) setName(json.name)
    }
    if (json.max_num_testers !== numTesters) {
        if (isMounted) setNumTesters(json.max_num_testers)
    }
    if (json.contact_email !== emailContact) {
        if (isMounted) setEmailContact(json.contact_email)
    }
    if (json.company_logo !== logoImage) {
        if (isMounted) setLogoImage(json.company_logo)
    }

    if (json.message_welcome !== '' && json.message_welcome != null) {
        if (!usingWelcomeMessage) {
            if (isMounted) setUsingWelcomeMessage(true)
        }
        if (isMounted) setWelcomeMessage(json.message_welcome)
    }
    if (json.message_thankyou !== '' && json.message_thankyou != null) {
        if (!usingThankyouMessage) {
            if (isMounted) setUsingThankyouMessage(true)
        }
        if (isMounted) setThankyouMessage(json.message_thankyou)
    }

    // load screener questions & set errors (if there are any)
    const loadedScreeners = json.screener.length > 0 ? objectSnakeToCamelCase(json.screener) : []

    for (const idx in loadedScreeners) {
        if ('options' in loadedScreeners[idx]) {
            loadedScreeners[idx].options = JSON.parse(loadedScreeners[idx].options)
        }
        loadedScreeners[idx].question = loadedScreeners[idx].text
    }

    // create screener errors with appropriate dimensions
    const newScreenerErrors = []
    for (i in loadedScreeners) {
        newScreenerErrors.push({})
    }

    if (isMounted) {
        setScreenerErrors(newScreenerErrors)
        setScreeners(loadedScreeners)
        // validateScreeners(loadedScreeners, newScreenerErrors, false);  // to find any extra errors (from considering screeners collectively)
    }

    // load tasks & set task errors accordingly
    const loadedTasks = json.tasks.length > 0 ? objectSnakeToCamelCase(json.tasks) : []
    const taskIndices = [...new Set(loadedTasks.map((task) => task.taskIndex))]
    const sortedLoadedTasks = []

    for (i in taskIndices) {
        const taskIndex = taskIndices[i]
        const thisIndexTasks = loadedTasks.filter((task) => task.taskIndex === taskIndex)

        // if we have a regular task, do corrections for possible media + append to list
        if (thisIndexTasks.length === 1) {
            const thisIndexTask = thisIndexTasks[0]
            thisIndexTask.isABTest = false

            const taskImgStr = thisIndexTask.image
            const taskVideoStr = thisIndexTask.video
            if (taskImgStr != null) {
                thisIndexTask.file = taskImgStr
            } else if (taskVideoStr != null) {
                thisIndexTask.file = taskVideoStr
            }

            sortedLoadedTasks.push(thisIndexTask)

            // if we have an AB test, we need to blend the two tasks into one
        } else {
            const aTask = thisIndexTasks[0]
            const bTask = thisIndexTasks[1]
            const abTask = {
                description: aTask.description,
                duration: aTask.duration,
                isABTest: true,
                media: [
                    { key: null, value: null },
                    { key: null, value: null }
                ],
                name: aTask.name,
                pauseRecording: aTask.pauseRecording,
                rating: aTask.rating,
                ratingText: aTask.ratingText,
                taskIndex,
                tol: aTask.tol,
                tolInstructions: aTask.tolInstructions
            }

            // add media --> media list
            if (aTask.url) {
                abTask.media[0] = { key: 'url', value: aTask.url }
            } else if (aTask.image) {
                abTask.media[0] = { key: 'file', value: aTask.image }
            } else if (aTask.video) {
                abTask.media[0] = { key: 'file', value: aTask.video }
            }
            if (bTask.url) {
                abTask.media[1] = { key: 'url', value: bTask.url }
            } else if (bTask.image) {
                abTask.media[1] = { key: 'file', value: bTask.image }
            } else if (bTask.video) {
                abTask.media[1] = { key: 'file', value: bTask.video }
            }

            sortedLoadedTasks.push(abTask)
        }
    }

    // correct task scheme if the last draft saved had AB testing but no defined AB-test tasks
    let taskScheme = json.task_scheme
    if (sortedLoadedTasks.filter((task) => task.isABTest).length === 0) {
        taskScheme = 'none'
    } else {
        taskScheme = 'ab'
    }
    if (isMounted) setTaskScheme(taskScheme)

    // set task errors to match sorted task list
    const newTaskErrors = []
    for (i in sortedLoadedTasks) {
        const taskErrorDict = {}
        const task = sortedLoadedTasks[i]
        for (const taskKey in task) {
            if (taskKey === 'media') {
                if (task[taskKey][0].key === 'url') {
                    const [isValidA, errorA] = V.validateTaskAttributeValue(
                        'url',
                        task.media[0].value
                    )
                    if (!isValidA) taskErrorDict.url = [errorA, null]
                }
                if (task[taskKey][1].key === 'url') {
                    const [isValidB, errorB] = V.validateTaskAttributeValue(
                        'url',
                        task.media[1].value
                    )
                    if (!isValidB) {
                        if ('url' in taskErrorDict) taskErrorDict.url[1] = errorB
                        else taskErrorDict.url = [null, errorB]
                    }
                }
            } else if (taskKey !== 'duration' || allowedTestParams != null) {
                const [isValid, error] = V.validateTaskAttributeValue(taskKey, task[taskKey])
                if (!isValid) taskErrorDict[taskKey] = error
            }
        }
        newTaskErrors.push(taskErrorDict)
    }

    if (isMounted) {
        setTaskErrors(newTaskErrors)
        setTasks(sortedLoadedTasks)
        setHaveLoadedDraft(true)
    }
}
