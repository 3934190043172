/**
 * This represents some generic auth provider API, like Firebase.
 */
const userAuthProvider = {
    isAuthenticated: false,
    username: null,
    async signin(username) {
        await new Promise((resolve) => setTimeout(resolve, 500)) // user delay
        userAuthProvider.isAuthenticated = true
        userAuthProvider.username = username
    },
    async signout() {
        await new Promise((resolve) => setTimeout(resolve, 500)) // user delay
        userAuthProvider.isAuthenticated = false
        userAuthProvider.username = ''
    }
}

export { userAuthProvider }
