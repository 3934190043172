// import checkedIconAlternate from '../../images/checked_icon_alternate.svg';
// import checkedIconSmall from '../../images/checked_icon_small.svg';
import './checkbox.css'

import React from 'react'

import checkedIcon from '../../images/checked_icon.svg'

/**
 *
 * @param {*} props
 */
const Checkbox = (props) => {
    const { alternateCheck, checked, className, id, onClick, useSmallIcon } = props
    return (
        <div
            className={`checkbox ${checked ? 'checked' : ''} ${className} ${alternateCheck ? 'alternate' : ''}`}
            id={id}
            onClick={onClick}>
            {checked
                ? !useSmallIcon && <img alt="checkbox_checked_icon" src={checkedIcon} />
                : null}
        </div>
    )
}

export default Checkbox
