import './_overview-row.scss'

import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { NavigationContext, NavigationContextItem } from '../../navigation/navigate'

const LinkOptions = styled.div.attrs({ className: 'test_invitation_link' })`
    display: flex;
    color: #4b8cf7;
    font-size: 12px !important;
    letter-spacing: 0.2px;
    line-height: 15px;
    margin-top: 5px;
`
const Link = styled.div`
    cursor: pointer;

    &:hover {
        filter: brightness(1.2);
    }
`
const Dash = styled.div`
    margin: 0px 2px;
`

const TestTitleLinks = ({
    test,
    setInvitationModalOpen,
    onClickPauseTest,
    onClickResumeTest,
    onClickLaunchLiveTest,
    onClickEditTest
}) => {
    const navigate = useNavigate()

    const { setNavState } = React.useContext(NavigationContext)

    switch (test.stage) {
        case 'draft':
            return (
                <LinkOptions>
                    <Link
                        onClick={() => {
                            onClickEditTest()
                        }}>
                        Edit
                    </Link>
                </LinkOptions>
            )
        case 'live':
            return (
                <LinkOptions>
                    <Link
                        onClick={() => {
                            setNavState(NavigationContextItem.TEST_SIDE_BAR)
                            navigate({ pathname: `/test/${test.id}`, state: { curTest: test } })
                        }}>
                        Test results
                    </Link>
                    <Dash> - </Dash>
                    <Link
                        onClick={() => {
                            onClickPauseTest()
                        }}>
                        Pause
                    </Link>
                    <Dash> - </Dash>
                    <Link
                        onClick={() => {
                            setInvitationModalOpen(test.id)
                        }}>
                        Share
                    </Link>
                </LinkOptions>
            )
        case 'review':
            return (
                <LinkOptions>
                    <Link
                        onClick={() => {
                            setNavState(NavigationContextItem.TEST_SIDE_BAR)
                            navigate({ pathname: `/test/${test.id}`, state: { curTest: test } })
                        }}>
                        Test results
                    </Link>
                    <Dash> - </Dash>
                    <Link
                        onClick={() => {
                            onClickEditTest()
                        }}>
                        Edit
                    </Link>
                    <Dash> - </Dash>
                    <Link
                        onClick={() => {
                            setInvitationModalOpen(test.id)
                        }}>
                        Share
                    </Link>
                    <Dash> - </Dash>
                    <Link
                        onClick={() => {
                            onClickLaunchLiveTest()
                        }}>
                        Launch
                    </Link>
                </LinkOptions>
            )
        case 'paused':
            return (
                <LinkOptions>
                    <Link
                        onClick={() => {
                            setNavState(NavigationContextItem.TEST_SIDE_BAR)
                            navigate({ pathname: `/test/${test.id}`, state: { curTest: test } })
                        }}>
                        Test results
                    </Link>
                    <Dash> - </Dash>
                    <Link
                        onClick={() => {
                            onClickResumeTest()
                        }}>
                        Resume
                    </Link>
                    <Dash> - </Dash>
                    <Link
                        onClick={() => {
                            setInvitationModalOpen(test.id)
                        }}>
                        Share
                    </Link>
                </LinkOptions>
            )
        case 'suspended':
            return (
                <LinkOptions>
                    <Link
                        onClick={() => {
                            setNavState(NavigationContextItem.TEST_SIDE_BAR)
                            navigate({ pathname: `/test/${test.id}`, state: { curTest: test } })
                        }}>
                        Test results
                    </Link>
                    <Dash> - </Dash>
                    <Link
                        onClick={() => {
                            setInvitationModalOpen(test.id)
                        }}>
                        Share
                    </Link>
                </LinkOptions>
            )
        default:
            return <></>
    }
}

export default TestTitleLinks
