import './_launchmodal.scss'

import React from 'react'
import styled from 'styled-components'

import CloseButton from '../atoms/buttons/CloseButton'
import PrimaryButton from '../atoms/buttons/PrimaryButton'

const ModCont = styled.div.attrs({ className: 'close__modal' })`
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: #00000066;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
`
const InnerModCont = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 6px -5px rgba(0, 0, 0, 0.15);
    justify-content: center;
    width: 27rem;
    padding: 2rem 2.2rem;
`

const ActionCont = styled.div`
    ${{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '2rem' }}
`

const SubscriptionNeededModal = ({ onClickCancel, onClickClose, subscriptionNeededModalOpen }) => {
    return (
        subscriptionNeededModalOpen && (
            <ModCont>
                <InnerModCont>
                    <div className="modal-title">
                        <CloseButton onClick={() => onClickCancel()} top={-30} right={-30} />
                        <h2>{'Upgrade your plan to launch or create a new test'}</h2>
                    </div>

                    <div>
                        {'Contact sales to upgrade your plan and gain full access to the platform!'}
                    </div>

                    <ActionCont>
                        <a
                            href="mailto:sales@tobii.com?subject=Upgrade%20plan,%20Tobii%20UX%20Explore"
                            target="_blank"
                            rel="noopener noreferrer">
                            <PrimaryButton
                                onClick={() => {
                                    onClickClose()
                                }}>
                                {'Contact sales'}
                            </PrimaryButton>
                        </a>
                    </ActionCont>
                </InnerModCont>
            </ModCont>
        )
    )
}

export default SubscriptionNeededModal
