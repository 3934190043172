import '../../components/auth/auth.scss'

import React from 'react'
import styled from 'styled-components'

import ContentContainer from '../../components/atoms/layout/ContentContainer'
import FormContainer from '../../components/atoms/layout/FormContainer'
import LogInForm from '../../components/organisms/forms/LoginForm'
import { BackgroundType, ContentAlign } from '../../data/enums/BackgroundType'

const SmallContainer = styled.div`
    transform: scale(0.9);
`

const LogInPage = (props) => {
    const width = props.windowWidth || 500

    if (width < 340) {
        return (
            <SmallContainer>
                <LogInForm viewPort={'SMALL'} />
            </SmallContainer>
        )
    } else if (width < 428) {
        return <LogInForm viewPort={'MOBILE'} />
    } else {
        return (
            <ContentContainer backType={BackgroundType.BLUE} contentAlign={ContentAlign.CENTER}>
                <FormContainer>
                    <LogInForm viewPort={'DESKTOP'} />
                </FormContainer>
            </ContentContainer>
        )
    }
}

export default LogInPage
